import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { GET, DOWNLOAD } from '../../../../../util/data';

const Component = () => {
    const [raw, setRaw] = useState([]);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('project/shipment', {}, (res) => {
            if (active && res !== null) {
                setRaw(res);
            }
        });
    }, [active]);

    const _handleExcelDownload = () => {
        if (raw.length !== 0) {
            GET('shipment/excel/notice', {}, (res) => {
                if (active && res !== null) {
                    alert.success('다운로드가 시작됩니다.');
                    DOWNLOAD(res.id);
                }
            });
        } else {
            alert.error('조회된 데이터가 없습니다.');
        }
    };

    return (
        <div className='container'>
            <table className='selector'>
                <tbody>
                    <tr className='high-tr'>
                        <th colSpan={5}>
                            Expected(Estimated) Delivery
                            <br />
                            (GII & WSA USA)
                        </th>
                    </tr>
                    <tr className='high-tr'>
                        <th width='20%'>WSA-USA Order No.</th>
                        <th width='20%'>Customer</th>
                        <th width='20%'>Order Date</th>
                        <th width='20%'>
                            WSA USA Required Ship Date
                            <br />
                            (WSA USA 요청 출하일)
                        </th>
                        <th width='20%'>
                            WSA KOREA Delivery Status Update
                            <br />
                            (WSA KOREA 출하 현황 업데이트)
                        </th>
                    </tr>
                    {raw.length !== 0 ? (
                        raw.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>{piece.orderNbr}</td>
                                <td className='center-td'>{piece.customer}</td>
                                <td className='center-td'>{piece.dateOfOrder}</td>
                                <td className='center-td'>{piece.dateOfDue}</td>
                                <td className='center-td'>
                                    {piece.shipment.dateOfShipment3 !== null && piece.shipment.dateOfShipment3 !== ''
                                        ? piece.shipment.dateOfShipment3
                                        : piece.shipment.dateOfShipment2 !== null &&
                                          piece.shipment.dateOfShipment2 !== ''
                                        ? piece.shipment.dateOfShipment2
                                        : piece.shipment.dateOfShipment1 !== null &&
                                          piece.shipment.dateOfShipment1 !== ''
                                        ? piece.shipment.dateOfShipment1
                                        : '미지정'}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={5}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <button className='printButton' onClick={_handleExcelDownload}>
                Excel 다운로드
            </button>
        </div>
    );
};

export default Component;
