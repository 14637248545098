import React from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';

import { PUT } from '../../../../../util/data';

const Component = ({ project, setProject }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const params = {
            id: project.shipment.id,
            dateOfShipment1: form.dateOfShipment1.value,
            contentOfShipment1: form.contentOfShipment1.value,
            dateOfShipment2: form.dateOfShipment2.value,
            contentOfShipment2: form.contentOfShipment2.value,
            dateOfShipment3: form.dateOfShipment3.value,
            contentOfShipment3: form.contentOfShipment3.value,
            dateOfPacking: form.dateOfPacking.value,
            dateOfShipment: form.dateOfShipment.value,
            dateOfLoad: form.dateOfLoad.value,
            dateOfArrival: form.dateOfArrival.value,
            note: form.note.value,
        };

        PUT('shipment', params, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    const _handleComplete = () => {
        PUT('project/complete', { id: project.id }, (res) => {
            setProject((project) => {
                let updated = { ...project };
                updated.status = updated.status === 'CURRENT' ? 'DONE' : 'CURRENT';
                return updated;
            });
            alert.success(res);
        });
    };

    return (
        <div>
            <ProjectViewer project={project} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th>차수</th>
                            <th>출하일자</th>
                            <th colSpan={2}>출하내용</th>
                        </tr>
                        <tr>
                            <th>1차</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfShipment1'
                                    defaultValue={project.shipment.dateOfShipment1}
                                />
                            </td>
                            <td colSpan={2}>
                                <textarea
                                    name='contentOfShipment1'
                                    defaultValue={project.shipment.contentOfShipment1}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>2차</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfShipment2'
                                    defaultValue={project.shipment.dateOfShipment2}
                                />
                            </td>
                            <td colSpan={2}>
                                <textarea
                                    name='contentOfShipment2'
                                    defaultValue={project.shipment.contentOfShipment2}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>3차</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfShipment3'
                                    defaultValue={project.shipment.dateOfShipment3}
                                />
                            </td>
                            <td colSpan={2}>
                                <textarea
                                    name='contentOfShipment3'
                                    defaultValue={project.shipment.contentOfShipment3}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th width='25%'>포장일</th>
                            <th width='25%'>출하일</th>
                            <th width='25%'>선적일</th>
                            <th width='25%'>도착일</th>
                        </tr>
                        <tr>
                            <td>
                                <input type='date' name='dateOfPacking' defaultValue={project.shipment.dateOfPacking} />
                            </td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfShipment'
                                    defaultValue={project.shipment.dateOfShipment}
                                />
                            </td>
                            <td>
                                <input type='date' name='dateOfLoad' defaultValue={project.shipment.dateOfLoad} />
                            </td>
                            <td>
                                <input type='date' name='dateOfArrival' defaultValue={project.shipment.dateOfArrival} />
                            </td>
                        </tr>
                        <tr>
                            <th>비고</th>
                            <td colSpan={3}>
                                <textarea className='wide-textarea' name='note' defaultValue={project.shipment.note} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
                <button type='button' className='printButton' onClick={_handleComplete}>
                    {project.status === 'CURRENT' ? '프로젝트 완료' : '프로젝트 진행'}
                </button>
            </form>
        </div>
    );
};

export default Component;
