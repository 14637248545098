import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { GET, DELETE } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

const Component = ({ element, setElement }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 20,
        keyword: '',
    });
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('purchase/material/all', data, (res) => {
            if (active && res !== null) {
                setRaw(res);
            }
        });
    }, [element, data, active]);

    const _handleRequest = (piece) => {
        setElement(piece);
    };

    const _handleRegister = () => {
        setElement({
            id: -1,
        });
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        setData(updated);
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');
                        let arr = [];
                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }
                        if (arr.length > 0) {
                            DELETE('purchase/material/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setData((data) => {
                                        let changedData = { ...data };
                                        changedData.detector = changedData.detector++;
                                        return changedData;
                                    });
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={9}>
                        <button type='button' onClick={_handleRegister}>
                            신규등록
                        </button>
                        <button type='button' onClick={_handleDeleteAll}>
                            선택삭제
                        </button>
                        <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                        <button type='button' onClick={_handleKeyword}>
                            검색
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th width='5%'>
                        <input type='checkbox' onClick={_handleSelectAll} />
                    </th>
                    <th width='10%'>자재유형</th>
                    <th width='10%'>분류코드</th>
                    <th width='15%'>품명</th>
                    <th width='15%'>재질</th>
                    <th width='15%'>규격</th>
                    <th width='10%'>단위</th>
                    <th width='10%'>크기</th>
                    <th width='10%'>관리등급</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr key={index}>
                        <td className='center-td'>
                            <input type='checkbox' name='list[]' value={piece.id} />
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.type === 'STEEL' ? '철강자재' : ''}
                            {piece.type === 'ELECTRICITY' ? '전기자재' : ''}
                            {piece.type === 'A' ? '공압품' : ''}
                            {piece.type === 'B' ? '유압품' : ''}
                            {piece.type === 'C' ? '베어링제품' : ''}
                            {piece.type === 'D' ? '플라스틱벨트' : ''}
                            {piece.type === 'E' ? '특수벨트' : ''}
                            {piece.type === 'F' ? '모터류' : ''}
                            {piece.type === 'G' ? '전장품' : ''}
                            {piece.type === 'K' ? '센서류' : ''}
                            {piece.type === 'N' ? '기타 기계류' : ''}
                            {piece.type === 'ETC' ? '기타 자재' : ''}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.code}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.name}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.material}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.specification}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.unit}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.size}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.grade}
                        </td>
                    </tr>
                ))}
                {data.size > raw.length
                    ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                          <tr key={index}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      ))
                    : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={9}>
                        <button type='button' onClick={() => _handlePage(0)}>
                            이전
                        </button>
                        <button type='reset'>{data.page + 1}</button>
                        <button type='button' onClick={() => _handlePage(1)}>
                            다음
                        </button>
                        <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                            <option value={10}>10개 데이터 조회</option>
                            <option value={20}>20개 데이터 조회</option>
                            <option value={40}>40개 데이터 조회</option>
                            <option value={60}>60개 데이터 조회</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
