import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { user_data } from '../../../actions';

import PopHeader from '../PopHeader';
import PopFooter from '../PopFooter';

import PopApp from '../PopApp';

import { AUTH_CHECK } from '../../../util/data';

import './index.css';

const Component = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        AUTH_CHECK((res) => {
            if (res !== false) {
                dispatch(user_data(res));
            }
        });
    }, [dispatch]);

    return (
        <div className='pop-core'>
            <PopHeader />
            <PopApp process={user.authority.pipe ? 'pipe' : user.authority.mct ? 'mct' : 'cnc'} />
            <PopFooter />
        </div>
    );
};

export default Component;
