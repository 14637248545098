import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { PUT } from '../../../../util/data';

const Component = ({ project, setProject }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].manufactureWork.id,
                dateOfReceipt: form.dateOfReceipt.value,
                dateOfStartOfManufacture1: form.dateOfStartOfManufacture1.value,
                progressOfManufacture1: form.progressOfManufacture1.value,
                dateOfFinishOfManufacture1: form.dateOfFinishOfManufacture1.value,
                dateOfStartOfManufacture2: form.dateOfStartOfManufacture2.value,
                progressOfManufacture2: form.progressOfManufacture2.value,
                dateOfFinishOfManufacture2: form.dateOfFinishOfManufacture2.value,
                woodWork: form.woodWork.value,
                dateOfFinishOfWoodWork: form.dateOfFinishOfWoodWork.value,
                canningTeam: form.canningTeam.value,
                dateOfFinishOfCanning: form.dateOfFinishOfCanning.value,
                dateOfFinishOfPainting: form.dateOfFinishOfPainting.value,
                note: form.note.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].manufactureWork.id,
                    dateOfReceipt: form.dateOfReceipt[i].value,
                    dateOfStartOfManufacture1: form.dateOfStartOfManufacture1[i].value,
                    progressOfManufacture1: form.progressOfManufacture1[i].value,
                    dateOfFinishOfManufacture1: form.dateOfFinishOfManufacture1[i].value,
                    dateOfStartOfManufacture2: form.dateOfStartOfManufacture2[i].value,
                    progressOfManufacture2: form.progressOfManufacture2[i].value,
                    dateOfFinishOfManufacture2: form.dateOfFinishOfManufacture2[i].value,
                    woodWork: form.woodWork[i].value,
                    dateOfFinishOfWoodWork: form.dateOfFinishOfWoodWork[i].value,
                    canningTeam: form.canningTeam[i].value,
                    dateOfFinishOfCanning: form.dateOfFinishOfCanning[i].value,
                    dateOfFinishOfPainting: form.dateOfFinishOfPainting[i].value,
                    note: form.note[i].value,
                });
            }
        }

        PUT('manufacture/work', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form fixed'>
                    <tbody>
                        <tr>
                            <th rowSpan={3} width='5%'>
                                No.
                            </th>
                            <th rowSpan={3} width='30%'>
                                아이템명
                                <br />
                                규격
                                <br />
                                수량 / 단위
                            </th>
                            <th rowSpan={3} width='9%'>
                                접수일
                            </th>
                            <th colSpan={2}>가공 1팀</th>
                            <th colSpan={2}>가공 2팀</th>
                            <th width='9%'>Wood 작업</th>
                            <th width='9%'>제관 작업</th>
                            <th rowSpan={3} width='9%'>
                                도장 작업
                                <br />
                                완료일
                            </th>
                            <th rowSpan={3} width='10%'>
                                이슈사항
                            </th>
                        </tr>
                        <tr>
                            <th width='9%'>시작일</th>
                            <th width='4.5%' rowSpan={2}>
                                진도
                            </th>
                            <th width='9%'>시작일</th>
                            <th width='4.5%' rowSpan={2}>
                                진도
                            </th>
                            <th>유/무</th>
                            <th>작업팀</th>
                        </tr>
                        <tr>
                            <th>완료일</th>
                            <th>완료일</th>
                            <th>완료일</th>
                            <th>완료일</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <Fragment key={index}>
                                <tr>
                                    <td rowSpan={2} className='center-td'>{piece.nbr}</td>
                                    <td rowSpan={2} className='center-td'>
                                        {piece.itemFamily !== null ? piece.itemFamily.name : piece.name}
                                        <br />
                                        {piece.specification === '' ? '규격없음' : piece.specification}
                                        <br />
                                        {piece.unit === '' ? '단위없음' : piece.unit} /{' '}
                                        {piece.quantity === '' ? '수량없음' : piece.quantity}
                                    </td>
                                    <td rowSpan={2}>
                                        <input
                                            type='date'
                                            name='dateOfReceipt'
                                            defaultValue={piece.manufactureWork.dateOfReceipt}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfStartOfManufacture1'
                                            defaultValue={piece.manufactureWork.dateOfStartOfManufacture1}
                                        />
                                    </td>
                                    <td rowSpan={2}>
                                        <select
                                            name='progressOfManufacture1'
                                            defaultValue={piece.manufactureWork.progressOfManufacture1}
                                        >
                                            <option value={0}>0</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={25}>25</option>
                                            <option value={30}>30</option>
                                            <option value={35}>35</option>
                                            <option value={40}>40</option>
                                            <option value={45}>45</option>
                                            <option value={50}>50</option>
                                            <option value={55}>55</option>
                                            <option value={60}>60</option>
                                            <option value={65}>65</option>
                                            <option value={70}>70</option>
                                            <option value={75}>75</option>
                                            <option value={80}>80</option>
                                            <option value={85}>85</option>
                                            <option value={90}>90</option>
                                            <option value={95}>95</option>
                                            <option value={100}>100</option>
                                            <option value={-100}>불필요</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfStartOfManufacture2'
                                            defaultValue={piece.manufactureWork.dateOfStartOfManufacture2}
                                        />
                                    </td>
                                    <td rowSpan={2}>
                                        <select
                                            name='progressOfManufacture2'
                                            defaultValue={piece.manufactureWork.progressOfManufacture2}
                                        >
                                            <option value={0}>0</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={25}>25</option>
                                            <option value={30}>30</option>
                                            <option value={35}>35</option>
                                            <option value={40}>40</option>
                                            <option value={45}>45</option>
                                            <option value={50}>50</option>
                                            <option value={55}>55</option>
                                            <option value={60}>60</option>
                                            <option value={65}>65</option>
                                            <option value={70}>70</option>
                                            <option value={75}>75</option>
                                            <option value={80}>80</option>
                                            <option value={85}>85</option>
                                            <option value={90}>90</option>
                                            <option value={95}>95</option>
                                            <option value={100}>100</option>
                                            <option value={-100}>불필요</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select name='woodWork' defaultValue={piece.manufactureWork.woodWork}>
                                            <option value='NONE'>미지정</option>
                                            <option value='TRUE'>유</option>
                                            <option value='FALSE'>무</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select name='canningTeam' defaultValue={piece.manufactureWork.canningTeam}>
                                            <option value='NONE'>미지정</option>
                                            <option value='ONE'>1팀</option>
                                            <option value='TWO'>2팀</option>
                                            <option value='THREE'>3팀</option>
                                            <option value='FOUR'>4팀</option>
                                            <option value='ETC'>기타</option>
                                        </select>
                                    </td>
                                    <td rowSpan={2}>
                                        <input
                                            type='date'
                                            name='dateOfFinishOfPainting'
                                            defaultValue={piece.manufactureWork.dateOfFinishOfPainting}
                                        />
                                    </td>
                                    <td rowSpan={2}>
                                        <textarea name='note' defaultValue={piece.manufactureWork.note} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinishOfManufacture1'
                                            defaultValue={piece.manufactureWork.dateOfFinishOfManufacture1}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinishOfManufacture2'
                                            defaultValue={piece.manufactureWork.dateOfFinishOfManufacture2}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinishOfWoodWork'
                                            defaultValue={piece.manufactureWork.dateOfFinishOfWoodWork}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinishOfCanning'
                                            defaultValue={piece.manufactureWork.dateOfFinishOfCanning}
                                        />
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
