// Menu Configuration
export const menu = [
    {
        title: '현황관리',
        path: '/status',
        redirect: '/status/project',
        sub: [
            {
                title: '현황관리',
                isHeader: true,
            },
            {
                title: '프로젝트 추진 현황',
                path: '/status/project',
            },
            // {
            //     title: '부서별 작업 현황',
            //     path: '/status/department',
            // },
            {
                title: '프로젝트 결재',
                path: '/status/approval',
            },
            {
                title: '공지사항',
                path: '/status/notice',
            },
        ],
        isAdmin: false,
    },
    {
        title: '영업관리',
        path: '/sales',
        redirect: '/sales/project/product',
        sub: [
            {
                title: '프로젝트 관리',
                isHeader: true,
            },
            {
                title: '완성품 프로젝트',
                path: '/sales/project/product',
            },
            {
                title: '부품 프로젝트',
                path: '/sales/project/part',
            },
            {
                title: 'A/S 프로젝트',
                path: '/sales/project/as',
            },
            {
                title: '출하 관리',
                isHeader: true,
            },
            {
                title: '출하현황 관리',
                path: '/sales/shipment/status',
            },
            {
                title: '출하일정 등록',
                path: '/sales/shipment/schedule',
            },
            {
                title: '출하일정 통보',
                path: '/sales/shipment/notice',
            },
        ],
        isAdmin: false,
    },
    // {
    //     title: '생산관리',
    //     path: '/production',
    //     redirect: '/production/planning',
    //     sub: [
    //         {
    //             title: '생산계획',
    //             isHeader: true,
    //         },
    //         {
    //             title: '일정계획 수립',
    //             path: '/production/planning',
    //         },
    //         {
    //             title: '일정계획 현황',
    //             path: '/production/status',
    //         },
    //     ],
    //     isAdmin: false,
    // },
    {
        title: '설계관리',
        path: '/design',
        redirect: '/design/planning/schedule',
        sub: [
            {
                title: '설계계획',
                isHeader: true,
            },
            {
                title: '설계계획 관리',
                path: '/design/planning/schedule',
            },
            {
                title: '설계작업 현황보고서',
                path: '/design/planning/report',
            },
            {
                title: '설계작업 모니터링',
                path: '/design/planning/monitoring',
            },
            {
                title: '설계 및 검도 작업관리',
                isHeader: true,
            },
            {
                title: '설계 작업관리',
                path: '/design/work/design',
            },
            {
                title: '검도 작업관리',
                path: '/design/work/check',
            },
            {
                title: 'BOM 관리',
                isHeader: true,
            },
            {
                title: 'Part List 관리',
                path: '/design/bom/partlist',
            },
            // {
            //     title: 'BOM 발행',
            //     path: '/design/bom/bom',
            // },
            {
                title: '구매분류코드 관리',
                path: '/design/bom/productCode',
            },
            {
                title: 'PDM 연동 (사내망 전용)',
                isHeader: true,
            },
            {
                title: 'PDM 데이터 불러오기',
                path: '/design/pdm/pdm',
            },
        ],
        isAdmin: false,
    },
    // New Version
    {
        title: '구매관리',
        path: '/purchase',
        redirect: '/purchase/state/monitoring',
        sub: [
            {
                title: '현황관리',
                isHeader: true,
            },
            {
                title: '자재구매 관리현황',
                path: '/purchase/state/monitoring',
            },
            {
                title: '리포트 관리',
                path: '/purchase/state/report',
            },
            {
                title: '신규등록 알림',
                path: '/purchase/state/alert',
            },
            {
                title: '자재원부',
                isHeader: true,
            },
            {
                title: '자재원부관리',
                path: '/purchase/material',
            },
            {
                title: '구매의뢰',
                isHeader: true,
            },
            {
                title: 'BOM 조회',
                path: '/purchase/request/bom',
            },
            {
                title: '구매 의뢰',
                path: '/purchase/request',
            },
            {
                title: '구매발주',
                isHeader: true,
            },
            {
                title: '구매 리스트 작성',
                path: '/purchase/order/listing',
            },
            {
                title: '견적 의뢰',
                path: '/purchase/order/quotation',
            },
            {
                title: '발주 의뢰',
                path: '/purchase/order',
            },
            {
                title: '수불관리',
                isHeader: true,
            },
            {
                title: '일괄 입출고',
                path: '/purchase/io/bundle',
            },
            {
                title: '자재 청구 및 반납',
                path: '/purchase/io/request',
            },
            {
                title: '개별 입출고',
                path: '/purchase/io/each',
            },
            {
                title: '재고관리',
                isHeader: true,
            },
            {
                title: '가공품 재고관리',
                path: '/purchase/inventory/manufacture',
            },
            {
                title: '구매품 재고관리',
                path: '/purchase/inventory/purchase',
            },
        ],
        isAdmin: false,
    },
    // Old Version
    // {
    //     title: '구매관리',
    //     path: '/purchase',
    //     redirect: '/purchase/info',
    //     sub: [
    //         {
    //             title: '구매관리',
    //             isHeader: true,
    //         },
    //         {
    //             title: '자재납품관리',
    //             path: '/purchase/info',
    //         },
    //     ],
    //     isAdmin: false,
    // },
    {
        title: '기계가공',
        path: '/manufacture',
        redirect: '/manufacture/laser',
        sub: [
            {
                title: '기계가공',
                isHeader: true,
            },
            // {
            //     title: '작업현황 관리',
            //     path: '/manufacture/status',
            // },
            {
                title: 'Laser 작업관리',
                path: '/manufacture/laser',
            },
            // {
            //     title: '작업수배',
            //     path: '/manufacture/assignment',
            // },
            // {
            //     title: '작업지시',
            //     path: '/manufacture/order',
            // },
            // {
            //     title: '작업실적 관리',
            //     path: '/manufacture/performance',
            // },
            {
                title: '기계가공 작업관리',
                path: '/manufacture/work',
            },
            // {
            //     title: '부품 재고관리',
            //     path: '/manufacture/inventory',
            // },
        ],
        isAdmin: false,
    },
    {
        title: '기계조립',
        path: '/assembly',
        redirect: '/assembly/work',
        sub: [
            {
                title: '기계조립',
                isHeader: true,
            },
            // {
            //     title: '작업지시',
            //     path: '/assembly/order',
            // },
            {
                title: '작업관리',
                path: '/assembly/work',
            },
            // {
            //     title: '작업결과 입력',
            //     path: '/assembly/performance',
            // },
            {
                title: '설치',
                isHeader: true,
            },
            {
                title: '설치작업보고서',
                path: '/assembly/install/report',
            },
        ],
        isAdmin: false,
    },
    {
        title: '전기제어',
        path: '/control',
        redirect: '/control/system',
        sub: [
            {
                title: '전기제어',
                isHeader: true,
            },
            {
                title: '제어시스템 등록',
                path: '/control/system',
            },
            {
                title: '제어시스템 관리',
                path: '/control/work',
            },
        ],
        isAdmin: false,
    },
    {
        title: '혁신',
        path: '/quality',
        redirect: '/quality/control/order',
        sub: [
            {
                title: '혁신',
                isHeader: true,
            },
            {
                title: '작업지시',
                path: '/quality/control/order',
            },
            {
                title: '작업관리',
                path: '/quality/control/work',
            },
            // {
            //     title: '후처리 작업결과 입력',
            //     path: '/quality/control/performance',
            // },
            {
                title: '부적합',
                isHeader: true,
            },
            {
                title: '부적합 관리',
                path: '/quality/unconformity/receipt',
            },
            {
                title: '부적합 모니터링',
                path: '/quality/unconformity/monitoring',
            },
            // {
            //     title: '부적합 처리',
            //     path: '/quality/unconformity/work',
            // },
        ],
        isAdmin: false,
    },
    {
        title: 'AS관리',
        path: '/as',
        redirect: '/as/receipt',
        sub: [
            {
                title: 'A/S관리',
                isHeader: true,
            },
            {
                title: 'A/S 조회',
                path: '/as/receipt',
            },
            {
                title: 'A/S 조치',
                path: '/as/result',
            },
        ],
        isAdmin: false,
    },
    {
        title: '이력관리',
        path: '/history',
        redirect: '/history/project',
        sub: [
            {
                title: '이력관리',
                isHeader: true,
            },
            {
                title: '프로젝트 이력관리',
                path: '/history/project',
            },
        ],
        isAdmin: false,
    },
    {
        title: '관리자',
        path: '/admin',
        redirect: '/admin/user',
        sub: [
            {
                title: '환경설정',
                isHeader: true,
            },
            {
                title: '사용자 관리',
                path: '/admin/user',
            },
            {
                title: '설계팀 관리',
                path: '/admin/design',
            },
            {
                title: '공지사항 관리',
                path: '/admin/notice',
            },
        ],
        isAdmin: true,
    },
];
