import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import FromBOM from '../../util/FromBOM';
import FromMaterial from '../../util/FromMaterial';

import { GET, POST, PUT, DOWNLOAD } from '../../../../../util/data';

const Component = ({ element, setElement }) => {
    const [source, setSource] = useState('');
    const [userRaw, setUserRaw] = useState([]);

    const [list, setList] = useState(element.id === -1 ? [] : element.purchaseDetails);

    const [type, setType] = useState(element.id === -1 ? 'STEEL' : element.type);
    const [done, setDone] = useState(false);
    const alert = useAlert();

    const user = useSelector((state) => state.user);

    // Project & Item Setting Init
    const [projectId, setProjectId] = useState(
        element.id === -1 ? -1 : element.project === null ? -1 : element.project.id
    );
    const [itemId, setItemId] = useState(element.id === -1 ? -1 : element.item === null ? -1 : element.item.id);
    const [projectRaw, setProjectRaw] = useState([]);
    const [itemRaw, setItemRaw] = useState([]);
    const [projectKeyword, setProjectKeyword] = useState('');
    const [itemKeyword, setItemKeyword] = useState('');

    useEffect(() => {
        GET('project/only', {}, (res) => {
            setProjectRaw(res.filter((piece) => piece.status === 'CURRENT'));
        });
    }, []);

    useEffect(() => {
        if (projectId !== -1) {
            GET('item/only', { id: projectId }, (res) => {
                setItemRaw(res);
            });
        }
    }, [projectId]);
    // Project & Item Setting End

    useEffect(() => {
        setSource('');
    }, [type]);

    useEffect(() => {
        setList(element.id === -1 ? [] : element.purchaseDetails);
        setUserRaw([]);
        GET('user/all', {}, (res) => {
            setUserRaw(res);
        });
    }, [element, type]);

    const _handleForm = (e) => {
        e.preventDefault();

        if (source !== '') {
            alert.error('불러오기 모드가 실행 중입니다.');
            return;
        }

        if (element.id > -1 || (element.id === -1 && !done)) {
            const func = element.id === -1 ? POST : PUT;

            const form = document.activeForm;

            let arr = [];

            if (list.length === 1) {
                arr.push({
                    id: list[0].id,
                    partListDescription: list[0].partListDescription,
                    purchaseMaterial: list[0].purchaseMaterial,
                    status: list[0].status,

                    unit: form.unit.value,
                    quantityOfRequest: form.quantityOfRequest.value,
                    supplier: form.supplier.value,
                    receiver: form.receiver.value,
                    note: form.note.value,
                });
            } else {
                for (let i = 0; i < list.length; i++) {
                    arr.push({
                        id: list[i].id,
                        partListDescription: list[i].partListDescription,
                        purchaseMaterial: list[i].purchaseMaterial,
                        status: list[i].status,

                        unit: form.unit[i].value,
                        quantityOfRequest: form.quantityOfRequest[i].value,
                        supplier: form.supplier[i].value,
                        receiver: form.receiver[i].value,
                        note: form.note[i].value,
                    });
                }
            }

            let params = {
                project: projectId === -1 ? null : { id: projectId },
                item: itemId === -1 ? null : { id: itemId },
                type: type,
                status: 'REQUEST',
                initStage: 'REQUEST',
                purchaseRequest: {
                    dateOfRequest: form.dateOfRequest.value,
                    drafter: form.drafter.value,
                    approval: type === 'STEEL' ? '남세현' : type === 'ELECTRICITY' ? '황광수' : '정찬영',
                },
                purchaseListing: null,
                purchaseQuotation: null,
                purchaseOrder: null,
                purchaseDetails: arr,
            };

            if (element.id !== -1) {
                params.id = element.id;
                params.purchaseRequest.id = element.purchaseRequest.id;
            }

            setDone(true);

            func('purchase', params, (res1) => {
                if (res1 !== null) {
                    if (element.id !== -1) {
                        GET('purchase', { id: element.id }, (res2) => {
                            setElement(res2);
                            setList(res2.purchaseDetails);
                        });
                    }
                    alert.success(res1);
                }
            });
        }
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _deleteList = () => {
        const checkbox = document.getElementsByName('list[]');

        setList((list) => {
            let updatedList = [...list];

            for (let i = checkbox.length - 1; i >= 0; i--) {
                if (checkbox[i].checked === true) {
                    updatedList[i].status = 'DELETE';
                    checkbox[i].checked = false;
                }
            }

            return updatedList;
        });
    };

    const _handleDownload = () => {
        const data = {
            id: element.id,
        };

        GET('purchase/excel/request', data, (res) => {
            if (res !== null) {
                alert.success('다운로드가 시작됩니다.');
                DOWNLOAD(res.id);
            }
        });
    };

    const pad = (n, width) => {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    };

    return (
        <Fragment>
            <form name='activeForm' onSubmit={_handleForm}>
                <table className='form'>
                    {element.id !== -1 ? (
                        <thead>
                            <tr>
                                <td colSpan={4}>
                                    <button type='button' onClick={_handleDownload}>
                                        출력
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    ) : null}
                    <tbody>
                        <tr>
                            <th colSpan={4}>기본 정보</th>
                        </tr>
                        <tr>
                            <th width='15%'>청구번호</th>
                            <td width='35%' className='center-td'>
                                <input
                                    type='text'
                                    defaultValue={
                                        element.id !== -1
                                            ? `${element.numberOfYear}-${
                                                  element.type === 'STEEL'
                                                      ? '철강'
                                                      : element.type === 'ELECTRICITY'
                                                      ? '전기'
                                                      : element.type === 'ETC'
                                                      ? '기타'
                                                      : element.type
                                              }-${pad(element.numberOfPurchase, 4)}`
                                            : '자동생성'
                                    }
                                    disabled
                                />
                            </td>
                            <th width='15%'>구매유형</th>
                            <td width='35%' className='center-td'>
                                <select name='type' value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value='STEEL'>철강자재</option>
                                    <option value='ELECTRICITY'>전기자재</option>
                                    <option value='ETC'>기타 구매</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th rowSpan={2}>프로젝트</th>
                            <td>
                                <select
                                    value={projectId}
                                    onChange={(e) => {
                                        setProjectId(Number(e.target.value));
                                        setItemId(-1);
                                    }}
                                >
                                    <option value={-1}>프로젝트 선택</option>
                                    {projectRaw
                                        .filter((piece) => {
                                            try {
                                                if (
                                                    piece.code.toUpperCase().match(projectKeyword.toUpperCase()) ||
                                                    piece.name.toUpperCase().match(projectKeyword.toUpperCase())
                                                ) {
                                                    return true;
                                                }
                                            } catch {
                                                return false;
                                            }
                                            return false;
                                        })
                                        .map((piece, index) => (
                                            <option value={piece.id} key={index}>
                                                {piece.code} ({piece.name})
                                            </option>
                                        ))}
                                </select>
                            </td>
                            <th rowSpan={2}>아이템</th>
                            <td>
                                <select value={itemId} onChange={(e) => setItemId(Number(e.target.value))}>
                                    <option value={-1}>아이템 선택</option>
                                    {itemRaw
                                        .filter((piece) => {
                                            try {
                                                if (piece.itemFamily !== null) {
                                                    if (
                                                        piece.itemFamily.name
                                                            .toUpperCase()
                                                            .match(itemKeyword.toUpperCase())
                                                    ) {
                                                        return true;
                                                    }
                                                } else {
                                                    if (piece.name.toUpperCase().match(itemKeyword.toUpperCase())) {
                                                        return true;
                                                    }
                                                }
                                            } catch {
                                                return false;
                                            }
                                            return false;
                                        })
                                        .map((piece, index) => (
                                            <option
                                                value={piece.id}
                                                key={index}
                                                /*disabled={piece.partList.status === 'DONE' ? false : true}*/
                                            >
                                                [{piece.nbr}]{' '}
                                                {piece.itemFamily !== null ? piece.itemFamily.name : piece.name} (
                                                {piece.specification})
                                                {piece.partList.status !== 'DONE' ? ' (작성미완료)' : ''}
                                            </option>
                                        ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type='text'
                                    value={projectKeyword}
                                    onChange={(e) => {
                                        setProjectKeyword(e.target.value);
                                        setProjectId(-1);
                                        setItemId(-1);
                                    }}
                                    placeholder='검색어'
                                />
                            </td>
                            <td>
                                <input
                                    type='text'
                                    value={itemKeyword}
                                    onChange={(e) => {
                                        setItemKeyword(e.target.value);
                                        setItemId(-1);
                                    }}
                                    placeholder='검색어'
                                />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={4}>구매 의뢰 정보</th>
                        </tr>
                        <tr>
                            <th>구매의뢰일</th>
                            <td colSpan={3}>
                                <input
                                    type='date'
                                    name='dateOfRequest'
                                    defaultValue={
                                        element.id !== -1
                                            ? element.purchaseRequest.dateOfRequest
                                            : new Date().toISOString().substr(0, 10)
                                    }
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td>
                                {userRaw.length !== 0 ? (
                                    <select
                                        name='drafter'
                                        defaultValue={
                                            userRaw
                                                .filter((piece) =>
                                                    type === 'STEEL'
                                                        ? piece.authority.manufacture2
                                                        : type === 'ELECTRICITY'
                                                        ? piece.authority.assembly
                                                        : piece.authority.purchase
                                                )
                                                .findIndex((piece) => piece.name === user.name) !== -1
                                                ? user.name
                                                : ''
                                        }
                                        required
                                    >
                                        <option value=''>선택</option>
                                        {userRaw
                                            .filter((piece) =>
                                                type === 'STEEL'
                                                    ? piece.authority.manufacture2
                                                    : type === 'ELECTRICITY'
                                                    ? piece.authority.assembly
                                                    : piece.authority.purchase
                                            )
                                            .map((piece, index) => (
                                                <option key={index}>{piece.name}</option>
                                            ))}
                                    </select>
                                ) : null}
                            </td>
                            <th>승인자</th>
                            <td>
                                <input
                                    type='text'
                                    value={type === 'STEEL' ? '남세현' : type === 'ELECTRICITY' ? '황광수' : '정찬영'}
                                    disabled
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {source === '' ? (
                    <table className='form'>
                        <thead>
                            <tr>
                                <td colSpan={10}>
                                    <button type='button' onClick={_deleteList}>
                                        선택삭제
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setSource('MATERIAL');
                                        }}
                                    >
                                        자재원부로부터 불러오기
                                    </button>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th width='5%'>
                                    <input type='checkbox' onClick={_handleSelectAll} />
                                </th>
                                <th width='15%'>분류번호</th>
                                <th width='15%'>품명</th>
                                <th width='10%'>규격</th>
                                <th width='5%'>Size</th>
                                <th width='5%'>단위</th>

                                <th width='5%'>수량</th>
                                <th width='10%'>구매처</th>
                                <th width='10%'>입고처</th>
                                <th width='20%'>비고</th>
                            </tr>
                            {list.map((piece, index) => (
                                <tr key={index} style={{ display: piece.status === 'DELETE' ? 'none' : null }}>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='list[]'
                                            value={
                                                piece.partListDescription !== null
                                                    ? piece.partListDescription.id
                                                    : piece.purchaseMaterial.id
                                            }
                                        />
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.partsNbr
                                            : piece.purchaseMaterial.code}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.description
                                            : piece.purchaseMaterial.name}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.specification
                                            : piece.purchaseMaterial.specification}
                                    </td>
                                    <td className='center-td'>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.size
                                            : piece.purchaseMaterial.size}
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' name='unit' defaultValue={piece.unit} />
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfRequest'
                                            defaultValue={piece.quantityOfRequest}
                                        />
                                    </td>
                                    <td>
                                        <input type='text' name='supplier' defaultValue={piece.supplier} />
                                    </td>
                                    <td>
                                        <input type='text' name='receiver' defaultValue={piece.receiver} />
                                    </td>
                                    <td>
                                        <input type='text' name='note' defaultValue={piece.note} />
                                    </td>
                                </tr>
                            ))}
                            {list.filter((piece) => piece.status !== 'DELETE').length === 0 ? (
                                <tr>
                                    <td className='center-td' colSpan={10}>
                                        등록된 정보가 없습니다.
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                ) : null}
                {source === 'BOM' ? <FromBOM setSource={setSource} setList={setList} type={type} /> : null}
                {source === 'MATERIAL' ? <FromMaterial setSource={setSource} setList={setList} /> : null}
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
