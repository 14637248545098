import React, { useEffect, useState } from 'react';

import { GET, GET_WITH_PAGING } from '../../../../../util/data';

const Component = () => {
    const [designers, setDesigners] = useState([]);
    const [designer, setDesigner] = useState('');
    const [raw, setRaw] = useState([]);
    const [data] = useState({
        page: 0,
        size: 0,
        sort: 'type',
        order: 'DESC',
        type: 'ALL',
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });

    useEffect(() => {
        GET('user/all', {}, (res) => {
            setDesigners(res.filter((piece) => (piece.authority.designWork && !piece.resign ? piece : null)));
        });
    }, []);

    useEffect(() => {
        GET_WITH_PAGING('project/paging', data, (res) => {
            res = res.data.content;

            let arr = [];

            for (let i = 0; i < res.length; i++) {
                const project = res[i];
                for (let j = 0; j < project.items.length; j++) {
                    const item = project.items[j];

                    if (
                        item.designPlanning.status !== 'UNNECESSARY' &&
                        item.designPlanning.designer !== null &&
                        item.designPlanning.designer !== '' &&
                        item.designPlanning.dateOfFinish !== null &&
                        item.designPlanning.dateOfFinish !== '' &&
                        (item.designWork.dateOfFinish === null || item.designWork.dateOfFinish === '')
                    ) {
                        arr.push({
                            project: project,
                            item: item,
                            designPlanning: item.designPlanning,
                            designWork: item.designWork,
                        });
                    }
                }
            }
            setRaw(arr);
        });
    }, [data]);

    const _handleDateDifference = (value) => {
        const today = new Date();
        const target = new Date(value);

        const diff = ((target.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

        return Number(diff);
    };

    return (
        <div className='container'>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={10}>
                            <select value={designer} onChange={(e) => setDesigner(e.target.value)}>
                                <option value=''>전체 설계자</option>
                                {designers.map((piece, index) => (
                                    <option value={piece.id} key={index}>
                                        {piece.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='18%'>지시번호 (프로젝트명)</th>
                        <th width='5%'>No.</th>
                        <th width='20%'>아이템명</th>
                        <th width='5%'>설계자</th>
                        <th width='8%'>완료지시일</th>
                        <th width='8%'>자료접수일</th>
                        <th width='8%'>작업시작일</th>
                        <th width='8%'>완료예정일</th>
                        <th width='5%'>진도</th>
                        <th width='15%'>이슈사항</th>
                    </tr>
                    {raw.filter((piece) =>
                        designer !== '' ? piece.designPlanning.designer.id === Number(designer) : piece
                    ).length !== 0 ? (
                        raw
                            .filter((piece) =>
                                designer !== '' ? piece.designPlanning.designer.id === Number(designer) : piece
                            )
                            .sort((a, b) =>
                                a.designPlanning.dateOfFinish === null || b.designPlanning.dateOfFinish === null
                                    ? 0
                                    : a.designPlanning.dateOfFinish.localeCompare(b.designPlanning.dateOfFinish)
                            )
                            .map((piece, index) => (
                                <tr
                                    onClick={() =>
                                        (window.location.href = `/design/planning/schedule?p=${piece.project.id}`)
                                    }
                                    key={index}
                                >
                                    <td>
                                        {piece.project.code}
                                        <br />({piece.project.name})
                                    </td>
                                    <td className='center-td'>{piece.item.nbr}</td>
                                    <td>
                                        {piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}
                                    </td>
                                    <td className='center-td'>{piece.designPlanning.designer.name}</td>
                                    <td
                                        className={
                                            _handleDateDifference(piece.designPlanning.dateOfFinish) >= 0
                                                ? 'center-td status-safe'
                                                : 'center-td status-emergency'
                                        }
                                    >
                                        {piece.designPlanning.dateOfFinish}
                                    </td>
                                    <td className='center-td'>{piece.designWork.dateOfReceipt}</td>
                                    <td className='center-td'>{piece.designWork.dateOfStart}</td>
                                    <td className='center-td'>{piece.designWork.dateOfSchedule}</td>
                                    <td className='center-td'>{piece.designWork.progress}</td>
                                    <td>
                                        <textarea defaultValue={piece.designWork.note} readOnly />
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={10}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
