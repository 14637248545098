import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import AfterServiceViewer from '../../../ui/viewer/AfterServiceViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileUploader from '../../../ui/uploader/FileUploader';

import { PUT } from '../../../../util/data';

const Component = ({ afterService, setAfterService }) => {
    const [process, setProcess] = useState('COMMON');
    const [attachments, setAttachments] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        if (process === 'COMMON') {
            setAttachments([]);
        } else {
            setAttachments([
                ...afterService.afterServiceResult.afterServiceResultByDepartments.find(
                    (piece) => piece.department === process
                ).attachments,
            ]);
        }
    }, [process, afterService]);

    const _handleProcess = (e) => {
        setProcess(e.target.value);
    };

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let params;

        if (process === 'COMMON') {
            params = {
                id: afterService.afterServiceResult.id,
                paid: form.paid.value,
                cost: form.cost.value,
                charger: form.charger.value,
            };
        } else {
            params = {
                id: afterService.afterServiceResult.afterServiceResultByDepartments.find(
                    (piece) => piece.department === process
                ).id,
                department: process,
                cause: form.cause.value,
                action: form.action.value,
                attachments: attachments,
            };
        }

        PUT(process === 'COMMON' ? 'as/result' : 'as/result/department', params, (res) => {
            if (res !== null) {
                if (process === 'COMMON') {
                    setAfterService((afterService) => {
                        let updated = { ...afterService };
                        updated.afterServiceResult = {
                            ...params,
                            afterServiceResultByDepartments:
                                afterService.afterServiceResult.afterServiceResultByDepartments,
                        };
                        return updated;
                    });
                } else {
                    setAfterService((afterService) => {
                        let updated = { ...afterService };
                        updated.afterServiceResult.afterServiceResultByDepartments[
                            updated.afterServiceResult.afterServiceResultByDepartments.findIndex(
                                (piece) => piece.department === process
                            )
                        ] = params;
                        return updated;
                    });
                }
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <AfterServiceViewer afterService={afterService} />
            <FileDownloader project={afterService} />
            <ProjectViewer
                project={
                    afterService.project === null
                        ? { ...afterService.afterServiceProject, type: 'UNDEF', items: [] }
                        : afterService.project
                }
                showItemList={true}
            />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={process === 'COMMON' ? 6 : 4}>
                                <select name='process' value={process} onChange={_handleProcess}>
                                    <option value='COMMON'>공정 : 공통</option>
                                    <option value='DESIGN'>공정 : 설계</option>
                                    <option value='PURCHASE'>공정 : 구매</option>
                                    <option value='MANUFACTURE'>공정 : 가공</option>
                                    <option value='ASSEMBLY'>공정 : 조립</option>
                                    <option value='QUALITY'>공정 : 품질</option>
                                </select>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {process === 'COMMON' ? (
                            <tr>
                                <th width='13.3%'>유/무상</th>
                                <td width='20%'>
                                    <select name='paid' defaultValue={afterService.afterServiceResult.paid}>
                                        <option value={true}>유상</option>
                                        <option value={false}>무상</option>
                                    </select>
                                </td>
                                <th width='13.3%'>소요비용</th>
                                <td width='20%'>
                                    <input
                                        type='number'
                                        name='cost'
                                        defaultValue={afterService.afterServiceResult.cost}
                                    />
                                </td>
                                <th width='13.3%'>조치확인자</th>
                                <td width='20%'>
                                    <input
                                        type='text'
                                        name='charger'
                                        defaultValue={afterService.afterServiceResult.charger}
                                    />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <th width='15%'>원인분석</th>
                                <td width='35%'>
                                    {process === 'DESIGN' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='cause'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'DESIGN'
                                                ).cause
                                            }
                                        />
                                    ) : null}
                                    {process === 'PURCHASE' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='cause'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'PURCHASE'
                                                ).cause
                                            }
                                        />
                                    ) : null}
                                    {process === 'MANUFACTURE' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='cause'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'MANUFACTURE'
                                                ).cause
                                            }
                                        />
                                    ) : null}
                                    {process === 'ASSEMBLY' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='cause'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'ASSEMBLY'
                                                ).cause
                                            }
                                        />
                                    ) : null}
                                    {process === 'QUALITY' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='cause'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'QUALITY'
                                                ).cause
                                            }
                                        />
                                    ) : null}
                                </td>
                                <th width='15%'>조치사항</th>
                                <td width='35%'>
                                    {process === 'DESIGN' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='action'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'DESIGN'
                                                ).action
                                            }
                                        />
                                    ) : null}
                                    {process === 'PURCHASE' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='action'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'PURCHASE'
                                                ).action
                                            }
                                        />
                                    ) : null}
                                    {process === 'MANUFACTURE' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='action'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'MANUFACTURE'
                                                ).action
                                            }
                                        />
                                    ) : null}
                                    {process === 'ASSEMBLY' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='action'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'ASSEMBLY'
                                                ).action
                                            }
                                        />
                                    ) : null}
                                    {process === 'QUALITY' ? (
                                        <textarea
                                            className='wide-textarea'
                                            name='action'
                                            defaultValue={
                                                afterService.afterServiceResult.afterServiceResultByDepartments.find(
                                                    (piece) => piece.department === 'QUALITY'
                                                ).action
                                            }
                                        />
                                    ) : null}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {process !== 'COMMON' ? (
                    <FileUploader attachments={attachments} setAttachments={setAttachments} />
                ) : null}
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
