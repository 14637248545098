import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { GET } from '../../../../util/data';

const Component = ({ piece, setPiece }) => {
    const [raw, setRaw] = useState([]);

    const [data, setData] = useState({
        page: 0,
        size: 20,
        status: 'CURRENT',
        keyword: '',
    });
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('partlist/paging', data, (res) => {
            setRaw(res);
        });
    }, [piece, data, active]);

    const _handleSetProject = (piece) => {
        setPiece(piece);
    };

    const _handleStatus = (value) => {
        let updated = { ...data };
        updated.status = value;
        setData(updated);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        setData(updated);
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={7}>
                        <select value={data.status} onChange={(e) => _handleStatus(e.target.value)}>
                            <option value='CURRENT'>선택 ① : 작업진행 프로젝트</option>
                            <option value='DONE'>선택 ① : 작업완료 프로젝트</option>
                        </select>
                        <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                        <button type='button' onClick={_handleKeyword}>
                            검색
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th width='30%'>지시번호 (프로젝트명)</th>
                    <th width='25%'>아이템명</th>
                    <th width='15%'>규격</th>
                    <th width='5%'>단위</th>
                    <th width='5%'>수량</th>
                    <th width='10%'>설계자</th>
                    <th width='10%'>설계완료지시일</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr
                        className={
                            piece.item.partListMerge !== null && piece.item.partListMerge !== -1 ? 'disabled-tr' : null
                        }
                        key={index}
                        onClick={
                            piece.item.partListMerge === null || piece.item.partListMerge === -1
                                ? () => _handleSetProject(piece)
                                : () => alert.error('Part List가 병합된 아이템입니다.')
                        }
                    >
                        <td>
                            <input type='hidden' name='itemId' defaultValue={piece.item.id} />
                            {piece.project.code} ({piece.project.name})
                        </td>
                        <td>{piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}</td>
                        <td>{piece.item.specification}</td>
                        <td>{piece.item.unit}</td>
                        <td>{piece.item.quantity}</td>
                        <td>
                            {piece.item.designPlanning.designer !== null ? piece.item.designPlanning.designer.name : ''}
                        </td>
                        <td>{piece.item.designPlanning.dateOfFinish}</td>
                    </tr>
                ))}
                {data.size > raw.length
                    ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                          <tr key={index}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      ))
                    : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={7}>
                        <button type='button' onClick={() => _handlePage(0)}>
                            이전
                        </button>
                        <button type='reset'>{data.page + 1}</button>
                        <button type='button' onClick={() => _handlePage(1)}>
                            다음
                        </button>
                        <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                            <option value={10}>10개 데이터 조회</option>
                            <option value={20}>20개 데이터 조회</option>
                            <option value={40}>40개 데이터 조회</option>
                            <option value={60}>60개 데이터 조회</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
