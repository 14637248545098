import React, { Fragment, useState } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { PUT } from '../../../../util/data';

const Component = ({ project, setProject, type }) => {
    const [done, setDone] = useState(false);

    const alert = useAlert();

    const _handleApproval = (e) => {
        e.preventDefault();

        if (!done) {
            setDone(true);

            if (type === 'sales') {
                const params = {
                    id: project.id,
                };

                PUT('project/confirm', params, (res) => {
                    if (res !== null) {
                        alert.success(res);
                    }
                });
            } else {
                const params = {
                    id: project.confirmation.id,
                };

                PUT('confirmation', params, (res) => {
                    if (res !== null) {
                        alert.success(res);
                    }
                });
            }
        }
    };

    return (
        <Fragment>
            <table className='approval'>
                <tbody>
                    <tr className='narrow-tr'>
                        <td className='title-td' width='55%' rowSpan={2}>
                            작 업 지 시 서
                        </td>
                        <td width='5%' rowSpan={2}>
                            결재
                        </td>
                        <td width='10%'>작성</td>
                        <td width='10%'>검토</td>
                        <td width='10%'>팀장</td>
                        <td width='10%'>승인</td>
                    </tr>
                    <tr className='wide-tr'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <table className='approval'>
                <tbody>
                    <tr className='narrow-tr'>
                        <td width='5%' rowSpan={2}>
                            확인
                        </td>
                        <td width='10%'>설계</td>
                        <td width='10%'>전기/조립</td>
                        <td width='10%'>가공</td>
                        <td width='10%'>구매</td>
                        <td width='10%'>품질</td>
                        <td width='45%' rowSpan={2}></td>
                    </tr>
                    <tr className='wide-tr'>
                        <td>{project.confirmation.design}</td>
                        <td>{project.confirmation.assembly}</td>
                        <td>{project.confirmation.manufacture}</td>
                        <td>{project.confirmation.purchase}</td>
                        <td>{project.confirmation.quality}</td>
                    </tr>
                </tbody>
            </table>
            <ProjectViewer project={project} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleApproval}>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
