import React, { Fragment } from 'react';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

const Component = ({ project }) => {
    const ITEM_SIZE = 27.5;

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={true} />
            <FileDownloader project={project} />
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={8}>설계 정보</th>
                    </tr>
                    <tr>
                        <th width={`${ITEM_SIZE}%`}>아이템명</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계자료 접수일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계자</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계 진도</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>검도자</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>이슈사항</th>
                    </tr>
                    {project.items.map((piece, index) => (
                        <tr key={index}>
                            <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                            {piece.designPlanning.status === 'NORMAL' ? (
                                <Fragment>
                                    <td className='center-td'>{piece.designWork.dateOfReceipt}</td>
                                    <td className='center-td'>
                                        {piece.designPlanning.designer !== null
                                            ? piece.designPlanning.designer.name
                                            : ''}
                                    </td>
                                    <td className='center-td'>{piece.designWork.dateOfStart}</td>
                                    <td className='center-td'>{piece.designWork.progress}</td>
                                    <td className='center-td'>{piece.designWork.dateOfFinish}</td>
                                    <td className='center-td'>
                                        {piece.designPlanning.checker !== null ? piece.designPlanning.checker.name : ''}
                                    </td>
                                    <td className='center-td'>{piece.designWork.note}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={7}>
                                    작업 불필요
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={5}>품질 정보</th>
                    </tr>
                    <tr>
                        <th width={`${ITEM_SIZE}%`}>아이템명</th>
                        <th width={`${(100 - ITEM_SIZE) / 4}%`}>제관검사팀</th>
                        <th width={`${(100 - ITEM_SIZE) / 4}%`}>제관검사 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 4}%`}>도장검사 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 4}%`}>후처리검사 완료일</th>
                    </tr>
                    {project.items.map((piece, index) => (
                        <tr key={index}>
                            <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                            {piece.qualityOrder.statusOfCanning === 'NORMAL' ? (
                                <Fragment>
                                    <td className='center-td'>
                                        {piece.qualityWork.canningTeam === 'NONE'
                                            ? '미지정'
                                            : piece.qualityWork.canningTeam === 'ONE'
                                            ? '1팀'
                                            : piece.qualityWork.canningTeam === 'TWO'
                                            ? '2팀'
                                            : piece.qualityWork.canningTeam === 'THREE'
                                            ? '3팀'
                                            : piece.qualityWork.canningTeam === 'FOUR'
                                            ? '4팀'
                                            : '기타'}
                                    </td>
                                    <td className='center-td'>{piece.qualityWork.dateOfCanning}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={2}>
                                    작업불필요
                                </td>
                            )}

                            <td className='center-td'>
                                {piece.qualityOrder.statusOfPainting === 'NORMAL'
                                    ? piece.qualityWork.dateOfPainting
                                    : '작업불필요'}
                            </td>
                            <td className='center-td'>
                                {piece.qualityOrder.statusOfPostProcessing === 'NORMAL'
                                    ? piece.qualityWork.dateOfPostProcessing
                                    : '작업불필요'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={8}>기계가공 - 레어저 정보</th>
                    </tr>
                    <tr>
                        <th width={`${ITEM_SIZE}%`}>아이템명</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>도면 접수일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>설계자</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>Pipe Laser 설계 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>Pipe Laser 설계 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>평판 Laser 설계 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>평판 Laser 설계 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 7}%`}>이슈사항</th>
                    </tr>
                    {project.items.map((piece, index) => (
                        <tr key={index}>
                            <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                            <td className='center-td'>{piece.manufactureLaser.dateOfReceipt}</td>
                            <td className='center-td'>{piece.manufactureLaser.worker}</td>
                            {piece.manufactureLaser.progressOfPipe !== -100 ? (
                                <Fragment>
                                    <td className='center-td'>{piece.manufactureLaser.dateOfStartOfPipe}</td>
                                    <td className='center-td'>{piece.manufactureLaser.dateOfFinishOfPipe}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={2}>
                                    작업불필요
                                </td>
                            )}
                            {piece.manufactureLaser.progressOfPlaning !== -100 ? (
                                <Fragment>
                                    <td className='center-td'>{piece.manufactureLaser.dateOfStartOfPlaning}</td>
                                    <td className='center-td'>{piece.manufactureLaser.dateOfFinishOfPlaning}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={2}>
                                    작업불필요
                                </td>
                            )}
                            <td className='center-td'>{piece.manufactureLaser.note}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={9}>기계가공 - 작업 정보</th>
                    </tr>
                    <tr>
                        <th width={`${ITEM_SIZE}%`}>아이템명</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>도면 접수일</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공1팀 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공1팀 진도</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공1팀 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공2팀 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공2팀 진도</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>가공2팀 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 8}%`}>Wood 완료일</th>
                    </tr>
                    {project.items.map((piece, index) => (
                        <tr key={index}>
                            <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                            <td className='center-td'>{piece.manufactureWork.dateOfReceipt}</td>
                            {piece.manufactureWork.progressOfManufacture1 !== -100 ? (
                                <Fragment>
                                    <td className='center-td'>{piece.manufactureWork.dateOfStartOfManufacture1}</td>
                                    <td className='center-td'>
                                        {piece.manufactureWork.progressOfManufacture1 !== 0
                                            ? piece.manufactureWork.progressOfManufacture1
                                            : ''}
                                    </td>
                                    <td className='center-td'>{piece.manufactureWork.dateOfFinishOfManufacture1}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={3}>
                                    작업불필요
                                </td>
                            )}
                            {piece.manufactureWork.progressOfManufacture2 !== -100 ? (
                                <Fragment>
                                    <td className='center-td'>{piece.manufactureWork.dateOfStartOfManufacture2}</td>
                                    <td className='center-td'>
                                        {piece.manufactureWork.progressOfManufacture2 !== 0
                                            ? piece.manufactureWork.progressOfManufacture2
                                            : ''}
                                    </td>
                                    <td className='center-td'>{piece.manufactureWork.dateOfFinishOfManufacture2}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={3}>
                                    작업불필요
                                </td>
                            )}
                            <td className='center-td'>{piece.manufactureWork.dateOfFinishOfWoodWork}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={7}>기계조립 정보</th>
                    </tr>
                    <tr>
                        <th width={`${ITEM_SIZE}%`}>아이템명</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>조립도면 접수일</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>조립팀</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>조립 시작일</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>조립 진도</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>조립 완료일</th>
                        <th width={`${(100 - ITEM_SIZE) / 6}%`}>이슈사항</th>
                    </tr>
                    {project.items.map((piece, index) => (
                        <tr key={index}>
                            <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                            {piece.assemblyOrder.status === 'NORMAL' ? (
                                <Fragment>
                                    <td className='center-td'>{piece.assemblyWork.dateOfReceipt}</td>
                                    <td className='center-td'>
                                        {piece.assemblyWork.team === 'NONE'
                                            ? '미지정'
                                            : piece.assemblyWork.team === 'ONE'
                                            ? '1팀'
                                            : piece.assemblyWork.team === 'TWO'
                                            ? '2팀'
                                            : piece.assemblyWork.team === 'THREE'
                                            ? '3팀'
                                            : piece.assemblyWork.team === 'FOUR'
                                            ? '4팀'
                                            : '외주'}
                                    </td>
                                    <td className='center-td'>{piece.assemblyWork.dateOfStart}</td>
                                    <td className='center-td'>
                                        {piece.assemblyWork.progress !== 0 ? piece.assemblyWork.progress : ''}
                                    </td>
                                    <td className='center-td'>{piece.assemblyWork.dateOfFinish}</td>
                                    <td className='center-td'>{piece.assemblyWork.note}</td>
                                </Fragment>
                            ) : (
                                <td className='center-td' colSpan={6}>
                                    작업 불필요
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={8}>제어기술 정보</th>
                    </tr>
                    <tr>
                        <th width={`${100 / 8}%`}>시스템 번호</th>
                        <th width={`${100 / 8}%`}>시스템명</th>
                        <th width={`${100 / 8}%`}>전기도면 작업</th>
                        <th width={`${100 / 8}%`}>판넬 작업</th>
                        <th width={`${100 / 8}%`}>배선 작업</th>
                        <th width={`${100 / 8}%`}>시운전 작업</th>
                        <th width={`${100 / 8}%`}>전체 진도</th>
                        <th width={`${100 / 8}%`}>해체 작업</th>
                    </tr>
                    {project.controlSystems.length !== 0 ? (
                        project.controlSystems.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>{piece.nbr}</td>
                                <td className='center-td'>{piece.name}</td>
                                <td className='center-td'>{piece.controlWork.dateOfDrawing}</td>
                                <td className='center-td'>{piece.controlWork.dateOfPanel}</td>
                                <td className='center-td'>{piece.controlWork.dateOfWiring}</td>
                                <td className='center-td'>{piece.controlWork.dateOfTesting}</td>
                                <td className='center-td'>
                                    {piece.controlWork.progress !== 0 ? piece.controlWork.progress : ''}
                                </td>
                                <td className='center-td'>{piece.controlWork.dateOfDissolution}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={8}>
                                등록된 제어 시스템이 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={3}>출하 정보</th>
                    </tr>
                    <tr>
                        <th width={`${100 / 3}%`}>차수</th>
                        <th width={`${100 / 3}%`}>출하일자</th>
                        <th width={`${100 / 3}%`}>출하내용</th>
                    </tr>
                    <tr>
                        <td className='center-td'>1차</td>
                        <td className='center-td'>{project.shipment.dateOfShipment1}</td>
                        <td className='center-td'>{project.shipment.contentOfShipment1}</td>
                    </tr>
                    <tr>
                        <td className='center-td'>2차</td>
                        <td className='center-td'>{project.shipment.dateOfShipment2}</td>
                        <td className='center-td'>{project.shipment.contentOfShipment2}</td>
                    </tr>
                    <tr>
                        <td className='center-td'>3차</td>
                        <td className='center-td'>{project.shipment.dateOfShipment3}</td>
                        <td className='center-td'>{project.shipment.contentOfShipment3}</td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
