import { info } from './info';
import { menu } from './menu';
import { serverPath } from './path';
import { size } from './size';
import { statusType, projectType, projectStatusType, formType, pagingType, orderType } from './type';

export default {
    info,
    menu,
    serverPath,
    size,
    statusType,
    projectType,
    projectStatusType,
    formType,
    pagingType,
    orderType,
};

export { info, menu, serverPath, size, statusType, projectType, projectStatusType, formType, pagingType, orderType };
