import React from 'react';

import { info } from '../../../configs';

import './index.css';

const Component = () => {
    return (
        <footer className='footer'>
            <div className='footer-server'>Powered by {info.server}</div>
            <div className='footer-copyright'>{info.copyright}</div>
            <div className='footer-version'>
                {info.version} ({info.commit}){info.isBeta ? ' (beta)' : null}
            </div>
        </footer>
    );
};

export default Component;
