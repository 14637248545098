import React, { useEffect } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import PageHeader from '../../ui/header/PageHeader';

import StatusProject from '../../pages/status/Project';
import StatusDepartment from '../../pages/status/Department';
import StatusApproval from '../../pages/status/Approval';
import StatusNotice from '../../pages/status/Notice';

import SalesProjectProduct from '../../pages/sales/project/Product';
import SalesProjectPart from '../../pages/sales/project/Part';
import SalesProjectAS from '../../pages/sales/project/AS';
import SalesShipmentStatus from '../../pages/sales/shipment/Status';
import SalesShipmentSchedule from '../../pages/sales/shipment/Schedule';
import SalesShipmentNotice from '../../pages/sales/shipment/Notice';

import ProductionPlanning from '../../pages/production/Planning';
import ProductionStatus from '../../pages/production/Status';

import DesignPlanningSchedule from '../../pages/design/planning/Schedule';
import DesignPlanningReport from '../../pages/design/planning/Report';
import DesignPlanningMonitoring from '../../pages/design/planning/Monitoring';
import DesignWorkDesign from '../../pages/design/work/Design';
import DesignWorkCheck from '../../pages/design/work/Check';
import DesignBomPartList from '../../pages/design/bom/PartList';
import DesignBomBom from '../../pages/design/bom/BOM';
import DesignBomProductCode from '../../pages/design/bom/ProductCode';

import DesignPDMPDM from '../../pages/design/pdm/PDM';

import PurchaseStateMonitoring from '../../pages/purchase/state/Monitoring';
import PurchaseStateReport from '../../pages/purchase/state/Report';
import PurchaseStateAlert from '../../pages/purchase/state/Alert';
import PurchaseMaterial from '../../pages/purchase/material/Material';
import PurchaseRequestBom from '../../pages/purchase/request/Bom';
import PurchaseRequest from '../../pages/purchase/request/Request';
import PurchaseOrderListing from '../../pages/purchase/order/Listing';
import PurchaseOrderQuotation from '../../pages/purchase/order/Quotation';
import PurchaseOrderOrder from '../../pages/purchase/order/Order';
import PurchaseIOBundle from '../../pages/purchase/io/Bundle';
import PurchaseIORequest from '../../pages/purchase/io/Request';
import PurchaseIOEach from '../../pages/purchase/io/Each';
import PurchaseInventoryManufacture from '../../pages/purchase/inventory/Manufacture';
import PurchaseInventoryPurchase from '../../pages/purchase/inventory/Purchase';

import ManufactureStatus from '../../pages/manufacture/Status';
import ManufactureLaser from '../../pages/manufacture/Laser';
import ManufactureAssignment from '../../pages/manufacture/Assignment';
import ManufactureOrder from '../../pages/manufacture/Order';
import ManufacturePerformance from '../../pages/manufacture/Performance';
import ManufactureWork from '../../pages/manufacture/Work';
import ManufactureInventory from '../../pages/manufacture/Inventory';

import AssemblyOrder from '../../pages/assembly/Order';
import AssemblyWork from '../../pages/assembly/Work';
import AssemblyPerformance from '../../pages/assembly/Performance';
import AssemblyInstallReport from '../../pages/assembly/install/Report';

import ControlSystem from '../../pages/control/System';
import ControlWork from '../../pages/control/Work';

import QualityControlOrder from '../../pages/quality/control/Order';
import QualityControlWork from '../../pages/quality/control/Work';
import QualityControlPerformance from '../../pages/quality/control/Performance';
import QualityUnconformityReceipt from '../../pages/quality/unconformity/Receipt';
import QualityUnconformityMonitoring from '../../pages/quality/unconformity/Monitoring';

import AfterServiceReceipt from '../../pages/afterService/Receipt';
import AfterServiceResult from '../../pages/afterService/Result';

import HistoryProject from '../../pages/history/Project';

import AdminUser from '../../pages/admin/User';
import AdminDesign from '../../pages/admin/Design';
import AdminNotice from '../../pages/admin/Notice';
import AdminLog from '../../pages/admin/Log';

import Error from '../../pages/etc/Error';

import './index.css';
import './division.css';
import './list.css';
import './form.css';
import './selector.css';
import './approval.css';
import './viewer.css';

const Component = () => {
    useEffect(() => {
        const _handleClick = (e) => {
            if (e.target.type === 'date') {
                e.preventDefault();
                e.target.value = '';
            }
        };

        document.addEventListener('dblclick', _handleClick);

        return () => {
            document.removeEventListener('dblclick', _handleClick);
        };
    });

    return (
        <div className='core'>
            <PageHeader />
            <Switch>
                <Redirect path='/' exact to='/status/project' />

                <Route path='/status/project' exact component={StatusProject} />
                <Route path='/status/department' exact component={StatusDepartment} />
                <Route path='/status/approval' exact component={StatusApproval} />
                <Route path='/status/notice' exact component={StatusNotice} />

                <Route path='/sales/project/product' exact component={SalesProjectProduct} />
                <Route path='/sales/project/part' exact component={SalesProjectPart} />
                <Route path='/sales/project/as' exact component={SalesProjectAS} />
                <Route path='/sales/shipment/status' exact component={SalesShipmentStatus} />
                <Route path='/sales/shipment/schedule' exact component={SalesShipmentSchedule} />
                <Route path='/sales/shipment/notice' exact component={SalesShipmentNotice} />

                <Route path='/production/planning' exact component={ProductionPlanning} />
                <Route path='/production/status' exact component={ProductionStatus} />

                <Route path='/design/planning/schedule' exact component={DesignPlanningSchedule} />
                <Route path='/design/planning/report' exact component={DesignPlanningReport} />
                <Route path='/design/planning/monitoring' exact component={DesignPlanningMonitoring} />
                <Route path='/design/work/design' exact component={DesignWorkDesign} />
                <Route path='/design/work/check' exact component={DesignWorkCheck} />
                <Route path='/design/bom/partlist' exact component={DesignBomPartList} />
                <Route path='/design/bom/bom' exact component={DesignBomBom} />
                <Route path='/design/bom/productCode' exact component={DesignBomProductCode} />
                <Route path='/design/pdm/pdm' exact component={DesignPDMPDM} />

                <Route path='/purchase/state/monitoring' exact component={PurchaseStateMonitoring} />
                <Route path='/purchase/state/report' exact component={PurchaseStateReport} />
                <Route path='/purchase/state/alert' exact component={PurchaseStateAlert} />
                <Route path='/purchase/material' exact component={PurchaseMaterial} />
                <Route path='/purchase/request/bom' exact component={PurchaseRequestBom} />
                <Route path='/purchase/request' exact component={PurchaseRequest} />
                <Route path='/purchase/order/listing' exact component={PurchaseOrderListing} />
                <Route path='/purchase/order/quotation' exact component={PurchaseOrderQuotation} />
                <Route path='/purchase/order' exact component={PurchaseOrderOrder} />
                <Route path='/purchase/io/bundle' exact component={PurchaseIOBundle} />
                <Route path='/purchase/io/request' exact component={PurchaseIORequest} />
                <Route path='/purchase/io/each' exact component={PurchaseIOEach} />
                <Route path='/purchase/inventory/manufacture' exact component={PurchaseInventoryManufacture} />
                <Route path='/purchase/inventory/purchase' exact component={PurchaseInventoryPurchase} />

                <Route path='/manufacture/status' exact component={ManufactureStatus} />
                <Route path='/manufacture/laser' exact component={ManufactureLaser} />
                <Route path='/manufacture/assignment' exact component={ManufactureAssignment} />
                <Route path='/manufacture/order' exact component={ManufactureOrder} />
                <Route path='/manufacture/performance' exact component={ManufacturePerformance} />
                <Route path='/manufacture/work' exact component={ManufactureWork} />
                <Route path='/manufacture/inventory' exact component={ManufactureInventory} />

                <Route path='/assembly/order' exact component={AssemblyOrder} />
                <Route path='/assembly/work' exact component={AssemblyWork} />
                <Route path='/assembly/performance' exact component={AssemblyPerformance} />
                <Route path='/assembly/install/report' exact component={AssemblyInstallReport} />

                <Route path='/control/system' exact component={ControlSystem} />
                <Route path='/control/work' exact component={ControlWork} />

                <Route path='/quality/control/order' exact component={QualityControlOrder} />
                <Route path='/quality/control/work' exact component={QualityControlWork} />
                <Route path='/quality/control/performance' exact component={QualityControlPerformance} />
                <Route path='/quality/unconformity/receipt' exact component={QualityUnconformityReceipt} />
                <Route path='/quality/unconformity/monitoring' exact component={QualityUnconformityMonitoring} />

                <Route path='/as/receipt' exact component={AfterServiceReceipt} />
                <Route path='/as/result' exact component={AfterServiceResult} />

                <Route path='/history/project' exact component={HistoryProject} />

                <Route path='/admin/user' exact component={AdminUser} />
                <Route path='/admin/design' exact component={AdminDesign} />
                <Route path='/admin/notice' exact component={AdminNotice} />
                <Route path='/admin/log' exact component={AdminLog} />

                <Route path='/' component={Error} />
            </Switch>
        </div>
    );
};

export default Component;
