import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { GET, POST, PUT, DELETE } from '../../../../util/data';

import './index.css';

const Component = ({ project, setProject }) => {
    const [detector, setDetector] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        setProject({
            id: project.id,
            items: [],
            controlSystems: [],
            attachments: [],
        });

        GET('project', { id: project.id }, (res) => {
            res.items.sort((a, b) => {
                try {
                    return (
                        Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                        Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                    );
                } catch {
                    return 1;
                }
            });
            setProject(res);
        });
    }, [detector, project.id, setProject]);

    const _handleForm1 = (e) => {
        e.preventDefault();

        const form = document.activeForm1;

        const list = document.getElementsByName('list[]');

        const arr = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].checked) {
                arr.push({
                    controlSystem: {
                        id: form.controlSystem.value === 'null' ? null : form.controlSystem.value,
                    },
                    item: {
                        id: list[i].value,
                    },
                });
                list[i].checked = false;
            }
        }

        PUT('control/system/items', arr, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    const _handleForm2 = (e) => {
        e.preventDefault();

        const form = document.activeForm2;

        const params = {
            project: { id: project.id },
            nbr: form.nbr.value,
            name: form.name.value,
            description: form.description.value,
            items: [],
        };

        POST('control/system', params, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                form.nbr.value = '';
                form.name.value = '';
                form.description.value = '';
                alert.success('성공적으로 업데이트 되었습니다.');
            }
        });
    };

    const _handleForm3 = (e) => {
        e.preventDefault();

        const form = document.activeForm3;

        const arr = [];

        if (project.controlSystems.length === 1) {
            arr[0] = {
                id: project.controlSystems[0].id,
                nbr: form.nbr.value,
                name: form.name.value,
                description: form.description.value,
                items: project.controlSystems[0].items,
            };
        } else {
            for (let i = 0; i < project.controlSystems.length; i++) {
                arr.push({
                    id: project.controlSystems[i].id,
                    nbr: form.nbr[i].value,
                    name: form.name[i].value,
                    description: form.description[i].value,
                    items: project.controlSystems[i].items,
                });
            }
        }

        PUT('control/system', arr, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    const _handleSelectAll = (e) => {
        const value = e.target.checked;

        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = value;
        }
    };

    const _handleDelete = (piece) => {
        DELETE('control/system', { controlSystemId: piece.id }, (res) => {
            setDetector((detector) => !detector);
            alert.success(res);
        });
    };

    return (
        <div>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm2' onSubmit={_handleForm2} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={3}>시스템 등록</th>
                        </tr>
                        <tr>
                            <th width='10%'>시스템 No.</th>
                            <th width='25%'>시스템명</th>
                            <th width='65%'>시스템 설명</th>
                        </tr>
                        <tr>
                            <td>
                                <input type='number' name='nbr' />
                            </td>
                            <td>
                                <input type='text' name='name' />
                            </td>
                            <td>
                                <input type='text' name='description' />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button className='submitButton'>저장</button>
            </form>
            <form name='activeForm3' onSubmit={_handleForm3} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={4}>시스템 리스트</th>
                        </tr>
                        <tr>
                            <th width='10%'>시스템 No.</th>
                            <th width='25%'>시스템명</th>
                            <th width='60%'>시스템 설명</th>
                            <th width='5%'>설정</th>
                        </tr>
                        {project.controlSystems.length !== 0 ? (
                            project.controlSystems
                                .sort((a, b) => a.nbr - b.nbr)
                                .map((piece, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input type='number' name='nbr' defaultValue={piece.nbr} />
                                        </td>
                                        <td>
                                            <input type='text' name='name' defaultValue={piece.name} />
                                        </td>
                                        <td>
                                            <input type='text' name='description' defaultValue={piece.description} />
                                        </td>
                                        <td>
                                            <button
                                                className='small-button'
                                                type='button'
                                                onClick={() => _handleDelete(piece)}
                                            >
                                                삭제
                                            </button>
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={4}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button className='submitButton'>저장</button>
            </form>
            <form name='activeForm1' onSubmit={_handleForm1} autoComplete='off'>
                <table className='form form-lining'>
                    <tbody>
                        <tr>
                            <th width='3%'>
                                <input type='checkbox' onClick={_handleSelectAll} />
                            </th>
                            <th width='26%'>시스템</th>
                            <th width='5%'>No.</th>
                            <th width='21%'>아이템명</th>
                            <th width='20%'>규격</th>
                            <th width='5%'>단위</th>
                            <th width='5%'>수량</th>
                            <th width='15%'>비고</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>
                                    <input type='checkbox' name='list[]' defaultValue={piece.id} />
                                </td>
                                <td>
                                    {project.controlSystems.map((raw, index) =>
                                        raw.controlSystemAndItems.find((element) => element.item.id === piece.id) ? (
                                            <div className='controlSystem-list' key={index}>
                                                ▶ [{raw.nbr}] {raw.name}
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </td>
                                <td className='center-td'>{piece.nbr}</td>
                                <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                <td>{piece.specification}</td>
                                <td className='center-td'>{piece.unit}</td>
                                <td className='center-td'>{piece.quantity}</td>
                                <td>{piece.note.length > 30 ? piece.note.substr(0, 30) + '...' : piece.note}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button className='submitButton'>저장</button>
                <select className='submitSelect' name='controlSystem' required>
                    <option value=''>시스템 선택</option>
                    {project.controlSystems
                        .sort((a, b) => a.nbr - b.nbr)
                        .map((piece, index) => (
                            <option value={piece.id} key={index}>
                                [{piece.nbr}] {piece.name}
                            </option>
                        ))}
                    <option value='null'>시스템 미지정</option>
                </select>
            </form>
        </div>
    );
};

export default Component;
