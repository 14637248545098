import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';
import DateInputer from '../../../../ui/inputer/DateInputer';
import WorkerInputer from '../../../../ui/inputer/WorkerInputer';

import { GET, PUT } from '../../../../../util/data';

const Component = ({ project, setProject }) => {
    const [users, setUsers] = useState([]);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        GET('user/all', {}, (res) => {
            if (active && res !== null) {
                setUsers(res);
            }
        });

        return () => {
            setActive(false);
        };
    }, [active]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].designPlanning.id,
                status: form.status.value,
                dateOfStart: form.dateOfStart.value,
                designer:
                    form.designer.value !== ''
                        ? {
                              id: form.designer.value,
                          }
                        : null,
                dateOfFinish: form.dateOfFinish.value,
                checker:
                    form.checker.value !== ''
                        ? {
                              id: form.checker.value,
                          }
                        : null,
                dateOfCheck: form.dateOfCheck.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].designPlanning.id,
                    status: form.status[i].value,
                    dateOfStart: form.dateOfStart[i].value,
                    designer:
                        form.designer[i].value !== ''
                            ? {
                                  id: form.designer[i].value,
                              }
                            : null,
                    dateOfFinish: form.dateOfFinish[i].value,
                    checker:
                        form.checker[i].value !== ''
                            ? {
                                  id: form.checker[i].value,
                              }
                            : null,
                    dateOfCheck: form.dateOfCheck[i].value,
                });
            }
        }

        PUT('design/planning', arr, (res1) => {
            GET('project', { id: project.id }, (res2) => {
                res2.items.sort((a, b) => {
                    try {
                        return (
                            Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                            Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                        );
                    } catch {
                        return 1;
                    }
                });
                setProject(res2);
                alert.success(res1);
            });
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={9}>
                                <DateInputer />
                                <WorkerInputer workers={users} name1='designer' name2='checker' />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='3%' rowSpan={2}>
                                No.
                            </th>
                            <th width='23%'>아이템명</th>
                            <th width='5%' rowSpan={2}>
                                작업
                                <br />
                                여부
                            </th>
                            <th width='6%' rowSpan={2}>
                                설계시작지시일
                            </th>
                            <th width='6%' rowSpan={2}>
                                설계자
                            </th>
                            <th width='6%' rowSpan={2}>
                                설계완료지시일
                            </th>
                            <th width='6%' rowSpan={2}>
                                검도자
                            </th>
                            <th width='6%' rowSpan={2}>
                                검도완료지시일
                            </th>
                            <th width='12%' rowSpan={2}>
                                설계작업 이슈사항
                            </th>
                        </tr>
                        <tr>
                            <th>규격 / 단위 / 수량</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <Fragment key={index}>
                                <tr>
                                    <td className='center-td' rowSpan={2}>
                                        {piece.nbr}
                                    </td>
                                    <td className='center-td'>
                                        {piece.itemFamily !== null ? piece.itemFamily.name : piece.name}
                                    </td>
                                    <td rowSpan={2}>
                                        <select name='status' defaultValue={piece.designPlanning.status}>
                                            <option value='NORMAL'>일반</option>
                                            <option value='OUTSOURCING'>외주</option>
                                            <option value='UNNECESSARY'>불필요</option>
                                        </select>
                                    </td>
                                    {piece.designPlanning.status !== 'UNNECESSARY' ? (
                                        <Fragment>
                                            <td rowSpan={2}>
                                                <input
                                                    type='date'
                                                    name='dateOfStart'
                                                    defaultValue={piece.designPlanning.dateOfStart}
                                                />
                                            </td>
                                            <td rowSpan={2}>
                                                {users.length !== 0 ? (
                                                    <select
                                                        name='designer'
                                                        defaultValue={
                                                            piece.designPlanning.designer !== null
                                                                ? piece.designPlanning.designer.id
                                                                : ''
                                                        }
                                                    >
                                                        <option value=''>선택</option>
                                                        {users
                                                            .filter((piece) => piece.authority.designWork)
                                                            .map((piece, index) => (
                                                                <option value={piece.id} key={index} hidden={piece.resign}>
                                                                    {piece.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                ) : (
                                                    <input type='hidden' name='designer' value='' />
                                                )}
                                            </td>
                                            <td rowSpan={2}>
                                                <input
                                                    type='date'
                                                    name='dateOfFinish'
                                                    defaultValue={piece.designPlanning.dateOfFinish}
                                                />
                                            </td>
                                            <td rowSpan={2}>
                                                {users.length !== 0 ? (
                                                    <select
                                                        name='checker'
                                                        defaultValue={
                                                            piece.designPlanning.checker !== null
                                                                ? piece.designPlanning.checker.id
                                                                : ''
                                                        }
                                                    >
                                                        <option value=''>선택</option>
                                                        {users
                                                            .filter((piece) => piece.authority.designWork)
                                                            .map((piece, index) => (
                                                                <option value={piece.id} key={index} hidden={piece.resign}>
                                                                    {piece.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                ) : (
                                                    <input type='hidden' name='checker' value='' />
                                                )}
                                            </td>
                                            <td rowSpan={2}>
                                                <input
                                                    type='date'
                                                    name='dateOfCheck'
                                                    defaultValue={piece.designPlanning.dateOfCheck}
                                                />
                                            </td>
                                            <td rowSpan={2}>
                                                <textarea name='note' defaultValue={piece.designWork.note} readOnly />
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <td colSpan={6} rowSpan={2} className='center-td'>
                                            <input
                                                type='hidden'
                                                name='dateOfStart'
                                                defaultValue={piece.designPlanning.dateOfStart}
                                            />
                                            <input
                                                type='hidden'
                                                name='designer'
                                                defaultValue={
                                                    piece.designPlanning.designer !== null
                                                        ? piece.designPlanning.designer.id
                                                        : null
                                                }
                                            />
                                            <input
                                                type='hidden'
                                                name='dateOfFinish'
                                                defaultValue={piece.designPlanning.dateOfFinish}
                                            />
                                            <input
                                                type='hidden'
                                                name='checker'
                                                defaultValue={
                                                    piece.designPlanning.checker !== null
                                                        ? piece.designPlanning.checker.id
                                                        : null
                                                }
                                            />
                                            <input
                                                type='hidden'
                                                name='dateOfCheck'
                                                defaultValue={piece.designPlanning.dateOfCheck}
                                            />
                                            <input type='hidden' name='note' defaultValue={piece.designPlanning.note} />
                                            작업불필요
                                        </td>
                                    )}
                                </tr>
                                <tr className='center-td'>
                                    <td>{`${piece.specification} / ${piece.unit} / ${piece.quantity}`}</td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
