import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const API_PATH = 'api';
const API_VERSION = 'v01';

const API_RES = (result, callback) => {
    callback(result.data);
};

const API_ERR = (error, callback, advancedAlert) => {
    if (error.status === 500) {
        confirmAlert({
            message: 'The server has some problem. Please try again later :(',
            buttons: [
                {
                    label: '확인',
                    onClick: () => (window.location.href = '/'),
                },
            ],
        });
    } else {
        advancedAlert.error(error.data);
        callback(null);
    }
};

const API_ERR_IN_POS = (error, callback, advancedAlert) => {
    if (error.status === 500) {
        confirmAlert({
            message: 'The server has some problem. Please try again later :(',
            buttons: [
                {
                    label: '확인',
                },
            ],
        });
    } else {
        advancedAlert.error(error.data);
        callback(null);
    }
};

export const AUTH_CHECK = (callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/auth`;

    console.log(`GET() is called : ${URL}`);

    Axios.get(URL).then((res) => {
        API_RES(res, callback);
    });
};

export const AUTH_IN = (data, advancedAlert, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/auth`;

    console.log(`POST() is called : ${URL}`);

    Axios.post(URL, data)
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            API_ERR(err.response, callback, advancedAlert);
        });
};

export const AUTH_IN_POS = (data, advancedAlert, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/auth/pos`;

    console.log(`POST() is called : ${URL}`);

    Axios.post(URL, data)
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            API_ERR_IN_POS(err.response, callback, advancedAlert);
        });
};

export const AUTH_OUT = (callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/auth`;

    console.log(`DELETE() is called : ${URL}`);

    Axios.delete(URL)
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            API_ERR(err.response, callback);
        });
};
