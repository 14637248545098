import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import DateInputer from '../../../ui/inputer/DateInputer';

import { PUT } from '../../../../util/data';

const Component = ({ project }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].itemPlanning.id,
                statusOfDesign: form.statusOfDesign.value,
                statusOfManufacture1: form.statusOfManufacture1.value,
                statusOfManufacture2: form.statusOfManufacture2.value,
                statusOfCanning: form.statusOfCanning.value,
                statusOfPainting: form.statusOfPainting.value,
                statusOfPostProcessing: form.statusOfPostProcessing.value,
                statusOfAssembly: form.statusOfAssembly.value,
                dateOfDesign: form.dateOfDesign.value,
                dateOfManufacture1: form.dateOfManufacture1.value,
                dateOfManufacture2: form.dateOfManufacture2.value,
                dateOfCanning: form.dateOfCanning.value,
                dateOfPainting: form.dateOfPainting.value,
                dateOfPostProcessing: form.dateOfPostProcessing.value,
                dateOfAssembly: form.dateOfAssembly.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].itemPlanning.id,
                    statusOfDesign: form.statusOfDesign[i].value,
                    statusOfManufacture1: form.statusOfManufacture1[i].value,
                    statusOfManufacture2: form.statusOfManufacture2[i].value,
                    statusOfCanning: form.statusOfCanning[i].value,
                    statusOfPainting: form.statusOfPainting[i].value,
                    statusOfPostProcessing: form.statusOfPostProcessing[i].value,
                    statusOfAssembly: form.statusOfAssembly[i].value,
                    dateOfDesign: form.dateOfDesign[i].value,
                    dateOfManufacture1: form.dateOfManufacture1[i].value,
                    dateOfManufacture2: form.dateOfManufacture2[i].value,
                    dateOfCanning: form.dateOfCanning[i].value,
                    dateOfPainting: form.dateOfPainting[i].value,
                    dateOfPostProcessing: form.dateOfPostProcessing[i].value,
                    dateOfAssembly: form.dateOfAssembly[i].value,
                });
            }
        }

        PUT('production/planning', arr, (res) => {
            alert.success(res);
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={9}>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowSpan={2}>No.</th>
                            <th>아이템명</th>
                            <th rowSpan={2}>설계</th>
                            <th rowSpan={2}>가공1팀</th>
                            <th rowSpan={2}>가공2팀</th>
                            <th rowSpan={2}>제관</th>
                            <th rowSpan={2}>도장</th>
                            <th rowSpan={2}>후처리</th>
                            <th rowSpan={2}>조립</th>
                        </tr>
                        <tr>
                            <th>규격 / 단위 / 수량</th>
                        </tr>
                        {project.items.length !== 0 ? (
                            project.items.map((piece, index) => (
                                <Fragment key={index}>
                                    <tr>
                                        <td rowSpan={2}>{piece.nbr}</td>
                                        <td>
                                            {piece.productFamily !== null
                                                ? `${piece.productFamily.name} / ${piece.itemFamily.name}`
                                                : piece.name}
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfDesign'
                                                defaultValue={piece.itemPlanning.statusOfDesign}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfManufacture1'
                                                defaultValue={piece.itemPlanning.statusOfManufacture1}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfManufacture2'
                                                defaultValue={piece.itemPlanning.statusOfManufacture2}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfCanning'
                                                defaultValue={piece.itemPlanning.statusOfCanning}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfPainting'
                                                defaultValue={piece.itemPlanning.statusOfPainting}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfPostProcessing'
                                                defaultValue={piece.itemPlanning.statusOfPostProcessing}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                name='statusOfAssembly'
                                                defaultValue={piece.itemPlanning.statusOfAssembly}
                                            >
                                                <option value='NORMAL'>작업필요</option>
                                                <option value='UNNECESSARY'>작업불필요</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {piece.specification} / {piece.unit} / {piece.quantity}
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfDesign'
                                                defaultValue={piece.itemPlanning.dateOfDesign}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfManufacture1'
                                                defaultValue={piece.itemPlanning.dateOfManufacture1}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfManufacture2'
                                                defaultValue={piece.itemPlanning.dateOfManufacture2}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfCanning'
                                                defaultValue={piece.itemPlanning.dateOfCanning}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfPainting'
                                                defaultValue={piece.itemPlanning.dateOfPainting}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfPostProcessing'
                                                defaultValue={piece.itemPlanning.dateOfPostProcessing}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfAssembly'
                                                defaultValue={piece.itemPlanning.dateOfAssembly}
                                            />
                                        </td>
                                    </tr>
                                </Fragment>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={12}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                
            </form>
        </Fragment>
    );
};

export default Component;
