import React, { useState } from 'react';

import Form from './form';

import ProjectSelector from '../../../ui/selector/ProjectSelector';
import Popup from '../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setProject({
            id: -2,
        });
    };

    const _handleStatusByProject = (piece) => {
        let res = 0;

        for (let i = 0; i < piece.items.length; i++) {
            if (
                piece.items[i].assemblyOrder.status !== 'UNNECESSARY' &&
                (piece.items[i].assemblyOrder.dateOfStart === null ||
                    piece.items[i].assemblyOrder.dateOfStart === '' ||
                    piece.items[i].assemblyOrder.dateOfFinish === null ||
                    piece.items[i].assemblyOrder.dateOfFinish === '')
            ) {
                res++;
                break;
            }
        }

        return res;
    };

    return (
        <div className='container'>
            <ProjectSelector
                project={project}
                setProject={setProject}
                isProjectManager={false}
                isItemSearch={true}
                _handleStatusByProject={_handleStatusByProject}
                defaultType='PRODUCT'
            />
            {project.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form project={project} se setProject={setProject} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
