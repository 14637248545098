import React, { useState, useEffect } from 'react';

import { GET_WITH_PAGING } from '../../../../../util/data';

const Component = () => {
    const [raw, setRaw] = useState([]);
    const [data] = useState({
        page: 0,
        size: 0,
        sort: 'type',
        order: 'DESC',
        type: 'ALL',
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET_WITH_PAGING('project/paging', data, (res) => {
            res = res.data.content;

            if (active && res !== null) {
                setRaw(
                    res.sort((a, b) =>
                        a.type === null || b.type === null
                            ? 1
                            : b.type.localeCompare(a.type) ||
                              (a.code === null || b.code === null ? 1 : a.code.localeCompare(b.code))
                    )
                );
            }
        });
    }, [data, active]);

    return (
        <div className='container'>
            <table className='selector'>
                <tbody>
                    <tr>
                        <th width='10%'>구분</th>
                        <th width='15%'>지시번호</th>
                        <th width='17.5%'>프로젝트명</th>
                        <th width='17.5%'>고객사</th>
                        <th width='10%'>포장일</th>
                        <th width='10%'>출하일</th>
                        <th width='10%'>선적일</th>
                        <th width='10%'>도착일</th>
                    </tr>
                    {raw.length !== 0 ? (
                        raw.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>
                                    {piece.type === 'PRODUCT' ? '완성품' : piece.type === 'PART' ? '부품' : 'A/S'}
                                </td>
                                <td>{piece.code}</td>
                                <td>{piece.name}</td>
                                <td>{piece.customer}</td>
                                <td className='center-td'>{piece.shipment.dateOfPacking}</td>
                                <td className='center-td'>{piece.shipment.dateOfShipment}</td>
                                <td className='center-td'>{piece.shipment.dateOfLoad}</td>
                                <td className='center-td'>{piece.shipment.dateOfArrival}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={8}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
