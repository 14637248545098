import React, { Fragment, useState } from 'react';

import Form from './form';

import ProjectSelector from '../../../ui/selector/ProjectSelector';
import ItemSelector from '../../../ui/selector/ItemSelector';
import Popup from '../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });
    const [item, setItem] = useState({
        id: -2,
    });

    const handleSlot = (piece) => {
        if (piece.partList.status !== 'DONE') return false;
        else return true;
    };

    const _handleExit = () => {
        setItem({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <ProjectSelector
                project={project}
                setProject={setProject}
                setItem={setItem}
                isProjectManager={false}
                isItemSearch={true}
            />
            {project.id === -2 ? (
                <Fragment></Fragment>
            ) : (
                <ItemSelector
                    project={project}
                    setProject={setProject}
                    setItem={setItem}
                    handleSlot={handleSlot}
                    showProject={true}
                    isSimpleProject={false}
                />
            )}
            {item.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form project={project} setProject={setProject} item={item} setItem={setItem} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
