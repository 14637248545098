import React, { useEffect } from 'react';

import PopupHeader from './PopupHeader';
import PopupContent from './PopupContent';
import PopupFooter from './PopupFooter';

import './index.css';

const Component = ({ title, data, exitFunc, hasButton, buttonTitle }) => {
    useEffect(() => {
        const _handleKey = (e) => {
            if (e.keyCode === 27) {
                exitFunc();
            }
        };

        document.addEventListener('keydown', _handleKey);

        return () => {
            document.removeEventListener('keydown', _handleKey);
        };
    });

    return (
        <div className='popup'>
            <div className='popup-core'>
                <PopupHeader title={title} exitFunc={exitFunc} />
                <PopupContent data={data} />
                <PopupFooter hasButton={hasButton} buttonTitle={buttonTitle} />
            </div>
            <div className='popup-background'></div>
        </div>
    );
};

export default Component;
