import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Form from './form';

import ProjectSelectorForConfirmation from '../../../ui/selector/ProjectSelectorForConfirmation';
import Popup from '../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });
    const user = useSelector((state) => state.user);

    const _handleExit = () => {
        setProject({
            id: -2,
        });
    };

    let type = '';

    if (user.authority.approvalOfSales) type = 'sales';
    else if (user.authority.approvalOfDesign) type = 'design';
    else if (user.authority.approvalOfManufacture) type = 'manufacture';
    else if (user.authority.approvalOfAssembly) type = 'assembly';
    else if (user.authority.approvalOfPurchase) type = 'purchase';
    else if (user.authority.approvalOfQuality) type = 'quality';

    return (
        <div className='container'>
            <ProjectSelectorForConfirmation project={project} setProject={setProject} />
            {project.id > -1 ? (
                <Popup
                    title='프로젝트 결재'
                    data={<Form project={project} setProject={setProject} type={type} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                    buttonTitle='프로젝트 결재'
                />
            ) : null}
        </div>
    );
};

export default Component;
