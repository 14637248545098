import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { print_data_set } from '../../../../actions';

import TextareaAutoSize from 'react-textarea-autosize';

import { GET } from '../../../../util/data';

import './index.css';

const Component = ({ print }) => {
    const [project, setProject] = useState({ id: -1 });
    const dispatch = useDispatch();

    useEffect(() => {
        GET('project', { id: print.data }, (res) => {
            res.items.sort((a, b) => {
                try {
                    return (
                        Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                        Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                    );
                } catch {
                    return 1;
                }
            });
            setProject(res);
        });
    }, [print]);

    useEffect(() => {
        if (project.id !== -1) window.print();
    }, [project.id]);

    const _handlePrint = () => {
        window.print();
    };

    const _handleExit = () => {
        dispatch(print_data_set({ type: 0, data: null }));
    };

    return project.id !== -1 ? (
        <div className='printZone'>
            <button onClick={_handleExit}>닫기</button>
            <button onClick={_handlePrint}>출력</button>
            <table className='printTable'>
                <thead>
                    <tr>
                        <th width='55%' rowSpan={2}>
                            작&nbsp;&nbsp;&nbsp;업&nbsp;&nbsp;&nbsp;지&nbsp;&nbsp;&nbsp;시&nbsp;&nbsp;&nbsp;서
                        </th>
                        <td width='5%' rowSpan={2}>
                            결재
                        </td>
                        <td width='10%'>작성</td>
                        <td width='10%'>검토</td>
                        <td width='10%'>팀장</td>
                        <td width='10%'>승인</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
            </table>
            <table className='printTable'>
                <thead>
                    <tr>
                        <td width='5%' rowSpan={2}>
                            확인
                        </td>
                        <td width='10%'>설계</td>
                        <td width='10%'>전기/조립</td>
                        <td width='10%'>가공</td>
                        <td width='10%'>구매</td>
                        <td width='10%'>품질</td>
                        <td width='45%' rowSpan={2}></td>
                    </tr>
                    <tr>
                        <td>{project.confirmation.design}</td>
                        <td>{project.confirmation.assembly}</td>
                        <td>{project.confirmation.manufacture}</td>
                        <td>{project.confirmation.purchase}</td>
                        <td>{project.confirmation.quality}</td>
                    </tr>
                </thead>
            </table>
            <table className='printTable'>
                <tbody>
                    <tr>
                        <th width='15%'>구분</th>
                        <td width='35%'>
                            {project.type === 'PRODUCT'
                                ? '완성품'
                                : project.type === 'PART'
                                ? '부품'
                                : project.type === 'AS'
                                ? ' A/S'
                                : '미등록 프로젝트'}
                        </td>
                        <th width='15%'>지시번호</th>
                        <td width='35%'>{project.code}</td>
                    </tr>
                    <tr>
                        <th>프로젝트명</th>
                        <td>{project.name}</td>
                        <th>납품처</th>
                        <td>{project.customer}</td>
                    </tr>
                    <tr>
                        <th>오더번호</th>
                        <td>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                        <th>전기사양</th>
                        <td>{project.electricity !== undefined ? project.electricity : ''}</td>
                    </tr>
                    <tr>
                        <th>PLC Type</th>
                        <td>{project.plc !== undefined ? project.plc : ''}</td>
                        <th>색상</th>
                        <td>{project.color !== undefined ? project.color : ''}</td>
                    </tr>
                    <tr>
                        <th>지시일자</th>
                        <td>{project.dateOfOrder !== undefined ? project.dateOfOrder : ''}</td>
                        <th>납품일자</th>
                        <td>{project.dateOfDue !== undefined ? project.dateOfDue : ''}</td>
                    </tr>
                </tbody>
            </table>
            <table className='printTable'>
                <tbody>
                    <tr>
                        <th width='5%'>No</th>
                        <th width='30%'>아이템명</th>
                        <th width='20%'>규격</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>수량</th>
                        <th width='25%'>비고</th>
                    </tr>
                    {project.items.length !== 0 ? (
                        project.items.map((piece, index) => (
                            <tr key={index}>
                                <td>{piece.nbr}</td>
                                <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                <td>{piece.specification}</td>
                                <td>{piece.unit}</td>
                                <td>{piece.quantity}</td>
                                <td>{piece.note}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6}>아이템 정보가 없습니다.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <table className='printTable'>
                <tbody>
                    <tr>
                        <th>세부작업 지시사항</th>
                    </tr>
                    <tr>
                        <td>
                            <TextareaAutoSize className='textareaAutoSize' defaultValue={project.note} readOnly />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='printTable'>
                <tbody>
                    <tr>
                        <th>첨부자료</th>
                    </tr>
                    <tr>
                        {project.attachments.length !== 0 ? (
                            <td>
                                {project.attachments.map((piece, index) => (
                                    <p key={index}>{piece.name}</p>
                                ))}
                            </td>
                        ) : (
                            <td>첨부파일이 없습니다.</td>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    ) : null;
};

export default Component;
