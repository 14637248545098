import React, { useState, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewerForUncomformity from '../../../../ui/viewer/ProjectViewerForUncomformity';
import FileUploader from '../../../../ui/uploader/FileUploader';

import { GET, POST, PUT } from '../../../../../util/data';

const Component = ({ project, setProject, item, setItem, receipt }) => {
    const [department, setDepartment] = useState(receipt.id === -1 ? '' : receipt.department);
    const [type, setType] = useState(receipt.id === -1 ? '' : receipt.type);
    const [attachments, setAttachments] = useState(receipt.id === -1 ? [] : [...receipt.attachments]);
    const [done, setDone] = useState(false);
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        if (receipt.id > -1 || (receipt.id === -1 && !done)) {
            const func = receipt.id === -1 ? POST : PUT;

            const form = document.activeForm;

            const data = {
                item: {
                    id: item.id,
                },
                code: form.code.value,
                dateOfIssue: form.dateOfIssue.value,
                contributor: form.contributor.value,
                department: department,
                type: type,
                content: form.content.value,
                cause: form.cause.value,
                attachments: attachments,
            };

            if (receipt.id !== -1) {
                data.id = receipt.id;
            }

            setDone(true);

            func('uncomformity', data, (res1) => {
                if (res1 !== null) {
                    GET('project', { id: project.id }, (res2) => {
                        res2.items.sort((a, b) => {
                            try {
                                return (
                                    Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                    Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                                );
                            } catch {
                                return 1;
                            }
                        });
                        setProject(res2);
                        setItem(res2.items.find((piece) => piece.id === item.id));
                        alert.success(res1);
                    });
                }
            });
        }
    };

    return (
        <Fragment>
            <ProjectViewerForUncomformity project={project} item={item} isSetItem={true} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='13.3%'>접수번호</th>
                            <td width='20%'>
                                <input type='text' name='code' defaultValue={receipt.id > -1 ? receipt.code : ''} />
                            </td>
                            <th width='13.3%'>발생일</th>
                            <td width='20%'>
                                <input
                                    type='date'
                                    name='dateOfIssue'
                                    defaultValue={receipt.id > -1 ? receipt.dateOfIssue : ''}
                                />
                            </td>
                            <th width='13.3%'>불량 발생자</th>
                            <td width='20%'>
                                <input
                                    type='text'
                                    name='contributor'
                                    defaultValue={receipt.id > -1 ? receipt.contributor : ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>발생부서</th>
                            <td>
                                <select
                                    name='department'
                                    value={department}
                                    onChange={(e) => setDepartment(e.target.value) || setType('')}
                                >
                                    <option value=''>선택</option>
                                    <option>설계부</option>
                                    <option>기계가공부</option>
                                    <option>구매부</option>
                                    <option>기계조립부</option>
                                    <option>기주기계</option>
                                    <option>우성기계</option>
                                    <option>탑기계</option>
                                    <option>일흥기계</option>
                                    <option>진성산업</option>
                                    <option>기타제관</option>
                                </select>
                            </td>
                            <th>부적합 유형</th>
                            <td>
                                <select name='type' value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value=''>선택</option>
                                    {department === '설계부' ? (
                                        <Fragment>
                                            <option>설계 치수 부적합</option>
                                            <option>설계 발주 부적합</option>
                                            <option>설계 간섭 부적합</option>
                                            <option>설계 도면 미일치</option>
                                            <option>설계 부품 누락</option>
                                            <option>상대물 미일치 부적합</option>
                                        </Fragment>
                                    ) : null}
                                    {department === '기계가공부' ? (
                                        <Fragment>
                                            <option>가공 치수 부적합</option>
                                            <option>Laser 설계 부적합</option>
                                            <option>가공 누락 부적합</option>
                                            <option>가공 소재 부적합</option>
                                        </Fragment>
                                    ) : null}
                                    {department === '구매부' ? (
                                        <Fragment>
                                            <option>자재 부적합</option>
                                            <option>외주가공 치수 부적합</option>
                                            <option>자재구매 부적합</option>
                                        </Fragment>
                                    ) : null}
                                    {department === '기계조립부' ? (
                                        <Fragment>
                                            <option>작업중 파손 부적합</option>
                                        </Fragment>
                                    ) : null}
                                    {department === '기주기계' ||
                                    department === '우성기계' ||
                                    department === '탑기계' ||
                                    department === '일흥기계' ||
                                    department === '기타제관' ? (
                                        <Fragment>
                                            <option>제작 치수 부적합</option>
                                            <option>제작 가공 치수 부적합</option>
                                            <option>제작 변형 부적합</option>
                                            <option>제작 가공 누락 부적합</option>
                                            <option>제작 상태 부적합</option>
                                            <option>제작 누락 부적합</option>
                                        </Fragment>
                                    ) : null}
                                    {department === '진성산업' ? (
                                        <Fragment>
                                            <option>도장 불량</option>
                                            <option>도장 색상 불량</option>
                                            <option>열변형</option>
                                            <option>도장 중 파손</option>
                                        </Fragment>
                                    ) : null}
                                </select>
                            </td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <th>보고내용</th>
                            <td colSpan={5}>
                                <textarea
                                    className='wide-textarea'
                                    name='content'
                                    defaultValue={receipt.id > -1 ? receipt.content : ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>발생원인</th>
                            <td colSpan={5}>
                                <textarea
                                    className='wide-textarea'
                                    name='cause'
                                    defaultValue={receipt.id > -1 ? receipt.cause : ''}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <FileUploader attachments={attachments} setAttachments={setAttachments} />
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
