import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import { GET, PUT } from '../../../../util/data';

const Component = ({ piece, setPiece, isDesignModule }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 0,
        projectType: 'ALL',
        workType: isDesignModule ? 'DESIGN' : 'CHECK',
        status: 'CURRENT',
        keyword: '',
    });
    const [mergeList, setMergeList] = useState([]);
    const [merge, setMerge] = useState('');
    const [checkDate, setCheckDate] = useState('');
    const [detector, setDetector] = useState(false);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (piece.project.id === -2) {
            GET('design/work', data, (res) => {
                if (active && res !== null) {
                    setRaw(res);
                }
            });
        }
    }, [detector, piece, data, active]);

    const _handleSetProject = (piece) => {
        setPiece(piece);
    };

    const _handleType = (value) => {
        let updated = { ...data };
        updated.projectType = value;
        updated.keyword = '';
        setData(updated);
    };

    const _handleStatus = (value) => {
        let updated = { ...data };
        if (value === 'DONE') {
            updated.size = 20;
        } else {
            updated.size = 0;
        }
        updated.page = 0;
        updated.status = value;
        updated.keyword = '';
        setData(updated);
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        setData(updated);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }

        _handleMergeList();
    };

    const _handleMergeList = () => {
        if (isDesignModule) {
            setMergeList(() => {
                const checkbox = document.getElementsByName('list[]');

                let arr = [];

                for (let i = 0; i < checkbox.length; i++) {
                    if (checkbox[i].checked === true) {
                        const value = Number(checkbox[i].value);
                        const target = raw.find((piece) => piece.project.items.find((piece) => piece.id === value));
                        arr.push({
                            project: target.project,
                            item: target.project.items.find((piece) => piece.id === value),
                        });
                    }
                }

                return arr;
            });
        }
    };

    const _handlePartListMerge = () => {
        if (merge === '') {
            alert.error('대표 아이템을 선택하여 주십시오.');
            return;
        }

        if (mergeList.length < 2) {
            alert.error('병합할 아이템을 선택하여 주십시오.');
            return;
        }

        const arr = [];

        for (let i = 0; i < mergeList.length; i++) {
            if (mergeList[i].item.id !== Number(merge)) {
                arr.push(mergeList[i].item);
            }
        }

        const data = {
            leader: mergeList.find((piece) => piece.item.id === Number(merge)).item,
            follower: arr,
        };

        PUT('partlist/merge', data, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    const _handlePartListSeparate = () => {
        if (mergeList.length < 1) {
            alert.error('분리할 아이템을 선택하여 주십시오.');
            return;
        }

        const arr = [];

        for (let i = 0; i < mergeList.length; i++) {
            arr.push(mergeList[i].item);
        }

        const data = {
            follower: arr,
        };

        PUT('partlist/separate', data, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handleCheck = () => {
        const checkbox = document.getElementsByName('list[]');

        let arr = [];

        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push({
                    id: checkbox[i].value,
                    dateOfCheck: checkDate,
                });
            }
        }

        PUT('design/work/check', arr, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={9}>
                        <select value={data.type} onChange={(e) => _handleType(e.target.value)}>
                            <option value='ALL'>선택 ① : 전체 프로젝트</option>
                            <option value='PRODUCT'>선택 ① : 완성품 프로젝트</option>
                            <option value='PART'>선택 ① : 부품 프로젝트</option>
                            <option value='AS'>선택 ① : A/S 프로젝트</option>
                        </select>
                        <select value={data.status} onChange={(e) => _handleStatus(e.target.value)}>
                            <option value='CURRENT'>선택 ② : 진행 중 아이템</option>
                            <option value='DONE'>선택 ② : 작업완료 아이템</option>
                        </select>
                        <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                        <button type='button' onClick={_handleKeyword}>
                            검색
                        </button>
                        {isDesignModule ? (
                            <Fragment>
                                <select name='merge' value={merge} onChange={(e) => setMerge(e.target.value)}>
                                    <option value=''>대표 아이템 설정</option>
                                    {mergeList.map((piece, index) => (
                                        <option value={piece.item.id} key={index}>
                                            {piece.project.code} -{' '}
                                            {piece.item.itemFamily !== null
                                                ? piece.item.itemFamily.name
                                                : piece.item.name}
                                        </option>
                                    ))}
                                </select>
                                <button type='button' onClick={_handlePartListMerge}>
                                    Part List 병합
                                </button>
                                <button type='button' onClick={_handlePartListSeparate}>
                                    Part List 분리
                                </button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <input
                                    type='date'
                                    name='checkDate'
                                    value={checkDate}
                                    onChange={(e) => setCheckDate(e.target.value)}
                                    onDoubleClick={() => setCheckDate('')}
                                />
                                <button type='button' onClick={_handleCheck}>
                                    일괄 검도 입력
                                </button>
                            </Fragment>
                        )}
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th width='3%'>
                        <input type='checkbox' onChange={_handleSelectAll} />
                    </th>
                    <th width='5%'>병합</th>
                    <th width='22.5%'>지시번호 (프로젝트명)</th>
                    <th width='5%'>No.</th>
                    <th width='22.5%'>아이템명</th>
                    <th width='15%'>규격</th>
                    <th width='7%'>단위</th>
                    <th width='7%'>수량</th>
                    <th width='10%'>{isDesignModule ? '설계완료지시일' : '검도완료지시일'}</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr key={index}>
                        <td className='center-td'>
                            <input
                                type='checkbox'
                                name='list[]'
                                value={isDesignModule ? piece.item.id : piece.item.designWork.id}
                                onChange={_handleMergeList}
                            />
                        </td>
                        <td className='center-td'>
                            {piece.item.partListMerge !== null && piece.item.partListMerge === -1 ? '대표' : ''}
                            {piece.item.partListMerge !== null && piece.item.partListMerge !== -1 ? '연결' : ''}
                        </td>
                        <td onClick={() => _handleSetProject(piece)}>
                            {piece.project.code} ({piece.project.name})
                        </td>
                        <td onClick={() => _handleSetProject(piece)} className='center-td'>
                            {piece.item.nbr}
                        </td>
                        <td onClick={() => _handleSetProject(piece)}>
                            {piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}
                        </td>
                        <td onClick={() => _handleSetProject(piece)}>{piece.item.specification}</td>
                        <td onClick={() => _handleSetProject(piece)} className='center-td'>
                            {piece.item.unit}
                        </td>
                        <td onClick={() => _handleSetProject(piece)} className='center-td'>
                            {piece.item.quantity}
                        </td>
                        <td onClick={() => _handleSetProject(piece)} className='center-td'>
                            {isDesignModule
                                ? piece.item.designPlanning.dateOfFinish
                                : piece.item.designPlanning.dateOfCheck}
                        </td>
                    </tr>
                ))}
                {data.status === 'DONE' ? (
                    data.size > raw.length ? (
                        Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                            <tr key={index}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        ))
                    ) : null
                ) : raw.length === 0 ? (
                    <tr>
                        <td className='center-td' colSpan={9}>
                            지시된 작업이 없습니다.
                        </td>
                    </tr>
                ) : null}
            </tbody>
            {data.status === 'DONE' ? (
                <tfoot>
                    <tr>
                        <td colSpan={9}>
                            <button type='button' onClick={() => _handlePage(0)}>
                                이전
                            </button>
                            <button type='reset'>{data.page + 1}</button>
                            <button type='button' onClick={() => _handlePage(1)}>
                                다음
                            </button>
                            <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                                <option value={10}>10개 데이터 조회</option>
                                <option value={20}>20개 데이터 조회</option>
                                <option value={40}>40개 데이터 조회</option>
                                <option value={60}>60개 데이터 조회</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
            ) : null}
        </table>
    );
};

export default Component;
