import React, { useState, useEffect } from 'react';

import qs from 'qs';

import Form from './form';

import ItemSelectorForPartList from '../../../../ui/selector/ItemSelectorForPartList';
import Popup from '../../../../ui/popup';

import { GET } from '../../../../../util/data';

const Component = () => {
    const { p, i } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    const [piece, setPiece] = useState({
        project: {
            id: -2,
            items: [],
        },
        item: {
            id: -2,
        },
    });

    useEffect(() => {
        if (p !== undefined && i !== undefined) {
            GET('project', { id: p }, (res) => {
                res.items.sort((a, b) => {
                    try {
                        return (
                            Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                            Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                        );
                    } catch {
                        return 1;
                    }
                });
                setPiece({
                    project: res,
                    item: res.items.find((piece) => piece.id === Number(i)),
                });
            });
        }
    }, [p, i]);

    const _handleExit = () => {
        setPiece({
            project: {
                id: -2,
                items: [],
            },
            item: {
                id: -2,
            },
        });
    };

    return (
        <div className='container'>
            <ItemSelectorForPartList piece={piece} setPiece={setPiece} />
            {piece.project.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form piece={piece} setPiece={setPiece} />}
                    exitFunc={_handleExit}
                />
            ) : null}
        </div>
    );
};

export default Component;
