import React, { useEffect, useState } from 'react';

import { GET } from '../../../../../util/data';

const DEPARTMENT = [
    {
        title: '설계부',
        options: [
            '설계 치수 부적합',
            '설계 발주 부적합',
            '설계 간섭 부적합',
            '설계 도면 미일치',
            '설계 부품 누락',
            '상대물 미일치 부적합',
        ],
    },
    {
        title: '기계가공부',
        options: ['가공 치수 부적합', 'Laser 설계 부적합', '가공 누락 부적합', '가공 소재 부적합'],
    },
    {
        title: '구매부',
        options: ['자재 부적합', '외주가공 치수 부적합', '자재구매 부적합'],
    },
    {
        title: '기계조립부',
        options: ['작업중 파손 부적합'],
    },
    {
        title: '기주기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '우성기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '탑기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '일흥기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '진성산업',
        options: ['도장 불량', '도장 색상 불량', '열변형', '도장 중 파손'],
    },
    {
        title: '기타제관',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
];

const Component = () => {
    const [initDate, setInitDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [department, setDepartment] = useState('');
    const [uncomformities, setUncomformities] = useState([]);

    useEffect(() => {
        GET('uncomformity/all', {}, (res) => {
            if (res !== null) {
                setUncomformities(res);
            }
        });
    }, []);

    return (
        <div className='container'>
            <table className='viewer form-lining'>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <input
                                type='date'
                                value={initDate}
                                onChange={(e) => setInitDate(e.target.value)}
                                onDoubleClick={() => setInitDate('')}
                            />
                            <input
                                type='date'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                onDoubleClick={() => setEndDate('')}
                            />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='15%'>부서명</th>
                        <th width='85%'>발생 불량 수량</th>
                    </tr>
                    {DEPARTMENT.map((piece1, index) => (
                        <tr
                            className={piece1.title === department ? 'selected-tr' : ''}
                            key={index}
                            onClick={() => setDepartment(piece1.title)}
                        >
                            <td className='center-td'>{piece1.title}</td>
                            <td>
                                {
                                    uncomformities
                                        .filter((piece2) => piece2.department === piece1.title)
                                        .filter((piece3) =>
                                            initDate !== '' && endDate !== ''
                                                ? new Date(initDate).getTime() <=
                                                      new Date(piece3.dateOfIssue).getTime() &&
                                                  new Date(piece3.dateOfIssue).getTime() <= new Date(endDate).getTime()
                                                : true
                                        ).length
                                }
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td className='center-td'>총계</td>
                        <td>
                            {
                                uncomformities.filter((piece3) =>
                                    initDate !== '' && endDate !== ''
                                        ? new Date(initDate).getTime() <= new Date(piece3.dateOfIssue).getTime() &&
                                          new Date(piece3.dateOfIssue).getTime() <= new Date(endDate).getTime()
                                        : true
                                ).length
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            {department !== '' ? (
                <table className='viewer form-lining'>
                    <tbody>
                        <tr>
                            <th width='15%'>발생 불량 유형</th>
                            <th width='85%'>발생 불량 수량</th>
                        </tr>
                        {DEPARTMENT.find((piece1) => piece1.title === department).options.map((piece2, index) => (
                            <tr key={index}>
                                <td className='center-td'>{piece2}</td>
                                <td>
                                    {
                                        uncomformities
                                            .filter(
                                                (piece3) => piece3.department === department && piece3.type === piece2
                                            )
                                            .filter((piece4) =>
                                                initDate !== '' && endDate !== ''
                                                    ? new Date(initDate).getTime() <=
                                                          new Date(piece4.dateOfIssue).getTime() &&
                                                      new Date(piece4.dateOfIssue).getTime() <=
                                                          new Date(endDate).getTime()
                                                    : true
                                            ).length
                                    }
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className='center-td'>총계</td>
                            <td>
                                {
                                    uncomformities
                                        .filter((piece3) => piece3.department === department)
                                        .filter((piece3) =>
                                            initDate !== '' && endDate !== ''
                                                ? new Date(initDate).getTime() <=
                                                      new Date(piece3.dateOfIssue).getTime() &&
                                                  new Date(piece3.dateOfIssue).getTime() <= new Date(endDate).getTime()
                                                : true
                                        ).length
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null}
        </div>
    );
};

export default Component;
