import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ItemSearch from './ItemSearch';
import Popup from '../../../ui/popup';

import { GET, GET_WITH_PAGING, DELETE } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

const Component = ({
    project,
    setProject,
    setItem,
    isProjectManager,
    productType,
    _handleStatusByProject,
    isItemSearch,
    defaultType,
}) => {
    const [totalPage, setTotalPage] = useState(null);
    const [raw, setRaw] = useState([]);
    const [itemRaw, setItemRaw] = useState([]);

    const [data, setData] = useState({
        page: 0,
        size: 20,
        sort: !isProjectManager ? 'type' : 'code',
        order: 'DESC',
        type: !isProjectManager ? (defaultType === undefined ? 'ALL' : defaultType) : productType,
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (project.id === -2) {
            GET_WITH_PAGING('project/paging', data, (res) => {
                if (active && res !== null) {
                    setTotalPage(res.data.totalPages);
                    for (let i = 0; i < res.data.content.length; i++) {
                        res.data.content[i].items.sort((a, b) => {
                            try {
                                return (
                                    Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                    Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                                );
                            } catch {
                                return 1;
                            }
                        });
                    }
                    setRaw(res.data.content);
                }
            });
        }
    }, [project, data, active]);

    const _handleSetProject = (piece) => {
        setProject(piece);
    };

    const _handleType = (value) => {
        let updated = { ...data };
        updated.type = value;
        if (value !== 'ALL') {
            updated.sort = 'code';
        } else {
            updated.sort = 'type';
        }
        updated.page = 0;
        setData(updated);
    };

    const _handleStatus = (value) => {
        let updated = { ...data };
        updated.status = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        updated.page = 0;
        setData(updated);
    };

    const _handleItemSearch = () => {
        const keyword = document.querySelector('.keyword').value.trim();
        const data = { keyword: keyword };

        if (keyword !== '') {
            GET('item/search', data, (res) => {
                if (active && res !== null) {
                    setItemRaw(res);
                }
            });
        }
    };

    const _handleExit = () => {
        setItemRaw([]);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            if (updated.page + 1 < totalPage) {
                updated.page += 1;
            }
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleSort = (value) => {
        let updated = { ...data };
        updated.sort = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleOrder = (value) => {
        let updated = { ...data };
        updated.order = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleRegister = () => {
        setProject({
            id: -1,
        });
    };

    const _handleSelectAll = () => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = true;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');

                        let arr = [];

                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }

                        if (arr.length > 0) {
                            DELETE('project/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setData((data) => {
                                        let changedData = { ...data };
                                        changedData.detector = changedData.detector++;
                                        return changedData;
                                    });
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handleNewProject = (orderDate) => {
        const today = new Date();
        const target = new Date(orderDate);

        const diff = ((target.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

        return Number(diff);
    };

    return (
        <Fragment>
            <table className={project.id === -2 ? 'selector' : 'hidden'}>
                <thead>
                    <tr>
                        <td colSpan={isProjectManager ? 7 : 6}>
                            {isProjectManager ? (
                                <Fragment>
                                    <button type='button' onClick={_handleRegister}>
                                        신규등록
                                    </button>
                                    <button type='button' onClick={_handleSelectAll}>
                                        전체선택
                                    </button>
                                    <button type='button' onClick={_handleDeleteAll}>
                                        선택삭제
                                    </button>
                                </Fragment>
                            ) : null}
                            <select
                                value={data.type}
                                onChange={(e) => _handleType(e.target.value)}
                                disabled={isProjectManager ? true : false}
                            >
                                <option value='ALL'>선택 ① : 전체 프로젝트</option>
                                <option value='PRODUCT'>선택 ① : 완성품 프로젝트</option>
                                <option value='PART'>선택 ① : 부품 프로젝트</option>
                                <option value='AS'>선택 ① : A/S 프로젝트</option>
                            </select>
                            <select value={data.status} onChange={(e) => _handleStatus(e.target.value)}>
                                <option value='PENDING' disabled={isProjectManager ? false : true}>
                                    선택 ② : 대기 프로젝트
                                </option>
                                <option value='CURRENT'>선택 ② : 진행 프로젝트</option>
                                <option value='DONE'>선택 ② : 완료 프로젝트</option>
                            </select>
                            <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                            <button type='button' onClick={_handleKeyword}>
                                검색
                            </button>
                            {isItemSearch ? (
                                <button type='button' onClick={_handleItemSearch}>
                                    아이템(시리얼) 검색
                                </button>
                            ) : null}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {isProjectManager ? <th width='5%'>선택</th> : null}
                        <th width='7%'>구분</th>
                        <th width='23%'>지시번호</th>
                        <th width='23%'>프로젝트명</th>
                        <th width={isProjectManager ? '18%' : '23%'}>고객사</th>
                        <th width='12%'>지시일자</th>
                        <th width='12%'>납품일자</th>
                    </tr>
                    {raw.map((piece, index) => (
                        <tr key={index}>
                            {isProjectManager ? (
                                <td className='center-td'>
                                    <input type='checkbox' name='list[]' value={piece.id} />
                                </td>
                            ) : null}
                            <td className='center-td' onClick={() => _handleSetProject(piece)}>
                                {piece.type === 'PRODUCT' ? '완성품' : piece.type === 'PART' ? '부품' : 'A/S'}
                            </td>
                            <td onClick={() => _handleSetProject(piece)}>
                                {_handleStatusByProject !== undefined ? (
                                    _handleStatusByProject(piece) === 0 ? (
                                        <span className='done-project'>[입력완료]</span>
                                    ) : (
                                        <span className='current-project'>[미완료]</span>
                                    )
                                ) : null}{' '}
                                {piece.code}{' '}
                                {_handleNewProject(piece.dateOfOrder) > -7 ? (
                                    <span className='new-project'>[NEW]</span>
                                ) : null}
                            </td>
                            <td onClick={() => _handleSetProject(piece)}>{piece.name}</td>
                            <td onClick={() => _handleSetProject(piece)}>{piece.customer}</td>
                            <td onClick={() => _handleSetProject(piece)} className='center-td'>
                                {piece.dateOfOrder}
                            </td>
                            <td onClick={() => _handleSetProject(piece)} className='center-td'>
                                {piece.dateOfDue}
                            </td>
                        </tr>
                    ))}
                    {data.size > raw.length
                        ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                              <tr key={index}>
                                  {isProjectManager ? <td></td> : null}
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                              </tr>
                          ))
                        : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={isProjectManager ? 7 : 6}>
                            <button type='button' onClick={() => _handlePage(0)}>
                                이전
                            </button>
                            <button type='reset'>{data.page + 1}</button>
                            <button type='reset'>/</button>
                            <button type='reset'>{totalPage === null ? '10' : totalPage}</button>
                            <button type='button' onClick={() => _handlePage(1)}>
                                다음
                            </button>
                            <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                                <option value={10}>10개 데이터 조회</option>
                                <option value={20}>20개 데이터 조회</option>
                                <option value={40}>40개 데이터 조회</option>
                                <option value={60}>60개 데이터 조회</option>
                            </select>
                            <select value={data.order} onChange={(e) => _handleOrder(e.target.value)}>
                                <option value='DESC'>정렬 ② : 내림차순</option>
                                <option value='ASC'>정렬 ② : 오름차순</option>
                            </select>
                            <select value={data.sort} onChange={(e) => _handleSort(e.target.value)}>
                                <option value='type'>정렬 ① : 구분</option>
                                <option value='code'>정렬 ① : 지시번호</option>
                                <option value='name'>정렬 ① : 프로젝트명</option>
                                <option value='customer'>정렬 ① : 고객사</option>
                                <option value='dateOfOrder'>정렬 ① : 지시일자</option>
                                <option value='dateOfDue'>정렬 ① : 납품일자</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
            </table>
            {itemRaw.length !== 0 ? (
                <Popup
                    title={`아이템 & 시리얼 검색 - ${document.querySelector('.keyword').value}`}
                    data={<ItemSearch itemRaw={itemRaw} setProject={setProject} setItem={setItem} />}
                    exitFunc={_handleExit}
                    hasButton={false}
                />
            ) : null}
        </Fragment>
    );
};

export default Component;
