import React from 'react';

import ProjectSelectorForPurchaseReport from '../../../../ui/selector/ProjectSelectorForPurchaseReport';

const Component = () => {
    return (
        <div className='container'>
            <ProjectSelectorForPurchaseReport />
        </div>
    );
};

export default Component;
