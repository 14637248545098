import React, { Fragment, useEffect } from 'react';

import uuid from 'react-uuid';

const Component = ({ project, item, setItem, showItemList, isAssembly }) => {
    const uid = 'selector-' + uuid();

    useEffect(() => {
        const textarea = document.querySelector(`.${uid}`);
        textarea.style.height = textarea.scrollHeight + 'px';
    }, [uid]);

    const _handleItem = (piece) => {
        if (setItem !== undefined) {
            setItem(piece);
        }
    };

    return (
        <Fragment>
            <table className='viewer'>
                <tbody>
                    <tr>
                        <th colSpan={4}>프로젝트정보</th>
                    </tr>
                    <tr>
                        <th width='15%'>구분</th>
                        <td width='35%' className='center-td'>
                            {project.type === 'PRODUCT'
                                ? '완성품'
                                : project.type === 'PART'
                                ? '부품'
                                : project.type === 'AS'
                                ? ' A/S'
                                : '미등록 프로젝트'}
                        </td>
                        <th width='15%'>지시번호</th>
                        <td width='35%' className='center-td'>
                            {project.code}
                        </td>
                    </tr>
                    <tr>
                        <th>프로젝트명</th>
                        <td className='center-td'>{project.name}</td>
                        <th>납품처</th>
                        <td className='center-td'>{project.customer}</td>
                    </tr>
                    <tr>
                        <th>오더번호</th>
                        <td className='center-td'>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                        <th>전기사양</th>
                        <td className='center-td'>{project.electricity !== undefined ? project.electricity : ''}</td>
                    </tr>
                    <tr>
                        <th>PLC Type</th>
                        <td className='center-td'>{project.plc !== undefined ? project.plc : ''}</td>
                        <th>색상</th>
                        <td className='center-td'>{project.color !== undefined ? project.color : ''}</td>
                    </tr>
                    <tr>
                        <th>지시일자</th>
                        <td className='center-td'>{project.dateOfOrder !== undefined ? project.dateOfOrder : ''}</td>
                        <th>납품일자</th>
                        <td className='center-td'>{project.dateOfDue !== undefined ? project.dateOfDue : ''}</td>
                    </tr>
                </tbody>
            </table>
            {showItemList ? (
                <table className='list'>
                    <thead>
                        <tr>
                            <th width='10%'>No.</th>
                            <th width={isAssembly !== undefined && isAssembly ? '25%' : '35%'}>아이템명</th>
                            <th width='15%'>규격</th>
                            <th width='10%'>단위</th>
                            <th width='10%'>수량</th>
                            {isAssembly !== undefined && isAssembly ? <th width='10%'>진도</th> : null}
                            <th width='20%'>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {project.items.length !== 0 ? (
                            project.items.map((piece, index) => (
                                <tr
                                    className={item !== undefined ? (piece.id === item.id ? 'selected-tr' : '') : ''}
                                    key={index}
                                    onClick={() => _handleItem(piece)}
                                >
                                    <td className='center-td'>{piece.nbr}</td>
                                    <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                    <td>{piece.specification}</td>
                                    <td className='center-td'>{piece.unit}</td>
                                    <td className='center-td'>{piece.quantity}</td>
                                    {isAssembly !== undefined && isAssembly ? (
                                        <td className='center-td'>{piece.assemblyWork.progress}</td>
                                    ) : null}
                                    <td>{piece.note}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={6}>
                                    아이템 정보가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            ) : null}
            <table className='viewer'>
                <tbody>
                    <tr>
                        <th>세부작업 지시사항</th>
                    </tr>
                    <tr>
                        <td>
                            <textarea className={uid} name='note' defaultValue={project.note} readOnly />
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
