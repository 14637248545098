import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import FileUploader from '../../../ui/uploader/FileUploader';

import { GET, POST, PUT } from '../../../../util/data';

const Component = ({ afterService }) => {
    const [projects, setProjects] = useState([]);
    const [attachments, setAttachments] = useState(afterService.id === -1 ? [] : [...afterService.attachments]);
    const [active, setActive] = useState(true);
    const [done, setDone] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('project/only', {}, (res) => {
            if (active && res !== null) {
                setProjects(res);
            }
        });
    }, [active]);

    const _handleForm = (e) => {
        e.preventDefault();

        if (afterService.id > -1 || (afterService.id === -1 && !done)) {
            const func = afterService.id === -1 ? POST : PUT;

            const form = document.activeForm;

            const params = {
                project:
                    form.project.value === 'afterServiceProject' ||
                    form.project.value === 'registeredAfterServiceProject'
                        ? null
                        : {
                              id: form.project.value,
                          },
                afterServiceProject:
                    form.project.value !== 'afterServiceProject'
                        ? null
                        : {
                              code: form.afterServiceProjectCode.value,
                              name: form.afterServiceProjectName.value,
                              customer: form.afterServiceProjectCustomer.value,
                              dateOfDue: form.afterServiceProjectDateOfDue.value,
                          },
                code: form.code.value,
                dateOfReceipt: form.dateOfReceipt.value,
                department: form.department.value,
                charger: form.charger.value,
                requester: form.requester.value,
                contact: form.contact.value,
                region: form.region.value,
                method: form.method.value,
                content: form.content.value,
                attachments: attachments,
            };

            if (afterService.id !== -1) {
                params.id = afterService.id;
            }

            setDone(true);

            func('as', params, (res) => {
                if (res !== null) {
                    alert.success(res);
                }
            });
        }
    };

    const _handleAfterServiceProject = (e) => {
        const value = e.target.value;

        const form = document.activeForm;
        const project = projects.find((piece) => piece.id === Number(value));

        if (value === 'afterServiceProject') {
            form.afterServiceProjectCode.value = '';
            form.afterServiceProjectName.value = '';
            form.afterServiceProjectCustomer.value = '';
            form.afterServiceProjectDateOfDue.value = '';
        } else {
            form.afterServiceProjectCode.value = project.code;
            form.afterServiceProjectName.value = project.name;
            form.afterServiceProjectCustomer.value = project.customer;
            form.afterServiceProjectDateOfDue.value = project.dateOfDue;
        }
    };

    return (
        <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={4}>프로젝트 정보</th>
                    </tr>
                    <tr>
                        <th>프로젝트</th>
                        <td colSpan={3}>
                            {projects.length !== 0 ? (
                                <select
                                    name='project'
                                    defaultValue={
                                        afterService.id > -1
                                            ? afterService.project !== null
                                                ? afterService.project.id
                                                : 'registeredAfterServiceProject'
                                            : 'afterServiceProject'
                                    }
                                    onChange={_handleAfterServiceProject}
                                >
                                    <option value='afterServiceProject'>미등록 프로젝트</option>
                                    {projects.map((piece, index) => (
                                        <option value={piece.id} key={index}>
                                            {piece.code} ({piece.name})
                                        </option>
                                    ))}
                                    <option value='registeredAfterServiceProject'>기등록 프로젝트</option>
                                </select>
                            ) : (
                                <input type='hidden' name='afterServiceProject' value='' />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th width='15%'>지시번호</th>
                        <td width='35%'>
                            <input
                                type='text'
                                name='afterServiceProjectCode'
                                defaultValue={
                                    afterService.id > -1
                                        ? afterService.project !== null
                                            ? afterService.project.code
                                            : afterService.afterServiceProject.code
                                        : ''
                                }
                            />
                        </td>
                        <th width='15%'>프로젝트명</th>
                        <td width='35%'>
                            <input
                                type='text'
                                name='afterServiceProjectName'
                                defaultValue={
                                    afterService.id > -1
                                        ? afterService.project !== null
                                            ? afterService.project.name
                                            : afterService.afterServiceProject.name
                                        : ''
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>납품처</th>
                        <td>
                            <input
                                type='text'
                                name='afterServiceProjectCustomer'
                                defaultValue={
                                    afterService.id > -1
                                        ? afterService.project !== null
                                            ? afterService.project.customer
                                            : afterService.afterServiceProject.customer
                                        : ''
                                }
                            />
                        </td>
                        <th>납품일자</th>
                        <td>
                            <input
                                type='date'
                                name='afterServiceProjectDateOfDue'
                                defaultValue={
                                    afterService.id > -1
                                        ? afterService.project !== null
                                            ? afterService.project.dateOfDue
                                            : afterService.afterServiceProject.dateOfDue
                                        : ''
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={4}>A/S 정보</th>
                    </tr>
                    <tr>
                        <th width='15%'>접수번호</th>
                        <td width='35%'>
                            <input
                                type='text'
                                name='code'
                                defaultValue={afterService.id > -1 ? afterService.code : ''}
                            />
                        </td>
                        <th width='15%'>접수일</th>
                        <td width='35%'>
                            <input
                                type='date'
                                name='dateOfReceipt'
                                defaultValue={afterService.id > -1 ? afterService.dateOfReceipt : ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>접수부서</th>
                        <td>
                            <input
                                type='text'
                                name='department'
                                defaultValue={afterService.id > -1 ? afterService.department : ''}
                            />
                        </td>
                        <th>접수자</th>
                        <td>
                            <input
                                type='text'
                                name='charger'
                                defaultValue={afterService.id > -1 ? afterService.charger : ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>요구자</th>
                        <td>
                            <input
                                type='text'
                                name='requester'
                                defaultValue={afterService.id > -1 ? afterService.requester : ''}
                            />
                        </td>
                        <th>연락처</th>
                        <td>
                            <input
                                type='text'
                                name='contact'
                                defaultValue={afterService.id > -1 ? afterService.contact : ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>지역</th>
                        <td>
                            <input
                                type='text'
                                name='region'
                                defaultValue={afterService.id > -1 ? afterService.region : ''}
                            />
                        </td>
                        <th>접수방법</th>
                        <td>
                            <input
                                type='text'
                                name='method'
                                defaultValue={afterService.id > -1 ? afterService.method : ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>접수내용</th>
                        <td colSpan={3}>
                            <textarea
                                className='wide-textarea'
                                name='content'
                                defaultValue={afterService.id > -1 ? afterService.content : ''}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <FileUploader attachments={attachments} setAttachments={setAttachments} />
            <button className='proceedButton'>저장</button>
        </form>
    );
};

export default Component;
