import React, { useState, useEffect } from 'react';

import './index.css';
import { GET } from '../../../../util/data';

const Component = () => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const textarea = document.querySelector('.update-list');
        textarea.scroll(0, textarea.scrollHeight);
    });

    const _handleGeneral = () => {
        GET('update/update_20200831/general', {}, (res) => {});
    };

    const _handleProject = (e) => {
        e.preventDefault();

        const form = document.update_form;

        const data = {
            i: form.initDate.value,
            e: form.endDate.value,
        };

        GET('update/update_20200831/project', data, (res) => {});

        setHistory((history) => {
            const updated = [...history];
            updated.push(data);
            return updated;
        });
    };

    const _handlePostProject = () => {
        GET('update/update_20200831/additional', {}, (res) => {});
    };

    return (
        <div className='update'>
            <div className='update-container'>
                <div className='title'>GENERAL DATA LOAD</div>
                <button className='update-button' onClick={_handleGeneral}>
                    EXECUTE
                </button>
                <div className='title'>PROJECT DATA LOAD</div>
                <form name='update_form' onSubmit={_handleProject}>
                    <input className='update-input' type='date' name='initDate' required />
                    <input className='update-input' type='date' name='endDate' required />
                    <button className='update-button'>EXECUTE</button>
                </form>
                <div className='update-list'>
                    {history.map((piece, index) => (
                        <div className='update-list-element' key={index}>
                            {piece.i} ~ {piece.e}
                        </div>
                    ))}
                </div>
                <div className='title'>POST-PROJECT DATA LOAD</div>
                <button className='update-button' onClick={_handlePostProject}>
                    EXECUTE
                </button>
            </div>
        </div>
    );
};

export default Component;
