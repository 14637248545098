import React from 'react';
import { useAlert } from 'react-alert';

import './index.css';

const Component = ({ hasButton, buttonTitle }) => {
    const alert = useAlert();

    const _handleSubmit = () => {
        const proceed = document.getElementsByClassName('proceedButton');

        if (proceed.length === 1) {
            proceed[0].click();
        } else {
            alert.error('업데이트 중 에러 발생! 관리자에게 문의하십시오.');
        }
    };

    return (
        <div className='popupFooter'>
            {hasButton !== undefined && hasButton ? (
                <button className='popupFooter-submit' onClick={_handleSubmit}>
                    {buttonTitle !== null && buttonTitle !== undefined ? buttonTitle : '저장'}
                </button>
            ) : null}
        </div>
    );
};

export default Component;
