import React from 'react';

import { info } from '../../../configs';

import './index.css';

const Component = () => {
    return (
        <footer className='pop-footer'>
            <div className='pop-footer-server'>Powered by {info.server}</div>
            <div className='pop-footer-copyright'>{info.copyright}</div>
            <div className='pop-footer-version'>
                {info.version} ({info.commit})
                {info.isBeta ? ' (beta)' : null}
            </div>
        </footer>
    );
};

export default Component;
