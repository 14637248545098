import React, { useState, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';
import FileUploader from '../../../../ui/uploader/FileUploader';

import { POST, PUT, GET } from '../../../../../util/data';

const Component = ({ project, setProject, report, setReport }) => {
    const [attachments, setAttachments] = useState(report.id === -1 ? [] : [...report.attachments]);
    const [done, setDone] = useState(false);

    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        if (report.id > -1 || (report.id === -1 && !done)) {
            const func = report.id === -1 ? POST : PUT;

            const form = document.activeForm;

            let params = {
                project: { id: project.id },
                dateOfIssue: form.dateOfIssue.value,
                charger: form.charger.value,
                purpose: form.purpose.value,
                location: form.location.value,
                content: form.content.value,
                attachments: attachments,
            };

            if (report.id !== -1) {
                params.id = report.id;
            }

            setDone(true);

            func('install/report', params, (res1) => {
                if (res1 !== null) {
                    GET('install/report/project', { id: project.id }, (res2) => {
                        setProject((project) => {
                            let updated = { ...project };
                            updated.installReports = res2;
                            return updated;
                        });
                        alert.success(res1);
                    });
                }
            });
        }
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='15%'>작성일</th>
                            <td width='35%'>
                                <input
                                    type='date'
                                    name='dateOfIssue'
                                    defaultValue={report.id !== -1 ? report.dateOfIssue : ''}
                                />
                            </td>
                            <th width='15%'>출장자</th>
                            <td width='35%'>
                                <input
                                    type='text'
                                    name='charger'
                                    defaultValue={report.id !== -1 ? report.charger : ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>출장목적</th>
                            <td>
                                <input
                                    type='text'
                                    name='purpose'
                                    defaultValue={report.id !== -1 ? report.purpose : ''}
                                />
                            </td>
                            <th>출장지</th>
                            <td>
                                <input
                                    type='text'
                                    name='location'
                                    defaultValue={report.id !== -1 ? report.location : ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>보고내용</th>
                            <td colSpan={3}>
                                <textarea
                                    className='wide-textarea'
                                    name='content'
                                    defaultValue={report.id !== -1 ? report.content : ''}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <FileUploader attachments={attachments} setAttachments={setAttachments} />
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
