import React, { useState } from 'react';

import Form from './form';

import PurchaseQuotationSelector from '../../../../ui/selector/PurchaseQuotationSelector';
import Popup from '../../../../ui/popup';

const Component = () => {
    const [element, setElement] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setElement({
            id: -2,
        });
    };

    return (
        <div className='container'>
            {element.id === -2 ? <PurchaseQuotationSelector element={element} setElement={setElement} /> : null}
            {element.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form element={element} setElement={setElement} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
