import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import List from './list';

const Component = () => {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!user.admin) {
            confirmAlert({
                message: '권한이 없습니다.',
                buttons: [
                    {
                        label: 'Okay',
                        onClick: () => (window.location.href = '/'),
                    },
                ],
            });
        }
    }, [user]);

    return (
        <div className='container'>
            <List />
        </div>
    );
};

export default Component;
