import React, { useState, useEffect, Fragment } from 'react';

import { GET } from '../../../../util/data';

const Component = ({ project, setProject }) => {
    const [raw, setRaw] = useState([]);
    const [keyword, setKeyword] = useState('');

    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (project.id === -2) {
            GET('project/confirmation', {}, (res) => {
                if (active) {
                    setRaw(res);
                }
            });
        }
    }, [project, active]);

    const _handleSetProject = (piece) => {
        setProject(piece);
    };

    return (
        <Fragment>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={6}>
                            <input
                                className='keyword'
                                type='text'
                                placeholder='검색어'
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='10%'>구분</th>
                        <th width='20%'>지시번호</th>
                        <th width='25%'>프로젝트명</th>
                        <th width='25%'>고객사</th>
                        <th width='10%'>지시일자</th>
                        <th width='10%'>납품일자</th>
                    </tr>
                    {raw.length !== 0 ? (
                        raw
                            .filter((piece) =>
                                piece !== ''
                                    ? piece.code.match(keyword) ||
                                      piece.name.match(keyword) ||
                                      piece.customer.match(keyword)
                                    : true
                            )
                            .map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td' onClick={() => _handleSetProject(piece)}>
                                        {piece.type === 'PRODUCT' ? '완성품' : piece.type === 'PART' ? '부품' : 'A/S'}
                                    </td>
                                    <td onClick={() => _handleSetProject(piece)}>{piece.code}</td>
                                    <td onClick={() => _handleSetProject(piece)}>{piece.name}</td>
                                    <td onClick={() => _handleSetProject(piece)}>{piece.customer}</td>
                                    <td onClick={() => _handleSetProject(piece)} className='center-td'>
                                        {piece.dateOfOrder}
                                    </td>
                                    <td onClick={() => _handleSetProject(piece)} className='center-td'>
                                        {piece.dateOfDue}
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={6}>
                                미결재 프로젝트가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;