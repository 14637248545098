import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { print_data_set } from '../../../../actions';

import { GET } from '../../../../util/data';

import './index.css';

const Component = ({ print }) => {
    const [project, setProject] = useState({ id: -1 });
    const dispatch = useDispatch();

    useEffect(() => {
        GET('project', { id: print.data }, (res) => {
            res.items.sort((a, b) => {
                try {
                    return (
                        Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                        Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                    );
                } catch {
                    return 1;
                }
            });
            setProject(res);
        });
    }, [print]);

    useEffect(() => {
        if (project.id !== -1) {
            window.print();
        }
    }, [project]);

    const _handlePrint = () => {
        window.print();
    };

    const _handleExit = () => {
        dispatch(print_data_set({ type: 0, data: null }));
    };

    return project.id !== -1 ? (
        <div className='projectPrint'>
            <table className='approval'>
                <tbody>
                    <tr className='narrow-tr'>
                        <td className='title-td' width='65%' rowSpan={2}>
                            출 장 업 무 보 고 서
                        </td>
                        <td width='5%' rowSpan={2}>
                            결재
                        </td>
                        <td width='10%'>담당</td>
                        <td width='10%'>부서장</td>
                        <td width='10%'>대표이사</td>
                    </tr>
                    <tr className='wide-tr'>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <table className='print'>
                <thead>
                    <tr>
                        <th width='15%'>출장지</th>
                        <td width='35%'>
                            {project.installReports.length !== 0 ? project.installReports[0].location : ''}
                        </td>
                        <th width='15%'>출장목적</th>
                        <td width='35%'>
                            {project.installReports.length !== 0 ? project.installReports[0].purpose : ''}
                        </td>
                    </tr>
                    <tr>
                        <th>세부내용</th>
                        <td colSpan={3}>
                            <table className='inner-print'>
                                <tbody>
                                    <tr>
                                        <th width='15%'>작성일자</th>
                                        <th width='70%'>보고내용</th>
                                        <th width='15%'>출장자</th>
                                    </tr>
                                    {project.installReports.map((piece, index) => (
                                        <tr key={index}>
                                            <td className='center-td'>{piece.dateOfIssue}</td>
                                            <td>
                                                <textarea
                                                    className='textarea-install-report-viewer'
                                                    defaultValue={piece.content}
                                                    readOnly
                                                />
                                            </td>
                                            <td className='center-td'>{piece.charger}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th>결재권자 지시사항</th>
                        <td colSpan={3}>
                            <textarea readOnly />
                        </td>
                    </tr>
                </thead>
            </table>
            <button className='printButton' onClick={_handleExit}>
                닫기
            </button>
            <button className='printButton' onClick={_handlePrint}>
                출력
            </button>
        </div>
    ) : null;
};

export default Component;
