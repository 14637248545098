import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import { GET } from '../../../../util/data';

const Component = () => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 20,
    });
    const [active, setActive] = useState(true);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (!user.admin) {
            confirmAlert({
                message: '권한이 없습니다.',
                buttons: [
                    {
                        label: 'Okay',
                        onClick: () => (window.location.href = '/'),
                    },
                ],
            });
        }
    }, [user]);

    useEffect(() => {
        GET('log/all', data, (res) => {
            if (active && res !== null) {
                setRaw(res);
            }
        });
    }, [active, data]);

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    return (
        <div className='container'>
            <table className='selector'>
                <tbody>
                    <tr>
                        <th width='20%'>ACCESS TIMESTAMP</th>
                        <th width='20%'>METHOD</th>
                        <th width='20%'>URL</th>
                        <th width='20%'>IP ADDRESS</th>
                        <th width='20%'>ACCESS USER</th>
                    </tr>
                    {raw.map((piece, index) => (
                        <tr key={index}>
                            <td>
                                {piece.dateOfIssue} {piece.timeOfIssue}
                            </td>
                            <td>{piece.method}</td>
                            <td>{piece.url}</td>
                            <td>{piece.ip}</td>
                            <td>{piece.username}</td>
                        </tr>
                    ))}
                    {data.size > raw.length
                        ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                              <tr key={index}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                              </tr>
                          ))
                        : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5}>
                            <button type='button' onClick={() => _handlePage(0)}>
                                이전
                            </button>
                            <button type='reset'>{data.page + 1}</button>
                            <button type='button' onClick={() => _handlePage(1)}>
                                다음
                            </button>
                            <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                                <option value={20}>20개 데이터 조회</option>
                                <option value={40}>40개 데이터 조회</option>
                                <option value={60}>60개 데이터 조회</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default Component;
