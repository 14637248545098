import React, { useState, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { PUT } from '../../../../util/data';

import './index.css';

const Component = ({ project }) => {
    const [system, setSystem] = useState('');
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const arr = [];

        if (system !== '') {
            arr[0] = {
                id: project.controlSystems.find((piece) => (system === '' ? piece : piece.id === Number(system)))
                    .controlWork.id,
                chargerOfDrawing: form.chargerOfDrawing.value,
                chargerOfPanel: form.chargerOfPanel.value,
                chargerOfWiring: form.chargerOfWiring.value,
                chargerOfTesting: form.chargerOfTesting.value,
                progress: form.progress.value,
                chargerOfDissolution: form.chargerOfDissolution.value,
                dateOfDrawing: form.dateOfDrawing.value,
                dateOfPanel: form.dateOfPanel.value,
                dateOfWiring: form.dateOfWiring.value,
                dateOfTesting: form.dateOfTesting.value,
                dateOfDissolution: form.dateOfDissolution.value,
            };
        } else if (project.controlSystems.length === 1) {
            arr[0] = {
                id: project.controlSystems[0].controlWork.id,
                chargerOfDrawing: form.chargerOfDrawing.value,
                chargerOfPanel: form.chargerOfPanel.value,
                chargerOfWiring: form.chargerOfWiring.value,
                chargerOfTesting: form.chargerOfTesting.value,
                progress: form.progress.value,
                chargerOfDissolution: form.chargerOfDissolution.value,
                dateOfDrawing: form.dateOfDrawing.value,
                dateOfPanel: form.dateOfPanel.value,
                dateOfWiring: form.dateOfWiring.value,
                dateOfTesting: form.dateOfTesting.value,
                dateOfDissolution: form.dateOfDissolution.value,
            };
        } else {
            for (let i = 0; i < project.controlSystems.length; i++) {
                arr.push({
                    id: project.controlSystems[i].controlWork.id,
                    chargerOfDrawing: form.chargerOfDrawing[i].value,
                    chargerOfPanel: form.chargerOfPanel[i].value,
                    chargerOfWiring: form.chargerOfWiring[i].value,
                    chargerOfTesting: form.chargerOfTesting[i].value,
                    progress: form.progress[i].value,
                    chargerOfDissolution: form.chargerOfDissolution[i].value,
                    dateOfDrawing: form.dateOfDrawing[i].value,
                    dateOfPanel: form.dateOfPanel[i].value,
                    dateOfWiring: form.dateOfWiring[i].value,
                    dateOfTesting: form.dateOfTesting[i].value,
                    dateOfDissolution: form.dateOfDissolution[i].value,
                });
            }
        }

        PUT('control/work', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <div>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <select className='controlWork-select' value={system} onChange={(e) => setSystem(e.target.value)}>
                    {project.controlSystems.length === 0 ? (
                        <option>등록된 시스템이 없습니다.</option>
                    ) : (
                        <Fragment>
                            <option value=''>전체 시스템</option>
                            {project.controlSystems.map((piece, index) => (
                                <option value={piece.id} key={index}>
                                    [{piece.nbr}] {piece.name}
                                </option>
                            ))}
                        </Fragment>
                    )}
                </select>
                {project.controlSystems
                    .filter((piece) => (system === '' ? piece : piece.id === Number(system)))
                    .map((piece, index) => (
                        <div className='controlSystem-box' key={index}>
                            <table className='form'>
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>시스템 정보</th>
                                    </tr>
                                    <tr>
                                        <th width='20%'>시스템 No.</th>
                                        <td width='80%'>{piece.nbr}</td>
                                    </tr>
                                    <tr>
                                        <th>시스템명</th>
                                        <td>{piece.name}</td>
                                    </tr>
                                    <tr>
                                        <th>시스템 설명</th>
                                        <td>{piece.description}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='form'>
                                <tbody>
                                    <tr>
                                        <th colSpan={6}>아이템 정보</th>
                                    </tr>
                                    <tr>
                                        <th width='5%'>No.</th>
                                        <th width='30%'>아이템명</th>
                                        <th width='20%'>규격</th>
                                        <th width='15%'>단위</th>
                                        <th width='15%'>수량</th>
                                        <th width='15%'>기계조립 공정율</th>
                                    </tr>
                                    {piece.controlSystemAndItems.length !== 0 ? (
                                        piece.controlSystemAndItems.map((piece, index) => (
                                            <tr key={index}>
                                                <td className='center-td'>{piece.item.nbr}</td>
                                                <td>
                                                    {piece.item.productFamily !== null
                                                        ? `${piece.item.productFamily.name} / ${piece.item.itemFamily.name}`
                                                        : piece.item.name}
                                                </td>
                                                <td>{piece.item.specification}</td>
                                                <td className='center-td'>{piece.item.unit}</td>
                                                <td className='center-td'>{piece.item.quantity}</td>
                                                <td className='center-td'>{piece.item.assemblyWork.progress} %</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className='center-td' colSpan={6}>
                                                조회된 데이터가 없습니다.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <table className='form'>
                                <tbody>
                                    <tr>
                                        <th colSpan={6}>제어기술 정보</th>
                                    </tr>
                                    <tr>
                                        <th width='16.6%'>전기도면 작업</th>
                                        <th width='16.6%'>판넬 작업</th>
                                        <th width='16.6%'>배선 작업</th>
                                        <th width='16.6%'>시운전 작업</th>
                                        <th width='16.6%'>전체 진도</th>
                                        <th width='16.6%'>해체 작업</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type='text'
                                                name='chargerOfDrawing'
                                                defaultValue={piece.controlWork.chargerOfDrawing}
                                                placeholder='담당자'
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name='chargerOfPanel'
                                                defaultValue={piece.controlWork.chargerOfPanel}
                                                placeholder='담당자'
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name='chargerOfWiring'
                                                defaultValue={piece.controlWork.chargerOfWiring}
                                                placeholder='담당자'
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name='chargerOfTesting'
                                                defaultValue={piece.controlWork.chargerOfTesting}
                                                placeholder='담당자'
                                            />
                                        </td>
                                        <td rowSpan={2}>
                                            <select name='progress' defaultValue={piece.controlWork.progress}>
                                                <option value={0}>0</option>
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option value={25}>25</option>
                                                <option value={30}>30</option>
                                                <option value={35}>35</option>
                                                <option value={40}>40</option>
                                                <option value={45}>45</option>
                                                <option value={50}>50</option>
                                                <option value={55}>55</option>
                                                <option value={60}>60</option>
                                                <option value={65}>65</option>
                                                <option value={70}>70</option>
                                                <option value={75}>75</option>
                                                <option value={80}>80</option>
                                                <option value={85}>85</option>
                                                <option value={90}>90</option>
                                                <option value={95}>95</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type='text'
                                                name='chargerOfDissolution'
                                                defaultValue={piece.controlWork.chargerOfDissolution}
                                                placeholder='담당자'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfDrawing'
                                                defaultValue={piece.controlWork.dateOfDrawing}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfPanel'
                                                defaultValue={piece.controlWork.dateOfPanel}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfWiring'
                                                defaultValue={piece.controlWork.dateOfWiring}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfTesting'
                                                defaultValue={piece.controlWork.dateOfTesting}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type='date'
                                                name='dateOfDissolution'
                                                defaultValue={piece.controlWork.dateOfDissolution}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                <button type='submit' className='proceedButton'></button>
            </form>
        </div>
    );
};

export default Component;
