import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: item.partList.id }, (res) => {
            res.sort((a, b) => {
                try {
                    if (a.type === 'PARTLIST') {
                        return Number(a.partsNbr.replace(/\D/g, '')) - Number(b.partsNbr.replace(/\D/g, ''));
                    } else {
                        return Number(a.bomCode.replace(/\D/g, '')) - Number(b.bomCode.replace(/\D/g, ''));
                    }
                } catch {
                    return 1;
                }
            });
            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(res);
            }, 10);
        });
    }, [item]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (partListDescriptions.length === 1) {
            arr[0] = {
                id: partListDescriptions[0].manufactureAssignment.id,
                t: Number(form.t.value),
                quantityOfInventory: form.quantityOfInventory.value,
                quantityOfProduction: form.quantityOfProduction.value,
                planing: form.planing.checked,
                defaultPlaning: form.defaultPlaning.value === 'true',
                bending: form.bending.checked,
                defaultBending: form.defaultBending.value === 'true',
                pipe: form.pipe.checked,
                defaultPipe: form.defaultPipe.value === 'true',
                mct: form.mct.checked,
                defaultMct: form.defaultMct.value === 'true',
                cnc: form.cnc.checked,
                defaultCnc: form.defaultCnc.value === 'true',
                bandsaw: form.bandsaw.checked,
                defaultBandsaw: form.defaultBandsaw.value === 'true',
                lathe: form.lathe.checked,
                defaultLathe: form.defaultLathe.value === 'true',
                milling: form.milling.checked,
                defaultMilling: form.defaultMilling.value === 'true',
                partialOutsourcing: form.partialOutsourcing.checked,
                defaultPartialOutsourcing: form.defaultPartialOutsourcing.value === 'true',
                wholeOutsourcing: form.wholeOutsourcing.checked,
                defaultWholeOutsourcing: form.defaultWholeOutsourcing.value === 'true',
                note: form.note.value,
            };
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                arr.push({
                    id: partListDescriptions[i].manufactureAssignment.id,
                    t: Number(form.t[i].value),
                    quantityOfInventory: form.quantityOfInventory[i].value,
                    quantityOfProduction: form.quantityOfProduction[i].value,
                    planing: form.planing[i].checked,
                    defaultPlaning: form.defaultPlaning[i].value === 'true',
                    bending: form.bending[i].checked,
                    defaultBending: form.defaultBending[i].value === 'true',
                    pipe: form.pipe[i].checked,
                    defaultPipe: form.defaultPipe[i].value === 'true',
                    mct: form.mct[i].checked,
                    defaultMct: form.defaultMct[i].value === 'true',
                    cnc: form.cnc[i].checked,
                    defaultCnc: form.defaultCnc[i].value === 'true',
                    bandsaw: form.bandsaw[i].checked,
                    defaultBandsaw: form.defaultBandsaw[i].value === 'true',
                    lathe: form.lathe[i].checked,
                    defaultLathe: form.defaultLathe[i].value === 'true',
                    milling: form.milling[i].checked,
                    defaultMilling: form.defaultMilling[i].value === 'true',
                    partialOutsourcing: form.partialOutsourcing[i].checked,
                    defaultPartialOutsourcing: form.defaultPartialOutsourcing[i].value === 'true',
                    wholeOutsourcing: form.wholeOutsourcing[i].checked,
                    defaultWholeOutsourcing: form.defaultWholeOutsourcing[i].value === 'true',
                    note: form.note[i].value,
                });
            }
        }

        PUT('manufacture/assignment', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} item={item} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form form-lining'>
                    <tbody>
                        <tr>
                            <th width='13%' rowSpan={2}>
                                부품코드
                            </th>
                            <th width='13%' rowSpan={2}>
                                부품명
                            </th>
                            <th width='10%' rowSpan={2}>
                                규격
                            </th>
                            <th width='4%' rowSpan={2}>
                                Set
                            </th>
                            <th width='4%' rowSpan={2}>
                                Q'TY
                            </th>
                            <th width='4%' rowSpan={2}>
                                설계량
                            </th>
                            <th width='4%' rowSpan={2}>
                                T
                            </th>
                            <th width='4%' rowSpan={2}>
                                재고
                                <br />
                                사용량
                            </th>
                            <th width='4%' rowSpan={2}>
                                작업
                                <br />
                                분배량
                            </th>
                            <th colSpan={10}>공정별분배</th>
                            <th width='10%' rowSpan={2}>
                                비고
                            </th>
                        </tr>
                        <tr>
                            <th width='3%'>평판</th>
                            <th width='3%'>절곡</th>
                            <th width='3%'>PIPE</th>
                            <th width='3%'>MCT</th>
                            <th width='3%'>CNC</th>
                            <th width='3%'>밴드쇼</th>
                            <th width='3%'>선반</th>
                            <th width='3%'>밀링</th>
                            <th width='3%'>일반외주</th>
                            <th width='3%'>전체외주</th>
                        </tr>
                        {partListDescriptions.length !== 0 ? (
                            partListDescriptions.map((piece, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={piece.type === 'PARTLIST' ? piece.partsNbr : piece.bomCode}
                                            readOnly={true}
                                        />
                                        <span>부품코드</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.description} readOnly={true} />
                                        <span>부품명</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.specification} readOnly={true} />
                                        <span>규격</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.setQuantity} readOnly={true} />
                                        <span>Set</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.quantity} readOnly={true} />
                                        <span>Q'TY</span>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={
                                                piece.type === 'PARTLIST' ? piece.quantity : piece.bomQuantity
                                            }
                                            readOnly={true}
                                        />
                                        <span>설계량</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            step='0.001'
                                            name='t'
                                            defaultValue={piece.manufactureAssignment.t}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>T</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfInventory'
                                            defaultValue={piece.manufactureAssignment.quantityOfInventory}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>재고사용량</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfProduction'
                                            defaultValue={piece.manufactureAssignment.quantityOfProduction}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>작업분배량</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='planing'
                                            defaultChecked={piece.manufactureAssignment.planing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultPlaning'
                                            defaultValue={piece.manufactureAssignment.planing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>평판</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='bending'
                                            defaultChecked={piece.manufactureAssignment.bending}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultBending'
                                            defaultValue={piece.manufactureAssignment.bending}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>절곡</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='pipe'
                                            defaultChecked={piece.manufactureAssignment.pipe}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultPipe'
                                            defaultValue={piece.manufactureAssignment.pipe}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>PIPE</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='mct'
                                            defaultChecked={piece.manufactureAssignment.mct}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultMct'
                                            defaultValue={piece.manufactureAssignment.mct}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>MCT</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='cnc'
                                            defaultChecked={piece.manufactureAssignment.cnc}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultCnc'
                                            defaultValue={piece.manufactureAssignment.cnc}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>CNC</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='bandsaw'
                                            defaultChecked={piece.manufactureAssignment.bandsaw}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultBandsaw'
                                            defaultValue={piece.manufactureAssignment.bandsaw}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>밴드쇼</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='lathe'
                                            defaultChecked={piece.manufactureAssignment.lathe}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultLathe'
                                            defaultValue={piece.manufactureAssignment.lathe}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>선반</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='milling'
                                            defaultChecked={piece.manufactureAssignment.milling}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultMilling'
                                            defaultValue={piece.manufactureAssignment.milling}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>밀링</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='partialOutsourcing'
                                            defaultChecked={piece.manufactureAssignment.partialOutsourcing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultPartialOutsourcing'
                                            defaultValue={piece.manufactureAssignment.partialOutsourcing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>일반외주</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='wholeOutsourcing'
                                            defaultChecked={piece.manufactureAssignment.wholeOutsourcing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='hidden'
                                            name='defaultWholeOutsourcing'
                                            defaultValue={piece.manufactureAssignment.wholeOutsourcing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>전체외주</span>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            name='note'
                                            defaultValue={piece.manufactureAssignment.note}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>비고</span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={20}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
