import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

import { serverPath } from '../../configs';

const API_PATH = 'api';
const API_VERSION = 'v01';

const API_RES = (result, callback) => {
    callback(result.data);
};

const API_ERR = (error, callback) => {
    if (error.status === 500) {
        confirmAlert({
            message: 'The server has some problem. Please try again later :(',
            buttons: [
                {
                    label: '확인',
                },
            ],
        });
        window.location.href = '/';
    } else {
        confirmAlert({
            message: error.data,
            buttons: [
                {
                    label: '확인',
                },
            ],
        });
        callback(null);
    }
};

export const UPLOAD = (data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/attachment`;

    console.log(`UPLOAD() is called : ${URL}`);

    Axios.post(URL, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            API_ERR(err.response, callback);
        });
};

export const DOWNLOAD = (data) => {
    const URL = `${serverPath}/${API_PATH}/${API_VERSION}/attachment/${data}`;

    console.log(`DOWNLOAD() is called : ${URL}`);

    window.location.href = URL;
};

export const DOWNLOAD_URL = (url) => {
    const URL = `${serverPath}/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`DOWNLOAD URL() is called : ${URL}`);

    window.location.href = URL;
};
