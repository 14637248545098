import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const [detector, setDetector] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: item.partList.id }, (res) => {
            res.sort((a, b) => {
                try {
                    if (a.type === 'PARTLIST') {
                        return Number(a.partsNbr.replace(/\D/g, '')) - Number(b.partsNbr.replace(/\D/g, ''));
                    } else {
                        return Number(a.bomCode.replace(/\D/g, '')) - Number(b.bomCode.replace(/\D/g, ''));
                    }
                } catch {
                    return 1;
                }
            });
            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(res);
            }, 10);
        });
    }, [detector, item]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];
        let error = 0;

        if (partListDescriptions.length === 1) {
            if (
                Number(form.quantity.value) ===
                Number(form.assembly.value) +
                    Number(form.outsourcing.value) +
                    Number(form.quality.value) +
                    Number(form.inventory.value) +
                    Number(form.etc.value)
            ) {
                arr[0] = {
                    id: partListDescriptions[0].manufacturePerformance.id,
                    checker: partListDescriptions[0].manufacturePerformance.checker,
                    defaultQuantity: form.defaultQuantity.value,
                    quantity: form.quantity.value,
                    assembly: form.assembly.value,
                    outsourcing: form.outsourcing.value,
                    quality: form.quality.value,
                    inventory: form.inventory.value,
                    etc: form.etc.value,
                    note: form.note.value,
                };
            } else {
                error++;
            }
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                if (
                    Number(form.quantity[i].value) ===
                    Number(form.assembly[i].value) +
                        Number(form.outsourcing[i].value) +
                        Number(form.quality[i].value) +
                        Number(form.inventory[i].value) +
                        Number(form.etc[i].value)
                ) {
                    arr.push({
                        id: partListDescriptions[i].manufacturePerformance.id,
                        checker: partListDescriptions[i].manufacturePerformance.checker,
                        defaultQuantity: form.defaultQuantity[i].value,
                        quantity: form.quantity[i].value,
                        assembly: form.assembly[i].value,
                        outsourcing: form.outsourcing[i].value,
                        quality: form.quality[i].value,
                        inventory: form.inventory[i].value,
                        etc: form.etc[i].value,
                        note: form.note[i].value,
                    });
                } else {
                    error++;
                    break;
                }
            }
        }

        if (error === 0) {
            PUT('manufacture/performance', arr, (res) => {
                if (res !== null) {
                    setDetector((detector) => !detector);
                    alert.success(res);
                }
            });
        } else {
            alert.error('작업완료량과 인수인계량이 동일하지 않습니다.');
        }
    };

    return (
        <Fragment>
            <ProjectViewer project={project} item={item} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form form-lining'>
                    <tbody>
                        <tr>
                            <th width='16.6%' rowSpan={2}>
                                부품코드
                            </th>
                            <th width='16.6%' rowSpan={2}>
                                부품명
                            </th>
                            <th width='16.6%' rowSpan={2}>
                                규격
                            </th>
                            <th width='5%' rowSpan={2}>
                                Set
                            </th>
                            <th width='5%' rowSpan={2}>
                                Q'TY
                            </th>
                            <th width='5%' rowSpan={2}>
                                작업량
                            </th>
                            <th width='5%' rowSpan={2}>
                                확인자
                            </th>
                            <th colSpan={5}>인수인계</th>
                            <th width='10%' rowSpan={2}>
                                비고
                            </th>
                        </tr>
                        <tr>
                            <th width='3%'>조립</th>
                            <th width='3%'>외주</th>
                            <th width='3%'>혁신</th>
                            <th width='3%'>재고</th>
                            <th width='3%'>기타</th>
                        </tr>
                        {partListDescriptions.length !== 0 ? (
                            partListDescriptions.map((piece, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={piece.type === 'PARTLIST' ? piece.partsNbr : piece.bomCode}
                                            readOnly={true}
                                        />
                                        <span>부품코드</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.description} readOnly={true} />
                                        <span>부품명</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.specification} readOnly={true} />
                                        <span>규격</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.setQuantity} readOnly={true} />
                                        <span>Set</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.quantity} readOnly={true} />
                                        <span>Q'TY</span>
                                    </td>
                                    <td>
                                        <input
                                            type='hidden'
                                            name='defaultQuantity'
                                            defaultValue={piece.manufacturePerformance.quantity}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <input
                                            type='number'
                                            name='quantity'
                                            defaultValue={piece.manufacturePerformance.quantity}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>작업량</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='text'
                                            defaultValue={
                                                piece.manufacturePerformance.checker !== null &&
                                                piece.manufacturePerformance.checker !== undefined
                                                    ? piece.manufacturePerformance.checker.name
                                                    : ''
                                            }
                                            readOnly={true}
                                        />
                                        <span>확인자</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='assembly'
                                            defaultValue={piece.manufacturePerformance.assembly}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>인수인계 - 조립</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='outsourcing'
                                            defaultValue={piece.manufacturePerformance.outsourcing}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>인수인계 - 외주</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quality'
                                            defaultValue={piece.manufacturePerformance.quality}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>인수인계 - 혁신</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='inventory'
                                            defaultValue={piece.manufacturePerformance.inventory}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>인수인계 - 재고</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='etc'
                                            defaultValue={piece.manufacturePerformance.etc}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>인수인계 - 기타</span>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            name='note'
                                            defaultValue={piece.manufactureAssignment.note}
                                            disabled={
                                                piece.type === 'PARTLIST'
                                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                                        ? false
                                                        : true
                                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>비고</span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={13}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
