import React from 'react';

const Component = () => {
    return (
        <div className='container'>
            <iframe
                width='100%'
                height='800px'
                scrolling='true'
                frameBorder={0}
                src='https://192.168.20.13:31000'
                title='PMS - PDM Interface'
            ></iframe>
        </div>
    );
};

export default Component;
