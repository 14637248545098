import React, { useState } from 'react';

import Form from './form';

import ProjectSelector from '../../../../ui/selector/ProjectSelector';
import Popup from '../../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setProject({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <ProjectSelector project={project} setProject={setProject} isProjectManager={true} productType='PART' />
            {project.id === -1 ? (
                <Popup
                    title='신규 등록'
                    data={<Form project={project} setProject={setProject} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
            {project.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form project={project} setProject={setProject} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
