import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import { PUT } from '../../../../../util/data';

const Component = ({ element, setElement }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (element.inventoryRequestDetails.length === 1) {
            arr.push({
                id: element.inventoryRequestDetails[0].id,
                quantityOfRequest: element.inventoryRequestDetails[0].quantityOfRequest,
                done: form.done.checked,
            });
            if (form.done.checked) {
                form.done.disabled = true;
            }
        } else {
            for (let i = 0; i < element.inventoryRequestDetails.length; i++) {
                arr.push({
                    id: element.inventoryRequestDetails[i].id,
                    quantityOfRequest: element.inventoryRequestDetails[i].quantityOfRequest,
                    done: form.done[i].checked,
                });
                if (form.done[i].checked) {
                    form.done[i].disabled = true;
                }
            }
        }

        const params = {
            type: element.type,
            inventoryRequestDetails: arr,
        };

        PUT('inventory/request/each', params, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    const pad = (n, width) => {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    };

    return (
        <Fragment>
            <form name='activeForm' onSubmit={_handleForm}>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={4}>기본 정보</th>
                        </tr>
                        <tr>
                            <th width='15%'>요청번호</th>
                            <td width='35%' className='center-td'>
                                {`${element.numberOfYear}-${
                                    element.department === '기계가공부'
                                        ? '가공'
                                        : element.department === '기계조립부'
                                        ? '조립'
                                        : element.department === '제어기술부'
                                        ? '제어'
                                        : ''
                                }-${pad(element.numberOfRequest, 4)}`}
                            </td>
                            <th width='15%'>구분</th>
                            <td width='35%' className='center-td'>
                                {element.type === 'INPUT' ? '반납' : '청구'}
                            </td>
                        </tr>
                        <tr>
                            <th>의뢰부서</th>
                            <td className='center-td'>{element.department}</td>
                            <th>의뢰일자</th>
                            <td className='center-td'>{element.dateOfRequest}</td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td className='center-td'>{element.drafter}</td>
                            <th>승인자</th>
                            <td className='center-td'>{element.approval}</td>
                        </tr>
                    </tbody>
                </table>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='12.5%'>분류번호</th>
                            <th width='17.5%'>품명</th>
                            <th width='15%'>규격</th>
                            <th width='5%'>Size</th>
                            <th width='5%'>단위</th>

                            <th width='5%'>수량</th>
                            <th width='15%'>용도/사유</th>
                            <th width='15%'>비고</th>
                            <th width='10%'>청구 및 반납</th>
                        </tr>
                        {element.inventoryRequestDetails.length !== 0 ? (
                            element.inventoryRequestDetails.map((piece, index) => (
                                <tr key={index}>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.partsNbr
                                            : piece.purchaseMaterial.code}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.description
                                            : piece.purchaseMaterial.name}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.specification
                                            : piece.purchaseMaterial.specification}
                                    </td>
                                    <td className='center-td'>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.size
                                            : piece.purchaseMaterial.size}
                                    </td>
                                    <td className='center-td'>{piece.unit}</td>
                                    <td className='center-td'>{piece.quantityOfRequest}</td>
                                    <td>{piece.purpose}</td>
                                    <td>{piece.note}</td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='done'
                                            defaultChecked={piece.done}
                                            disabled={piece.done}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={9}>
                                    등록된 정보가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
