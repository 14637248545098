import Axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const API_PATH = 'api';
const API_VERSION = 'v01';

const API_RES = (result, callback) => {
    callback(result.data);
};

const API_RES_WITH_PAGING = (result, callback) => {
    callback(result);
};

const API_ERR = (error, callback) => {
    if (error.status === 500) {
        confirmAlert({
            message: 'The server has some problem. Please try again later :(',
            buttons: [
                {
                    label: '확인',
                    onClick: () =>
                        window.location.pathname.match('/pop?')
                            ? (window.location.href = '/pop')
                            : (window.location.href = '/'),
                },
            ],
        });
    } else if (error.status === 401) {
        confirmAlert({
            message: '세션 정보가 올바르지 않습니다. 다시 로그인해 주십시오.',
            buttons: [
                {
                    label: '확인',
                    onClick: () =>
                        window.location.pathname.match('/pop?')
                            ? (window.location.href = '/pop')
                            : (window.location.href = '/'),
                },
            ],
        });
    } else {
        confirmAlert({
            message: error.data,
            buttons: [
                {
                    label: '확인',
                    onClick: () => callback(null),
                },
            ],
        });
    }
};

export const GET = (url, data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`GET() is called : ${URL}`);

    Axios.get(URL, { params: data })
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            console.error(err);
            API_ERR(err.response, callback);
        });
};

export const GET_WITH_PAGING = (url, data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`GET() is called : ${URL}`);

    Axios.get(URL, { params: data })
        .then((res) => {
            API_RES_WITH_PAGING(res, callback);
        })
        .catch((err) => {
            console.error(err);
            API_ERR(err.response, callback);
        });
};

export const POST = (url, data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`POST() is called : ${URL}`);

    Axios.post(URL, data)
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            console.error(err);
            API_ERR(err.response, callback);
        });
};

export const PUT = (url, data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`PUT() is called : ${URL}`);

    Axios.put(URL, data)
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            console.error(err);
            API_ERR(err.response, callback);
        });
};

export const DELETE = (url, data, callback) => {
    const URL = `/${API_PATH}/${API_VERSION}/${url}`;

    console.log(`DELETE() is called : ${URL}`);

    Axios.delete(URL, { params: data })
        .then((res) => {
            API_RES(res, callback);
        })
        .catch((err) => {
            console.error(err);
            API_ERR(err.response, callback);
        });
};
