import React, { Fragment, useState } from 'react';

const Component = ({ project, setProject, setItem, handleSlot }) => {
    const [list, setList] = useState([...project.items]);

    const _handleSetItem = (piece) => {
        setItem(piece);
    };

    const _handleResetProject = () => {
        setProject({
            id: -2,
        });
    };

    const _handleKeyword = () => {
        const keyword = document.querySelector('.keyword').value;

        if (keyword === '') {
            setList([...project.items]);
        } else {
            setList([
                ...project.items.filter((piece) =>
                    piece.itemFamily !== null
                        ? piece.itemFamily.name.toLowerCase().match(keyword.toLowerCase())
                        : piece.name.toLowerCase().match(keyword.toLowerCase())
                ),
            ]);
        }
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    return (
        <Fragment>
            <table className='viewer'>
                <tbody>
                    <tr>
                        <th colSpan={4}>프로젝트정보</th>
                    </tr>
                    <tr>
                        <th width='15%'>구분</th>
                        <td width='35%' className='center-td'>
                            {project.type === 'PRODUCT'
                                ? '완성품'
                                : project.type === 'PART'
                                ? '부품'
                                : project.type === 'AS'
                                ? ' A/S'
                                : '미등록 프로젝트'}
                        </td>
                        <th width='15%'>지시번호</th>
                        <td width='35%' className='center-td'>
                            {project.code}
                        </td>
                    </tr>
                    <tr>
                        <th>프로젝트명</th>
                        <td className='center-td'>{project.name}</td>
                        <th>납품처</th>
                        <td className='center-td'>{project.customer}</td>
                    </tr>
                    <tr>
                        <th>오더번호</th>
                        <td className='center-td'>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                        <th>전기사양</th>
                        <td className='center-td'>{project.electricity !== undefined ? project.electricity : ''}</td>
                    </tr>
                    <tr>
                        <th>PLC Type</th>
                        <td className='center-td'>{project.plc !== undefined ? project.plc : ''}</td>
                        <th>색상</th>
                        <td className='center-td'>{project.color !== undefined ? project.color : ''}</td>
                    </tr>
                    <tr>
                        <th>지시일자</th>
                        <td className='center-td'>{project.dateOfOrder !== undefined ? project.dateOfOrder : ''}</td>
                        <th>납품일자</th>
                        <td className='center-td'>{project.dateOfDue !== undefined ? project.dateOfDue : ''}</td>
                    </tr>
                </tbody>
            </table>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={5}>
                            <button className='focusButton' type='button' onClick={_handleResetProject}>
                                프로젝트 재선택
                            </button>
                            <div className='division'>|</div>
                            <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                            <button type='button' onClick={_handleKeyword}>
                                검색
                            </button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>No.</th>
                        <th width='25%'>아이템명</th>
                        <th width='20%'>규격</th>
                        <th width='10%'>불량수량</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>수량</th>
                        <th width='20%'>비고</th>
                    </tr>
                    {list.length !== 0 ? (
                        list.map((piece, index) => (
                            <tr
                                className={handleSlot(piece) ? '' : 'closed-tr'}
                                onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}
                                key={index}
                            >
                                <td className='center-td'>{piece.nbr}</td>
                                <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                <td>{piece.specification}</td>
                                <td className='center-td'>{piece.uncomformities.length}</td>
                                <td className='center-td'>{piece.unit}</td>
                                <td className='center-td'>{piece.quantity}</td>
                                <td>{piece.note.length > 30 ? piece.note.substr(0, 30) + '...' : piece.note}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={6}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
