import React, { Fragment, useState, useEffect } from 'react';

import { GET } from '../../../../../util/data';

const Component = ({ setSource, setList }) => {
    const [type, setType] = useState('STEEL');
    const [sourceRaw, setSourceRaw] = useState([]);

    useEffect(() => {
        GET('purchase/material', {}, (res) => {
            setSourceRaw(res);
        });
    }, []);

    const _handleList = () => {
        const checkbox = document.getElementsByName('list[]');

        let arr = [];

        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push({
                    id: null,
                    partListDescription: null,
                    purchaseMaterial: sourceRaw.find((piece) => piece.id === Number(checkbox[i].value)),
                    unit: sourceRaw.find((piece) => piece.id === Number(checkbox[i].value)).unit,
                    quantityOfRequest: '',
                    supplier: '',
                    receiver: '',
                    note: '',
                    status: null,
                });
            }
        }

        setList((list) => list.concat(arr));
        setSource('');
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    return (
        <Fragment>
            <table className='form'>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <button type='button' onClick={() => setSource('')}>
                                되돌아가기
                            </button>
                            <button className='focusButton' type='button' onClick={_handleList}>
                                선택 불러오기
                            </button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='15%'>자재원부 유형</th>
                        <td width='85%'>
                            <select value={type} onChange={(e) => setType(e.target.value)}>
                                <option value='STEEL'>철강자재</option>
                                <option value='ELECTRICITY'>전기자재</option>
                                <option value='ETC'>기타 자재</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th width='5%'>
                            <input type='checkbox' onClick={_handleSelectAll} />
                        </th>
                        <th width='15%'>분류번호</th>
                        <th width='30%'>품명</th>
                        <th width='30%'>규격</th>
                        <th width='10%'>Size</th>
                        <th width='10%'>단위</th>
                    </tr>
                    {sourceRaw.filter((piece) => piece.type === type).length !== 0 ? (
                        sourceRaw
                            .filter((piece) => piece.type === type)
                            .map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td'>
                                        <input type='checkbox' name='list[]' value={piece.id} />
                                    </td>
                                    <td>{piece.code}</td>
                                    <td>{piece.name}</td>
                                    <td>{piece.specification}</td>
                                    <td className='center-td'>{piece.size}</td>
                                    <td className='center-td'>{piece.unit}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={6}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
