import React, { Fragment } from 'react';

const Component = ({ project, item, isSetItem }) => {
    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={4}>프로젝트정보</th>
                    </tr>
                    <tr>
                        <th width='15%'>구분</th>
                        <td width='35%' className='center-td'>
                            {project.type === 'PRODUCT'
                                ? '완성품'
                                : project.type === 'PART'
                                ? '부품'
                                : project.type === 'AS'
                                ? ' A/S'
                                : '미등록 프로젝트'}
                        </td>
                        <th width='15%'>지시번호</th>
                        <td width='35%' className='center-td'>
                            {project.code}
                        </td>
                    </tr>
                    <tr>
                        <th>프로젝트명</th>
                        <td className='center-td'>{project.name}</td>
                        <th>납품처</th>
                        <td className='center-td'>{project.customer}</td>
                    </tr>
                    <tr>
                        <th>오더번호</th>
                        <td className='center-td'>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                        <th>전기사양</th>
                        <td className='center-td'>{project.electricity !== undefined ? project.electricity : ''}</td>
                    </tr>
                    <tr>
                        <th>PLC Type</th>
                        <td className='center-td'>{project.plc !== undefined ? project.plc : ''}</td>
                        <th>색상</th>
                        <td className='center-td'>{project.color !== undefined ? project.color : ''}</td>
                    </tr>
                    <tr>
                        <th>지시일자</th>
                        <td className='center-td'>{project.dateOfOrder !== undefined ? project.dateOfOrder : ''}</td>
                        <th>납품일자</th>
                        <td className='center-td'>{project.dateOfDue !== undefined ? project.dateOfDue : ''}</td>
                    </tr>
                </tbody>
            </table>
            {isSetItem ? (
                <table className='list'>
                    <thead>
                        <tr>
                            <th width='5%'>No</th>
                            <th width='30%'>아이템명</th>
                            <th width='20%'>규격</th>
                            <th width='10%'>단위</th>
                            <th width='10%'>수량</th>
                            <th width='25%'>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='center-td'>{item.nbr}</td>
                            <td>{item.itemFamily !== null ? item.itemFamily.name : item.name}</td>
                            <td>{item.specification}</td>
                            <td className='center-td'>{item.unit}</td>
                            <td className='center-td'>{item.quantity}</td>
                            <td>{item.note}</td>
                        </tr>
                    </tbody>
                </table>
            ) : null}
        </Fragment>
    );
};

export default Component;
