import React, { Fragment } from 'react';

import FileDownloader from '../../../ui/downloader/FileDownloader';

const Component = ({ notice }) => {
    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th width='10%'>제목</th>
                        <td width='90%'>
                            <input type='text' defaultValue={notice.title} readOnly />
                        </td>
                    </tr>
                    <tr>
                        <th>내용</th>
                        <td>
                            <textarea
                                className='x-wide-textarea'
                                name='content'
                                defaultValue={notice.id > -1 ? notice.content : ''}
                                readOnly
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <FileDownloader project={notice} />
        </Fragment>
    );
};

export default Component;
