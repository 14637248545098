import React, { Fragment, useState } from 'react';

import Form from './form';

import ProjectSelector from '../../../../ui/selector/ProjectSelector';
import ItemSelectorForUncomformity from '../../../../ui/selector/ItemSelectorForUncomformity';
import UnconformitySelector from '../../../../ui/selector/UnconformitySelector';
import Popup from '../../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });
    const [item, setItem] = useState({
        id: -2,
    });
    const [receipt, setReceipt] = useState({
        id: -2,
    });

    const handleSlot = (piece) => {
        return true;
    };

    const _handleExit = () => {
        setReceipt({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <ProjectSelector project={project} setProject={setProject} setItem={setItem} isItemSearch={true} />
            {project.id === -2 ? (
                <Fragment></Fragment>
            ) : item.id === -2 ? (
                <ItemSelectorForUncomformity
                    project={project}
                    setProject={setProject}
                    setItem={setItem}
                    handleSlot={handleSlot}
                />
            ) : (
                <UnconformitySelector
                    project={project}
                    setProject={setProject}
                    item={item}
                    setItem={setItem}
                    receipt={receipt}
                    setReceipt={setReceipt}
                />
            )}
            {receipt.id === -1 ? (
                <Popup
                    title='신규 등록'
                    data={
                        <Form
                            project={project}
                            setProject={setProject}
                            item={item}
                            setItem={setItem}
                            receipt={receipt}
                        />
                    }
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
            {receipt.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={
                        <Form
                            project={project}
                            setProject={setProject}
                            item={item}
                            setItem={setItem}
                            receipt={receipt}
                        />
                    }
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
