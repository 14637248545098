import React, { Fragment } from 'react';

const Component = ({ item }) => {
    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={6}>아이템정보</th>
                    </tr>
                    <tr>
                        <th width='16.6%'>No.</th>
                        <th width='16.6%'>아이템명</th>
                        <th width='16.6%'>규격</th>
                        <th width='16.6%'>단위</th>
                        <th width='16.6%'>수량</th>
                        <th width='16.6%'>비고</th>
                    </tr>
                    <tr>
                        <td className='center-td'>{item.nbr}</td>
                        <td className='center-td'>
                            {item.itemFamily !== null
                                ? item.itemFamily.name
                                : item.name}
                        </td>
                        <td className='center-td'>{item.specification}</td>
                        <td className='center-td'>{item.unit}</td>
                        <td className='center-td'>{item.quantity}</td>
                        <td className='center-td'>{item.note}</td>
                    </tr>
                </tbody>
            </table>
            <table className='form'>
                <tbody>
                    <tr>
                        <th colSpan={5}>설계계획정보</th>
                    </tr>
                    <tr>
                        <th width='20%'>설계자</th>
                        <th width='20%'>설계시작지시일</th>
                        <th width='20%'>설계완료지시일</th>
                        <th width='20%'>검도자</th>
                        <th width='20%'>검도완료지시일</th>
                    </tr>
                    <tr>
                        <td className='center-td'>
                            {item.designPlanning.designer !== null
                                ? item.designPlanning.designer.name
                                : ''}
                        </td>
                        <td className='center-td'>{item.designPlanning.dateOfStart}</td>
                        <td className='center-td'>{item.designPlanning.dateOfFinish}</td>
                        <td className='center-td'>
                            {item.designPlanning.checker !== null
                                ? item.designPlanning.checker.name
                                : ''}
                        </td>
                        <td className='center-td'>{item.designPlanning.dateOfCheck}</td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
