import React, { useState, Fragment } from 'react';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

const Component = ({ project }) => {
    const [view, setView] = useState('PROJECT');

    const _handleDateDifference = (value) => {
        const today = new Date();
        const target = new Date(value);

        const diff = ((target.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

        return Number(diff);
    };

    const LEFT_SIZE = 40;

    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th>지시번호</th>
                        <td className='center-td'>{project.code}</td>
                        <th>프로젝트명</th>
                        <td className='center-td'>{project.name}</td>
                        <th>납품처</th>
                        <td className='center-td'>{project.customer}</td>
                    </tr>
                    <tr>
                        <th>지시일자</th>
                        <td className='center-td'>{project.dateOfOrder}</td>
                        <th>납품일자</th>
                        <td className='center-td'>{project.dateOfDue}</td>
                        <th>잔여/지연일</th>
                        <td
                            className={
                                _handleDateDifference(project.dateOfDue) >= 0
                                    ? 'center-td status-safe'
                                    : 'center-td status-emergency'
                            }
                        >
                            {_handleDateDifference(project.dateOfDue)}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='form'>
                <thead>
                    <tr>
                        <td
                            colSpan={
                                view === 'PROJECT'
                                    ? 1
                                    : view === 'DESIGN'
                                    ? 12
                                    : view === 'MANUFACTURE'
                                    ? 15
                                    : view === 'QUALITY'
                                    ? 14
                                    : view === 'ASSEMBLY'
                                    ? 10
                                    : 0
                            }
                        >
                            <button onClick={() => setView('PROJECT')}>작업지시</button>
                            <button onClick={() => setView('DESIGN')}>설계</button>
                            <button onClick={() => setView('MANUFACTURE')}>가공</button>
                            <button onClick={() => setView('QUALITY')}>혁신</button>
                            <button onClick={() => setView('ASSEMBLY')}>조립 / 제어</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {view === 'PROJECT' ? (
                            <Fragment>
                                <td style={{ border: 'none', padding: '0' }}>
                                    <ProjectViewer project={project} showItemList={true} />
                                    <FileDownloader project={project} />
                                </td>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <th width='3%' rowSpan={3}>
                                    No.
                                </th>
                                <th width='21%' rowSpan={3}>
                                    아이템명
                                </th>
                                <th width='8%' rowSpan={3}>
                                    규격
                                </th>
                                <th width='5%' rowSpan={3}>
                                    단위
                                </th>
                                <th width='3%' rowSpan={3}>
                                    수량
                                </th>
                            </Fragment>
                        )}

                        {view === 'DESIGN' ? (
                            <Fragment>
                                <th colSpan={6}>설계</th>
                                <th>검도</th>
                            </Fragment>
                        ) : null}
                        {view === 'MANUFACTURE' ? <th colSpan={10}>기계가공</th> : null}
                        {view === 'QUALITY' ? <th colSpan={9}>혁신</th> : null}
                        {view === 'ASSEMBLY' ? (
                            <Fragment>
                                <th colSpan={3}>기계조립</th>
                                <th colSpan={2}>전기제어</th>
                            </Fragment>
                        ) : null}
                    </tr>
                    <tr>
                        {view === 'DESIGN' ? (
                            <Fragment>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    설계자
                                </th>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    지시일
                                </th>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    진도
                                </th>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    예정일
                                </th>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    완료일
                                </th>
                                <th width='15%' rowSpan={2}>
                                    비고
                                </th>
                                <th width={`${(100 - LEFT_SIZE - 15) / 6}%`} rowSpan={2}>
                                    완료일
                                </th>
                            </Fragment>
                        ) : null}
                        {view === 'MANUFACTURE' ? (
                            <Fragment>
                                <th colSpan={2}>평판 레이저 설계</th>
                                <th colSpan={2}>PIPE 레이저 설계</th>
                                <th colSpan={3}>가공1팀</th>
                                <th colSpan={3}>가공2팀</th>
                            </Fragment>
                        ) : null}
                        {view === 'QUALITY' ? (
                            <Fragment>
                                <th colSpan={3}>제관작업</th>
                                <th colSpan={3}>도장작업</th>
                                <th colSpan={3}>후처리작업</th>
                            </Fragment>
                        ) : null}
                        {view === 'ASSEMBLY' ? (
                            <Fragment>
                                <th width={`${(100 - LEFT_SIZE) / 4}%`} rowSpan={2}>
                                    지시일
                                </th>
                                <th width={`${(100 - LEFT_SIZE) / 4}%`} rowSpan={2}>
                                    진도
                                </th>
                                <th width={`${(100 - LEFT_SIZE) / 4}%`} rowSpan={2}>
                                    완료일
                                </th>
                                <th width={`${(100 - LEFT_SIZE) / 4}%`} rowSpan={2}>
                                    시스템 정보
                                </th>
                            </Fragment>
                        ) : null}
                    </tr>
                    <tr>
                        {view === 'MANUFACTURE' ? (
                            <Fragment>
                                <th width={`${(100 - LEFT_SIZE) / 10 - 1}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>완료일</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 - 1}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>완료일</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>시작일</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 - 1}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>완료일</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>시작일</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 - 1}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 10 + 0.66}%`}>완료일</th>
                            </Fragment>
                        ) : null}
                        {view === 'QUALITY' ? (
                            <Fragment>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>지시일</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>완료일</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>지시일</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>완료일</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>지시일</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>진도</th>
                                <th width={`${(100 - LEFT_SIZE) / 9}%`}>완료일</th>
                            </Fragment>
                        ) : null}
                    </tr>
                    {view !== 'PROJECT'
                        ? project.items
                              .sort((a, b) => {
                                  try {
                                      return (
                                          Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                          Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                                      );
                                  } catch {
                                      return 1;
                                  }
                              })
                              .map((piece, index) => (
                                  <tr className='high-tr' key={index}>
                                      <td className='center-td'>{piece.nbr}</td>
                                      <td>{piece.productFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                      <td className='center-td'>{piece.specification}</td>
                                      <td className='center-td'>{piece.unit}</td>
                                      <td className='center-td'>{piece.quantity}</td>
                                      {view === 'DESIGN' ? (
                                          piece.designPlanning.status === 'UNNECESSARY' ? (
                                              <td className='center-td' colSpan={7}>
                                                  작업불필요
                                              </td>
                                          ) : (
                                              <Fragment>
                                                  <td className='center-td'>
                                                      {piece.designPlanning.designer !== null
                                                          ? piece.designPlanning.designer.name
                                                          : ''}
                                                  </td>
                                                  <td className='center-td'>{piece.designPlanning.dateOfFinish}</td>
                                                  <td className='center-td'>
                                                      {piece.designWork.progress !== 0 ? piece.designWork.progress : ''}
                                                  </td>
                                                  <td className='center-td'>{piece.designWork.dateOfSchedule}</td>
                                                  <td className='center-td'>{piece.designWork.dateOfFinish}</td>
                                                  <td className='center-td'>{piece.designWork.note}</td>
                                                  <td className='center-td'>{piece.designWork.dateOfCheck}</td>
                                              </Fragment>
                                          )
                                      ) : null}
                                      {view === 'MANUFACTURE' ? (
                                          <Fragment>
                                              {piece.manufactureLaser.progressOfPlaning !== -100 ? (
                                                  <Fragment>
                                                      <td className='center-td'>
                                                          {piece.manufactureLaser.progressOfPlaning !== 0
                                                              ? piece.manufactureLaser.progressOfPlaning
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureLaser.dateOfFinishOfPlaning}
                                                      </td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={2}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              {piece.manufactureLaser.progressOfPipe !== -100 ? (
                                                  <Fragment>
                                                      <td className='center-td'>
                                                          {piece.manufactureLaser.progressOfPipe !== 0
                                                              ? piece.manufactureLaser.progressOfPipe
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureLaser.dateOfFinishOfPipe}
                                                      </td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={2}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              {piece.manufactureWork.progressOfManufacture1 !== -100 ? (
                                                  <Fragment>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.dateOfStartOfManufacture1}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.progressOfManufacture1 !== 0
                                                              ? piece.manufactureWork.progressOfManufacture1
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.dateOfFinishOfManufacture1}
                                                      </td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              {piece.manufactureWork.progressOfManufacture2 !== -100 ? (
                                                  <Fragment>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.dateOfStartOfManufacture2}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.progressOfManufacture2 !== 0
                                                              ? piece.manufactureWork.progressOfManufacture2
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.manufactureWork.dateOfFinishOfManufacture2}
                                                      </td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                          </Fragment>
                                      ) : null}
                                      {view === 'QUALITY' ? (
                                          <Fragment>
                                              {piece.qualityOrder.statusOfCanning !== 'UNNECESSARY' ? (
                                                  <Fragment>
                                                      <td className='center-td'>{piece.qualityOrder.dateOfCanning}</td>
                                                      <td className='center-td'>
                                                          {piece.qualityWork.progressOfCanning !== 0
                                                              ? piece.qualityWork.progressOfCanning
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>{piece.qualityWork.dateOfCanning}</td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              {piece.qualityOrder.statusOfPainting !== 'UNNECESSARY' ? (
                                                  <Fragment>
                                                      <td className='center-td'>{piece.qualityOrder.dateOfPainting}</td>
                                                      <td className='center-td'>
                                                          {piece.qualityWork.progressOfPainting !== 0
                                                              ? piece.qualityWork.progressOfPainting
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>{piece.qualityWork.dateOfPainting}</td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              {piece.qualityOrder.statusOfPostProcessing !== 'UNNECESSARY' ? (
                                                  <Fragment>
                                                      <td className='center-td'>
                                                          {piece.qualityOrder.dateOfPostProcessing}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.qualityWork.progressOfPostProcessing !== 0
                                                              ? piece.qualityWork.progressOfPostProcessing
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>
                                                          {piece.qualityWork.dateOfPostProcessing}
                                                      </td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                          </Fragment>
                                      ) : null}
                                      {view === 'ASSEMBLY' ? (
                                          <Fragment>
                                              {piece.assemblyOrder.status !== 'UNNECESSARY' ? (
                                                  <Fragment>
                                                      <td className='center-td'>{piece.assemblyOrder.dateOfFinish}</td>
                                                      <td className='center-td'>
                                                          {piece.assemblyWork.progress !== 0
                                                              ? piece.assemblyWork.progress
                                                              : ''}
                                                      </td>
                                                      <td className='center-td'>{piece.assemblyWork.dateOfFinish}</td>
                                                  </Fragment>
                                              ) : (
                                                  <td className='center-td' colSpan={3}>
                                                      작업불필요
                                                  </td>
                                              )}
                                              <td>
                                                  {project.controlSystems.map((raw, index) =>
                                                      raw.controlSystemAndItems.find(
                                                          (element) => element.item.id === piece.id
                                                      ) ? (
                                                          <div className='controlSystem-list' key={index}>
                                                              ▶ [{raw.nbr}] {raw.name}
                                                          </div>
                                                      ) : (
                                                          ''
                                                      )
                                                  )}
                                              </td>
                                          </Fragment>
                                      ) : null}
                                  </tr>
                              ))
                        : null}
                </tbody>
            </table>
            {view === 'ASSEMBLY' ? (
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={10}>제어기술 시스템</th>
                        </tr>
                        <tr>
                            <th width='10%'>시스템 No.</th>
                            <th width='18%'>시스템명</th>
                            <th width='12%'>전기도면 작업완료일</th>
                            <th width='12%'>판넬전장 작업완료일</th>
                            <th width='12%'>배선 작업완료일</th>
                            <th width='12%'>시운전 완료일</th>
                            <th width='12%'>진도</th>
                            <th width='12%'>해체 작업완료일</th>
                        </tr>
                        {project.controlSystems.length !== 0 ? (
                            project.controlSystems.map((piece, index) => (
                                <Fragment key={index}>
                                    <tr>
                                        <td className='center-td' rowSpan={2}>
                                            {piece.nbr}
                                        </td>
                                        <td className='center-td' rowSpan={2}>
                                            {piece.name}
                                        </td>
                                        <td className='center-td'>{piece.controlWork.chargerOfDrawing}</td>
                                        <td className='center-td'>{piece.controlWork.chargerOfPanel}</td>
                                        <td className='center-td'>{piece.controlWork.chargerOfWiring}</td>
                                        <td className='center-td'>{piece.controlWork.chargerOfTesting}</td>
                                        <td className='center-td' rowSpan={2}>
                                            {piece.controlWork.progress !== 0 ? piece.controlWork.progress : ''}
                                        </td>
                                        <td className='center-td'>{piece.controlWork.chargerOfDissolution}</td>
                                    </tr>
                                    <tr>
                                        <td className='center-td'>{piece.controlWork.dateOfDrawing}</td>
                                        <td className='center-td'>{piece.controlWork.dateOfPanel}</td>
                                        <td className='center-td'>{piece.controlWork.dateOfWiring}</td>
                                        <td className='center-td'>{piece.controlWork.dateOfTesting}</td>
                                        <td className='center-td'>{piece.controlWork.dateOfDissolution}</td>
                                    </tr>
                                </Fragment>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={8}>
                                    등록된 제어시스템이 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            ) : null}
        </Fragment>
    );
};

export default Component;
