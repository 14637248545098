import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { print_data_set } from '../../../../../actions';
import { useAlert } from 'react-alert';

import ItemUploader from '../../../../ui/uploader/ItemUploader';
import FileUploader from '../../../../ui/uploader/FileUploader';

import { projectType, formType } from '../../../../../configs';

import { GET, POST, PUT } from '../../../../../util/data';

const Component = ({ project, setProject }) => {
    const [done, setDone] = useState(false);
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [attachments, setAttachments] = useState(project.id === -1 ? [] : [...project.attachments]);
    const alert = useAlert();

    useEffect(() => {
        if (project.id > -1) {
            setItems([...project.items]);
        }
    }, [project.id, project.items]);

    const _handleForm = (e) => {
        e.preventDefault();

        if (project.id > -1 || (project.id === -1 && !done)) {
            const func = project.id === -1 ? POST : PUT;

            let itemArr = [];

            for (let i = 0; i < items.length; i++) {
                if (items[i].name !== '' || items[i].itemFamily !== '') {
                    itemArr.push({
                        id: items[i].id,
                        nbr: items[i].nbr,
                        productFamily: null,
                        itemFamily: null,
                        name: items[i].name,
                        specification: items[i].specification,
                        unit: items[i].unit,
                        quantity: items[i].quantity,
                        note: items[i].note,
                        status: items[i].status,
                    });
                }
            }

            const form = document.activeForm;

            const params = {
                type: projectType.AS,
                code: form.code.value,
                name: form.name.value,
                customer: form.customer.value,
                orderNbr: form.orderNbr.value,
                electricity: form.electricity.value,
                plc: form.plc.value,
                color: form.color.value,
                dateOfOrder: form.dateOfOrder.value,
                dateOfDue: form.dateOfDue.value,
                note: form.note.value,
                items: itemArr,
                attachments: attachments,
            };

            if (project.id !== -1) {
                params.id = project.id;
            }

            setDone(true);

            func('project', params, (res1) => {
                if (res1 !== null) {
                    if (project.id > -1) {
                        GET('project', { id: project.id }, (res2) => {
                            res2.items.sort((a, b) => {
                                try {
                                    return (
                                        Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                        Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                                    );
                                } catch {
                                    return 1;
                                }
                            });
                            setProject(res2);
                        });
                    }
                    alert.success(res1);
                }
            });
        }
    };

    const _handlePrint = () => {
        dispatch(print_data_set({ type: 1, data: project.id }));
    };

    return (
        <div className='printArea'>
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='approval'>
                    <tbody>
                        <tr className='narrow-tr'>
                            <td className='title-td' width='55%' rowSpan={2}>
                                작 업 지 시 서
                            </td>
                            <td width='5%' rowSpan={2}>
                                결재
                            </td>
                            <td width='10%'>작성</td>
                            <td width='10%'>검토</td>
                            <td width='10%'>팀장</td>
                            <td width='10%'>승인</td>
                        </tr>
                        <tr className='wide-tr'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table className='approval'>
                    <tbody>
                        <tr className='narrow-tr'>
                            <td width='5%' rowSpan={2}>
                                확인
                            </td>
                            <td width='10%'>설계</td>
                            <td width='10%'>전기/조립</td>
                            <td width='10%'>가공</td>
                            <td width='10%'>구매</td>
                            <td width='10%'>품질</td>
                            <td width='45%' rowSpan={2}></td>
                        </tr>
                        <tr className='wide-tr'>
                            <td>{project.id > -1 ? project.confirmation.design : null}</td>
                            <td>{project.id > -1 ? project.confirmation.assembly : null}</td>
                            <td>{project.id > -1 ? project.confirmation.manufacture : null}</td>
                            <td>{project.id > -1 ? project.confirmation.purchase : null}</td>
                            <td>{project.id > -1 ? project.confirmation.quality : null}</td>
                        </tr>
                    </tbody>
                </table>
                <table className='viewer'>
                    <tbody>
                        <tr>
                            <th width='20%'>구분</th>
                            <td width='30%' className='center-td'>
                                A/S
                            </td>
                            <th width='20%'>지시번호 *</th>
                            <td width='30%'>
                                <input
                                    type='text'
                                    name='code'
                                    defaultValue={project.id > -1 ? project.code : ''}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>프로젝트명 *</th>
                            <td>
                                <input
                                    type='text'
                                    name='name'
                                    defaultValue={project.id > -1 ? project.name : ''}
                                    required
                                />
                            </td>
                            <th>납품처 *</th>
                            <td>
                                <input
                                    type='text'
                                    name='customer'
                                    defaultValue={project.id > -1 ? project.customer : ''}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>오더번호</th>
                            <td>
                                <input
                                    type='text'
                                    name='orderNbr'
                                    defaultValue={project.id > -1 ? project.orderNbr : ''}
                                />
                            </td>
                            <th>전기사양</th>
                            <td>
                                <input
                                    type='text'
                                    name='electricity'
                                    defaultValue={project.id > -1 ? project.electricity : ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>PLC Type</th>
                            <td>
                                <input type='text' name='plc' defaultValue={project.id > -1 ? project.plc : ''} />
                            </td>
                            <th>색상</th>
                            <td>
                                <input type='text' name='color' defaultValue={project.id > -1 ? project.color : ''} />
                            </td>
                        </tr>
                        <tr>
                            <th>지시일자 *</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfOrder'
                                    defaultValue={project.id > -1 ? project.dateOfOrder : ''}
                                    required
                                />
                            </td>
                            <th>납품일자 *</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfDue'
                                    defaultValue={project.id > -1 ? project.dateOfDue : ''}
                                    required
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ItemUploader
                    classify={projectType.AS}
                    status={project.id > -1 ? formType.MODIFY : formType.REGISTER}
                    items={items}
                    setItems={setItems}
                />
                <table className='viewer'>
                    <tbody>
                        <tr>
                            <th>세부작업 지시사항</th>
                        </tr>
                        <tr>
                            <td>
                                <textarea
                                    className='textarea-project-print wide-textarea'
                                    name='note'
                                    defaultValue={project.id > -1 ? project.note : ''}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <FileUploader attachments={attachments} setAttachments={setAttachments} />
                <button type='submit' className='proceedButton'></button>
                {project.id !== -1 ? (
                    <button type='button' className='printButton' onClick={_handlePrint}>
                        출력
                    </button>
                ) : null}
            </form>
        </div>
    );
};

export default Component;
