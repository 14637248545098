import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import FromBOM from '../../util/FromBOM';
import FromMaterial from '../../util/FromMaterial';

import { GET, PUT, DOWNLOAD } from '../../../../../util/data';

const Component = ({ element, setElement }) => {
    const [source, setSource] = useState('');

    const [list, setList] = useState(element.purchaseDetails);
    const alert = useAlert();

    // ItemRaw Init
    const [itemRaw, setItemRaw] = useState([]);

    useEffect(() => {
        if (element.project !== null && element.project.id !== -1) {
            GET('item/only', { id: element.project.id }, (res) => {
                setItemRaw(res);
            });
        }
    }, [element.project]);
    // ItemRaw Final

    const _handleForm = (e) => {
        e.preventDefault();

        if (source !== '') {
            alert.error('불러오기 모드가 실행 중입니다.');
            return;
        }

        const form = document.activeForm;

        let arr = [];

        if (list.length === 1) {
            arr.push({
                id: list[0].id,
                partListDescription: list[0].partListDescription,
                purchaseMaterial: list[0].purchaseMaterial,
                status: list[0].status,

                unit: form.unit.value,
                quantityOfPurchase: form.quantityOfPurchase.value,
                price: form.price.value,
                note: form.note.value,
            });
        } else {
            for (let i = 0; i < list.length; i++) {
                arr.push({
                    id: list[i].id,
                    partListDescription: list[i].partListDescription,
                    purchaseMaterial: list[i].purchaseMaterial,
                    status: list[i].status,

                    unit: form.unit[i].value,
                    quantityOfPurchase: form.quantityOfPurchase[i].value,
                    price: form.price[i].value,
                    note: form.note[i].value,
                });
            }
        }

        let params = {
            id: element.id,
            project: element.project,
            item: element.item,
            type: element.type,
            status: 'ORDER',
            purchaseRequest: null,
            purchaseListing: null,
            purchaseQuotation: null,
            purchaseOrder: {
                id: element.purchaseOrder.id,
                orderType: form.orderType.value,
                supplier: form.supplier.value,
                dateOfOrder: form.dateOfOrder.value,
                price: form.totalPrice.value,
                charger: form.charger.value,
                orderer: form.orderer.value,
                dateOfDue: form.dateOfDue.value,
                methodOfPayment: form.methodOfPayment.value,
                contract: form.contract.value,
                mobile: form.mobile.value,
                delivery: form.delivery.value,
            },
            purchaseDetails: arr,
        };

        PUT('purchase', params, (res1) => {
            if (res1 !== null) {
                GET('purchase', { id: element.id }, (res2) => {
                    setElement(res2);
                    setList(res2.purchaseDetails);
                });
                alert.success(res1);
            }
        });
    };

    const _deleteList = () => {
        const checkbox = document.getElementsByName('list[]');

        setList((list) => {
            let updatedList = [...list];

            for (let i = checkbox.length - 1; i >= 0; i--) {
                if (checkbox[i].checked === true) {
                    updatedList[i].status = 'DELETE';
                    checkbox[i].checked = false;
                }
            }

            return updatedList;
        });
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleDownload = () => {
        const data = {
            id: element.id,
        };

        GET('purchase/excel/order', data, (res) => {
            if (res !== null) {
                alert.success('다운로드가 시작됩니다.');
                DOWNLOAD(res.id);
            }
        });
    };

    const pad = (n, width) => {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    };

    const _handlePrice = (a, b) => {
        try {
            return (Number(a) * Number(b)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } catch {
            return 0;
        }
    };

    return (
        <Fragment>
            <form name='activeForm' onSubmit={_handleForm}>
                <table className='form'>
                    {element.id !== -1 ? (
                        <thead>
                            <tr>
                                <td colSpan={4}>
                                    <button type='button' onClick={_handleDownload}>
                                        출력
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    ) : null}
                    <tbody>
                        <tr>
                            <th colSpan={4}>기본 정보</th>
                        </tr>
                        <tr>
                            <th width='15%'>청구번호</th>
                            <td width='35%' className='center-td'>
                                <input
                                    type='text'
                                    defaultValue={`${element.numberOfYear}-${
                                        element.type === 'STEEL'
                                            ? '철강'
                                            : element.type === 'ELECTRICITY'
                                            ? '전기'
                                            : element.type === 'ETC'
                                            ? '기타'
                                            : element.type
                                    }-${pad(element.numberOfPurchase, 4)}`}
                                    disabled
                                />
                            </td>
                            <th width='15%'>구매유형</th>
                            <td width='35%' className='center-td'>
                                <select name='type' defaultValue={element.type} disabled>
                                    <option value=''>선택</option>
                                    <option value='STEEL'>철강자재</option>
                                    <option value='ELECTRICITY'>전기자재</option>
                                    <option value='A'>공압품</option>
                                    <option value='B'>유압품</option>
                                    <option value='C'>베어링제품</option>
                                    <option value='D'>플라스틱벨트</option>
                                    <option value='E'>특수벨트</option>
                                    <option value='F'>모터류</option>
                                    <option value='G'>전장품</option>
                                    <option value='K'>센서류</option>
                                    <option value='N'>기타 기계류</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>프로젝트</th>
                            <td>
                                <input
                                    type='text'
                                    defaultValue={element.project !== null ? element.project.name : ''}
                                    disabled
                                />
                            </td>
                            <th>아이템</th>
                            <td>
                                <input
                                    type='text'
                                    defaultValue={
                                        element.item !== null
                                            ? element.item.itemFamily !== null
                                                ? element.item.itemFamily.name
                                                : element.item.name
                                            : ''
                                    }
                                    disabled
                                />
                            </td>
                        </tr>
                        {element.id !== -1 && element.initStage === 'REQUEST' ? (
                            <Fragment>
                                <tr>
                                    <th colSpan={4}>구매 의뢰 정보</th>
                                </tr>
                                <tr>
                                    <th>구매의뢰일</th>
                                    <td colSpan={3}>
                                        <input
                                            type='date'
                                            name='dateOfRequest'
                                            defaultValue={
                                                element.id !== -1 ? element.purchaseRequest.dateOfRequest : ''
                                            }
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>작성자</th>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={element.id !== -1 ? element.purchaseRequest.drafter : ''}
                                            disabled
                                        />
                                    </td>
                                    <th>승인자</th>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={element.id !== -1 ? element.purchaseRequest.approval : ''}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </Fragment>
                        ) : null}
                        <tr>
                            <th colSpan={4}>구매 리스트 정보</th>
                        </tr>
                        <tr>
                            <th>작성일</th>
                            <td colSpan={3}>
                                <input
                                    type='date'
                                    name='dateOfListing'
                                    defaultValue={element.purchaseListing.dateOfListing}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td>
                                <input type='text' defaultValue={element.purchaseListing.drafter} disabled />
                            </td>
                            <th>승인자</th>
                            <td>
                                <select name='approval' defaultValue={element.purchaseListing.approval} disabled>
                                    <option>정찬영</option>
                                    <option>권택훈</option>
                                    <option>이상수</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={6}>발주 정보</th>
                        </tr>
                        <tr>
                            <th width='13.3%'>발주종류</th>
                            <td width='86.7%' colSpan={5}>
                                <select name='orderType' defaultValue={element.purchaseOrder.orderType} required>
                                    <option value=''>선택</option>
                                    <option value='NORMAL'>일반발주</option>
                                    <option value='OUTSOURCING'>외주발주</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th width='13.3%'>업체명</th>
                            <td width='20%'>
                                <input type='text' name='supplier' defaultValue={element.purchaseOrder.supplier} />
                            </td>
                            <th width='13.3%'>발주일자</th>
                            <td width='20%'>
                                <input
                                    type='date'
                                    name='dateOfOrder'
                                    defaultValue={element.purchaseOrder.dateOfOrder}
                                    required
                                />
                            </td>
                            <th width='13.3%'>발주금액</th>
                            <td width='20%'>
                                <input type='number' name='totalPrice' defaultValue={element.purchaseOrder.price} />
                            </td>
                        </tr>
                        <tr>
                            <th>담당자</th>
                            <td>
                                <input type='text' name='charger' defaultValue={element.purchaseOrder.charger} />
                            </td>
                            <th>발주자</th>
                            <td>
                                <input type='text' name='orderer' defaultValue={element.purchaseOrder.orderer} />
                            </td>
                            <th>납기일</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfDue'
                                    defaultValue={element.purchaseOrder.dateOfDue}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>결재방법</th>
                            <td>
                                <input
                                    type='text'
                                    name='methodOfPayment'
                                    defaultValue={element.purchaseOrder.methodOfPayment}
                                />
                            </td>
                            <th>연락처</th>
                            <td className='center-td'>031 - 431 - 6297</td>
                            <th>팩스</th>
                            <td className='center-td'>031 - 431 - 6081</td>
                        </tr>
                        <tr>
                            <th>계약서</th>
                            <td>
                                <input type='text' name='contract' defaultValue={element.purchaseOrder.contract} />
                            </td>
                            <th>핸드폰</th>
                            <td>
                                <input type='text' name='mobile' defaultValue={element.purchaseOrder.mobile} />
                            </td>
                            <th>납품장소</th>
                            <td>
                                <input type='text' name='delivery' defaultValue={element.purchaseOrder.delivery} />
                            </td>
                        </tr>
                        <tr>
                            <th>Project</th>
                            <td className='center-td'>{element.project !== null ? element.project.code : null}</td>
                            <th>Item(System)</th>
                            <td className='center-td'>
                                {element.item !== null
                                    ? element.item.itemFamily !== null
                                        ? element.item.itemFamily.name
                                        : element.item.name
                                    : null}
                            </td>
                            <th>운반비</th>
                            <td className='center-td'>당사 포함</td>
                        </tr>
                    </tbody>
                </table>
                {source === '' ? (
                    <table className='form'>
                        <thead>
                            <tr>
                                <td colSpan={11}>
                                    <button type='button' onClick={_deleteList}>
                                        선택삭제
                                    </button>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setSource('MATERIAL');
                                        }}
                                    >
                                        자재원부로부터 불러오기
                                    </button>{' '}
                                    <button
                                        type='button'
                                        onClick={() => {
                                            setSource('BOM');
                                        }}
                                    >
                                        BOM으로부터 불러오기
                                    </button>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th width='5%'>
                                    <input type='checkbox' onClick={_handleSelectAll} />
                                </th>
                                <th width='10%'>Parts</th>
                                <th width='10%'>분류번호</th>
                                <th width='10%'>품명</th>
                                <th width='10%'>규격</th>
                                <th width='5%'>Size</th>
                                <th width='5%'>단위</th>

                                <th width='5%'>수량</th>
                                <th width='10%'>단가</th>
                                <th width='10%'>금액</th>
                                <th width='20%'>비고</th>
                            </tr>
                            {list.map((piece, index) => (
                                <tr key={index} style={{ display: piece.status === 'DELETE' ? 'none' : null }}>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='list[]'
                                            value={
                                                piece.partListDescription !== null
                                                    ? piece.partListDescription.id
                                                    : piece.purchaseMaterial.id
                                            }
                                        />
                                    </td>
                                    <td>{piece.partListDescription !== null ? piece.partListDescription.parts : ''}</td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.partsNbr
                                            : piece.purchaseMaterial.code}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.description
                                            : piece.purchaseMaterial.name}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.specification
                                            : piece.purchaseMaterial.specification}
                                    </td>
                                    <td className='center-td'>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.size
                                            : piece.purchaseMaterial.size}
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' name='unit' defaultValue={piece.unit} />
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfPurchase'
                                            defaultValue={piece.quantityOfPurchase}
                                        />
                                    </td>
                                    <td>
                                        <input type='text' name='price' defaultValue={piece.price} />
                                    </td>
                                    <td>{_handlePrice(piece.quantityOfPurchase, piece.price)}</td>
                                    <td>
                                        <input type='text' name='note' defaultValue={piece.note} />
                                    </td>
                                </tr>
                            ))}
                            {list.filter((piece) => piece.status !== 'DELETE').length === 0 ? (
                                <tr>
                                    <td className='center-td' colSpan={11}>
                                        등록된 정보가 없습니다.
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                ) : null}
                {source === 'BOM' ? (
                    <FromBOM
                        setSource={setSource}
                        setList={setList}
                        projectId={element.project === null ? -1 : element.project.id}
                        itemRaw={itemRaw}
                        itemId={element.item === null ? -1 : element.item.id}
                        type={element.type}
                    />
                ) : null}
                {source === 'MATERIAL' ? <FromMaterial setSource={setSource} setList={setList} /> : null}
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
