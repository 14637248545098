import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { menu } from '../../../configs';

import FI from 'feather-icons-react';

import './index.css';

const Component = () => {
    const location = useLocation();

    const thisMenu = menu.find((piece) => piece.path.split('/')[1] === location.pathname.split('/')[1]);

    return (
        <div className='sidebar'>
            {thisMenu !== undefined && thisMenu.sub !== undefined
                ? thisMenu.sub.map((piece, index) =>
                      piece.isHeader ? (
                          <div className='sidebar-menu-header' key={index}>
                              <FI className='sidebar-menu-icon' icon='menu' />
                              <div className='sidebar-menu-title'>{piece.title}</div>
                          </div>
                      ) : (
                          <Link to={piece.path} key={index}>
                              <div
                                  className={piece.path === location.pathname ? 'sidebar-menu-active' : 'sidebar-menu'}
                              >
                                  {piece.title}
                              </div>
                          </Link>
                      )
                  )
                : null}
        </div>
    );
};

export default Component;
