import React from 'react';

const Component = () => {
    return (
        <div className='container'>
            <div className='centralize'>해당 URL을 찾을 수 없습니다 :(</div>
        </div>
    );
};

export default Component;
