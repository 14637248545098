import React, { useState, useEffect, Fragment } from 'react';

import { GET, GET_WITH_PAGING } from '../../../../util/data';

const Component = () => {
    const [totalPage, setTotalPage] = useState(null);
    const [raw, setRaw] = useState([]);
    const [purchase, setPurchase] = useState([]);

    const [data, setData] = useState({
        page: 0,
        size: 10,
        sort: 'type',
        order: 'DESC',
        type: 'ALL',
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET_WITH_PAGING('project/paging', data, (res) => {
            if (active && res !== null) {
                setTotalPage(res.data.totalPages);
                for (let i = 0; i < res.data.content.length; i++) {
                    res.data.content[i].items.sort((a, b) => {
                        try {
                            return (
                                Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                            );
                        } catch {
                            return 1;
                        }
                    });
                }
                setRaw(res.data.content);
            }
        });

        GET('purchase/everything', {}, (res) => {
            setPurchase(res);
        });
    }, [data, active]);

    const _handleType = (value) => {
        let updated = { ...data };
        updated.type = value;
        if (value !== 'ALL') {
            updated.sort = 'code';
        } else {
            updated.sort = 'type';
        }
        updated.page = 0;
        setData(updated);
    };

    const _handleStatus = (value) => {
        let updated = { ...data };
        updated.status = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        updated.page = 0;
        setData(updated);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            if (updated.page + 1 < totalPage) {
                updated.page += 1;
            }
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleSort = (value) => {
        let updated = { ...data };
        updated.sort = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleOrder = (value) => {
        let updated = { ...data };
        updated.order = value;
        updated.page = 0;
        setData(updated);
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handleDataColor = (piece, type) => {
        try {
            let target = purchase.find((element) => element.item.id === piece.id && element.type === type);

            if (target === undefined) return;

            if (target.status === 'WAREHOUSING') {
                let completed = 0;

                for (let i = 0; i < target.purchaseDetails.length; i++) {
                    if (
                        target.purchaseDetails[i].quantityOfPurchase ===
                        target.purchaseDetails[i].quantityOfShipment + target.purchaseDetails[i].quantityOfWarehousing
                    )
                        completed++;
                }

                if (completed === 0) {
                    return 'yellow';
                } else if (completed === target.purchaseDetails.length) {
                    return 'green';
                } else {
                    return 'orange';
                }
            }
        } catch {
            return '';
        }
    };

    const _handleDataValue = (piece, type) => {
        try {
            let target = purchase.find((element) => element.item.id === piece.id && element.type === type);

            if (target === undefined) return;

            if (target.status === 'WAREHOUSING') {
                return target.purchaseOrder.dateOfDue;
            }
        } catch {
            return '';
        }
    };

    return (
        <Fragment>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={11}>
                            <select value={data.type} onChange={(e) => _handleType(e.target.value)}>
                                <option value='ALL'>선택 ① : 전체 프로젝트</option>
                                <option value='PRODUCT'>선택 ① : 완성품 프로젝트</option>
                                <option value='PART'>선택 ① : 부품 프로젝트</option>
                                <option value='AS'>선택 ① : A/S 프로젝트</option>
                            </select>
                            <select value={data.status} onChange={(e) => _handleStatus(e.target.value)}>
                                <option value='CURRENT'>선택 ② : 진행 프로젝트</option>
                                <option value='DONE'>선택 ② : 완료 프로젝트</option>
                            </select>
                            <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                            <button type='button' onClick={_handleKeyword}>
                                검색
                            </button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='11%'>프로젝트명</th>
                        <th width='11%'>아이템명</th>
                        <th width='8.666%'>공압품</th>
                        <th width='8.666%'>유압품</th>
                        <th width='8.666%'>베어링제품</th>
                        <th width='8.666%'>모터류</th>
                        <th width='8.666%'>P-BELT류</th>
                        <th width='8.666%'>RT/PVC벨트</th>
                        <th width='8.666%'>Roller류</th>
                        <th width='8.666%'>기타자재(A)</th>
                        <th width='8.666%'>기타자재(B)</th>



                    </tr>
                    {raw.map((piece, index) => (
                        <Fragment key={index}>
                            {piece.items.map((piece2, index2) => (
                                <tr key={index2}>
                                    {index2 === 0 ? (
                                        <td className='center-td' rowSpan={piece.items.length}>
                                            {piece.code}
                                            <br />
                                            {piece.name}
                                        </td>
                                    ) : null}
                                    <td>{piece2.itemFamily !== null ? piece2.itemFamily.name : piece2.name}</td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'A') }}
                                    >
                                        {_handleDataValue(piece2, 'A')}
                                    </td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'B') }}
                                    >
                                        {_handleDataValue(piece2, 'B')}
                                    </td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'C') }}
                                    >
                                        {_handleDataValue(piece2, 'C')}
                                    </td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'F') }}
                                    >
                                        {_handleDataValue(piece2, 'F')}
                                    </td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'D') }}
                                    >
                                        {_handleDataValue(piece2, 'D')}
                                    </td>
                                    <td
                                        className='center-td'
                                        style={{ backgroundColor: _handleDataColor(piece2, 'E') }}
                                    >
                                        {_handleDataValue(piece2, 'E')}
                                    </td>
                                    <td
                                        className='center-td'
                                        
                                    >
                                        
                                    </td>
                                    <td
                                        className='center-td'
                                        
                                    >
                                        
                                    </td>
                                    <td
                                        className='center-td'
                                        
                                    >
                                        
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                    {data.size > raw.length
                        ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                              <tr key={index}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                              </tr>
                          ))
                        : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={11}>
                            <button type='button' onClick={() => _handlePage(0)}>
                                이전
                            </button>
                            <button type='reset'>{data.page + 1}</button>
                            <button type='reset'>/</button>
                            <button type='reset'>{totalPage === null ? '10' : totalPage}</button>
                            <button type='button' onClick={() => _handlePage(1)}>
                                다음
                            </button>
                            <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                                <option value={5}>5개 데이터 조회</option>
                                <option value={10}>10개 데이터 조회</option>
                                <option value={20}>20개 데이터 조회</option>
                            </select>
                            <select value={data.order} onChange={(e) => _handleOrder(e.target.value)}>
                                <option value='DESC'>정렬 ② : 내림차순</option>
                                <option value='ASC'>정렬 ② : 오름차순</option>
                            </select>
                            <select value={data.sort} onChange={(e) => _handleSort(e.target.value)}>
                                <option value='type'>정렬 ① : 구분</option>
                                <option value='code'>정렬 ① : 지시번호</option>
                                <option value='name'>정렬 ① : 프로젝트명</option>
                                <option value='customer'>정렬 ① : 고객사</option>
                                <option value='dateOfOrder'>정렬 ① : 지시일자</option>
                                <option value='dateOfDue'>정렬 ① : 납품일자</option>
                            </select>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </Fragment>
    );
};

export default Component;
