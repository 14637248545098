// Information Configuration
export const info = {
	title: 'WSA PMS',
	subtitle: 'Production Management System',

	version: 'v2.5.1',
	commit: '20240908',
	isBeta: false,

	server: 'WSA Local Server Ubuntu 18.04',
	copyright: 'Copyright 2024. SMCG all rights reserved.',
}
