import React, { useState } from 'react';

import Form from './form';

import ItemSelectorForDesign from '../../../../ui/selector/ItemSelectorForDesign';
import Popup from '../../../../ui/popup';

const Component = () => {
    const [piece, setPiece] = useState({
        project: {
            id: -2,
        },
        item: {
            id: -2,
        },
    });

    const _handleExit = () => {
        setPiece({
            project: {
                id: -2,
            },
            item: {
                id: -2,
            },
        });
    };

    return (
        <div className='container'>
            <ItemSelectorForDesign piece={piece} setPiece={setPiece} isDesignModule={false} />
            {piece.project.id > -1 ? (
                <Popup title='조회 및 업데이트' data={<Form piece={piece} />} exitFunc={_handleExit} hasButton={true} />
            ) : null}
        </div>
    );
};

export default Component;
