import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewerForUncomformity from '../../../../ui/viewer/ProjectViewerForUncomformity';
import DateInputer from '../../../../ui/inputer/DateInputer';

import { PUT } from '../../../../../util/data';

const Component = ({ project }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].qualityWork.id,
                canningTeam: form.canningTeam.value,
                progressOfCanning: form.progressOfCanning.value,
                progressOfPainting: form.progressOfPainting.value,
                progressOfPostProcessing: form.progressOfPostProcessing.value,
                dateOfCanning: form.dateOfCanning.value,
                dateOfPainting: form.dateOfPainting.value,
                dateOfPostProcessing: form.dateOfPostProcessing.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].qualityWork.id,
                    canningTeam: form.canningTeam[i].value,
                    progressOfCanning: form.progressOfCanning[i].value,
                    progressOfPainting: form.progressOfPainting[i].value,
                    progressOfPostProcessing: form.progressOfPostProcessing[i].value,
                    dateOfCanning: form.dateOfCanning[i].value,
                    dateOfPainting: form.dateOfPainting[i].value,
                    dateOfPostProcessing: form.dateOfPostProcessing[i].value,
                });
            }
        }

        PUT('quality/work', arr, (res) => {
            alert.success(res);
        });
    };

    return (
        <Fragment>
            <ProjectViewerForUncomformity project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={12}>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='5%' rowSpan={2}>
                                No.
                            </th>
                            <th width='20%' rowSpan={2}>
                                아이템명
                            </th>
                            <th width='12.5%' rowSpan={2}>
                                규격
                            </th>
                            <th width='10%' rowSpan={2}>
                                단위
                            </th>
                            <th width='10%' rowSpan={2}>
                                수량
                            </th>
                            <th width='12.5%' colSpan={3}>
                                제관 검사
                            </th>
                            <th width='12.5%' colSpan={2}>
                                도장 검사
                            </th>
                            <th width='12.5%' colSpan={2}>
                                후처리 검사
                            </th>
                        </tr>
                        <tr>
                            <th width='5%'>작업팀</th>
                            <th width='7.5%'>완료일</th>
                            <th width='5%'>진도</th>
                            <th width='7.5%'>완료일</th>
                            <th width='5%'>진도</th>
                            <th width='7.5%'>완료일</th>
                            <th width='5%'>진도</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <Fragment key={index}>
                                <tr>
                                    <td>{piece.nbr}</td>
                                    <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                    <td>{piece.specification}</td>
                                    <td>{piece.unit}</td>
                                    <td>{piece.quantity}</td>
                                    {piece.qualityOrder.statusOfCanning !== 'UNNECESSARY' ? (
                                        <Fragment>
                                            <td>
                                                <select name='canningTeam' defaultValue={piece.qualityWork.canningTeam}>
                                                    <option value='NONE'>미지정</option>
                                                    <option value='ONE'>1팀</option>
                                                    <option value='TWO'>2팀</option>
                                                    <option value='THREE'>3팀</option>
                                                    <option value='FOUR'>4팀</option>
                                                    <option value='ETC'>기타</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input
                                                    type='date'
                                                    name='dateOfCanning'
                                                    defaultValue={piece.qualityWork.dateOfCanning}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    name='progressOfCanning'
                                                    defaultValue={piece.qualityWork.progressOfCanning}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                    <option value={35}>35</option>
                                                    <option value={40}>40</option>
                                                    <option value={45}>45</option>
                                                    <option value={50}>50</option>
                                                    <option value={55}>55</option>
                                                    <option value={60}>60</option>
                                                    <option value={65}>65</option>
                                                    <option value={70}>70</option>
                                                    <option value={75}>75</option>
                                                    <option value={80}>80</option>
                                                    <option value={85}>85</option>
                                                    <option value={90}>90</option>
                                                    <option value={95}>95</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <td colSpan={3} className='center-td'>
                                            <input
                                                type='hidden'
                                                name='canningTeam'
                                                defaultValue={piece.qualityWork.canningTeam}
                                            />
                                            <input
                                                type='hidden'
                                                name='progressOfCanning'
                                                defaultValue={piece.qualityWork.progressOfCanning}
                                            />
                                            <input
                                                type='hidden'
                                                name='dateOfCanning'
                                                defaultValue={piece.qualityWork.dateOfCanning}
                                            />
                                            작업불필요
                                        </td>
                                    )}
                                    {piece.qualityOrder.statusOfPainting !== 'UNNECESSARY' ? (
                                        <Fragment>
                                            <td>
                                                <input
                                                    type='date'
                                                    name='dateOfPainting'
                                                    defaultValue={piece.qualityWork.dateOfPainting}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    name='progressOfPainting'
                                                    defaultValue={piece.qualityWork.progressOfPainting}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                    <option value={35}>35</option>
                                                    <option value={40}>40</option>
                                                    <option value={45}>45</option>
                                                    <option value={50}>50</option>
                                                    <option value={55}>55</option>
                                                    <option value={60}>60</option>
                                                    <option value={65}>65</option>
                                                    <option value={70}>70</option>
                                                    <option value={75}>75</option>
                                                    <option value={80}>80</option>
                                                    <option value={85}>85</option>
                                                    <option value={90}>90</option>
                                                    <option value={95}>95</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <td colSpan={2} className='center-td'>
                                            <input
                                                type='hidden'
                                                name='progressOfPainting'
                                                defaultValue={piece.qualityWork.progressOfPainting}
                                            />
                                            <input
                                                type='hidden'
                                                name='dateOfPainting'
                                                defaultValue={piece.qualityWork.dateOfPainting}
                                            />
                                            작업불필요
                                        </td>
                                    )}
                                    {piece.qualityOrder.statusOfPostProcessing !== 'UNNECESSARY' ? (
                                        <Fragment>
                                            <td>
                                                <input
                                                    type='date'
                                                    name='dateOfPostProcessing'
                                                    defaultValue={piece.qualityWork.dateOfPostProcessing}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    name='progressOfPostProcessing'
                                                    defaultValue={piece.qualityWork.progressOfPostProcessing}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={30}>30</option>
                                                    <option value={35}>35</option>
                                                    <option value={40}>40</option>
                                                    <option value={45}>45</option>
                                                    <option value={50}>50</option>
                                                    <option value={55}>55</option>
                                                    <option value={60}>60</option>
                                                    <option value={65}>65</option>
                                                    <option value={70}>70</option>
                                                    <option value={75}>75</option>
                                                    <option value={80}>80</option>
                                                    <option value={85}>85</option>
                                                    <option value={90}>90</option>
                                                    <option value={95}>95</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <td colSpan={2} className='center-td'>
                                            <input
                                                type='hidden'
                                                name='progressOfPostProcessing'
                                                defaultValue={piece.qualityWork.progressOfPostProcessing}
                                            />
                                            <input
                                                type='hidden'
                                                name='dateOfPostProcessing'
                                                defaultValue={piece.qualityWork.dateOfPostProcessing}
                                            />
                                            작업불필요
                                        </td>
                                    )}
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
