import React, { useEffect, useState } from 'react';

const Component = () => {
    const [list] = useState([]);

    const [data, setData] = useState({
        today: new Date().toISOString().substr(0, 10),
    });

    useEffect(() => {
        // GET('warehousing/purchase', data, (res) => {
        //     let arr = [];
        //     for (let i = 0; i < res.length; i++) {
        //         const index1 = arr.findIndex(
        //             (piece) =>
        //                 res[i].purchaseMaterial !== null &&
        //                 piece.purchaseMaterial !== null &&
        //                 piece.purchaseMaterial.id === res[i].purchaseMaterial.id
        //         );
        //         const index2 = arr.findIndex(
        //             (piece) =>
        //                 res[i].partListDescription !== null &&
        //                 piece.partListDescription !== null &&
        //                 piece.partListDescription.id === res[i].partListDescription.id
        //         );
        //         if (index1 === -1 && index2 === -1) {
        //             arr.push({
        //                 purchaseMaterial: res[i].purchaseMaterial !== null ? res[i].purchaseMaterial : null,
        //                 partListDescription: res[i].partListDescription !== null ? res[i].partListDescription : null,
        //                 quantity: res[i].quantity,
        //             });
        //         } else {
        //             if (res[i].type === 'INPUT') {
        //                 arr[index1 === -1 ? index2 : index1].quantity += res[i].quantity;
        //             } else {
        //                 arr[index1 === -1 ? index2 : index1].quantity -= res[i].quantity;
        //             }
        //         }
        //     }
        //     setList(arr);
        // });
    }, [data]);

    const _handleToday = (value) => {
        let updated = { ...data };
        updated.today = value;
        setData(updated);
    };

    return (
        <div className='container'>
            <table className='form'>
                <thead>
                    <tr>
                        <td colSpan={7}>
                            <select style={{ width: '150px' }}>
                                <option>전체 제품군</option>
                                <option value='STEEL'>철강자재</option>
                                <option value='ELECTRICITY'>전기자재</option>
                                <option value='A'>공압품</option>
                                <option value='B'>유압품</option>
                                <option value='C'>베어링제품</option>
                                <option value='D'>플라스틱벨트</option>
                                <option value='E'>특수벨트</option>
                                <option value='F'>모터류</option>
                                <option value='G'>전장품</option>
                                <option value='K'>센서류</option>
                                <option value='N'>기타 기계류</option>
                                <option value='ETC'>기타 구매</option>
                            </select>
                            <input type='date' value={data.today} onChange={(e) => _handleToday(e.target.value)} />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>No.</th>
                        <th width='10%'>제품군</th>
                        <th width='10%'>분류코드</th>
                        <th width='27.5%'>품명</th>
                        <th width='27.5%'>규격</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>현재 재고량</th>
                    </tr>
                    {list.length !== 0 ? (
                        list.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>{index + 1}</td>
                                <td className='center-td'>
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'STEEL'
                                        ? '철강자재'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'ELECTRICITY'
                                        ? '전기자재'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'A'
                                        ? '공압품'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'B'
                                        ? '유압품'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'C'
                                        ? '베어링제품'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'D'
                                        ? '플라스틱벨트'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'E'
                                        ? '특수벨트'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'F'
                                        ? '모터류'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'G'
                                        ? '전장품'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'K'
                                        ? '센서류'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'N'
                                        ? '기타 기계류'
                                        : ''}
                                    {piece.purchaseMaterial !== null && piece.purchaseMaterial.type === 'ETC'
                                        ? '기타 자재'
                                        : ''}
                                </td>
                                <td className='center-td'>
                                    {piece.purchaseMaterial !== null
                                        ? piece.purchaseMaterial.code
                                        : piece.partListDescription.partsNbr}
                                </td>
                                <td className='center-td'>
                                    {piece.purchaseMaterial !== null
                                        ? piece.purchaseMaterial.name
                                        : piece.partListDescription.description}
                                </td>
                                <td className='center-td'>
                                    {piece.purchaseMaterial !== null
                                        ? piece.purchaseMaterial.specification
                                        : piece.partListDescription.specification}
                                </td>
                                <td className='center-td'>
                                    {piece.purchaseMaterial !== null ? piece.purchaseMaterial.unit : ''}
                                </td>
                                <td className='center-td'>{piece.quantity}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} className='center-td'>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
