import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Reducers from './reducers';

import Router from './router';

import './index.css';

ReactDOM.render(
    <Provider
        store={createStore(Reducers)}
    >
        <Router />
    </Provider>,
    document.getElementById('root')
);
