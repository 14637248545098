import React from 'react';
import { useAlert } from 'react-alert';

import { POST } from '../../../../util/data';

const Component = ({ setThisUser }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const params = {
            username: form.username.value,
            password: form.password.value,
            name: form.name.value,
            admin: form.admin.value,
        };

        POST('user', params, (res) => {
            if (res !== null) {
                setThisUser({
                    id: -2,
                });
                alert.success(res);
            }
        });
    };

    return (
        <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
            <table className='form'>
                <tbody>
                    <tr>
                        <th>아이디</th>
                        <th>비밀번호</th>
                        <th>사용자명</th>
                        <th>관리자여부</th>
                    </tr>
                    <tr>
                        <td>
                            <input type='text' name='username' />
                        </td>
                        <td>
                            <input type='password' name='password' />
                        </td>
                        <td>
                            <input type='text' name='name' />
                        </td>
                        <td>
                            <select name='admin'>
                                <option value={false}>일반계정</option>
                                <option value={true}>관리자계정</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type='submit' className='proceedButton'>
                저장
            </button>
        </form>
    );
};

export default Component;
