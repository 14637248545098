import React, { useState, useEffect } from 'react';

import qs from 'qs';

import Form from './form';

import ProjectSelector from '../../../../ui/selector/ProjectSelector';
import Popup from '../../../../ui/popup';

import { GET } from '../../../../../util/data';

const Component = () => {
    const { p } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    const [project, setProject] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setProject({
            id: -2,
        });
    };

    useEffect(() => {
        if (p !== undefined) {
            GET('project', { id: p }, (res) => {
                res.items.sort((a, b) => {
                    try {
                        return (
                            Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                            Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                        );
                    } catch {
                        return 1;
                    }
                });
                setProject(res);
            });
        }
    }, [p]);

    const _handleStatusByProject = (piece) => {
        let res = 0;

        for (let i = 0; i < piece.items.length; i++) {
            if (piece.items[i].designPlanning.status !== 'UNNECESSARY') {
                if (
                    piece.items[i].designPlanning.designer === null ||
                    piece.items[i].designPlanning.dateOfFinish === null ||
                    piece.items[i].designPlanning.dateOfFinish === '' ||
                    piece.items[i].designPlanning.checker === null ||
                    piece.items[i].designPlanning.dateOfCheck === null ||
                    piece.items[i].designPlanning.dateOfCheck === ''
                ) {
                    res++;
                    break;
                }
            }
        }

        return res;
    };

    return (
        <div className='container'>
            <ProjectSelector
                project={project}
                setProject={setProject}
                isProjectManager={false}
                _handleStatusByProject={_handleStatusByProject}
                isItemSearch={true}
            />
            {project.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form project={project} setProject={setProject} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
