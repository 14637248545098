import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import UserList from './list';
import Form from './form';

import Popup from '../../../ui/popup';

const Component = () => {
    const [thisUser, setThisUser] = useState({
        id: -2,
    });
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!user.admin) {
            confirmAlert({
                message: '권한이 없습니다.',
                buttons: [
                    {
                        label: 'Okay',
                        onClick: () => (window.location.href = '/'),
                    },
                ],
            });
        }
    }, [user]);

    const _handleExit = () => {
        setThisUser({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <UserList thisUser={thisUser} setThisUser={setThisUser} />
            {thisUser.id === -1 ? (
                <Popup
                    title='신규 등록'
                    data={<Form setThisUser={setThisUser} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
