import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';
import ItemViewer from '../ItemViewer';

import { PUT } from '../../../../../util/data';

const Component = ({ piece, setPiece }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const data = {
            id: piece.item.designWork.id,
            dateOfReceipt: form.dateOfReceipt.value,
            dateOfStart: form.dateOfStart.value,
            progress: Number(form.progress.value),
            dateOfSchedule: form.dateOfSchedule.value,
            dateOfFinish: form.dateOfFinish.value,
            dateOfCheck: piece.item.designWork.dateOfCheck,
            serialNbr: form.serialNbr.value,
            note: form.note.value,
        };

        if (
            data.progress === 100 ||
            (data.dateOfFinish !== null && data.dateOfFinish !== undefined && data.dateOfFinish !== '')
        ) {
            if (piece.item.partList.status !== 'DONE' && piece.item.partList.status !== 'UNNECESSARY') {
                alert.error('파트리스트(BOM) 미완료');
                return;
            }
        }

        PUT('design/work', data, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    const _handleStatus = () => {
        const data = {
            id: piece.item.partList.id,
            status: piece.item.partList.status === 'UNNECESSARY' ? 'CURRENT' : 'UNNECESSARY',
        };

        PUT('partlist/status', data, (res) => {
            if (res !== null) {
                setPiece((piece) => {
                    let updated = { ...piece };
                    piece.item.partList.status =
                        piece.item.partList.status === 'UNNECESSARY' ? 'CURRENT' : 'UNNECESSARY';
                    return updated;
                });
                alert.success(res);
            }
        });
    };

    const _handlePartListLink = (type, piece) => {
        if (type === 'PARTLIST') {
            window.open(`/design/bom/partlist?p=${piece.project.id}&i=${piece.item.id}`, '_black');
        } else {
            window.open(`/design/bom/bom?p=${piece.project.id}&i=${piece.item.id}`, '_black');
        }
    };

    return (
        <Fragment>
            <ProjectViewer project={piece.project} showItemList={false} />
            <FileDownloader project={piece.project} />
            <ItemViewer item={piece.item} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='14.28%'>설계자료접수일</th>
                            <th width='14.28%'>설계시작일</th>
                            <th width='14.28%'>작업진도</th>
                            <th width='14.28%'>설계완료예정일</th>
                            <th width='14.28%'>설계완료일</th>
                            <th width='14.28%'>검도완료일</th>
                            <th width='14.28%'>Serial No.</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfReceipt'
                                    defaultValue={piece.item.designWork.dateOfReceipt}
                                />
                            </td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfStart'
                                    defaultValue={piece.item.designWork.dateOfStart}
                                />
                            </td>
                            <td>
                                <select name='progress' defaultValue={piece.item.designWork.progress}>
                                    <option value={0}>0</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                    <option value={90}>90</option>
                                    <option value={95}>95</option>
                                    <option value={100}>100</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfSchedule'
                                    defaultValue={piece.item.designWork.dateOfSchedule}
                                />
                            </td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfFinish'
                                    defaultValue={piece.item.designWork.dateOfFinish}
                                />
                            </td>
                            <td className='center-td'>{piece.item.designWork.dateOfCheck}</td>
                            <td>
                                <input type='text' name='serialNbr' defaultValue={piece.item.designWork.serialNbr} />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={7}>이슈사항</th>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <textarea
                                    className='wide-textarea'
                                    name='note'
                                    defaultValue={piece.item.designWork.note}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {piece.item.partListMerge === null || piece.item.partListMerge === -1 ? (
                    <Fragment>
                        <button type='button' className='printButton' onClick={_handleStatus}>
                            {piece.item.partList.status === 'UNNECESSARY'
                                ? 'Part List / BOM 작업 필요'
                                : 'Part List / BOM 작업 불필요'}
                        </button>
                        <button type='button' className='printButton' onClick={() => _handlePartListLink('BOM', piece)}>
                            BOM 바로가기
                        </button>
                        <button
                            type='button'
                            className='printButton'
                            onClick={() => _handlePartListLink('PARTLIST', piece)}
                        >
                            Part List 바로가기
                        </button>
                    </Fragment>
                ) : null}
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
