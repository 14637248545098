import React, { useState, useEffect } from 'react';

import Form from './form';
import FullScreen from '../../../ui/fullscreen';

import { GET, GET_WITH_PAGING } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

import './index.css';

const Component = () => {
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ id: -2 });
    const [detector, setDetector] = useState(false);

    useEffect(() => {
        GET_WITH_PAGING(
            'project/paging',
            {
                page: 0,
                size: 0,
                sort: '',
                order: '',
                type: 'PRODUCT',
                status: 'CURRENT',
                keyword: '',
            },
            (res) => {
                res = res.data.content;

                setProjects(
                    res.sort((a, b) =>
                        a.dateOfDue === null || b.dateOfDue === null
                            ? 1
                            : b.dateOfDue.localeCompare(a.dateOfDue) ||
                              (a.code === null || b.code === null ? 1 : a.code.localeCompare(b.code))
                    )
                );
            }
        );
    }, []);

    useEffect(() => {
        GET('project/confirmation', {}, (res) => {
            if (res.length > 0) {
                confirmAlert({
                    message: '미결제 프로젝트가 존재합니다. 결제 페이지로 이동하시겠습니까?',
                    buttons: [
                        {
                            label: '지금 이동하기',
                            onClick: () => (window.location.href = '/status/approval'),
                        },
                        {
                            label: '나중에 하기',
                        },
                    ],
                });
            }
        });
    }, []);

    const _handleProject = (piece) => {
        window.localStorage.setItem(
            'view_list',
            window.localStorage.getItem('view_list') === null
                ? piece.id
                : window.localStorage.getItem('view_list') + ' ' + piece.id
        );

        setProject(piece);
    };

    const _handleClear = () => {
        window.localStorage.clear();
        setDetector(detector ? false : true);
    };

    const _handleDateDifference = (value) => {
        const today = new Date();
        const target = new Date(value);

        const diff = ((target.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

        return Number(diff);
    };

    const _handleExit = () => {
        setProject({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={8}>
                            <button onClick={_handleClear}>읽음 표시 삭제</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>No.</th>
                        <th width='15%'>지시번호</th>
                        <th width='30%'>프로젝트명</th>
                        <th width='10%'>지시일자</th>
                        <th width='10%'>납품일자</th>
                        <th width='10%'>우선순위</th>
                        <th width='10%'>출하예정일</th>
                        <th width='10%'>
                            잔여/
                            <br />
                            지연일
                        </th>
                    </tr>
                    {projects.length !== 0 ? (
                        projects.map((piece, index) => (
                            <tr
                                key={index}
                                className={
                                    window.localStorage.getItem('view_list') !== null
                                        ? window.localStorage
                                              .getItem('view_list')
                                              .split(' ')
                                              .findIndex((cookie) => piece.id === Number(cookie)) !== -1
                                            ? 'hide-tr'
                                            : null
                                        : null
                                }
                                onClick={() => _handleProject(piece)}
                            >
                                <td className='center-td'>{index + 1}</td>
                                <td>{piece.code}</td>
                                <td>{piece.name}</td>
                                <td className='center-td'>{piece.dateOfOrder}</td>
                                <td className='center-td'>{piece.dateOfDue}</td>
                                <td className='center-td'></td>
                                <td className='center-td'>
                                    {piece.shipment.dateOfShipment3 !== null && piece.shipment.dateOfShipment3 !== ''
                                        ? piece.shipment.dateOfShipment3
                                        : piece.shipment.dateOfShipment2 !== null &&
                                          piece.shipment.dateOfShipment2 !== ''
                                        ? piece.shipment.dateOfShipment2
                                        : piece.shipment.dateOfShipment1 !== null &&
                                          piece.shipment.dateOfShipment1 !== ''
                                        ? piece.shipment.dateOfShipment1
                                        : ''}
                                </td>
                                <td
                                    className={
                                        _handleDateDifference(piece.dateOfDue) >= 0
                                            ? 'center-td status-safe'
                                            : 'center-td status-emergency'
                                    }
                                >
                                    {_handleDateDifference(piece.dateOfDue)}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={8}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {project.id > -1 ? (
                <FullScreen title='프로젝트 상세정보' data={<Form project={project} />} exitFunc={_handleExit} />
            ) : null}
        </div>
    );
};

export default Component;
