import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import { GET, PUT } from '../../../util/data';

import './index.css';

const Component = ({ process }) => {
    const [raw, setRaw] = useState([]);
    const [processList] = useState([
        { path: 'pipe', title: '파이프 레이저', type: 'PIPE' },
        { path: 'mct', title: 'MCT', type: 'MCT' },
        { path: 'cnc', title: 'CNC', type: 'CNC' },
    ]);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        const data = {
            process: processList.find((piece) => piece.path === process).type,
        };

        GET('pop', data, (res) => {
            if (active && res !== null) {
                let arr = [];

                for (let i = 0; i < res.length; i++) {
                    if (
                        arr.length === 0 ||
                        arr.find((piece) => piece.partListDescription.id === res[i].partListDescription.id)
                    ) {
                        arr.push(res[i]);
                    }
                }

                setRaw(arr);
            }
        });
    }, [active, processList, process]);

    useEffect(() => {
        const _loadData = () => {
            const data = {
                process: processList.find((piece) => piece.path === process).type,
            };

            GET('pop', data, (res) => {
                if (active && res !== null) {
                    let arr = [];

                    for (let i = 0; i < res.length; i++) {
                        if (
                            arr.length === 0 ||
                            arr.find((piece) => piece.partListDescription.id === res[i].partListDescription.id)
                        ) {
                            arr.push(res[i]);
                        }
                    }

                    setRaw(arr);
                }
            });
        };

        const interval = setInterval(() => {
            _loadData();
        }, 15000);

        return () => clearInterval(interval);
    }, [active, processList, process]);

    const _handleFinish = (piece) => {
        const params = {
            id: piece.manufactureOrder.id,
            dateOfFinish: new Date(),
        };

        PUT('manufacture/order/finish', params, (res) => {
            const data = {
                process: processList.find((piece) => piece.path === process).type,
            };

            GET('pop', data, (res) => {
                if (active && res !== null) {
                    setRaw(res);
                }
            });
            alert.success(res);
        });
    };

    const date = new Date();
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

    return (
        <div className='pop-app'>
            <table className='pop-form'>
                <thead>
                    <tr>
                        <td width='60%' rowSpan={2}>
                            {processList.find((piece) => piece.path === process).title} 작업관리시스템
                        </td>
                        <th width='15%'>작 업 일</th>
                        <td width='25%'>{`${year}-${month}-${day}`}</td>
                    </tr>
                    <tr>
                        <th>작 업 자</th>
                        <td>
                            {user.name} ({user.username})
                        </td>
                    </tr>
                </thead>
            </table>
            <table className='pop-form'>
                <tbody>
                    <tr>
                        <th width='3%' rowSpan={2}>
                            No
                        </th>
                        <th width='25.5%'>프로젝트명</th>
                        <th width='25.5%'>부품코드</th>
                        <th width='15%' rowSpan={2}>
                            규격
                        </th>
                        <th width='5%' rowSpan={2}>
                            Set
                        </th>
                        <th width='5%' rowSpan={2}>
                            Q'TY
                        </th>
                        <th width='7%' rowSpan={2}>
                            T
                        </th>
                        <th width='7%' rowSpan={2}>
                            작업수량
                        </th>
                        <th width='7%' rowSpan={2}>
                            작업완료
                        </th>
                    </tr>
                    <tr>
                        <th>아이템명</th>
                        <th>부품명</th>
                    </tr>
                    {raw.length !== 0 ? (
                        raw.map((piece, index) => (
                            <Fragment key={index}>
                                <tr>
                                    <td rowSpan={2}>{index + 1}</td>
                                    <td>{piece.project.name}</td>
                                    <td>{piece.partListDescription.partsNbr}</td>
                                    <td rowSpan={2}>{piece.partListDescription.specification}</td>
                                    <td rowSpan={2}>{piece.partListDescription.setQuantity}</td>
                                    <td rowSpan={2}>{piece.partListDescription.quantity}</td>
                                    <td rowSpan={2}>{piece.partListDescription.manufactureAssignment.t}</td>
                                    <td rowSpan={2}>
                                        {piece.partListDescription.manufactureAssignment.quantityOfProduction}
                                    </td>
                                    <td rowSpan={2}>
                                        <button onClick={() => _handleFinish(piece)}>작업완료</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}
                                    </td>
                                    <td>{piece.partListDescription.description}</td>
                                </tr>
                            </Fragment>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={11}>
                                지시된 작업이 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
