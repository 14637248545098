import React, { Fragment, useState } from 'react';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';

import ProjectViewerForUncomformity from '../../viewer/ProjectViewerForUncomformity';

import { GET, DELETE } from '../../../../util/data';

const DEPARTMENT = [
    {
        title: '설계부',
        options: [
            '설계 치수 부적합',
            '설계 발주 부적합',
            '설계 간섭 부적합',
            '설계 도면 미일치',
            '설계 부품 누락',
            '상대물 미일치 부적합',
        ],
    },
    {
        title: '기계가공부',
        options: ['가공 치수 부적합', 'Laser 설계 부적합', '가공 누락 부적합', '가공 소재 부적합'],
    },
    {
        title: '구매부',
        options: ['자재 부적합', '외주가공 치수 부적합', '자재구매 부적합'],
    },
    {
        title: '기계조립부',
        options: ['작업중 파손 부적합'],
    },
    {
        title: '기주기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '우성기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '탑기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '일흥기계',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
    {
        title: '진성산업',
        options: ['도장 불량', '도장 색상 불량', '열변형', '도장 중 파손'],
    },
    {
        title: '기타제관',
        options: [
            '제작 치수 부적합',
            '제작 가공 치수 부적합',
            '제작 변형 부적합',
            '제작 가공 누락 부적합',
            '제작 상태 부적합',
            '제작 누락 부적합',
        ],
    },
];

const Component = ({ project, setProject, item, setItem, receipt, setReceipt }) => {
    const [data, setData] = useState({
        department: '',
        type: '',
        initDate: '',
        endDate: '',
    });
    const alert = useAlert();

    const _handleSetReceipt = (piece) => {
        setReceipt(piece);
    };

    const _handleResetProject = () => {
        setItem({
            id: -2,
        });
        setProject({
            id: -2,
        });
    };

    const _handleResetItem = () => {
        setItem({
            id: -2,
        });
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');
                        let arr = [];
                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }
                        if (arr.length > 0) {
                            DELETE('uncomformity/all', { ids: arr.toString() }, (res1) => {
                                if (res1 !== null) {
                                    GET('project', { id: project.id }, (res2) => {
                                        res2.items.sort((a, b) => {
                                            try {
                                                return (
                                                    Number(a.nbr.replace(/\D/g, '')) - Number(b.nbr.replace(/\D/g, ''))
                                                );
                                            } catch {
                                                return 1;
                                            }
                                        });
                                        setProject(res2);
                                        setItem(res2.items.find((piece) => piece.id === item.id));
                                        alert.success(res1);
                                    });
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    const _handleDepartment = (value) => {
        let updated = { ...data };
        updated.department = value;
        updated.type = '';
        setData(updated);
    };

    const _handleType = (value) => {
        let updated = { ...data };
        updated.type = value;
        setData(updated);
    };

    const _handleInitDate = (value) => {
        let updated = { ...data };
        updated.initDate = value;
        setData(updated);
    };

    const _handleEndDate = (value) => {
        let updated = { ...data };
        updated.endDate = value;
        setData(updated);
    };

    const _handleResetDates = () => {
        let updated = { ...data };
        updated.initDate = '';
        updated.endDate = '';
        setData(updated);
    };

    return (
        <Fragment>
            <ProjectViewerForUncomformity project={project} item={item} isSetItem={true} />
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={7}>
                            <button type='button' onClick={_handleResetProject}>
                                프로젝트 재선택
                            </button>
                            <button type='button' onClick={_handleResetItem}>
                                아이템 재선택
                            </button>
                            <div className='division'>|</div>
                            <button
                                type='button'
                                onClick={() =>
                                    setReceipt({
                                        id: -1,
                                    })
                                }
                            >
                                신규티켓 작성
                            </button>
                            <button type='button' onClick={_handleDeleteAll}>
                                선택티켓 삭제
                            </button>
                            <div className='division'>|</div>
                            <input
                                type='date'
                                value={data.initDate}
                                onChange={(e) => _handleInitDate(e.target.value)}
                            />
                            <input type='date' value={data.endDate} onChange={(e) => _handleEndDate(e.target.value)} />
                            <button type='button' onClick={_handleResetDates}>
                                초기화
                            </button>
                            <select
                                className='right-side'
                                value={data.type}
                                onChange={(e) => _handleType(e.target.value)}
                            >
                                <option value=''>필터 ② : 부적합유형</option>
                                {data.department !== ''
                                    ? DEPARTMENT.find(
                                          (piece) => piece.title === data.department
                                      ).options.map((piece, index) => <option key={index}>{piece}</option>)
                                    : null}
                            </select>
                            <select
                                className='right-side'
                                value={data.department}
                                onChange={(e) => _handleDepartment(e.target.value)}
                            >
                                <option value=''>필터 ① : 발생부서</option>
                                {DEPARTMENT.map((piece, index) => (
                                    <option key={index}>{piece.title}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>
                            <input type='checkbox' onClick={_handleSelectAll} />
                        </th>
                        <th width='10%'>발생일자</th>
                        <th width='20%'>아이템명</th>
                        <th width='20%'>지시번호</th>
                        <th width='20%'>프로젝트명</th>
                        <th width='10%'>발생부서</th>
                        <th width='15%'>부적합유형</th>
                    </tr>
                    {item.uncomformities
                        .filter((piece) =>
                            data.initDate !== '' && data.endDate !== ''
                                ? piece.dateOfIssue !== null &&
                                  piece.dateOfIssue !== '' &&
                                  new Date(data.initDate).getTime() <= new Date(piece.dateOfIssue).getTime() &&
                                  new Date(piece.dateOfIssue).getTime() <= new Date(data.endDate).getTime()
                                : true
                        )
                        .filter((piece) => (data.department !== '' ? piece.department === data.department : true))
                        .filter((piece) => (data.type !== '' ? piece.type === data.type : true)).length !== 0 ? (
                        item.uncomformities
                            .filter((piece) =>
                                data.initDate !== '' && data.endDate !== ''
                                    ? piece.dateOfIssue !== null &&
                                      piece.dateOfIssue !== '' &&
                                      new Date(data.initDate).getTime() <= new Date(piece.dateOfIssue).getTime() &&
                                      new Date(piece.dateOfIssue).getTime() <= new Date(data.endDate).getTime()
                                    : true
                            )
                            .filter((piece) => (data.department !== '' ? piece.department === data.department : true))
                            .filter((piece) => (data.type !== '' ? piece.type === data.type : true))
                            .sort((a, b) =>
                                a.dateOfIssue === null || b.dateOfIssue === null
                                    ? 0
                                    : a.dateOfIssue.localeCompare(b.dateOfIssue)
                            )
                            .map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td'>
                                        <input type='checkbox' name='list[]' defaultValue={piece.id} />
                                    </td>
                                    <td className='center-td' onClick={() => _handleSetReceipt(piece)}>
                                        {piece.dateOfIssue}
                                    </td>
                                    <td onClick={() => _handleSetReceipt(piece)}>
                                        {item.itemFamily !== null ? item.itemFamily.name : item.name}
                                    </td>
                                    <td onClick={() => _handleSetReceipt(piece)}>{project.code}</td>
                                    <td onClick={() => _handleSetReceipt(piece)}>{project.name}</td>
                                    <td className='center-td' onClick={() => _handleSetReceipt(piece)}>
                                        {piece.department}
                                    </td>
                                    <td className='center-td' onClick={() => _handleSetReceipt(piece)}>
                                        {piece.type}
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={7}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
