import React, { Fragment, useState } from 'react';
import { useAlert } from 'react-alert';

import ImageUploader from '../../../../ui/uploader/ImageUploader';

import { POST, PUT } from '../../../../../util/data';

const Component = ({ element }) => {
    const [type, setType] = useState(element.id === -1 ? 'STEEL' : element.type);

    const [attachment, setAttachment] = useState(element.id === -1 ? null : element.attachment);
    const [done, setDone] = useState(false);

    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        if (element.id > -1 && type !== 'STEEL') {
            alert.error('코드 체계 정립 후 업데이트 예정');
            return;
        }

        if (element.id > -1 || (element.id === -1 && !done)) {
            const func = element.id === -1 ? POST : PUT;

            const form = document.activeForm;

            let params = {
                type: type,
                name: form.name.value,
                grade: form.grade.value,
                specification: form.specification.value,
                unit: form.unit.value,
                source: form.source.value,
                unitOfSource: form.unitOfSource.value,
                color: form.color.value,
                appropriateLevelOfAmount: form.appropriateLevelOfAmount.value,
                safeLevelOfAmount: form.safeLevelOfAmount.value,
                attachment: attachment,
            };

            if (element.id !== -1) {
                params.id = element.id;
            }

            setDone(true);

            func('purchase/material', params, (res) => {
                if (res !== null) {
                    alert.success(res);
                }
            });
        }
    };

    return (
        <Fragment>
            <form name='activeForm' onSubmit={_handleForm}>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='15%'>자재유형 *</th>
                            <td width='35%'>
                                <select name='type' value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value='STEEL'>철강자재</option>
                                    <option value='ELECTRICITY' disabled>
                                        전기자재 (코드 체계 정립 후 업데이트 예정)
                                    </option>
                                    <option value='ETC' disabled>
                                        기타 자재 (코드 체계 정립 후 업데이트 예정)
                                    </option>
                                </select>
                            </td>
                            <th width='15%'>분류코드</th>
                            <td width='35%' className='center-td'>
                                <input
                                    type='text'
                                    defaultValue={element.id !== -1 ? element.code : '자동생성'}
                                    disabled
                                />
                            </td>
                        </tr>
                        {type === 'STEEL' ? (
                            <Fragment>
                                <tr>
                                    <th>품명 *</th>
                                    <td>
                                        <select
                                            name='name'
                                            defaultValue={element.id !== -1 ? element.name : ''}
                                            required
                                        >
                                            <option value=''>선택</option>
                                            <option>각파이프</option>
                                            <option>잔넬</option>
                                            <option>앵글</option>
                                            <option>원형파이프</option>
                                            <option>평철</option>
                                            <option>H형강</option>
                                            <option>경량H형강</option>
                                            <option>철판/SS400/열연</option>
                                            <option>환봉/SC45C/미각기</option>
                                            <option>환봉/SC45C/마환봉</option>
                                            <option>환봉/SCM22/마환봉</option>
                                            <option>각철/SC45C/미각기</option>
                                            <option>육각인발파이프</option>
                                            <option>HGI파이프</option>
                                            <option>전산볼트 </option>
                                            <option>합판</option>
                                            <option>방수합판</option>
                                        </select>
                                    </td>
                                    <th>관리등급</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='grade'
                                            defaultValue={element.id !== -1 ? element.grade : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>규격 *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='specification'
                                            defaultValue={element.id !== -1 ? element.specification : ''}
                                            required
                                        />
                                    </td>
                                    <th>단위</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='unit'
                                            defaultValue={element.id !== -1 ? element.unit : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>공급처</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='source'
                                            defaultValue={element.id !== -1 ? element.source : ''}
                                        />
                                    </td>
                                    <th>공급단위</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='unitOfSource'
                                            defaultValue={element.id !== -1 ? element.unitOfSource : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>색상</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='color'
                                            defaultValue={element.id !== -1 ? element.color : ''}
                                        />
                                    </td>
                                    <td colSpan={2} />
                                </tr>
                                <tr>
                                    <th>적정보유량</th>
                                    <td>
                                        <input
                                            type='number'
                                            name='appropriateLevelOfAmount'
                                            defaultValue={element.id !== -1 ? element.appropriateLevelOfAmount : ''}
                                        />
                                    </td>
                                    <th>안전보유량</th>
                                    <td>
                                        <input
                                            type='number'
                                            name='safeLevelOfAmount'
                                            defaultValue={element.id !== -1 ? element.safeLevelOfAmount : ''}
                                        />
                                    </td>
                                </tr>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <tr>
                                    <th>품명 *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='name'
                                            defaultValue={element.id !== -1 ? element.name : ''}
                                        />
                                    </td>
                                    <th>관리등급</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='grade'
                                            defaultValue={element.id !== -1 ? element.grade : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>재질 *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='material'
                                            defaultValue={element.id !== -1 ? element.material : ''}
                                        />
                                    </td>
                                    <th>단위</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='unit'
                                            defaultValue={element.id !== -1 ? element.unit : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>규격 *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='specification'
                                            defaultValue={element.id !== -1 ? element.specification : ''}
                                        />
                                    </td>
                                    <th>색상</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='color'
                                            defaultValue={element.id !== -1 ? element.color : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>두께 *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='thickness'
                                            defaultValue={element.id !== -1 ? element.thickness : ''}
                                        />
                                    </td>
                                    <th>공급처</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='source'
                                            defaultValue={element.id !== -1 ? element.source : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Size *</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='size'
                                            defaultValue={element.id !== -1 ? element.size : ''}
                                        />
                                    </td>
                                    <th>공급단위</th>
                                    <td>
                                        <input
                                            type='text'
                                            name='unitOfSource'
                                            defaultValue={element.id !== -1 ? element.unitOfSource : ''}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>적정보유량</th>
                                    <td>
                                        <input
                                            type='number'
                                            name='appropriateLevelOfAmount'
                                            defaultValue={element.id !== -1 ? element.appropriateLevelOfAmount : ''}
                                        />
                                    </td>
                                    <th>안전보유량</th>
                                    <td>
                                        <input
                                            type='number'
                                            name='safeLevelOfAmount'
                                            defaultValue={element.id !== -1 ? element.safeLevelOfAmount : ''}
                                        />
                                    </td>
                                </tr>
                            </Fragment>
                        )}
                    </tbody>
                </table>
                <ImageUploader attachment={attachment} setAttachment={setAttachment} />
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;