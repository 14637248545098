import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import { GET, DELETE } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

const Component = ({ afterService, setAfterService, isAfterServiceManager }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 20,
        sort: 'code',
        order: 'DESC',
        type: 'ALL',
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (afterService.id === -2) {
            GET('as/paging', data, (res) => {
                if (active && res !== null) {
                    setRaw(res);
                }
            });
        }
    }, [afterService, data, active]);

    const _handleSetAfterService = (piece) => {
        setAfterService(piece);
    };

    const _handleStatus = (value) => {
        let updated = { ...data };
        updated.status = value;
        setData(updated);
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        setData(updated);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    const _handleSort = (value) => {
        let updated = { ...data };
        updated.sort = value;
        setData(updated);
    };

    const _handleOrder = (value) => {
        let updated = { ...data };
        updated.order = value;
        setData(updated);
    };

    const _handleRegister = () => {
        setAfterService({
            id: -1,
        });
    };

    const _handleSelectAll = () => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = true;
        }
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');

                        let arr = [];

                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }

                        if (arr.length > 0) {
                            DELETE('as/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setData((data) => {
                                        let changedData = { ...data };
                                        changedData.detector = changedData.detector++;
                                        return changedData;
                                    });
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={isAfterServiceManager ? 8 : 7}>
                        {isAfterServiceManager ? (
                            <Fragment>
                                <button type='button' onClick={_handleRegister}>
                                    신규등록
                                </button>
                                <button type='button' onClick={_handleSelectAll}>
                                    전체선택
                                </button>
                                <button type='button' onClick={_handleDeleteAll}>
                                    선택삭제
                                </button>
                            </Fragment>
                        ) : null}
                        <select value={data.status} onChange={(e) => _handleStatus(e.target.value)}>
                            <option value='CURRENT'>선택 ① : 진행 A/S</option>
                            <option value='DONE'>선택 ① : 완료 A/S</option>
                        </select>
                        <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                        <button type='button' onClick={_handleKeyword}>
                            검색
                        </button>
                        <select
                            className='right-side'
                            value={data.order}
                            onChange={(e) => _handleOrder(e.target.value)}
                        >
                            <option value='DESC'>정렬 ② : 내림차순</option>
                            <option value='ASC'>정렬 ② : 오름차순</option>
                        </select>
                        <select className='right-side' value={data.sort} onChange={(e) => _handleSort(e.target.value)}>
                            <option value='code'>정렬 ① : 접수번호</option>
                            <option value='name'>정렬 ① : 접수일</option>
                        </select>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {isAfterServiceManager ? <th width='5%'>선택</th> : null}
                    <th width={isAfterServiceManager ? '24.6%' : '26.3%'}>접수번호</th>
                    <th width='7%'>접수일</th>
                    <th width={isAfterServiceManager ? '24.6%' : '26.3%'}>프로젝트명</th>
                    <th width={isAfterServiceManager ? '24.6%' : '26.3%'}>고객사</th>
                    <th width='7%'>출하일자</th>
                    <th width='7%'>사용월수</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr key={index}>
                        {isAfterServiceManager ? (
                            <td className='center-td'>
                                <input type='checkbox' name='list[]' value={piece.id} />
                            </td>
                        ) : null}
                        <td onClick={() => _handleSetAfterService(piece)}>{piece.code}</td>
                        <td className='center-td' onClick={() => _handleSetAfterService(piece)}>
                            {piece.dateOfReceipt}
                        </td>
                        <td onClick={() => _handleSetAfterService(piece)}>
                            {piece.project !== null ? piece.project.name : piece.afterServiceProject.name}
                        </td>
                        <td onClick={() => _handleSetAfterService(piece)}>
                            {piece.project !== null ? piece.project.customer : piece.afterServiceProject.customer}
                        </td>
                        <td className='center-td' onClick={() => _handleSetAfterService(piece)}>
                            {piece.project !== null ? piece.project.dateOfDue : piece.afterServiceProject.dateOfDue}
                        </td>
                        <td className='center-td' onClick={() => _handleSetAfterService(piece)}>
                            -
                        </td>
                    </tr>
                ))}
                {data.size > raw.length
                    ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                          <tr key={index}>
                              {isAfterServiceManager ? <td></td> : null}
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      ))
                    : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={isAfterServiceManager ? 8 : 7}>
                        <button type='button' onClick={() => _handlePage(0)}>
                            이전
                        </button>
                        <button type='reset'>{data.page + 1}</button>
                        <button type='button' onClick={() => _handlePage(1)}>
                            다음
                        </button>
                        <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                            <option value={20}>20개 데이터 조회</option>
                            <option value={40}>40개 데이터 조회</option>
                            <option value={60}>60개 데이터 조회</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
