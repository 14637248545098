import React from 'react';
import { useDispatch } from 'react-redux';
import { print_data_set } from '../../../../actions';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';

import { GET, DELETE } from '../../../../util/data';

const Component = ({ project, setProject, setReport }) => {
    const dispatch = useDispatch();
    const alert = useAlert();

    const _handleSetReport = (piece) => {
        setReport(piece);
    };

    const _handleResetProject = () => {
        setProject({
            id: -2,
        });
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');
                        let arr = [];
                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }
                        if (arr.length > 0) {
                            DELETE('install/report/all', { ids: arr.toString() }, (res1) => {
                                if (res1 !== null) {
                                    GET('install/report/project', { id: project.id }, (res2) => {
                                        setProject((project) => {
                                            let updated = { ...project };
                                            updated.installReports = res2;
                                            return updated;
                                        });
                                        alert.success(res1);
                                    });
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    const _handlePrint = () => {
        dispatch(print_data_set({ type: 2, data: project.id }));
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={5}>
                        <button className='focusButton' type='button' onClick={_handleResetProject}>
                            프로젝트 재선택
                        </button>
                        <div className='division'>|</div>
                        <button
                            type='button'
                            onClick={() =>
                                setReport({
                                    id: -1,
                                })
                            }
                        >
                            신규 보고서 작성
                        </button>
                        <button type='button' onClick={_handleDeleteAll}>
                            선택삭제
                        </button>
                        <div className='division'>|</div>
                        <button type='button' onClick={_handlePrint}>
                            출력
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th width='5%'>
                        <input type='checkbox' onClick={_handleSelectAll} />
                    </th>
                    <th width='20%'>작성일</th>
                    <th width='25%'>출장자</th>
                    <th width='25%'>출장목적</th>
                    <th width='25%'>출장지</th>
                </tr>
                {project.installReports.length !== 0 ? (
                    project.installReports.map((piece, index) => (
                        <tr key={index}>
                            <td className='center-td'>
                                <input type='checkbox' name='list[]' defaultValue={piece.id} />
                            </td>
                            <td onClick={() => _handleSetReport(piece)}>{piece.dateOfIssue}</td>
                            <td onClick={() => _handleSetReport(piece)}>{piece.charger}</td>
                            <td onClick={() => _handleSetReport(piece)}>{piece.purpose}</td>
                            <td onClick={() => _handleSetReport(piece)}>{piece.location}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className='center-td' colSpan={5}>
                            조회된 데이터가 없습니다.
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default Component;
