import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';
import ItemViewer from '../ItemViewer';

import { GET, POST, PUT, DELETE, DOWNLOAD } from '../../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

import './index.css';

const Component = ({ piece, setPiece }) => {
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const [detector, setDetector] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: piece.item.partList.id, type: 'BOM' }, (res) => {
            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(res);
            }, 10);
        });
    }, [detector, piece]);

    const _handleForm2 = (e) => {
        e.preventDefault();

        const form = document.activeForm2;

        const data = {
            type: 'BOM',
            partList: {
                id: piece.item.partList.id,
            },
            partsNbr: form.partsNbr.value,
            description: form.description.value,
            bomCode: form.bomCode.value,
            bomLength: form.bomLength.value,
            setQuantity: form.setQuantity.value,
            quantity: form.quantity.value,
            bomQuantity: form.bomQuantity.value,
            bomPurpose: form.bomPurpose.value,
            noteSpecification: form.noteSpecification.value,
            noteQuantity: form.noteQuantity.value,
        };

        POST('partlist/description', data, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                form.partsNbr.value = '';
                form.description.value = '';
                form.bomCode.value = '';
                form.bomLength.value = '';
                form.setQuantity.value = '';
                form.quantity.value = '';
                form.bomQuantity.value = '';
                form.bomPurpose.value = '';
                form.noteSpecification.value = '';
                form.noteQuantity.value = '';
                alert.success(res);
            }
        });
    };

    const _handleForm3 = (e) => {
        e.preventDefault();

        const form = document.activeForm3;

        let arr = [];

        if (partListDescriptions.length === 1) {
            arr[0] = {
                id: partListDescriptions[0].id,
                type: 'BOM',
                partsNbr: form.partsNbr.value,
                description: form.description.value,
                bomCode: form.bomCode.value,
                bomLength: form.bomLength.value,
                setQuantity: form.setQuantity.value,
                quantity: form.quantity.value,
                bomQuantity: form.bomQuantity.value,
                bomPurpose: form.bomPurpose.value,
                noteSpecification: form.noteSpecification.value,
                noteQuantity: form.noteQuantity.value,
            };
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                arr.push({
                    id: partListDescriptions[i].id,
                    type: 'BOM',
                    partsNbr: form.partsNbr[i].value,
                    description: form.description[i].value,
                    bomCode: form.bomCode[i].value,
                    bomLength: form.bomLength[i].value,
                    setQuantity: form.setQuantity[i].value,
                    quantity: form.quantity[i].value,
                    bomQuantity: form.bomQuantity[i].value,
                    bomPurpose: form.bomPurpose[i].value,
                    noteSpecification: form.noteSpecification[i].value,
                    noteQuantity: form.noteQuantity[i].value,
                });
            }
        }

        PUT('partlist/description', arr, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    const _handleStatus = () => {
        const data = {
            id: piece.item.partList.id,
            status: piece.item.partList.status === 'CURRENT' ? 'DONE' : 'CURRENT',
        };

        PUT('partlist/status', data, (res) => {
            if (res !== null) {
                setPiece((piece) => {
                    let updated = { ...piece };
                    updated.item.partList.status = data.status;
                    return updated;
                });
                alert.success(res);
            }
        });
    };

    const _handleDownload = () => {
        if (partListDescriptions.length !== 0) {
            const data = {
                project: piece.project,
                item: piece.item,
                partList: piece.item.partList,
            };

            POST('bom/excel/download', data, (res) => {
                if (res !== null) {
                    alert.success('다운로드가 시작됩니다.');
                    DOWNLOAD(res.id);
                }
            });
        } else {
            alert.error('조회된 데이터가 없습니다.');
        }
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');

                        let arr = [];

                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }

                        if (arr.length > 0) {
                            DELETE('partlist/description/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setDetector((detector) => !detector);
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    const _handleSerVariable = () => {
        const form = document.activeForm3;

        const value = form.setVariable.value;

        if (value !== '') {
            const checkbox = document.getElementsByName('list[]');

            if (partListDescriptions.length === 1) {
                if (checkbox[0].checked === true) {
                    form.setQuantity.value = value;
                }
            } else {
                for (let i = 0; i < checkbox.length; i++) {
                    if (checkbox[i].checked === true) {
                        form.setQuantity[i].value = value;
                    }
                }
            }
        }
    };

    const _handleInitUpload = () => {
        document.querySelector('.excelUploader-input').click();
    };

    const _handleExcelUpload = () => {
        const file = document.querySelector('.excelUploader-input');

        let formData = new FormData();

        formData.append('partListId', piece.item.partList.id);
        formData.append('file', file.files[0]);

        POST('bom/excel/upload', formData, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
            file.value = '';
        });
    };

    const _handleTemplate = () => {
        GET('bom/template', {}, (res) => {
            if (res !== null) {
                alert.success('다운로드가 시작됩니다.');
                DOWNLOAD(res.id);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={piece.project} showItemList={false} />
            <FileDownloader project={piece.project} />
            <ItemViewer project={piece.project} item={piece.item} />
            {piece.project.items.length !== 0 ? (
                <Fragment>
                    <form name='activeForm2' onSubmit={_handleForm2} autoComplete='off'>
                        <table className='form'>
                            <tbody>
                                <tr>
                                    <th colSpan={10}>BOM 신규 입력</th>
                                </tr>
                                <tr>
                                    <th width='18%' rowSpan={2}>
                                        분류번호
                                    </th>
                                    <th width='16%' rowSpan={2}>
                                        부품명
                                    </th>
                                    <th width='16%' rowSpan={2}>
                                        부품번호
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        길이
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        Set
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        Q'TY
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        수량
                                    </th>
                                    <th width='10%' rowSpan={2}>
                                        도면용도
                                    </th>
                                    <th colSpan={2}>Note</th>
                                </tr>
                                <tr>
                                    <th width='15%'>규격</th>
                                    <th width='5%'>수량</th>
                                </tr>
                                <tr>
                                    <td>
                                        <input type='text' name='bomCode' />
                                    </td>
                                    <td>
                                        <input type='text' name='description' />
                                    </td>
                                    <td>
                                        <input type='text' name='partsNbr' />
                                    </td>
                                    <td>
                                        <input type='text' name='bomLength' />
                                    </td>
                                    <td>
                                        <input type='text' name='setQuantity' />
                                    </td>
                                    <td>
                                        <input type='text' name='quantity' />
                                    </td>
                                    <td>
                                        <input type='text' name='bomQuantity' />
                                    </td>
                                    <td>
                                        <input type='text' name='bomPurpose' />
                                    </td>
                                    <td>
                                        <textarea name='noteSpecification' />
                                    </td>
                                    <td>
                                        <textarea name='noteQuantity' />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type='submit' className='submitButton'>
                            저장
                        </button>
                    </form>
                    <form name='activeForm3' onSubmit={_handleForm3} autoComplete='off'>
                        <table className='form'>
                            <thead>
                                <tr>
                                    <td colSpan={11}>
                                        <button type='button' onClick={_handleStatus}>
                                            {piece.item.partList.status === 'CURRENT'
                                                ? 'BOM 작업 완료'
                                                : 'BOM 작업 미완료'}
                                        </button>
                                        <div className='division'>|</div>
                                        <button type='button' onClick={_handleDeleteAll}>
                                            선택삭제
                                        </button>
                                        <div className='division'>|</div>
                                        <input
                                            type='file'
                                            className='excelUploader-input'
                                            onChange={_handleExcelUpload}
                                        />
                                        <button type='button' onClick={_handleInitUpload}>
                                            엑셀 업로드
                                        </button>
                                        <button type='button' onClick={_handleDownload}>
                                            엑셀 다운로드
                                        </button>
                                        <div className='division'>|</div>
                                        <input type='number' name='setVariable' defaultValue={piece.item.quantity} />
                                        <button type='button' onClick={_handleSerVariable}>
                                            Set 입력
                                        </button>
                                        <div className='division'>|</div>
                                        <button type='button' onClick={_handleTemplate}>
                                            양식파일 다운로드
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th width='3%' rowSpan={2}>
                                        <input type='checkbox' onClick={_handleSelectAll} />
                                    </th>
                                    <th width='15%' rowSpan={2}>
                                        분류번호
                                    </th>
                                    <th width='16%' rowSpan={2}>
                                        부품명
                                    </th>
                                    <th width='16%' rowSpan={2}>
                                        부품번호
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        길이
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        Set
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        Q'TY
                                    </th>
                                    <th width='5%' rowSpan={2}>
                                        수량
                                    </th>
                                    <th width='10%' rowSpan={2}>
                                        도면용도
                                    </th>
                                    <th colSpan={2}>Note</th>
                                </tr>
                                <tr>
                                    <th width='15%'>규격</th>
                                    <th width='5%'>수량</th>
                                </tr>
                                {partListDescriptions.length !== 0 ? (
                                    partListDescriptions.map((piece, index) => (
                                        <tr key={index}>
                                            <td className='center-td'>
                                                <input type='checkbox' name='list[]' value={piece.id} />
                                            </td>
                                            <td>
                                                <input type='text' name='bomCode' defaultValue={piece.bomCode} />
                                            </td>
                                            <td>
                                                <input
                                                    type='text'
                                                    name='description'
                                                    defaultValue={piece.description}
                                                />
                                            </td>
                                            <td>
                                                <input type='text' name='partsNbr' defaultValue={piece.partsNbr} />
                                            </td>
                                            <td>
                                                <input type='text' name='bomLength' defaultValue={piece.bomLength} />
                                            </td>
                                            <td>
                                                <input
                                                    type='text'
                                                    name='setQuantity'
                                                    defaultValue={piece.setQuantity}
                                                />
                                            </td>
                                            <td>
                                                <input type='text' name='quantity' defaultValue={piece.quantity} />
                                            </td>
                                            <td>
                                                <input
                                                    type='text'
                                                    name='bomQuantity'
                                                    defaultValue={piece.bomQuantity}
                                                />
                                            </td>
                                            <td>
                                                <input type='text' name='bomPurpose' defaultValue={piece.bomPurpose} />
                                            </td>
                                            <td>
                                                <textarea
                                                    name='noteSpecification'
                                                    defaultValue={piece.noteSpecification}
                                                />
                                            </td>
                                            <td>
                                                <textarea name='noteQuantity' defaultValue={piece.noteQuantity} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className='center-td' colSpan={11}>
                                            조회된 데이터가 없습니다.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <button type='submit' className='submitButton'>
                            저장
                        </button>
                    </form>
                </Fragment>
            ) : null}
        </Fragment>
    );
};

export default Component;
