import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import DateInputer from '../../../ui/inputer/DateInputer';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: item.partList.id }, (res) => {
            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(res);
            }, 10);
        });
    }, [item]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (partListDescriptions.length === 1) {
            arr[0] = {
                id: partListDescriptions[0].assemblyPerformance.id,
                dateOfStart: form.dateOfStart.value,
                dateOfFinish: form.dateOfFinish.value,
            };
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                arr.push({
                    id: partListDescriptions[i].assemblyPerformance.id,
                    dateOfStart: form.dateOfStart[i].value,
                    dateOfFinish: form.dateOfFinish[i].value,
                });
            }
        }

        PUT('assembly/performance', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={true} item={item} setItem={setItem} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form form-lining'>
                    <thead>
                        <tr>
                            <td colSpan={6}>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='30%'>Parts</th>
                            <th width='10%'>No.</th>
                            <th width='30%'>Description</th>
                            <th width='10%'>Q'TY</th>
                            <th width='10%'>작업착수일</th>
                            <th width='10%'>작업완료일</th>
                        </tr>
                        {partListDescriptions.length !== 0 ? (
                            partListDescriptions.map((piece, index) => (
                                <tr key={index}>
                                    <td>{piece.parts}</td>
                                    <td className='center-td'>{piece.partsNbr}</td>
                                    <td>{piece.description}</td>
                                    <td className='center-td'>{piece.quantity}</td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfStart'
                                            defaultValue={piece.assemblyPerformance.dateOfStart}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinish'
                                            defaultValue={piece.assemblyPerformance.dateOfFinish}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={6}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
