import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import { PUT } from '../../../../../util/data';

const Component = ({ element, setElement }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (element.purchaseDetails.length === 1) {
            let sum = Number(form.sum.value === '' ? 0 : form.sum.value);
            let quantityOfWarehousing = Number(
                form.quantityOfWarehousing.value === '' ? 0 : form.quantityOfWarehousing.value
            );
            let quantityOfShipment = Number(form.quantityOfShipment.value === '' ? 0 : form.quantityOfShipment.value);
            if (sum === quantityOfWarehousing + quantityOfShipment) {
                arr.push({
                    id: element.purchaseDetails.id,
                    quantityOfWarehousing: quantityOfWarehousing,
                    quantityOfShipment: quantityOfShipment,
                });
                if (sum !== 0) {
                    form.sum.disabled = true;
                    form.quantityOfWarehousing.disabled = true;
                    form.quantityOfShipment.disabled = true;
                }
            } else {
                for (let i = 0; i < element.purchaseDetails.length; i++) {
                    if (
                        element.purchaseDetails.quantityOfWarehousing === 0 &&
                        element.purchaseDetails.quantityOfShipment === 0
                    ) {
                        form.sum.disabled = false;
                        form.quantityOfWarehousing.disabled = false;
                        form.quantityOfShipment.disabled = false;
                    }
                }
                alert.error('확인수량이 올바르지 않습니다.');
                return;
            }
        } else {
            for (let i = 0; i < element.purchaseDetails.length; i++) {
                let sum = Number(form.sum[i].value === '' ? 0 : form.sum[i].value);
                let quantityOfWarehousing = Number(
                    form.quantityOfWarehousing[i].value === '' ? 0 : form.quantityOfWarehousing[i].value
                );
                let quantityOfShipment = Number(
                    form.quantityOfShipment[i].value === '' ? 0 : form.quantityOfShipment[i].value
                );
                if (sum === quantityOfWarehousing + quantityOfShipment) {
                    arr.push({
                        id: element.purchaseDetails[i].id,
                        quantityOfWarehousing: quantityOfWarehousing,
                        quantityOfShipment: quantityOfShipment,
                    });
                    if (sum !== 0) {
                        form.sum[i].disabled = true;
                        form.quantityOfWarehousing[i].disabled = true;
                        form.quantityOfShipment[i].disabled = true;
                    }
                } else {
                    for (let i = 0; i < element.purchaseDetails.length; i++) {
                        if (
                            element.purchaseDetails[i].quantityOfWarehousing === 0 &&
                            element.purchaseDetails[i].quantityOfShipment === 0
                        ) {
                            form.sum[i].disabled = false;
                            form.quantityOfWarehousing[i].disabled = false;
                            form.quantityOfShipment[i].disabled = false;
                        }
                    }
                    alert.error('확인수량이 올바르지 않습니다.');
                    return;
                }
            }
        }

        let params = {
            id: element.id,
            project: element.project,
            item: element.item,
            type: element.type,
            status: element.status,
            purchaseRequest: null,
            purchaseListing: null,
            purchaseQuotation: null,
            purchaseOrder: {
                id: element.purchaseOrder.id,
                dateOfDue: form.dateOfDue.value,
            },
            purchaseDetails: arr,
        };

        console.log(params);

        PUT('purchase', params, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <form name='activeForm' onSubmit={_handleForm}>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={6}>발주 정보</th>
                        </tr>
                        <tr>
                            <th width='13.3%'>발주종류</th>
                            <td width='86.7%' colSpan={5}>
                                {element.purchaseOrder.orderType === 'NORMAL' ? '일반발주' : '외주발주'}
                            </td>
                        </tr>
                        <tr>
                            <th width='13.3%'>업체명</th>
                            <td width='20%'>{element.purchaseOrder.supplier}</td>
                            <th width='13.3%'>발주일자</th>
                            <td width='20%'>{element.purchaseOrder.dateOfOrder}</td>
                            <th width='13.3%'>발주금액</th>
                            <td width='20%'>{element.purchaseOrder.price}</td>
                        </tr>
                        <tr>
                            <th>담당자</th>
                            <td>{element.purchaseOrder.charger}</td>
                            <th>발주자</th>
                            <td>{element.purchaseOrder.orderer}</td>
                            <th>납기일</th>
                            <td>{element.purchaseOrder.dateOfDue}</td>
                        </tr>
                        <tr>
                            <th>결재방법</th>
                            <td>{element.purchaseOrder.methodOfPayment}</td>
                            <th>연락처</th>
                            <td className='center-td'>031 - 431 - 6297</td>
                            <th>팩스</th>
                            <td className='center-td'>031 - 431 - 6081</td>
                        </tr>
                        <tr>
                            <th>계약서</th>
                            <td>{element.purchaseOrder.contract}</td>
                            <th>핸드폰</th>
                            <td>{element.purchaseOrder.mobile}</td>
                            <th>납품장소</th>
                            <td>{element.purchaseOrder.delivery}</td>
                        </tr>
                        <tr>
                            <th>Project</th>
                            <td className='center-td'>{element.project !== null ? element.project.code : null}</td>
                            <th>Item(System)</th>
                            <td className='center-td'>
                                {element.item !== null
                                    ? element.item.itemFamily !== null
                                        ? element.item.itemFamily.name
                                        : element.item.name
                                    : null}
                            </td>
                            <th>운반비</th>
                            <td className='center-td'>당사 포함</td>
                        </tr>
                    </tbody>
                </table>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='13.3%'>입고일자 변경</th>
                            <td width='86.7%'>
                                <input type='date' name='dateOfDue' defaultValue={element.purchaseOrder.dateOfDue} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='15%'>분류번호</th>
                            <th width='20%'>품명</th>
                            <th width='15%'>규격</th>
                            <th width='5%'>Size</th>
                            <th width='5%'>단위</th>
                            <th width='10%'>발주수량</th>
                            <th width='10%'>확인수량</th>
                            <th width='10%'>사용부서출고</th>
                            <th width='10%'>창고입고</th>
                        </tr>
                        {element.purchaseDetails.length !== 0 ? (
                            element.purchaseDetails.map((piece, index) => (
                                <tr key={index}>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.partsNbr
                                            : piece.purchaseMaterial.code}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.description
                                            : piece.purchaseMaterial.name}
                                    </td>
                                    <td>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.specification
                                            : piece.purchaseMaterial.specification}
                                    </td>
                                    <td className='center-td'>
                                        {piece.partListDescription !== null
                                            ? piece.partListDescription.size
                                            : piece.purchaseMaterial.size}
                                    </td>
                                    <td className='center-td'>{piece.unit}</td>
                                    <td className='center-td'>{piece.quantityOfPurchase}</td>
                                    <td className='center-td'>
                                        <input
                                            type='number'
                                            name='sum'
                                            defaultValue={
                                                piece.quantityOfWarehousing === 0 && piece.quantityOfShipment === 0
                                                    ? piece.quantityOfPurchase
                                                    : piece.quantityOfWarehousing + piece.quantityOfShipment
                                            }
                                            disabled={
                                                piece.quantityOfWarehousing !== 0 || piece.quantityOfShipment !== 0
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfShipment'
                                            defaultValue={piece.quantityOfShipment}
                                            disabled={
                                                piece.quantityOfWarehousing !== 0 || piece.quantityOfShipment !== 0
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='quantityOfWarehousing'
                                            defaultValue={piece.quantityOfWarehousing}
                                            disabled={
                                                piece.quantityOfWarehousing !== 0 || piece.quantityOfShipment !== 0
                                            }
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={10}>
                                    등록된 정보가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
