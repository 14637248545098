export const statusType = {
	NORMAL: 'NORMAL',
	DELETE: 'DELETE',
}

export const projectType = {
	ALL: 'ALL',
	PRODUCT: 'PRODUCT',
	PART: 'PART',
	AS: 'AS',
}

export const projectStatusType = {
	PENDING: 'PENDING',
	CURRENT: 'CURRENT',
	DONE: 'DONE',
}

export const formType = {
	REGISTER: 'REGISTER',
	MODIFY: 'MODIFY',
}

export const pagingType = {
	PREV: 'PREV',
	NEXT: 'NEXT',
}

export const orderType = {
	DESC: 'DESC',
	ASC: 'ASC',
}
