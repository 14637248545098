import React, { Fragment, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';

import { POST, DOWNLOAD, GET } from '../../../../../util/data';

const Component = ({ project }) => {
    const [items, setItems] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        GET('purchase/info', { id: project.id }, (res) => {
            setItems(res);
        });
    }, [project]);

    const _handleDownloadPartList = (piece) => {
        const data = {
            project: project,
            item: piece.item,
            partList: piece.item.partList,
        };

        POST('partlist/description/excel/download', data, (res) => {
            if (res !== null) {
                alert.success('다운로드가 시작됩니다.');
                DOWNLOAD(res.id);
            }
        });
    };

    const _handleDownloadBOM = (piece) => {
        const data = {
            project: project,
            item: piece.item,
            partList: piece.item.partList,
        };

        POST('bom/excel/download', data, (res) => {
            if (res !== null) {
                alert.success('다운로드가 시작됩니다.');
                DOWNLOAD(res.id);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <table className='form form-lining'>
                <tbody>
                    <tr>
                        <th width='5%'>No</th>
                        <th width='25%'>아이템명</th>
                        <th width='15%'>규격</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>수량</th>
                        <th>비고</th>
                        <th width='3rem'>다운로드</th>
                    </tr>
                    {items.map((piece, index) => (
                        <tr key={index}>
                            <td className='center-td'>{piece.item.nbr}</td>
                            <td>{piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}</td>
                            <td className='center-td'>{piece.item.specification}</td>
                            <td className='center-td'>{piece.item.unit}</td>
                            <td className='center-td'>{piece.item.quantity}</td>
                            <td>{piece.item.note}</td>
                            <td className='center-td'>
                                <button
                                    className='downloadButton'
                                    onClick={() => _handleDownloadPartList(piece)}
                                    disabled={
                                        piece.item.partList.status === 'DONE' && piece.countPartList > 0 ? false : true
                                    }
                                >
                                    PART LIST
                                </button>
                                <button
                                    className='downloadButton'
                                    onClick={() => _handleDownloadBOM(piece)}
                                    disabled={
                                        piece.item.partList.status === 'DONE' && piece.countBom > 0 ? false : true
                                    }
                                >
                                    BOM
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
