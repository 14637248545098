import React, { useState, useEffect } from 'react';

import { GET } from '../../../../util/data';

const Component = ({ notice, setNotice }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 20,
    });
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (notice.id === -2) {
            GET('notice/all', data, (res) => {
                if (active && res !== null) {
                    setRaw(res);
                }
            });
        }
    }, [active, notice, data]);

    const _handleSetNotice = (piece) => {
        setNotice(piece);
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    return (
        <table className='selector'>
            <tbody>
                <tr>
                    <th width='15%'>작성일시</th>
                    <th width='70%'>제목</th>
                    <th width='15%'>작성자</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr key={index} onClick={() => _handleSetNotice(piece)}>
                        <td className='center-td'>{piece.dateOfIssue}</td>
                        <td>{piece.title}</td>
                        <td className='center-td'>{piece.author !== null ? piece.author.name : ''}</td>
                    </tr>
                ))}
                {data.size > raw.length
                    ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                          <tr key={index}>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      ))
                    : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={3}>
                        <button type='button' onClick={() => _handlePage(0)}>
                            이전
                        </button>
                        <button type='reset'>{data.page + 1}</button>
                        <button type='button' onClick={() => _handlePage(1)}>
                            다음
                        </button>
                        <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                            <option value={20}>20개 데이터 조회</option>
                            <option value={40}>40개 데이터 조회</option>
                            <option value={60}>60개 데이터 조회</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
