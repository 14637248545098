import React from 'react';

const Component = ({ itemRaw, setProject, setItem }) => {
    const _handleSelect = (piece) => {
        setProject(piece.project);
        if (setItem !== undefined) {
            setItem(piece.item);
        }
    };

    return (
        <table className='selector'>
            <tbody>
                <tr>
                    <th width='15%'>지시번호</th>
                    <th width='15%'>프로젝트명</th>
                    <th width='5%'>No.</th>
                    <th width='15%'>품명</th>
                    <th width='10%'>일련번호</th>
                    <th width='10%'>규격</th>
                    <th width='5%'>단위</th>
                    <th width='5%'>수량</th>
                    <th width='10%'>수주일</th>
                    <th width='10%'>납기일</th>
                </tr>
                {itemRaw.map((piece, index) => (
                    <tr key={index} onClick={() => _handleSelect(piece)}>
                        <td>{piece.project.code}</td>
                        <td>{piece.project.name}</td>
                        <td className='center-td'>{piece.item.nbr}</td>
                        <td>{piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}</td>
                        <td className='center-td'>{piece.item.designWork.serialNbr}</td>
                        <td>{piece.item.specification}</td>
                        <td className='center-td'>{piece.item.unit}</td>
                        <td className='center-td'>{piece.item.quantity}</td>
                        <td className='center-td'>{piece.project.dateOfOrder}</td>
                        <td className='center-td'>{piece.project.dateOfDue}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Component;
