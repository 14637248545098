import React, { Fragment } from 'react';

import { DOWNLOAD } from '../../../../util/data/';

import { serverPath } from '../../../../configs/path';

import FI from 'feather-icons-react';

import './index.css';

const API_PATH = 'api';
const API_VERSION = 'v01';

const Component = ({ project }) => {
    const _handleSize = (value) => {
        if (value === 0) {
            return '용량정보없음';
        }

        let tail = ['KB', 'MB', 'GB'];
        let tailIndex = 0;

        value /= 1024;

        while (value > 1024) {
            value /= 1024;
            tailIndex++;
        }

        return value.toFixed(0) + tail[tailIndex];
    };

    const _handleDownload = (id) => {
        DOWNLOAD(id);
    };

    const _handleMouseEnter = (id) => {
        document.querySelector(
            '.image-previewer-core'
        ).style.backgroundImage = `url('${serverPath}/${API_PATH}/${API_VERSION}/attachment/${id}')`;
        document.querySelector('.image-previewer').style.display = 'block';
    };

    const _handleMouseLeave = () => {
        document.querySelector('.image-previewer').style.display = 'none';
    };

    return (
        <Fragment>
            <table className='viewer'>
                <tbody>
                    <tr>
                        <th>첨부자료</th>
                    </tr>
                    <tr>
                        {project.attachments.length !== 0 ? (
                            <td className='fileDownloader'>
                                {project.attachments.map((piece, index) => (
                                    <div
                                        className='fileDownloader-list'
                                        onClick={() => _handleDownload(piece.id)}
                                        key={index}
                                    >
                                        <div className='fileDownloader-list-title'>{piece.name}</div>
                                        <div className='fileDownloader-list-size'>
                                            ({_handleSize(Number(piece.size))})
                                        </div>
                                        {piece.name.match('.jpg') ||
                                        piece.name.match('.jpeg') ||
                                        piece.name.match('.png') ||
                                        piece.name.match('.bmp') ? (
                                            <FI
                                                className='fileUploader-list-icon'
                                                icon='search'
                                                onMouseEnter={() => _handleMouseEnter(piece.id)}
                                                onMouseLeave={_handleMouseLeave}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </td>
                        ) : (
                            <td className='center-td'>첨부파일이 없습니다.</td>
                        )}
                    </tr>
                </tbody>
            </table>
            <div className='image-previewer'>
                <div className='image-previewer-header'>이미지 미리보기</div>
                <div className='image-previewer-core'></div>
            </div>
        </Fragment>
    );
};

export default Component;
