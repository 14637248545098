import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewerForUncomformity from '../../../../ui/viewer/ProjectViewerForUncomformity';
import DateInputer from '../../../../ui/inputer/DateInputer';

import { GET, PUT } from '../../../../../util/data';

const Component = ({ project, setProject }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].qualityOrder.id,
                statusOfCanning: form.statusOfCanning.checked ? 'UNNECESSARY' : 'NORMAL',
                statusOfPainting: form.statusOfPainting.checked ? 'UNNECESSARY' : 'NORMAL',
                statusOfPostProcessing: form.statusOfPostProcessing.checked ? 'UNNECESSARY' : 'NORMAL',
                dateOfCanning: form.dateOfCanning.value,
                dateOfPainting: form.dateOfPainting.value,
                dateOfPostProcessing: form.dateOfPostProcessing.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].qualityOrder.id,
                    statusOfCanning: form.statusOfCanning[i].checked ? 'UNNECESSARY' : 'NORMAL',
                    statusOfPainting: form.statusOfPainting[i].checked ? 'UNNECESSARY' : 'NORMAL',
                    statusOfPostProcessing: form.statusOfPostProcessing[i].checked ? 'UNNECESSARY' : 'NORMAL',
                    dateOfCanning: form.dateOfCanning[i].value,
                    dateOfPainting: form.dateOfPainting[i].value,
                    dateOfPostProcessing: form.dateOfPostProcessing[i].value,
                });
            }
        }

        PUT('quality/order', arr, (res1) => {
            GET('project', { id: project.id }, (res2) => {
                res2.items.sort((a, b) => {
                    try {
                        return (
                            Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                            Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                        );
                    } catch {
                        return 1;
                    }
                });
                setProject(res2);
                alert.success(res1);
            });
        });
    };

    return (
        <Fragment>
            <ProjectViewerForUncomformity project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={11}>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='5%' rowSpan={2}>
                                No.
                            </th>
                            <th width='22.5%' rowSpan={2}>
                                아이템명
                            </th>
                            <th width='15%' rowSpan={2}>
                                규격
                            </th>
                            <th width='10%' rowSpan={2}>
                                단위
                            </th>
                            <th width='10%' rowSpan={2}>
                                수량
                            </th>
                            <th width='12.5%' colSpan={2}>
                                제관 작업
                            </th>
                            <th width='12.5%' colSpan={2}>
                                도장 작업
                            </th>
                            <th width='12.5%' colSpan={2}>
                                후처리 작업
                            </th>
                        </tr>
                        <tr>
                            <th width='7.5%'>지시일</th>
                            <th width='5%'>불필요</th>
                            <th width='7.5%'>지시일</th>
                            <th width='5%'>불필요</th>
                            <th width='7.5%'>지시일</th>
                            <th width='5%'>불필요</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <tr key={index}>
                                <td>{piece.nbr}</td>
                                <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                <td>{piece.specification}</td>
                                <td>{piece.unit}</td>
                                <td>{piece.quantity}</td>
                                {piece.qualityOrder.statusOfCanning === 'NORMAL' ? (
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfCanning'
                                            defaultValue={piece.qualityOrder.dateOfCanning}
                                        />
                                    </td>
                                ) : (
                                    <td className='center-td'>
                                        <input
                                            type='hidden'
                                            name='dateOfCanning'
                                            defaultValue={piece.qualityOrder.dateOfCanning}
                                        />
                                        작업불필요
                                    </td>
                                )}
                                <td className='center-td'>
                                    <input
                                        type='checkbox'
                                        name='statusOfCanning'
                                        defaultChecked={
                                            piece.qualityOrder.statusOfCanning === 'UNNECESSARY' ? true : false
                                        }
                                    />
                                </td>
                                {piece.qualityOrder.statusOfPainting === 'NORMAL' ? (
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfPainting'
                                            defaultValue={piece.qualityOrder.dateOfPainting}
                                        />
                                    </td>
                                ) : (
                                    <td className='center-td'>
                                        <input
                                            type='hidden'
                                            name='dateOfPainting'
                                            defaultValue={piece.qualityOrder.dateOfPainting}
                                        />
                                        작업불필요
                                    </td>
                                )}
                                <td className='center-td'>
                                    <input
                                        type='checkbox'
                                        name='statusOfPainting'
                                        defaultChecked={
                                            piece.qualityOrder.statusOfPainting === 'UNNECESSARY' ? true : false
                                        }
                                    />
                                </td>
                                {piece.qualityOrder.statusOfPostProcessing === 'NORMAL' ? (
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfPostProcessing'
                                            defaultValue={piece.qualityOrder.dateOfPostProcessing}
                                        />
                                    </td>
                                ) : (
                                    <td className='center-td'>
                                        <input
                                            type='hidden'
                                            name='dateOfPostProcessing'
                                            defaultValue={piece.qualityOrder.dateOfPostProcessing}
                                        />
                                        작업불필요
                                    </td>
                                )}
                                <td className='center-td'>
                                    <input
                                        type='checkbox'
                                        name='statusOfPostProcessing'
                                        defaultChecked={
                                            piece.qualityOrder.statusOfPostProcessing === 'UNNECESSARY' ? true : false
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
