import React, { Fragment, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';

import { GET, PUT, DELETE } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

const Component = ({ element, setElement, stage }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState({
        page: 0,
        size: 20,
        keyword: '',
        status: stage,
    });
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('inventory/request/all', data, (res) => {
            if (active && res !== null) {
                setRaw(res);
            }
        });
    }, [element, data, active]);

    const _handleRequest = (piece) => {
        setElement(piece);
    };

    const _handleRegister = () => {
        setElement({
            id: -1,
        });
    };

    const _handleKeyword = () => {
        let updated = { ...data };
        updated.keyword = document.querySelector('.keyword').value;
        setData(updated);
    };

    const _handleEnter = (e) => {
        if (e.keyCode === 13) {
            _handleKeyword();
        }
    };

    const _handlePage = (direction) => {
        let updated = { ...data };
        if (!direction) {
            if (updated.page !== 0) updated.page -= 1;
        } else {
            updated.page += 1;
        }
        setData(updated);
    };

    const _handleSize = (value) => {
        let updated = { ...data };
        updated.size = value;
        setData(updated);
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleProceedAll = () => {
        const checkbox = document.getElementsByName('list[]');
        let arr = [];
        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push(checkbox[i].value);
                checkbox[i].checked = false;
            }
        }
        if (arr.length > 0) {
            PUT(
                'inventory/request/status',
                { ids: arr.toString(), status: stage === 'REQUEST' ? 'IO' : 'DONE' },
                (res) => {
                    if (res !== null) {
                        setData((data) => {
                            let changedData = { ...data };
                            changedData.detector = changedData.detector++;
                            return changedData;
                        });
                        alert.success(res);
                    }
                }
            );
        }
    };

    const _handleReturnAll = () => {
        const checkbox = document.getElementsByName('list[]');
        let arr = [];
        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push(checkbox[i].value);
                checkbox[i].checked = false;
            }
        }
        if (arr.length > 0) {
            PUT('inventory/request/status', { ids: arr.toString(), status: 'REQUEST' }, (res) => {
                if (res !== null) {
                    setData((data) => {
                        let changedData = { ...data };
                        changedData.detector = changedData.detector++;
                        return changedData;
                    });
                    alert.success(res);
                }
            });
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');
                        let arr = [];
                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }
                        if (arr.length > 0) {
                            DELETE('inventory/request/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setData((data) => {
                                        let changedData = { ...data };
                                        changedData.detector = changedData.detector++;
                                        return changedData;
                                    });
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    const pad = (n, width) => {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    };

    return (
        <table className='selector'>
            <thead>
                <tr>
                    <td colSpan={6}>
                        {stage === 'REQUEST' ? (
                            <Fragment>
                                <button type='button' onClick={_handleRegister}>
                                    신규등록
                                </button>
                                <button type='button' onClick={_handleProceedAll}>
                                    자재 청구 및 반납 의뢰
                                </button>
                            </Fragment>
                        ) : null}
                        {stage === 'IO' ? (
                            <Fragment>
                                <button type='button' onClick={_handleReturnAll}>
                                    자재 청구 및 반납 반려
                                </button>
                                <button type='button' onClick={_handleProceedAll}>
                                    자재 청구 및 반납 완료
                                </button>
                            </Fragment>
                        ) : null}
                        <button type='button' onClick={_handleDeleteAll}>
                            선택삭제
                        </button>
                        <input className='keyword' type='text' placeholder='검색어' onKeyDown={_handleEnter} />
                        <button type='button' onClick={_handleKeyword}>
                            검색
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th width='5%'>
                        <input type='checkbox' onClick={_handleSelectAll} />
                    </th>
                    <th width='15%'>구분</th>
                    <th width='20%'>코드</th>
                    <th width='20%'>요청일자</th>
                    <th width='20%'>의뢰부서</th>
                    <th width='20%'>의뢰자</th>
                </tr>
                {raw.map((piece, index) => (
                    <tr key={index}>
                        <td className='center-td'>
                            <input type='checkbox' name='list[]' value={piece.id} />
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.type === 'INPUT' ? '반납' : '청구'}
                        </td>
                        <td onClick={() => _handleRequest(piece)}>
                        {`${piece.numberOfYear}-${
                            piece.department === '기계가공부'
                                ? '가공'
                                : piece.department === '기계조립부'
                                ? '조립'
                                : piece.department === '제어기술부'
                                ? '제어'
                                : ''
                        }-${pad(piece.numberOfRequest, 4)}`}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.dateOfRequest}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.department}
                        </td>
                        <td className='center-td' onClick={() => _handleRequest(piece)}>
                            {piece.drafter}
                        </td>
                    </tr>
                ))}
                {data.size > raw.length
                    ? Array.apply(null, Array(data.size - raw.length)).map((_, index) => (
                          <tr key={index}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                      ))
                    : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={6}>
                        <button type='button' onClick={() => _handlePage(0)}>
                            이전
                        </button>
                        <button type='reset'>{data.page + 1}</button>
                        <button type='button' onClick={() => _handlePage(1)}>
                            다음
                        </button>
                        <select value={data.size} onChange={(e) => _handleSize(e.target.value)}>
                            <option value={10}>10개 데이터 조회</option>
                            <option value={20}>20개 데이터 조회</option>
                            <option value={40}>40개 데이터 조회</option>
                            <option value={60}>60개 데이터 조회</option>
                        </select>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
