import React, { useState } from 'react';

import Form from './form';

import ProductCodeSelector from '../../../../ui/selector/ProductCodeSelector';
import Popup from '../../../../ui/popup';

const Component = () => {
    const [productCode, setProductCode] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setProductCode({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <ProductCodeSelector productCode={productCode} setProductCode={setProductCode} />
            {productCode.id === -1 ? (
                <Popup
                    title='신규 등록'
                    data={<Form productCode={productCode} setProductCode={setProductCode} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
