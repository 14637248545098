import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewerForUncomformity from '../../../../ui/viewer/ProjectViewerForUncomformity';
import DateInputer from '../../../../ui/inputer/DateInputer';

import { GET, PUT } from '../../../../../util/data';

const Component = ({ project, item }) => {
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [detector, setDetector] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: item.partList.id }, (res) => {
            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(
                    res.filter((piece) =>
                        !showAll
                            ? piece.qualityPerformance.status === 'NORMAL' &&
                              (piece.qualityPerformance.dateOfFinish === null ||
                                  piece.qualityPerformance.dateOfFinish === '')
                            : true
                    )
                );
            }, 10);
        });
    }, [item, showAll, detector]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (partListDescriptions.length === 1) {
            arr[0] = {
                id: partListDescriptions[0].qualityPerformance.id,
                dateOfFinish: form.dateOfFinish.value,
                status: form.status.checked ? 'UNNECESSARY' : 'NORMAL',
            };
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                arr.push({
                    id: partListDescriptions[i].qualityPerformance.id,
                    dateOfFinish: form.dateOfFinish[i].value,
                    status: form.status[i].checked ? 'UNNECESSARY' : 'NORMAL',
                });
            }
        }

        PUT('quality/performance', arr, (res) => {
            if (res !== null) {
                setDetector(detector ? false : true);
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewerForUncomformity project={project} item={item} isSetItem={true} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form form-lining'>
                    <thead>
                        <tr>
                            <td colSpan={6}>
                                <select
                                    value={showAll}
                                    onChange={(e) => setShowAll(e.target.value === 'true' ? true : false)}
                                >
                                    <option value='true'>미입력 리스트 : 끄기</option>
                                    <option value='false'>미입력 리스트 : 켜기</option>
                                </select>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='27.5%'>Parts</th>
                            <th width='10%'>No.</th>
                            <th width='27.5%'>Description</th>
                            <th width='10%'>Q'TY</th>
                            <th width='10%'>불필요</th>
                            <th width='15%'>작업완료일</th>
                        </tr>
                        {partListDescriptions.map((piece, index) => (
                            <tr key={index}>
                                <td>{piece.parts}</td>
                                <td className='center-td'>{piece.partsNbr}</td>
                                <td>{piece.description}</td>
                                <td className='center-td'>{piece.quantity}</td>
                                <td className='center-td'>
                                    <input
                                        type='checkbox'
                                        name='status'
                                        defaultChecked={
                                            piece.qualityPerformance.status === 'UNNECESSARY' ? true : false
                                        }
                                    />
                                </td>
                                {piece.qualityPerformance.status === 'NORMAL' ? (
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfFinish'
                                            defaultValue={piece.qualityPerformance.dateOfFinish}
                                        />
                                    </td>
                                ) : (
                                    <td className='center-td'>
                                        <input
                                            type='hidden'
                                            name='dateOfFinish'
                                            defaultValue={piece.qualityPerformance.dateOfFinish}
                                        />
                                        작업불필요
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
