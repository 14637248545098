import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import { UPLOAD } from '../../../../util/data';

import { serverPath } from '../../../../configs/path';

import './index.css';

const API_PATH = 'api';
const API_VERSION = 'v01';

const Component = ({ attachment, setAttachment }) => {
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    const _handleInitUpload = () => {
        document.querySelector('.fileUploader-input').click();
    };

    const _handleClearFiles = () => {
        setAttachment(null);
    };

    const _handleSetFiles = () => {
        const file = document.querySelector('.fileUploader-input');

        if (
            file.value.match('.jpg') ||
            file.value.match('.jpeg') ||
            file.value.match('.png') ||
            file.value.match('.bmp')
        ) {
            let formData = new FormData();

            for (let i = 0; i < file.files.length; i++) {
                formData.append('files', file.files[i]);
            }

            UPLOAD(formData, (res) => {
                if (active && res !== null) {
                    setAttachment(res[0]);
                    file.value = '';
                }
            });
        } else {
            alert.error('지원되는 파일 형식이 아닙니다.');
        }
        file.value = '';
    };

    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th>이미지</th>
                    </tr>
                    <tr>
                        <td>
                            <input type='file' className='fileUploader-input' onChange={_handleSetFiles} />
                            <button type='button' onClick={_handleInitUpload}>
                                업로드
                            </button>
                            <button type='button' onClick={_handleClearFiles}>
                                삭제
                            </button>
                            {attachment !== null ? (
                                <div
                                    className='ImageUploader-preview'
                                    style={{
                                        backgroundImage: `url('${serverPath}/${API_PATH}/${API_VERSION}/attachment/${attachment.id}')`,
                                    }}
                                ></div>
                            ) : null}
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
