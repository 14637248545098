import React from 'react';

import FI from 'feather-icons-react';

import './index.css';

const Component = ({ title, exitFunc }) => {
    return (
        <div className='popupHeader'>
            <div className='popupHeader-title'>{title}</div>
            <FI className='popupHeader-exit' icon='x' onClick={exitFunc} />
        </div>
    );
};

export default Component;
