import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import DateInputer from '../../../ui/inputer/DateInputer';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const [process, setProcess] = useState('ALL');
    const [partListDescriptions, setPartListDescriptions] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        GET('partlist/description', { id: item.partList.id }, (res) => {
            let arr = [];

            for (let i = 0; i < res.length; i++) {
                const temp = res[i];

                for (let j = 0; j < temp.manufactureOrders.length; j++) {
                    if (process !== 'ALL') {
                        if (temp.manufactureOrders[j].process === process) {
                            arr.push({
                                ...temp,
                                manufactureOrders: temp.manufactureOrders[j],
                            });
                        }
                    } else {
                        arr.push({
                            ...temp,
                            manufactureOrders: temp.manufactureOrders[j],
                        });
                    }
                }
            }

            setPartListDescriptions([]);
            setTimeout(() => {
                setPartListDescriptions(arr);
            }, 10);
        });
    }, [item, process]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (partListDescriptions.length === 1) {
            arr[0] = {
                id: partListDescriptions[0].manufactureOrders.id,
                process: partListDescriptions[0].manufactureOrders.process,
                dateOfStart: form.dateOfStart.value,
                priority: form.priority.value,
                dateOfFinish: null,
                note: form.note.value,
            };
        } else {
            for (let i = 0; i < partListDescriptions.length; i++) {
                arr.push({
                    id: partListDescriptions[i].manufactureOrders.id,
                    process: partListDescriptions[i].manufactureOrders.process,
                    dateOfStart: form.dateOfStart[i].value,
                    priority: form.priority[i].value,
                    dateOfFinish: null,
                    note: form.note[i].value,
                });
            }
        }

        PUT('manufacture/order', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    const _handleProcessName = (value) => {
        if (value === 'PLANING') return '평판';
        if (value === 'BENDING') return '절곡';
        if (value === 'PIPE') return 'PIPE';
        if (value === 'MCT') return 'MCT';
        if (value === 'CNC') return 'CNC';
        if (value === 'BANDSAW') return '밴드쇼';
        if (value === 'LATHE') return '선반';
        if (value === 'MILLING') return '밀링';
        if (value === 'PARTIAL_OUTSOURCING') return '일부외주';
        if (value === 'WHOLE_OUTSOURCING') return '전체외주';
        return '';
    };

    return (
        <Fragment>
            <ProjectViewer project={project} item={item} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={11}>
                                <select value={process} onChange={(e) => setProcess(e.target.value)}>
                                    <option value='ALL'>전체공정</option>
                                    <option value='PLANING'>평판</option>
                                    <option value='BENDING'>절곡</option>
                                    <option value='PIPE'>PIPE</option>
                                    <option value='MCT'>MCT</option>
                                    <option value='CNC'>CNC</option>
                                    <option value='BANDSAW'>밴드쇼</option>
                                    <option value='LATHE'>선반</option>
                                    <option value='MILLING'>밀링</option>
                                    <option value='PARTIAL_OUTSOURCING'>일반외주</option>
                                    <option value='WHOLE_OUTSOURCING'>전체외주</option>
                                </select>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='5%'>공정</th>
                            <th width='14%'>부품코드</th>
                            <th width='14%'>부품명</th>
                            <th width='14%'>규격</th>
                            <th width='4%'>Set</th>
                            <th width='4%'>Q'TY</th>
                            <th width='4%'>T</th>
                            <th width='6%'>작업지시량</th>
                            <th width='15%'>작업지시일</th>
                            <th width='5%'>우선순위</th>
                            <th width='10%'>비고</th>
                        </tr>
                        {partListDescriptions.length !== 0 ? (
                            partListDescriptions.map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td'>
                                        <input
                                            type='text'
                                            defaultValue={_handleProcessName(piece.manufactureOrders.process)}
                                            readOnly={true}
                                        />
                                        <span>공정</span>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            defaultValue={piece.type === 'PARTLIST' ? piece.partsNbr : piece.bomCode}
                                            readOnly={true}
                                        />
                                        <span>부품코드</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.description} readOnly={true} />
                                        <span>부품명</span>
                                    </td>
                                    <td>
                                        <input type='text' defaultValue={piece.specification} readOnly={true} />
                                        <span>규격</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.setQuantity} readOnly={true} />
                                        <span>Set</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='text' defaultValue={piece.quantity} readOnly={true} />
                                        <span>Q'TY</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='text'
                                            defaultValue={piece.manufactureAssignment.t}
                                            readOnly={true}
                                        />
                                        <span>T</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='text'
                                            defaultValue={piece.manufactureAssignment.quantityOfProduction}
                                            readOnly={true}
                                        />
                                        <span>작업지시량</span>
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='dateOfStart'
                                            defaultValue={piece.manufactureOrders.dateOfStart}
                                        />
                                        <span>작업지시일</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            name='priority'
                                            defaultValue={piece.manufactureOrders.priority}
                                        />
                                        <span>우선순위</span>
                                    </td>
                                    <td>
                                        <input type='text' name='note' defaultValue={piece.manufactureOrders.note} />
                                        <span>비고</span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='center-td' colSpan={11}>
                                    조회된 데이터가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
