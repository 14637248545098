import React, { useEffect } from 'react';

import FullscreenHeader from './FullscreenHeader';
import FullscreenContent from './FullscreenContent';

import './index.css';

const Component = ({ title, data, exitFunc }) => {
    useEffect(() => {
        const _handleKey = (e) => {
            if (e.keyCode === 27) {
                exitFunc();
            }
        };

        document.addEventListener('keydown', _handleKey);

        return () => {
            document.removeEventListener('keydown', _handleKey);
        };
    });

    return (
        <div className='fullscreen'>
            <FullscreenHeader title={title} exitFunc={exitFunc} />
            <FullscreenContent data={data} />
        </div>
    );
};

export default Component;
