import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { user_data } from '../../../actions';
import { useAlert } from 'react-alert';

import { AUTH_IN } from '../../../util/data';

import FI from 'feather-icons-react';

import './index.css';

const Component = () => {
    const [openBrowserNotice, setOpenBrowserNotice] = useState(false);
    const dispatch = useDispatch();
    const alert = useAlert();

    const _handleLogin = (e) => {
        e.preventDefault();

        const form = document.auth;

        AUTH_IN({ username: form.username.value, password: form.password.value }, alert, (res) => {
            if (res !== null) {
                dispatch(user_data(res));
                alert.success('로그인 되었습니다.');
            }
        });
    };

    return (
        <div className='auth'>
            <div className='auth-notice'>
                해당 어플리케이션은 보안상의 이유로 구버전 IE를 지원을 하지 않습니다.{' '}
                <font className='auth-notice-link' onClick={() => setOpenBrowserNotice(true)}>
                    더 알아보기
                </font>
            </div>
            <div className='auth-container'>
                <form name='auth' onSubmit={_handleLogin}>
                    <div className='auth-title'>WOOSUNG AUTOCON</div>
                    <div className='auth-description'>Production Management System</div>
                    <input className='auth-input' type='text' name='username' placeholder='아이디' required autoFocus />
                    <input className='auth-input' type='password' name='password' placeholder='비밀번호' required />
                    <button className='auth-button' type='submit'>
                        로그인
                    </button>
                </form>
            </div>
            {openBrowserNotice ? (
                <div className='auth-browser'>
                    <p>
                        <b>Internet Explorer (인터넷 익스플로어) 지원 중단 안내</b>
                    </p>
                    <p></p>
                    <p>해당 어플리케이션은 구형 IE에서 정상적으로 작동하지 않을 수 있습니다.</p>
                    <p>
                        MS(마이크로소프트)는 2016년 1월 12일 부로 IE 8과 9, 2020년 1월 31일 부로 IE 10에 대한 정기 보안
                        업데이트 지원을 종료하였습니다.
                    </p>
                    <p>이에 따라, 해당 브라우저를 통해서는 정상적인 접속이 불가한 점을 안내드립니다.</p>
                    <p></p>
                    <p>
                        <b>* 지원 종료일</b>
                    </p>
                    <p>Internet Explorer 8 & 9 지원 종료일 : 2016년 1월 12일</p>
                    <p>Internet Explorer 10 지원 종료일 : 2020년 1월 31일</p>
                    <p></p>
                    <p>
                        <b>* 지원 종료란?</b>
                    </p>
                    <p>
                        Internet Explorer 제조사인 Microsoft사에서 더 이상 제품에 대한 보안패치 및 기술지원을 하지
                        않겠다고 선언한 것을 의미합니다.
                    </p>
                    <p>이로 인해 제품취약점을 이용한 개인정보 유출 및 악성코드 감염 위험성이 높아지게 됩니다.</p>
                    <p>
                        안전한 PC사용을 위하여 최신 Edge 버전이나 크롬 등 타 브라우저 최신 버전 사용이 반드시
                        필요합니다.
                    </p>
                    <p></p>
                    <p>최적의 환경을 위해 PC 웹브라우저를 업데이트 하세요.</p>
                    <p>업데이트 하실 수 있는 다운로드 경로 안내 드리겠습니다.</p>
                    <p></p>
                    <p>
                        <a href='https://www.microsoft.com/ko-kr/edge' target='_blank' rel='noopener noreferrer'>
                            Edge 브라우저 다운로드
                        </a>
                    </p>
                    <p>
                        <a href='https://www.google.com/intl/ko/chrome/' target='_blank' rel='noopener noreferrer'>
                            크롬 브라우저 다운로드
                        </a>
                    </p>
                    <p>
                        <a href='https://www.mozilla.org/ko/firefox/new/' target='_blank' rel='noopener noreferrer'>
                            파이어폭스 브라우저 다운로드
                        </a>
                    </p>
                    <p></p>
                    <p>감사합니다.</p>
                    <FI className='auth-browser-icon' icon='x' onClick={() => setOpenBrowserNotice(false)} />
                </div>
            ) : null}
        </div>
    );
};

export default Component;
