import React, { useEffect } from 'react';

const Component = ({ afterService }) => {
    useEffect(() => {
        const textarea = document.querySelector('.textarea-as-viewer');
        textarea.style.height = textarea.scrollHeight + 'px';
    }, []);

    return (
        <table className='viewer'>
            <tbody>
                <tr>
                    <th colSpan={4}>A/S 정보</th>
                </tr>
                <tr>
                    <th width='15%'>접수번호</th>
                    <td width='35%'>{afterService.code}</td>
                    <th width='15%'>접수일</th>
                    <td width='35%'>{afterService.dateOfReceipt}</td>
                </tr>
                <tr>
                    <th>접수부서</th>
                    <td>{afterService.department}</td>
                    <th>접수자</th>
                    <td>{afterService.charger}</td>
                </tr>
                <tr>
                    <th>요구자</th>
                    <td>{afterService.requester}</td>
                    <th>연락처</th>
                    <td>{afterService.contact}</td>
                </tr>
                <tr>
                    <th>지역</th>
                    <td>{afterService.region}</td>
                    <th>접수방법</th>
                    <td>{afterService.method}</td>
                </tr>
                <tr>
                    <th>접수내용</th>
                    <td colSpan={3}>
                        <textarea
                            className='textarea-as-viewer'
                            name='content'
                            defaultValue={afterService.content}
                            readOnly
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default Component;
