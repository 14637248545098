import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { user_data } from '../../../actions';

import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { info } from '../../../configs';

import { AUTH_OUT } from '../../../util/data';

import CI from '../../../assets/images/ci.png';

import { menu } from '../../../configs';

import './index.css';

const Component = () => {
    const [keyword, setKeyword] = useState('');
    const [search, setSearch] = useState([]);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const alert = useAlert();

    useEffect(() => {
        if (keyword === '') {
            setSearch([]);
        } else {
            let arr = [];

            arr = arr.concat(menu.filter((piece) => piece.title.match(keyword) && !piece.isAdmin));

            for (let i = 0; i < menu.length; i++) {
                if (!menu[i].isAdmin) {
                    arr = arr.concat(menu[i].sub.filter((piece) => piece.title.match(keyword) && !piece.isHeader));
                }
            }

            setSearch(arr);
        }
    }, [keyword]);

    const _handleLogout = () => {
        AUTH_OUT((res) => {
            if (res !== false) {
                alert.success('로그아웃 되었습니다.');
                dispatch(
                    user_data({
                        id: -1,
                        username: '',
                        name: '',
                        admin: false,
                    })
                );
            }
        });
    };

    return (
        <header className='header'>
            <Link to='/status/project'>
                <img className='header-ci' src={CI} alt='ci' />
                <div className='header-title'>{info.title}</div>
            </Link>
            <div className='header-search'>
                <input
                    className='header-search-input'
                    type='text'
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder='메뉴 검색'
                />
                {search.length !== 0 ? (
                    <div className='header-search-result'>
                        {search.map((piece, index) => (
                            <Link
                                to={piece.redirect === undefined ? piece.path : piece.redirect}
                                key={index}
                                onClick={() => setKeyword('')}
                            >
                                <div className='header-search-element'>{piece.title}</div>
                            </Link>
                        ))}
                    </div>
                ) : null}
            </div>
            <button className='header-button' onClick={_handleLogout}>
                로그아웃
            </button>
            {user.admin ? (
                <Link to='/admin/user'>
                    <button className='header-button'>환경설정</button>
                </Link>
            ) : null}
            <div className='header-user'>
                반갑습니다, {user.name} ({user.username}) 님
            </div>
        </header>
    );
};

export default Component;
