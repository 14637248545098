import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';

import { GET, DELETE } from '../../../../util/data';

const Component = ({ productCode, setProductCode }) => {
    const [raw, setRaw] = useState([]);
    const [data, setData] = useState([]);
    const [type, setType] = useState('');
    const [keyword, setKeyword] = useState('');
    const [detect, setDetect] = useState(0);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('productCode', {}, (res) => {
            if (active) {
                setRaw(res);
                setData(res.sort((a, b) => a.productCode.localeCompare(b.productCode)));
            }
        });
    }, [active, detect, productCode]);

    useEffect(() => {
        let data = [...raw];

        if (type !== '') {
            data = data.filter((piece) => piece.productCode.substr(0, 3) === '5' + type + '1');
        }

        if (keyword !== '') {
            data = data.filter(
                (piece) =>
                    piece.productCode.toUpperCase().match(keyword.toUpperCase()) ||
                    piece.description.toUpperCase().match(keyword.toUpperCase()) ||
                    piece.specification.toUpperCase().match(keyword.toUpperCase())
            );
        }

        setData(data.sort((a, b) => a.productCode.localeCompare(b.productCode)));
    }, [raw, type, keyword]);

    const _handleRegister = () => {
        setProductCode({
            id: -1,
        });
    };

    const _handleType = (value) => {
        setType(value);
    };

    const _handleKeyword = (value) => {
        setKeyword(value);
    };

    const _handleReset = () => {
        setType('');
        setKeyword('');
    };

    const _handleDelete = (piece) => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        DELETE('productCode', piece, (res) => {
                            if (res !== null) {
                                setDetect((detect) => detect + 1);
                                alert.success(res);
                            }
                        });
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    return (
        <Fragment>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan='5'>
                            <button onClick={_handleRegister}>신규등록</button>
                            <button onClick={_handleDelete}>선택삭제</button>
                            <select value={type} onChange={(e) => _handleType(e.target.value)}>
                                <option value=''>선택</option>
                                <option value='A'>공압제품(A)</option>
                                <option value='B'>유압제품(B)</option>
                                <option value='C'>베어링제품(C)</option>
                                <option value='D'>플라스틱벨트(D)</option>
                                <option value='E'>특수벨트(E)</option>
                                <option value='G'>체인/케이블베어(G)</option>
                                <option value='H'>롤러류(H)</option>
                                <option value='J'>볼트/소모품(J)</option>
                                <option value='M'>기타자재A(M)</option>
                                <option value='N'>기타자재B(N)</option>
                            </select>
                            <input
                                className='keyword'
                                type='text'
                                placeholder='검색어'
                                value={keyword}
                                onChange={(e) => _handleKeyword(e.target.value)}
                            />
                            <button onClick={_handleReset}>초기화</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='14%'>PRODUCT CODE</th>
                        <th width='25%'>DESCRIPTION</th>
                        <th width='44%'>SPECIFICATION</th>
                        <th width='10%'>길이 여부</th>
                        <th width='7%'>관리</th>
                    </tr>
                    {data.length !== 0 ? (
                        data.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>{piece.productCode}</td>
                                <td>{piece.description}</td>
                                <td>{piece.specification}</td>
                                <td className='center-td'>
                                    {piece.lengthType === 'NEEDLESS'
                                        ? '불필요'
                                        : piece.lengthType === 'SELECTIVE'
                                        ? '선택'
                                        : '필수'}
                                </td>
                                <td className='center-td'>
                                    <button onClick={() => _handleDelete(piece)}>삭제</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan='5' className='center-td'>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
