import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { user_data } from '../actions';

import Wrapper from 'react-div-100vh';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './alert-template';

import { BrowserRouter } from 'react-router-dom';

import PopCore from '../components/pop/PopCore';

import Auth from '../components/frames/Auth';
import Header from '../components/frames/Header';
import Navigation from '../components/frames/Navigation';
import Sidebar from '../components/frames/Sidebar';
import Core from '../components/frames/Core';
import Footer from '../components/frames/Footer';

import ProjectPrint from '../components/ui/print/ProjectPrint';
import InstallReportPrint from '../components/ui/print/InstallReportPrint';

import { AUTH_CHECK } from '../util/data';

import config from '../configs';

import './index.css';

import Update20200831 from '../components/pages/update/Update20200831';

const options = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    transition: transitions.SCALE,
};

const Router = () => {
    const print = useSelector((state) => state.print);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        AUTH_CHECK((res) => {
            if (res !== false) {
                dispatch(user_data(res));
            }
        });
    }, [dispatch]);

    if (print.type === 0) {
        return (
            <Wrapper>
                <style>{`
                    :root {
                        --header: ${config.size.header};
                        --navigation: ${config.size.navigation};
                        --sidebar: ${config.size.sidebar};
                        --footer: ${config.size.footer};
                    }
                `}</style>
                <div className='router'>
                    <AlertProvider template={AlertTemplate} {...options}>
                        {window.location.pathname.match('/update/update20200831') ? <Update20200831 /> : null}
                        {user.id === -1 ? (
                            <Fragment>
                                <Auth />
                                <Footer />
                            </Fragment>
                        ) : user !== null && (user.authority.pipe || user.authority.mct || user.authority.cnc) ? (
                            <PopCore />
                        ) : (
                            <BrowserRouter>
                                <Header />
                                <Navigation />
                                <Sidebar />
                                <Core />
                                <Footer />
                            </BrowserRouter>
                        )}
                    </AlertProvider>
                </div>
            </Wrapper>
        );
    } else if (print.type === 1) {
        return <ProjectPrint print={print} />;
    } else if (print.type === 2) {
        return <InstallReportPrint print={print} />;
    }
};

export default Router;
