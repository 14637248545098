const data = {
    type: 0, // 0 : NONE, 1 : PROJECT, 2 : INSTALL_REPORT
    data: null,
};

const reducer = (state = data, action) => {
    switch (action.type) {
        case 'PRINT_DATA_SET':
            return Object.assign({}, state, {
                type: action.payload.type,
                data: action.payload.data,
            });
        default:
            return state;
    }
};

export default reducer;
