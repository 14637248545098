import React, { Fragment, useState, useEffect } from 'react';

import uuid from 'react-uuid';

import FileDownloader from '../../downloader/FileDownloader';

const Component = ({
    project,
    setProject,
    setItem,
    handleSlot,
    showProject,
    isSimpleProject,
    _handleStatusByItem,
    isAssembly,
}) => {
    const uid = 'selector-' + uuid();

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (!isSimpleProject) {
            const textarea = document.querySelector(`.${uid}`);
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }, [isSimpleProject, uid]);

    const _handleSetItem = (piece) => {
        try {
            document.querySelector('.core').scrollTop = 0;
        } catch {}
        setItem(piece);
    };

    const _handleResetProject = () => {
        setProject({
            id: -2,
        });
    };

    return (
        <Fragment>
            {showProject ? (
                <Fragment>
                    <table className='viewer'>
                        <tbody>
                            <tr>
                                <th colSpan={4}>프로젝트정보</th>
                            </tr>
                            <tr>
                                <th width='15%'>구분</th>
                                <td width='35%' className='center-td'>
                                    {project.type === 'PRODUCT'
                                        ? '완성품'
                                        : project.type === 'PART'
                                        ? '부품'
                                        : project.type === 'AS'
                                        ? ' A/S'
                                        : '미등록 프로젝트'}
                                </td>
                                <th width='15%'>지시번호</th>
                                <td width='35%' className='center-td'>
                                    {project.code}
                                </td>
                            </tr>
                            <tr>
                                <th>프로젝트명</th>
                                <td className='center-td'>{project.name}</td>
                                <th>납품처</th>
                                <td className='center-td'>{project.customer}</td>
                            </tr>
                            <tr>
                                <th>오더번호</th>
                                <td className='center-td'>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                                <th>전기사양</th>
                                <td className='center-td'>
                                    {project.electricity !== undefined ? project.electricity : ''}
                                </td>
                            </tr>
                            <tr>
                                <th>PLC Type</th>
                                <td className='center-td'>{project.plc !== undefined ? project.plc : ''}</td>
                                <th>색상</th>
                                <td className='center-td'>{project.color !== undefined ? project.color : ''}</td>
                            </tr>
                            <tr>
                                <th>지시일자</th>
                                <td className='center-td'>
                                    {project.dateOfOrder !== undefined ? project.dateOfOrder : ''}
                                </td>
                                <th>납품일자</th>
                                <td className='center-td'>
                                    {project.dateOfDue !== undefined ? project.dateOfDue : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {!isSimpleProject ? (
                        <Fragment>
                            <table className='viewer'>
                                <tbody>
                                    <tr>
                                        <th>세부작업 지시사항</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                className={uid}
                                                name='note'
                                                defaultValue={project.note}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <FileDownloader project={project} />
                        </Fragment>
                    ) : null}
                </Fragment>
            ) : null}
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={isAssembly !== undefined && isAssembly ? 7 : 6}>
                            <button className='focusButton' type='button' onClick={_handleResetProject}>
                                프로젝트 재선택
                            </button>
                            <div className='division'>|</div>
                            <input
                                className='keyword'
                                type='text'
                                placeholder='검색어'
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>No.</th>
                        <th width={isAssembly !== undefined && isAssembly ? '30%' : '40%'}>아이템명</th>
                        <th width='15%'>규격</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>수량</th>
                        {isAssembly !== undefined && isAssembly ? <th width='10%'>진도</th> : null}
                        <th width='20%'>비고</th>
                    </tr>
                    {project.items.length !== 0 ? (
                        project.items
                            .filter((piece) =>
                                piece.itemFamily !== null
                                    ? piece.itemFamily.name.toLowerCase().match(keyword.toLowerCase())
                                    : piece.name.toLowerCase().match(keyword.toLowerCase())
                            )
                            .map((piece, index) => (
                                <tr
                                    className={handleSlot(piece) ? '' : 'closed-tr'}
                                    onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}
                                    key={index}
                                >
                                    <td className='center-td'>{piece.nbr}</td>
                                    <td>
                                        {_handleStatusByItem !== undefined ? (
                                            _handleStatusByItem(piece) === 0 ? (
                                                <span className='done-project'>[입력완료] </span>
                                            ) : (
                                                <span className='current-project'>[미완료] </span>
                                            )
                                        ) : null}
                                        {piece.partListMerge !== null ? (
                                            piece.partListMerge === -1 ? (
                                                <span>
                                                    <b>[대표] </b>
                                                </span>
                                            ) : (
                                                <span>
                                                    <b>[병합] </b>
                                                </span>
                                            )
                                        ) : null}
                                        {piece.itemFamily !== null ? piece.itemFamily.name : piece.name}
                                    </td>
                                    <td>{piece.specification}</td>
                                    <td className='center-td'>{piece.unit}</td>
                                    <td className='center-td'>{piece.quantity}</td>
                                    {isAssembly !== undefined && isAssembly ? (
                                        <td className='center-td'>{piece.assemblyWork.progress}</td>
                                    ) : null}
                                    <td>{piece.note.length > 30 ? piece.note.substr(0, 30) + '...' : piece.note}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={isAssembly !== undefined && isAssembly ? 7 : 6}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
