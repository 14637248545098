import React, { useState, useEffect, Fragment } from 'react';

import { UPLOAD, DOWNLOAD } from '../../../../util/data';
import { statusType } from '../../../../configs';

import FI from 'feather-icons-react';

import { serverPath } from '../../../../configs/path';

import './index.css';

const API_PATH = 'api';
const API_VERSION = 'v01';

const Component = ({ attachments, setAttachments }) => {
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    const _handleInitUpload = () => {
        document.querySelector('.fileUploader-input').click();
    };

    const _handleClearFiles = () => {
        setAttachments((data) => {
            let changedData = [...data];
            for (let i = 0; i < changedData.length; i++) {
                changedData[i].status = statusType.DELETE;
            }
            return changedData;
        });
    };

    const _handleSetFiles = () => {
        const file = document.querySelector('.fileUploader-input');

        let formData = new FormData();

        for (let i = 0; i < file.files.length; i++) {
            formData.append('files', file.files[i]);
        }

        UPLOAD(formData, (res) => {
            if (active && res !== null) {
                setAttachments((data) => {
                    let changedData = [...data];
                    changedData = changedData.concat(res);
                    return changedData;
                });
                file.value = '';
            }
        });

        // alert.info('베타 버전에서는 사용할 수 없는 기능입니다.');
        file.value = '';
    };

    const _handleSize = (value) => {
        let tail = ['KB', 'MB', 'GB'];
        let tailIndex = 0;

        value /= 1024;

        while (value > 1024) {
            value /= 1024;
            tailIndex++;
        }

        return value.toFixed(0) + tail[tailIndex];
    };

    const _handleDeleteFile = (index) => {
        setAttachments((data) => {
            let changedData = [...data];
            changedData[index].status = statusType.DELETE;
            return changedData;
        });
    };

    const _handleRecoverFile = (index) => {
        setAttachments((data) => {
            let changedData = [...data];
            changedData[index].status = statusType.NORMAL;
            return changedData;
        });
    };

    const _handleDownload = (id) => {
        DOWNLOAD(id);
    };

    const _handleMouseEnter = (id) => {
        document.querySelector(
            '.image-previewer-core'
        ).style.backgroundImage = `url('${serverPath}/${API_PATH}/${API_VERSION}/attachment/${id}')`;
        document.querySelector('.image-previewer').style.display = 'block';
    };

    const _handleMouseLeave = () => {
        document.querySelector('.image-previewer').style.display = 'none';
    };

    return (
        <Fragment>
            <table className='form'>
                <tbody>
                    <tr>
                        <th>첨부자료</th>
                    </tr>
                    <tr>
                        <td>
                            <input type='file' className='fileUploader-input' multiple onChange={_handleSetFiles} />
                            <button type='button' onClick={_handleInitUpload}>
                                업로드
                            </button>
                            <button type='button' onClick={_handleClearFiles}>
                                전체삭제
                            </button>
                            {attachments.map((piece, index) => (
                                <div className='fileUploader-list' key={index}>
                                    <div
                                        className={
                                            piece.status === statusType.DELETE
                                                ? 'fileUploader-list-title-delete'
                                                : 'fileUploader-list-title'
                                        }
                                    >
                                        {piece.name}
                                    </div>
                                    <div className='fileUploader-list-size'>({_handleSize(Number(piece.size))})</div>
                                    {piece.status === statusType.DELETE ? (
                                        <FI
                                            className='fileUploader-list-icon'
                                            icon='refresh-ccw'
                                            onClick={() => _handleRecoverFile(index)}
                                        />
                                    ) : (
                                        <Fragment>
                                            <FI
                                                className='fileUploader-list-icon'
                                                icon='x'
                                                onClick={() => _handleDeleteFile(index)}
                                            />
                                            <FI
                                                className='fileUploader-list-icon'
                                                icon='download'
                                                onClick={() => _handleDownload(piece.id)}
                                            />
                                            {piece.name.match('.jpg') ||
                                            piece.name.match('.jpeg') ||
                                            piece.name.match('.png') ||
                                            piece.name.match('.bmp') ? (
                                                <FI
                                                    className='fileUploader-list-icon'
                                                    icon='search'
                                                    onMouseEnter={() => _handleMouseEnter(piece.id)}
                                                    onMouseLeave={_handleMouseLeave}
                                                />
                                            ) : null}
                                        </Fragment>
                                    )}
                                </div>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='image-previewer'>
                <div className='image-previewer-header'>이미지 미리보기</div>
                <div className='image-previewer-core'></div>
            </div>
        </Fragment>
    );
};

export default Component;
