import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const data = {
            id: item.manufactureLaser.id,
            dateOfReceipt: form.dateOfReceipt.value,
            worker: form.worker.value,
            dateOfStartOfPipe: form.dateOfStartOfPipe.value,
            progressOfPipe: form.progressOfPipe.value,
            dateOfFinishOfPipe: form.dateOfFinishOfPipe.value,
            dateOfStartOfPlaning: form.dateOfStartOfPlaning.value,
            progressOfPlaning: form.progressOfPlaning.value,
            dateOfFinishOfPlaning: form.dateOfFinishOfPlaning.value,
            note: form.note.value,
        };

        PUT('manufacture/laser', data, (res1) => {
            if (res1 !== null) {
                GET('project', { id: project.id }, (res2) => {
                    res2.items.sort((a, b) => {
                        try {
                            return (
                                Number(a.nbr.replace('-', '.').replace(/\D./g, '')) -
                                Number(b.nbr.replace('-', '.').replace(/\D./g, ''))
                            );
                        } catch {
                            return 1;
                        }
                    });
                    setProject(res2);
                });
                alert.success(res1);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} item={item} showItemList={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th colSpan={2}>Laser 설계도면 접수일</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfReceipt'
                                    defaultValue={item.manufactureLaser.dateOfReceipt}
                                />
                            </td>
                            <th colSpan={2}>Laser 설계자</th>
                            <td>
                                <input type='text' name='worker' defaultValue={item.manufactureLaser.worker} />
                            </td>
                        </tr>
                        <tr>
                            <th width='10%' rowSpan={3}>
                                Pipe
                                <br />
                                Laser
                                <br />
                                설계
                            </th>
                            <th width='10%'>시작일</th>
                            <td width='30%'>
                                <input
                                    type='date'
                                    name='dateOfStartOfPipe'
                                    defaultValue={item.manufactureLaser.dateOfStartOfPipe}
                                />
                            </td>
                            <th width='10%' rowSpan={3}>
                                평판
                                <br />
                                Laser
                                <br />
                                설계
                            </th>
                            <th width='10%'>시작일</th>
                            <td width='30%'>
                                <input
                                    type='date'
                                    name='dateOfStartOfPlaning'
                                    defaultValue={item.manufactureLaser.dateOfStartOfPlaning}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>진도</th>
                            <td>
                                <select name='progressOfPipe' defaultValue={item.manufactureLaser.progressOfPipe}>
                                    <option value={0}>0</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                    <option value={90}>90</option>
                                    <option value={95}>95</option>
                                    <option value={100}>100</option>
                                    <option value={-100}>작업불필요</option>
                                </select>
                            </td>
                            <th>진도</th>
                            <td>
                                <select name='progressOfPlaning' defaultValue={item.manufactureLaser.progressOfPlaning}>
                                    <option value={0}>0</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                    <option value={90}>90</option>
                                    <option value={95}>95</option>
                                    <option value={100}>100</option>
                                    <option value={-100}>작업불필요</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>종료일</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfFinishOfPipe'
                                    defaultValue={item.manufactureLaser.dateOfFinishOfPipe}
                                />
                            </td>
                            <th>종료일</th>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfFinishOfPlaning'
                                    defaultValue={item.manufactureLaser.dateOfFinishOfPlaning}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>이슈사항</th>
                            <td colSpan={4}>
                                <textarea
                                    className='wide-textarea'
                                    name='note'
                                    defaultValue={item.manufactureLaser.note}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
