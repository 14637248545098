import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { menu } from '../../../configs';

import './index.css';

const Component = () => {
    const location = useLocation();

    return (
        <div className='navigation'>
            {menu.map((piece, index) =>
                !piece.isAdmin ? (
                    <Link
                        className={
                            piece.path.split('/')[1] === location.pathname.split('/')[1]
                                ? 'navigation-menu-active'
                                : 'navigation-menu'
                        }
                        to={piece.redirect}
                        key={index}
                    >
                        {piece.title}
                    </Link>
                ) : null
            )}
        </div>
    );
};

export default Component;
