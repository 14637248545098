import React, { useState, useEffect, Fragment } from 'react';
import { useAlert } from 'react-alert';

import { GET, PUT, DELETE } from '../../../../util/data';

import { confirmAlert } from 'react-confirm-alert';

const Component = ({ thisUser, setThisUser }) => {
    const [raw, setRaw] = useState([]);
    const [detector, setDetector] = useState(false);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (thisUser.id === -2) {
            setRaw([]);
            GET('user/all', {}, (res) => {
                if (active && res !== null) {
                    setRaw(res);
                }
            });
        }
    }, [active, detector, thisUser]);

    const _handleRegister = () => {
        setThisUser({
            id: -1,
        });
    };

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeListForm;

        let arr = [];

        if (raw.length === 0) {
            arr[0] = {
                id: raw[0].id,
                username: form.username.value,
                password:
                    form.password.value === '' || form.password.value === form.defaultPassword.value
                        ? null
                        : form.password.value,
                name: form.name.value,
                admin: form.admin.checked,
                resign: form.resign.checked,
                authority: {
                    id: raw[0].authority.id,
                    access: form.access.checked,
                    projectOrder: form.projectOrder.checked,
                    designPlanning: form.designPlanning.checked,
                    designWork: form.designWork.checked,
                    manufacture1: form.manufacture1.checked,
                    manufacture2: form.manufacture2.checked,
                    assembly: form.assembly.checked,
                    control: form.control.checked,
                    shipment: form.shipment.checked,
                    purchase: form.purchase.checked,
                    quality: form.quality.checked,
                    pipe: form.pipe.checked,
                    mct: form.mct.checked,
                    cnc: form.cnc.checked,
                    approvalOfSales: form.approvalOfSales.checked,
                    approvalOfDesign: form.approvalOfDesign.checked,
                    approvalOfManufacture: form.approvalOfManufacture.checked,
                    approvalOfAssembly: form.approvalOfAssembly.checked,
                    approvalOfQuality: form.approvalOfQuality.checked,
                    approvalOfPurchase: form.approvalOfPurchase.checked,
                },
            };
        } else {
            for (let i = 0; i < raw.length; i++) {
                arr.push({
                    id: raw[i].id,
                    username: form.username[i].value,
                    password:
                        form.password[i].value === '' || form.password[i].value === form.defaultPassword[i].value
                            ? null
                            : form.password[i].value,
                    name: form.name[i].value,
                    admin: form.admin[i].checked,
                    resign: form.resign[i].checked,
                    authority: {
                        id: raw[i].authority.id,
                        access: form.access[i].checked,
                        projectOrder: form.projectOrder[i].checked,
                        designPlanning: form.designPlanning[i].checked,
                        designWork: form.designWork[i].checked,
                        manufacture1: form.manufacture1[i].checked,
                        manufacture2: form.manufacture2[i].checked,
                        assembly: form.assembly[i].checked,
                        control: form.control[i].checked,
                        shipment: form.shipment[i].checked,
                        purchase: form.purchase[i].checked,
                        quality: form.quality[i].checked,
                        pipe: form.pipe[i].checked,
                        mct: form.mct[i].checked,
                        cnc: form.cnc[i].checked,
                        approvalOfSales: form.approvalOfSales[i].checked,
                        approvalOfDesign: form.approvalOfDesign[i].checked,
                        approvalOfManufacture: form.approvalOfManufacture[i].checked,
                        approvalOfAssembly: form.approvalOfAssembly[i].checked,
                        approvalOfQuality: form.approvalOfQuality[i].checked,
                        approvalOfPurchase: form.approvalOfPurchase[i].checked,
                    },
                });
            }
        }

        PUT('user', arr, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    const _handleSelectAll = () => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = true;
        }
    };

    const _handleDeleteAll = () => {
        confirmAlert({
            message: '한번 삭제된 데이터는 복구할 수 없습니다. 퇴사자의 경우 퇴사여부 변경을 통해 데이터를 유지하십시오. 삭제를 계속 진행하시겠습니까?',
            buttons: [
                {
                    label: '진행',
                    onClick: () => {
                        const checkbox = document.getElementsByName('list[]');

                        let arr = [];

                        for (let i = 0; i < checkbox.length; i++) {
                            if (checkbox[i].checked === true) {
                                arr.push(checkbox[i].value);
                                checkbox[i].checked = false;
                            }
                        }

                        if (arr.length > 0) {
                            DELETE('user/all', { ids: arr.toString() }, (res) => {
                                if (res !== null) {
                                    setDetector(!detector);
                                    alert.success(res);
                                }
                            });
                        }
                    },
                },
                {
                    label: '취소',
                },
            ],
        });
    };

    return (
        <Fragment>
            <form name='activeListForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <thead>
                        <tr>
                            <td colSpan={26}>
                                <button type='button' onClick={_handleRegister}>
                                    신규등록
                                </button>
                                <button type='button' onClick={_handleSelectAll}>
                                    전체선택
                                </button>
                                <button type='button' onClick={_handleDeleteAll}>
                                    선택삭제
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='3.5%' rowSpan={2}>
                                선택
                            </th>
                            <th width='6%' rowSpan={2}>
                                아이디
                            </th>
                            <th width='5.5%' rowSpan={2}>
                                비밀번호
                            </th>
                            <th width='6%' rowSpan={2}>
                                사용자명
                            </th>
                            <th width='3.5%' rowSpan={2}>
                                관리자
                                <br />
                                권한
                            </th>
                            <th colSpan={11}>일반권한</th>
                            <th colSpan={3}>POP권한</th>
                            <th colSpan={6}>결재권한</th>
                            <th width='3.5%' rowSpan={2}>
                                퇴사
                                <br />
                                여부
                            </th>
                        </tr>
                        <tr>
                            <th width='3.5%'>
                                접속
                                <br />
                                읽기
                            </th>
                            <th width='3.5%'>
                                작업
                                <br />
                                지시
                            </th>
                            <th width='3.5%'>
                                설계
                                <br />
                                계획
                            </th>
                            <th width='3.5%'>설계</th>
                            <th width='3.5%'>
                                레이져
                                <br />
                                설계
                            </th>
                            <th width='3.5%'>가공</th>
                            <th width='3.5%'>조립</th>
                            <th width='3.5%'>
                                전기
                                <br />
                                제어
                            </th>
                            <th width='3.5%'>
                                출하
                                <br />
                                설치
                            </th>
                            <th width='3.5%'>구매</th>
                            <th width='3.5%'>품질</th>
                            <th width='3.5%'>PIPE</th>
                            <th width='3.5%'>MCT</th>
                            <th width='3.5%'>CNC</th>
                            <th width='3.5%'>영업</th>
                            <th width='3.5%'>설계</th>
                            <th width='3.5%'>가공</th>
                            <th width='3.5%'>조립</th>
                            <th width='3.5%'>품질</th>
                            <th width='3.5%'>구매</th>
                        </tr>
                        {raw
                            .filter((piece) => piece.username !== 'deleted')
                            .map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td'>
                                        <input type='checkbox' name='list[]' value={piece.id} />
                                    </td>
                                    <td>
                                        <input type='text' name='username' defaultValue={piece.username} />
                                        <span>아이디</span>
                                    </td>
                                    <td>
                                        <input
                                            type='password'
                                            name='password'
                                            defaultValue={piece.password}
                                            onClick={(e) => (e.target.value = '')}
                                        />
                                        <input type='hidden' name='defaultPassword' defaultValue={piece.password} />
                                        <span>비밀번호</span>
                                    </td>
                                    <td>
                                        <input type='text' name='name' defaultValue={piece.name} />
                                        <span>사용자명</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='admin' defaultChecked={piece.admin} />
                                        <span>관리자권한</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='access' defaultChecked={piece.authority.access} />
                                        <span>접속 / 읽기</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='projectOrder'
                                            defaultChecked={piece.authority.projectOrder}
                                        />
                                        <span>작업지시</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='designPlanning'
                                            defaultChecked={piece.authority.designPlanning}
                                        />
                                        <span>설계계획</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='designWork'
                                            defaultChecked={piece.authority.designWork}
                                        />
                                        <span>설계작업</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='manufacture1'
                                            defaultChecked={piece.authority.manufacture1}
                                        />
                                        <span>레이져설계</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='manufacture2'
                                            defaultChecked={piece.authority.manufacture2}
                                        />
                                        <span>가공</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='assembly'
                                            defaultChecked={piece.authority.assembly}
                                        />
                                        <span>조립</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='control'
                                            defaultChecked={piece.authority.control}
                                        />
                                        <span>전기제어</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='shipment'
                                            defaultChecked={piece.authority.shipment}
                                        />
                                        <span>출하 / 설치</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='purchase'
                                            defaultChecked={piece.authority.purchase}
                                        />
                                        <span>구매</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='quality'
                                            defaultChecked={piece.authority.quality}
                                        />
                                        <span>품질</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='pipe' defaultChecked={piece.authority.pipe} />
                                        <span>POP권한 - PIPE</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='mct' defaultChecked={piece.authority.mct} />
                                        <span>POP권한 - MCT</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='cnc' defaultChecked={piece.authority.cnc} />
                                        <span>POP권한 - CNC</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfSales'
                                            defaultChecked={piece.authority.approvalOfSales}
                                        />
                                        <span>결재권한 - 영업</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfDesign'
                                            defaultChecked={piece.authority.approvalOfDesign}
                                        />
                                        <span>결재권한 - 설계</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfManufacture'
                                            defaultChecked={piece.authority.approvalOfManufacture}
                                        />
                                        <span>결재권한 - 가공</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfAssembly'
                                            defaultChecked={piece.authority.approvalOfAssembly}
                                        />
                                        <span>결재권한 - 조립</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfQuality'
                                            defaultChecked={piece.authority.approvalOfQuality}
                                        />
                                        <span>결재권한 - 품질</span>
                                    </td>
                                    <td className='center-td'>
                                        <input
                                            type='checkbox'
                                            name='approvalOfPurchase'
                                            defaultChecked={piece.authority.approvalOfPurchase}
                                        />
                                        <span>결재권한 - 구매</span>
                                    </td>
                                    <td className='center-td'>
                                        <input type='checkbox' name='resign' defaultChecked={piece.resign} />
                                        <span>퇴사여부</span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <button className='submitButton'>저장</button>
            </form>
        </Fragment>
    );
};

export default Component;
