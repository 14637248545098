import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import './index.css';

const Component = ({ workers, name1, name2 }) => {
    const [workerValue, setWorkerValue] = useState('');
    const [onToggle, setOnToggle] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        const _handleClick = (e) => {
            if (
                onToggle &&
                e.target.className !== 'workerInputer-input' &&
                (e.target.name === name1 || e.target.name === name2)
            ) {
                e.target.disabled = true;
                e.target.value = workerValue;
                e.target.disabled = false;
            }
        };

        document.addEventListener('click', _handleClick);

        return () => {
            document.removeEventListener('click', _handleClick);
        };
    });

    const _handleToggle = () => {
        if (workerValue === '') {
            alert.error('작업자를 먼저 선택하여 주십시오.');
            document.querySelector('.workerInputer-input').focus();
        } else {
            if (onToggle) {
                setWorkerValue('');
            }
            setOnToggle((onToggle) => !onToggle);
        }
    };

    return (
        <div className='workerInputer'>
            <select
                className='workerInputer-input'
                type='date'
                value={workerValue}
                onChange={(e) => setWorkerValue(e.target.value)}
            >
                <option value=''>작업자 선택</option>
                {workers.filter((piece) => piece.authority.designWork).map((piece, index) => (
                    <option value={piece.id} key={index}>
                        {piece.name}
                    </option>
                ))}
            </select>
            <div
                className={
                    onToggle ? 'workerInputer-toggle-box workerInputer-toggle-box-active' : 'workerInputer-toggle-box'
                }
                onClick={_handleToggle}
            >
                <div
                    className={onToggle ? 'workerInputer-toggle workerInputer-toggle-active' : 'workerInputer-toggle'}
                ></div>
            </div>
        </div>
    );
};

export default Component;
