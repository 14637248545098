import React, { useEffect, useState } from 'react';

import { GET, GET_WITH_PAGING } from '../../../../../util/data';

const Component = () => {
    const [team, setTeam] = useState('');
    const [designers, setDesigners] = useState([]);
    const [designer, setDesigner] = useState('');
    const [raw, setRaw] = useState([]);
    const [data] = useState({
        page: 0,
        size: 0,
        sort: 'type',
        order: 'DESC',
        type: 'ALL',
        status: 'CURRENT',
        keyword: '',
        detector: 0,
    });

    useEffect(() => {
        GET('user/all', {}, (res) => {
            setDesigners(res.filter((piece) => (piece.authority.designWork && !piece.resign ? piece : null)));
        });
    }, []);

    useEffect(() => {
        GET_WITH_PAGING('project/paging', data, (res) => {
            res = res.data.content;

            let arr = [];

            for (let i = 0; i < res.length; i++) {
                const project = res[i];
                for (let j = 0; j < project.items.length; j++) {
                    const item = project.items[j];

                    if (
                        item.designPlanning.status !== 'UNNECESSARY' &&
                        item.designPlanning.designer !== null &&
                        item.designPlanning.designer !== ''
                    ) {
                        arr.push({
                            project: project,
                            item: item,
                            designPlanning: item.designPlanning,
                            designWork: item.designWork,
                        });
                    }
                }
            }
            setRaw(arr);
        });
    }, [data]);

    const _handleDateDifference = (value) => {
        const today = new Date();
        const target = new Date(value);

        const diff = ((target.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);

        return Number(diff);
    };

    return (
        <div className='container'>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={13}>
                            <select value={team} onChange={(e) => setTeam(e.target.value)}>
                                <option value=''>전체 팀</option>
                                <option value='ONE'>1팀</option>
                                <option value='TWO'>2팀</option>
                                <option value='THREE'>3팀</option>
                                <option value='RESEARCH'>R&D팀</option>
                            </select>
                            <select value={designer} onChange={(e) => setDesigner(e.target.value)}>
                                <option value=''>전체 설계자</option>
                                {team !== ''
                                    ? designers
                                          .filter((piece) => piece.designTeam.team === team)
                                          .map((piece, index) => (
                                              <option value={piece.id} key={index}>
                                                  {piece.name}
                                              </option>
                                          ))
                                    : null}
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%' rowSpan={2}>
                            팀명
                        </th>
                        <th width='5%' rowSpan={2}>
                            설계자
                        </th>
                        <th width='2%' rowSpan={2}>
                            상태
                        </th>
                        <th width='18%' rowSpan={2}>
                            프로젝트명
                        </th>
                        <th width='5%' rowSpan={2}>
                            No.
                        </th>
                        <th width='18%' rowSpan={2}>
                            아이템명 (규격)
                        </th>
                        <th colSpan={3}>지시일</th>
                        <th colSpan={3}>입력일</th>
                        <th width='5%' rowSpan={2}>
                            진도
                        </th>
                    </tr>
                    <tr>
                        <th width='7%'>설계작업</th>
                        <th width='7%'>설계시작</th>
                        <th width='7%'>설계완료</th>
                        <th width='7%'>설계시작</th>
                        <th width='7%'>완료예정</th>
                        <th width='7%'>설계완료</th>
                    </tr>
                    {raw.filter((piece) =>
                        team !== ''
                            ? designer !== ''
                                ? piece.designPlanning.designer.designTeam.team === team &&
                                  piece.designPlanning.designer.id === Number(designer)
                                : piece.designPlanning.designer.designTeam.team === team
                            : piece
                    ).length !== 0 ? (
                        raw
                            .filter((piece) =>
                                team !== ''
                                    ? designer !== ''
                                        ? piece.designPlanning.designer.designTeam.team === team &&
                                          piece.designPlanning.designer.id === Number(designer)
                                        : piece.designPlanning.designer.designTeam.team === team
                                    : piece
                            )
                            .sort((a, b) =>
                                a.designPlanning.dateOfFinish === null || b.designPlanning.dateOfFinish === null
                                    ? 0
                                    : a.designPlanning.dateOfFinish.localeCompare(b.designPlanning.dateOfFinish)
                            )
                            .map((piece, index) => (
                                <tr
                                    key={index}
                                    onClick={() =>
                                        (window.location.href = `/design/planning/schedule?p=${piece.project.id}`)
                                    }
                                >
                                    <td className='center-td'>
                                        {piece.designPlanning.designer.designTeam.team === 'NONE' ? '미지정' : ''}
                                        {piece.designPlanning.designer.designTeam.team === 'ONE' ? '1팀' : ''}
                                        {piece.designPlanning.designer.designTeam.team === 'TWO' ? '2팀' : ''}
                                        {piece.designPlanning.designer.designTeam.team === 'THREE' ? '3팀' : ''}
                                        {piece.designPlanning.designer.designTeam.team === 'RESEARCH' ? 'R&D팀' : ''}
                                    </td>
                                    <td className='center-td'>{piece.designPlanning.designer.name}</td>
                                    <td
                                        className={
                                            piece.designWork.dateOfFinish !== null &&
                                            piece.designWork.dateOfFinish !== ''
                                                ? 'center-td status-done'
                                                : _handleDateDifference(piece.designPlanning.dateOfFinish) >= 0
                                                ? 'center-td status-safe'
                                                : 'center-td status-emergency'
                                        }
                                    ></td>
                                    <td>{piece.project.name}</td>
                                    <td className='center-td'>{piece.item.nbr}</td>
                                    <td>
                                        {piece.item.itemFamily !== null ? piece.item.itemFamily.name : piece.item.name}{' '}
                                        (
                                        {piece.item.specification === null || piece.item.specification === ''
                                            ? '규격없음'
                                            : piece.item.specification}
                                        )
                                    </td>
                                    <td className='center-td'>{piece.item.itemPlanning.dateOfDesign}</td>
                                    <td className='center-td'>{piece.designPlanning.dateOfStart}</td>
                                    <td className='center-td'>{piece.designPlanning.dateOfFinish}</td>
                                    <td className='center-td'>{piece.designWork.dateOfStart}</td>
                                    <td className='center-td'>{piece.designWork.dateOfSchedule}</td>
                                    <td className='center-td'>{piece.designWork.dateOfFinish}</td>
                                    <td className='center-td'>{piece.designWork.progress}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={13}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
