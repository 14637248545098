import React, { useState, Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import FileUploader from '../../../ui/uploader/FileUploader';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, item, setItem }) => {
    const [attachments, setAttachments] = useState([
        ...item.assemblyWork.attachments.filter(
            (attachment) => attachment.status === null || attachment.status === 'NORMAL'
        ),
    ]);
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const data = {
            id: item.assemblyWork.id,
            dateOfReceipt: form.dateOfReceipt.value,
            team: form.team.value,
            dateOfWarehousingOfPainting: form.dateOfWarehousingOfPainting.value,
            dateOfWarehousingOfPurchase: form.dateOfWarehousingOfPurchase.value,
            dateOfWarehousingOfManufacture: form.dateOfWarehousingOfManufacture.value,
            dateOfStart: form.dateOfStart.value,
            progress: form.progress.value,
            dateOfFinish: form.dateOfFinish.value,
            note: form.note.value,
            attachments: attachments,
        };

        PUT('assembly/work', data, (res1) => {
            if (res1 !== null) {
                GET('project', { id: project.id }, (res2) => {
                    res2.items.sort((a, b) => {
                        try {
                            return (
                                Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                                Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                            );
                        } catch {
                            return 1;
                        }
                    });
                    setProject(res2);
                });
                alert.success(res1);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={true} item={item} setItem={setItem} isAssembly={true} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='25%'>도면접수일</th>
                            <th width='25%'>작업팀</th>
                            <th width='25%'>(도장제품 검사완료일)</th>
                            <th width='25%'>도장제품 입고완료일</th>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfReceipt'
                                    defaultValue={item.assemblyWork.dateOfReceipt}
                                />
                            </td>
                            <td>
                                <select name='team' defaultValue={item.assemblyWork.team}>
                                    <option value='NONE'>미지정</option>
                                    <option value='ONE'>1팀</option>
                                    <option value='TWO'>2팀</option>
                                    <option value='THREE'>3팀</option>
                                    <option value='FOUR'>4팀</option>
                                    <option value='OUTSOURCING'>외주</option>
                                </select>
                            </td>
                            <td>{item.qualityWork.dateOfPainting}</td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfWarehousingOfPainting'
                                    defaultValue={item.assemblyWork.dateOfWarehousingOfPainting}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>구매부품 입고완료일</th>
                            <th>(가공부품 검사완료일)</th>
                            <th>가공부품 입고완료일</th>
                            <td rowSpan={4}></td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfWarehousingOfPurchase'
                                    defaultValue={item.assemblyWork.dateOfWarehousingOfPurchase}
                                />
                            </td>
                            <td>{item.qualityWork.dateOfCanning}</td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfWarehousingOfManufacture'
                                    defaultValue={item.assemblyWork.dateOfWarehousingOfManufacture}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>작업시작일</th>
                            <th>작업진도</th>
                            <th>작업완료일</th>
                        </tr>
                        <tr>
                            <td>
                                <input type='date' name='dateOfStart' defaultValue={item.assemblyWork.dateOfStart} />
                            </td>
                            <td>
                                <select name='progress' defaultValue={item.assemblyWork.progress}>
                                    <option value={0}>0</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={25}>25</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                    <option value={90}>90</option>
                                    <option value={95}>95</option>
                                    <option value={100}>100</option>
                                </select>
                            </td>
                            <td>
                                <input type='date' name='dateOfFinish' defaultValue={item.assemblyWork.dateOfFinish} />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={4}>이슈사항</th>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                                <textarea className='wide-textarea' name='note' defaultValue={item.assemblyWork.note} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <FileUploader attachments={attachments} setAttachments={setAttachments} />
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
