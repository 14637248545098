import React, { useState, useEffect, Fragment } from 'react';

import { GET } from '../../../../../util/data';

const Component = ({ setSource, setList, projectId, itemRaw, itemId, type }) => {
    const [sourceRaw, setSourceRaw] = useState([]);
    const [parts, setParts] = useState('');
    const [partsRaw, setPartsRaw] = useState([]);
    const [productType, setProductType] = useState(
        type === null || type === undefined || type === ''
            ? 'all'
            : type === 'A'
            ? 'pneumatic'
            : type === 'B'
            ? 'hydraulic'
            : type === 'C' || type === 'D' || type === 'E' || type === 'N'
            ? 'mechanical'
            : type === 'F' || type === 'G' || type === 'K'
            ? 'electrical'
            : 'all'
    );

    useEffect(() => {
        if (projectId !== -1 && itemId !== -1) {
            GET(
                'partlist/description',
                { id: itemRaw.find((piece) => piece.id === Number(itemId)).partList.id },
                (res) => {
                    res.sort((a, b) => {
                        try {
                            if (a.type === 'PARTLIST') {
                                return (
                                    a.parts - b.parts &&
                                    Number(a.partsNbr.replace(/\D/g, '')) - Number(b.partsNbr.replace(/\D/g, ''))
                                );
                            } else {
                                return Number(a.bomCode.replace(/\D/g, '')) - Number(b.bomCode.replace(/\D/g, ''));
                            }
                        } catch {
                            return 1;
                        }
                    });
                    setSourceRaw(res);

                    let arr = [];

                    for (let i = 0; i < res.length; i++) {
                        if (arr.findIndex((piece) => piece === res[i].parts) === -1) {
                            arr.push(res[i].parts);
                        }
                    }

                    setParts('');
                    setPartsRaw(arr);
                }
            );
        } else {
            setSourceRaw([]);
        }
    }, [projectId, itemRaw, itemId]);

    const _handleQuantity = (thisRaw) => {
        let a = thisRaw.quantity;
        let b = thisRaw.setQuantity;

        try {
            a = Number(a);
        } catch {
            a = 1;
        }

        try {
            b = Number(b);
            b = b === 0 ? 1 : b;
        } catch {
            b = 1;
        }

        try {
            if (!isNaN(a) && !isNaN(b)) {
                return a * b;
            } else if (!isNaN(a)) {
                return a;
            } else if (!isNaN(b)) {
                return b;
            } else {
                return 0;
            }
        } catch {
            return 0;
        }
    };

    const _handleList = () => {
        const checkbox = document.getElementsByName('list[]');

        let arr = [];

        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                const thisRaw = sourceRaw.find((piece) => piece.id === Number(checkbox[i].value));

                arr.push({
                    id: null,
                    partListDescription: thisRaw,
                    purchaseMaterial: null,
                    unit: '',
                    quantityOfRequest: _handleQuantity(thisRaw),
                    quantityOfPurchase: _handleQuantity(thisRaw),
                    supplier: '',
                    receiver: '',
                    note: '',
                    status: null,
                });
            }
        }

        setList((list) => list.concat(arr));
        setSource('');
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    return (
        <Fragment>
            <table className='form'>
                <thead>
                    <tr>
                        <td colSpan={7}>
                            <button type='button' onClick={() => setSource('')}>
                                되돌아가기
                            </button>
                            <button className='focusButton' type='button' onClick={_handleList}>
                                선택 불러오기
                            </button>
                            <select value={parts} onChange={(e) => setParts(e.target.value)}>
                                <option value=''>전체 Parts</option>
                                {partsRaw.map((piece, index) => (
                                    <option key={index}>{piece}</option>
                                ))}
                            </select>
                            <select value={productType} onChange={(e) => setProductType(e.target.value)}>
                                <option value='all'>전체 제품군</option>
                                <option value='manufacture'>가공품</option>
                                <option value='mechanical'>기계 구매품</option>
                                <option value='electrical'>전기 구매품</option>
                                <option value='hydraulic'>유압 구매품</option>
                                <option value='pneumatic'>공압 구매품</option>
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>
                            <input type='checkbox' onClick={_handleSelectAll} />
                        </th>
                        <th width='20%'>Parts</th>
                        <th width='15%'>분류번호</th>
                        <th width='20%'>품명</th>
                        <th width='20%'>규격</th>
                        <th width='10%'>Set</th>
                        <th width='10%'>Q'TY</th>
                    </tr>
                    {sourceRaw
                        .filter((piece) => (parts !== '' ? piece.parts === parts : true))
                        .filter((piece) =>
                            productType === 'manufacture'
                                ? piece.type === 'PARTLIST'
                                    ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                      Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                        ? true
                                        : false
                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                    ? false
                                    : true
                                : productType === 'mechanical'
                                ? piece.type === 'PARTLIST'
                                    ? 200 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                      Number(piece.partsNbr.replace(/\D/g, '')) < 299
                                        ? true
                                        : false
                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                    ? false
                                    : true
                                : productType === 'electrical'
                                ? piece.type === 'PARTLIST'
                                    ? 300 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                      Number(piece.partsNbr.replace(/\D/g, '')) < 399
                                        ? true
                                        : false
                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                    ? false
                                    : true
                                : productType === 'hydraulic'
                                ? piece.type === 'PARTLIST'
                                    ? 400 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                      Number(piece.partsNbr.replace(/\D/g, '')) < 499
                                        ? true
                                        : false
                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                    ? false
                                    : true
                                : productType === 'pneumatic'
                                ? piece.type === 'PARTLIST'
                                    ? 500 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                      Number(piece.partsNbr.replace(/\D/g, '')) < 599
                                        ? true
                                        : false
                                    : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                    ? false
                                    : true
                                : productType === 'all'
                                ? true
                                : false
                        ).length !== 0 ? (
                        sourceRaw
                            .filter((piece) => (parts !== '' ? piece.parts === parts : true))
                            .filter((piece) =>
                                productType === 'manufacture'
                                    ? piece.type === 'PARTLIST'
                                        ? 0 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                          Number(piece.partsNbr.replace(/\D/g, '')) < 200
                                            ? true
                                            : false
                                        : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                        ? false
                                        : true
                                    : productType === 'mechanical'
                                    ? piece.type === 'PARTLIST'
                                        ? 200 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                          Number(piece.partsNbr.replace(/\D/g, '')) < 299
                                            ? true
                                            : false
                                        : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                        ? false
                                        : true
                                    : productType === 'electrical'
                                    ? piece.type === 'PARTLIST'
                                        ? 300 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                          Number(piece.partsNbr.replace(/\D/g, '')) < 399
                                            ? true
                                            : false
                                        : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                        ? false
                                        : true
                                    : productType === 'hydraulic'
                                    ? piece.type === 'PARTLIST'
                                        ? 400 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                          Number(piece.partsNbr.replace(/\D/g, '')) < 499
                                            ? true
                                            : false
                                        : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                        ? false
                                        : true
                                    : productType === 'pneumatic'
                                    ? piece.type === 'PARTLIST'
                                        ? 500 <= Number(piece.partsNbr.replace(/\D/g, '')) &&
                                          Number(piece.partsNbr.replace(/\D/g, '')) < 599
                                            ? true
                                            : false
                                        : 0 <= piece.bomCode.charAt(0) && piece.bomCode.charAt(0) < 5
                                        ? false
                                        : true
                                    : productType === 'all'
                                    ? true
                                    : false
                            )
                            .map((piece, index) => (
                                <tr key={index}>
                                    <td className='center-td'>
                                        <input type='checkbox' name='list[]' value={piece.id} />
                                    </td>
                                    <td>{piece.parts}</td>
                                    <td>{piece.partsNbr}</td>
                                    <td>{piece.description}</td>
                                    <td>{piece.specification}</td>
                                    <td className='center-td'>{piece.setQuantity}</td>
                                    <td className='center-td'>{piece.quantity}</td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={7}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
