import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import { POST } from '../../../../../util/data';

const Component = ({ project, setProject }) => {
    const [done, setDone] = useState(false);
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        if (!done) {
            const form = document.activeForm;

            const params = {
                type: form.type.value,
                description: form.description.value,
                specification: form.specification.value,
                lengthType: form.lengthType.value,
            };

            POST('productCode', params, (res) => {
                if (res !== null) {
                    setDone(true);
                    alert.success(res);
                }
            });
        }
    };

    return (
        <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
            <table className='viewer'>
                <tbody>
                    <tr>
                        <th width='25%'>분류코드 *</th>
                        <td width='75%'>
                            <select disabled>
                                <option>자동생성</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>대분류 *</th>
                        <td>
                            <select disabled>
                                <option>구매품</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>중분류 *</th>
                        <td>
                            <select name='type' required>
                                <option value=''>선택</option>
                                <option value='A'>공압제품(A)</option>
                                <option value='B'>유압제품(B)</option>
                                <option value='C'>베어링제품(C)</option>
                                <option value='D'>플라스틱벨트(D)</option>
                                <option value='E'>특수벨트(E)</option>
                                <option value='G'>체인/케이블베어(G)</option>
                                <option value='H'>롤러류(H)</option>
                                <option value='J'>볼트/소모품(J)</option>
                                <option value='M'>기타자재A(M)</option>
                                <option value='N'>기타자재B(N)</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>품명 *</th>
                        <td>
                            <input type='text' name='description' required />
                        </td>
                    </tr>
                    <tr>
                        <th>규격 *</th>
                        <td>
                            <input type='text' name='specification' required />
                        </td>
                    </tr>
                    <tr>
                        <th>길이 여부 *</th>
                        <td>
                            <select name='lengthType' required>
                                <option value=''>선택</option>
                                <option value='NEEDLESS'>불필요</option>
                                <option value='SELECTIVE'>선택</option>
                                <option value='ESSENTIAL'>필수</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type='submit' className='proceedButton'></button>
        </form>
    );
};

export default Component;
