import React, { useState } from 'react';

import NoticeList from './list';
import Form from './form';

import Popup from '../../../ui/popup';

const Component = () => {
    const [notice, setNotice] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setNotice({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <NoticeList notice={notice} setNotice={setNotice} />
            {notice.id > -1 ? (
                <Popup title='조회' data={<Form notice={notice} setNotice={setNotice} />} exitFunc={_handleExit} />
            ) : null}
        </div>
    );
};

export default Component;
