import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import './index.css';

const Component = () => {
    const [dateValue, setDateValue] = useState('');
    const [onToggle, setOnToggle] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        const _handleClick = (e) => {
            if (onToggle && e.target.className !== 'dateInputer-input' && e.target.type === 'date') {
                e.preventDefault();
                e.target.value = dateValue;
            }
        };

        document.addEventListener('click', _handleClick);

        return () => {
            document.removeEventListener('click', _handleClick);
        };
    });

    const _handleToggle = () => {
        if (dateValue === '') {
            alert.error('날짜를 먼저 선택하여 주십시오.');
            document.querySelector('.dateInputer-input').focus();
        } else {
            if (onToggle) {
                setDateValue('');
            } else {
                alert.info('클릭하여 날짜를 입력할 수 있습니다.');
            }
            setOnToggle((onToggle) => !onToggle);
        }
    };

    return (
        <div className='dateInputer'>
            <input
                className='dateInputer-input'
                type='date'
                value={dateValue}
                onChange={(e) => setDateValue(e.target.value)}
            />
            <div
                className={onToggle ? 'dateInputer-toggle-box dateInputer-toggle-box-active' : 'dateInputer-toggle-box'}
                onClick={_handleToggle}
            >
                <div className={onToggle ? 'dateInputer-toggle dateInputer-toggle-active' : 'dateInputer-toggle'}></div>
            </div>
        </div>
    );
};

export default Component;
