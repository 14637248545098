import React from 'react';

import FI from 'feather-icons-react';

import './index.css';

const Component = ({ title, exitFunc }) => {
    return (
        <div className='fullscreenHeader'>
            <div className='fullscreenHeader-title'>{title}</div>
            <FI className='fullscreenHeader-exit' icon='x' onClick={exitFunc} />
        </div>
    );
};

export default Component;
