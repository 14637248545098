import React from 'react';

const Component = () => {
    return (
        <div className='container'>
            <div className='centralize'>해당 페이지는 업데이트 중입니다.</div>
        </div>
    );
};

export default Component;
