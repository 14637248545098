export const user_data = (value) => {
    return {
        type: 'USER_DATA',
        payload: {
            id: value.id,
            username: value.username,
            name: value.name,
            admin: value.admin,
            authority: value.authority
        },
    };
};
