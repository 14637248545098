import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../ui/downloader/FileDownloader';
import DateInputer from '../../../ui/inputer/DateInputer';

import { PUT } from '../../../../util/data';

const Component = ({ project }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        let arr = [];

        if (project.items.length === 1) {
            arr[0] = {
                id: project.items[0].assemblyOrder.id,
                dateOfStart: form.dateOfStart.value,
                dateOfFinish: form.dateOfFinish.value,
                status: form.status.value,
            };
        } else {
            for (let i = 0; i < project.items.length; i++) {
                arr.push({
                    id: project.items[i].assemblyOrder.id,
                    dateOfStart: form.dateOfStart[i].value,
                    dateOfFinish: form.dateOfFinish[i].value,
                    status: form.status[i].value,
                });
            }
        }

        PUT('assembly/order', arr, (res) => {
            alert.success(res);
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={project} showItemList={false} />
            <FileDownloader project={project} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form form-lining'>
                    <thead>
                        <tr>
                            <td colSpan={8}>
                                <DateInputer />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width='5%'>No.</th>
                            <th width='30%'>아이템명</th>
                            <th width='20%'>규격</th>
                            <th width='10%'>단위</th>
                            <th width='5%'>수량</th>
                            <th width='10%'>시작지시일</th>
                            <th width='10%'>완료지시일</th>
                            <th width='10%'>상태</th>
                        </tr>
                        {project.items.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>{piece.nbr}</td>
                                <td>{piece.itemFamily !== null ? piece.itemFamily.name : piece.name}</td>
                                <td>{piece.specification}</td>
                                <td className='center-td'>{piece.unit}</td>
                                <td className='center-td'>{piece.quantity}</td>
                                <td>
                                    <input
                                        type='date'
                                        name='dateOfStart'
                                        defaultValue={piece.assemblyOrder.dateOfStart}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='date'
                                        name='dateOfFinish'
                                        defaultValue={piece.assemblyOrder.dateOfFinish}
                                    />
                                </td>
                                <td>
                                    <select name='status' defaultValue={piece.assemblyOrder.status}>
                                        <option value='NORMAL'>작업필요</option>
                                        <option value='UNNECESSARY'>작업불필요</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
