import React, { useState } from 'react';

import Form from './form';

import AfterServiceSelector from '../../../ui/selector/AfterServiceSelector';
import Popup from '../../../ui/popup';

const Component = () => {
    const [afterService, setAfterService] = useState({
        id: -2,
    });

    const _handleExit = () => {
        setAfterService({
            id: -2,
        });
    };

    return (
        <div className='container'>
            <AfterServiceSelector
                afterService={afterService}
                setAfterService={setAfterService}
                isAfterServiceManager={false}
            />
            {afterService.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form afterService={afterService} setAfterService={setAfterService} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
