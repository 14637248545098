import React, { Fragment, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import uuid from 'react-uuid';

import FileDownloader from '../../downloader/FileDownloader';

import { GET, PUT } from '../../../../util/data';

const Component = ({ project, setProject, setItem, handleSlot, showProject, isSimpleProject, _handleStatusByItem }) => {
    const alert = useAlert();
    const uid = 'selector-' + uuid();

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        if (!isSimpleProject) {
            const textarea = document.querySelector(`.${uid}`);
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }, [isSimpleProject, uid]);

    const _handleSetItem = (piece) => {
        setItem(piece);
    };

    const _handleResetProject = () => {
        setProject({
            id: -2,
        });
    };

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleCheck = () => {
        const checkbox = document.getElementsByName('list[]');

        let arr = [];

        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push({
                    id: checkbox[i].value,
                });
            }
        }

        PUT('manufacture/laser/all', arr, (res1) => {
            GET('project', { id: project.id }, (res2) => {
                res2.items.sort((a, b) => {
                    try {
                        return (
                            Number(a.nbr.replace('-', '.').replace(/\D\./g, '')) -
                            Number(b.nbr.replace('-', '.').replace(/\D\./g, ''))
                        );
                    } catch {
                        return 1;
                    }
                });
                setProject(res2);
            });
            alert.success(res1);
        });
    };

    return (
        <Fragment>
            {showProject ? (
                <Fragment>
                    <table className='viewer'>
                        <tbody>
                            <tr>
                                <th colSpan={4}>프로젝트정보</th>
                            </tr>
                            <tr>
                                <th width='15%'>구분</th>
                                <td width='35%' className='center-td'>
                                    {project.type === 'PRODUCT'
                                        ? '완성품'
                                        : project.type === 'PART'
                                        ? '부품'
                                        : project.type === 'AS'
                                        ? ' A/S'
                                        : '미등록 프로젝트'}
                                </td>
                                <th width='15%'>지시번호</th>
                                <td width='35%' className='center-td'>
                                    {project.code}
                                </td>
                            </tr>
                            <tr>
                                <th>프로젝트명</th>
                                <td className='center-td'>{project.name}</td>
                                <th>납품처</th>
                                <td className='center-td'>{project.customer}</td>
                            </tr>
                            <tr>
                                <th>오더번호</th>
                                <td className='center-td'>{project.orderNbr !== undefined ? project.orderNbr : ''}</td>
                                <th>전기사양</th>
                                <td className='center-td'>
                                    {project.electricity !== undefined ? project.electricity : ''}
                                </td>
                            </tr>
                            <tr>
                                <th>PLC Type</th>
                                <td className='center-td'>{project.plc !== undefined ? project.plc : ''}</td>
                                <th>색상</th>
                                <td className='center-td'>{project.color !== undefined ? project.color : ''}</td>
                            </tr>
                            <tr>
                                <th>지시일자</th>
                                <td className='center-td'>
                                    {project.dateOfOrder !== undefined ? project.dateOfOrder : ''}
                                </td>
                                <th>납품일자</th>
                                <td className='center-td'>
                                    {project.dateOfDue !== undefined ? project.dateOfDue : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {!isSimpleProject ? (
                        <Fragment>
                            <table className='viewer'>
                                <tbody>
                                    <tr>
                                        <th>세부작업 지시사항</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                className={uid}
                                                name='note'
                                                defaultValue={project.note}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <FileDownloader project={project} />
                        </Fragment>
                    ) : null}
                </Fragment>
            ) : null}
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={5}>
                            <button className='focusButton' type='button' onClick={_handleResetProject}>
                                프로젝트 재선택
                            </button>
                            <button type='button' onClick={_handleCheck}>
                                일괄 작업불필요 처리
                            </button>
                            <div className='division'>|</div>
                            <input
                                className='keyword'
                                type='text'
                                placeholder='검색어'
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>
                            <input type='checkbox' onChange={_handleSelectAll} />
                        </th>
                        <th width='5%'>No.</th>
                        <th width='30%'>아이템명</th>
                        <th width='25%'>규격</th>
                        <th width='10%'>단위</th>
                        <th width='10%'>수량</th>
                        <th width='20%'>비고</th>
                    </tr>
                    {project.items.length !== 0 ? (
                        project.items
                            .filter((piece) =>
                                piece.itemFamily !== null
                                    ? piece.itemFamily.name.toLowerCase().match(keyword.toLowerCase())
                                    : piece.name.toLowerCase().match(keyword.toLowerCase())
                            )
                            .map((piece, index) => (
                                <tr className={handleSlot(piece) ? '' : 'closed-tr'} key={index}>
                                    <td className='center-td'>
                                        <input type='checkbox' name='list[]' value={piece.manufactureLaser.id} />
                                    </td>
                                    <td
                                        className='center-td'
                                        onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}
                                    >
                                        {piece.nbr}
                                    </td>
                                    <td onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}>
                                        {_handleStatusByItem !== undefined ? (
                                            _handleStatusByItem(piece) === 0 ? (
                                                <span className='done-project'>[입력완료]</span>
                                            ) : (
                                                <span className='current-project'>[미완료]</span>
                                            )
                                        ) : null}{' '}
                                        {piece.itemFamily !== null ? piece.itemFamily.name : piece.name}
                                    </td>
                                    <td onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}>
                                        {piece.specification}
                                    </td>
                                    <td
                                        className='center-td'
                                        onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}
                                    >
                                        {piece.unit}
                                    </td>
                                    <td
                                        className='center-td'
                                        onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}
                                    >
                                        {piece.quantity}
                                    </td>
                                    <td onClick={handleSlot(piece) ? () => _handleSetItem(piece) : null}>
                                        {piece.note.length > 30 ? piece.note.substr(0, 30) + '...' : piece.note}
                                    </td>
                                </tr>
                            ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={6}>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default Component;
