import React, { Fragment, useState } from 'react';

import Form from './form';

import ProjectSelector from '../../../ui/selector/ProjectSelector';
import ItemSelector from '../../../ui/selector/ItemSelector';
import Popup from '../../../ui/popup';

const Component = () => {
    const [project, setProject] = useState({
        id: -2,
    });
    const [item, setItem] = useState({
        id: -2,
    });

    const handleSlot = (piece) => {
        if (piece.assemblyOrder.status === 'UNNECESSARY') return false;
        else return true;
    };

    const _handleExit = () => {
        setItem({
            id: -2,
        });
    };

    const _handleStatusByProject = (piece) => {
        let res = 0;

        for (let i = 0; i < piece.items.length; i++) {
            if (
                piece.items[i].assemblyOrder.status !== 'UNNECESSARY' &&
                (piece.items[i].assemblyWork.dateOfFinish === null || piece.items[i].assemblyWork.dateOfFinish === '')
            ) {
                res++;
                break;
            }
        }

        return res;
    };

    const _handleStatusByItem = (piece) => {
        let res = 0;

        if (
            piece.assemblyOrder.status !== 'UNNECESSARY' &&
            (piece.assemblyWork.dateOfFinish === null || piece.assemblyWork.dateOfFinish === '')
        ) {
            res++;
        }

        return res;
    };

    return (
        <div className='container'>
            <ProjectSelector
                project={project}
                setProject={setProject}
                setItem={setItem}
                isProjectManager={false}
                isItemSearch={true}
                _handleStatusByProject={_handleStatusByProject}
                defaultType='PRODUCT'
            />
            {project.id === -2 ? (
                <Fragment></Fragment>
            ) : (
                <ItemSelector
                    project={project}
                    setProject={setProject}
                    setItem={setItem}
                    handleSlot={handleSlot}
                    showProject={true}
                    isSimpleProject={false}
                    _handleStatusByItem={_handleStatusByItem}
                    isAssembly={true}
                />
            )}
            {item.id > -1 ? (
                <Popup
                    title='조회 및 업데이트'
                    data={<Form project={project} setProject={setProject} item={item} setItem={setItem} />}
                    exitFunc={_handleExit}
                    hasButton={true}
                />
            ) : null}
        </div>
    );
};

export default Component;
