import React, { useState, useEffect, Fragment } from 'react';

import { formType, projectType } from '../../../../configs';

import { GET } from '../../../../util/data';

const Component = ({ classify, status, items, setItems }) => {
    const [productFamilies, setProductFamilies] = useState([]);
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        if (status === formType.REGISTER) {
            let arr = Array(10);

            for (let i = 0; i < arr.length; i++) {
                arr[i] = {
                    id: '',
                    nbr: '',
                    productFamily: '',
                    itemFamily: '',
                    name: '',
                    specification: '',
                    unit: '',
                    quantity: '',
                    note: '',
                    status: 'NORMAL',
                };
            }

            setItems(arr);
        }
    }, [status, setItems]);

    useEffect(() => {
        GET('productFamily/all', {}, (res) => {
            if (active && res !== null) {
                setProductFamilies(res);
            }
        });
    }, [active]);

    const _handleData = (index, identifier, value) => {
        setItems((data) => {
            let changedData = [...data];
            changedData[index][identifier] = value;
            return changedData;
        });
    };

    const _handleSize = () => {
        setItems((data) => {
            let arr = Array(10);

            for (let i = 0; i < arr.length; i++) {
                arr[i] = {
                    id: '',
                    nbr: '',
                    productFamily: '',
                    itemFamily: '',
                    name: '',
                    specification: '',
                    unit: '',
                    quantity: '',
                    note: '',
                    status: 'NORMAL',
                };
            }

            let changedData = [...data];
            changedData = changedData.concat(arr);
            return changedData;
        });
    };

    return (
        <table className='viewer'>
            <tbody>
                <tr>
                    <th width='5%'>NO</th>
                    {classify === projectType.PRODUCT ? <th width='20%'>제품군</th> : null}
                    <th width={classify === projectType.PRODUCT ? '20%' : '40%'}>아이템명</th>
                    <th width='15%'>규격</th>
                    <th width='7.5%'>단위</th>
                    <th width='7.5%'>수량</th>
                    <th width='15%'>비고</th>
                    <th width='10%'>상태</th>
                </tr>
                {productFamilies.length !== 0 ? (
                    items.length !== 0 ? (
                        items.map((piece, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type='text'
                                        value={piece.nbr}
                                        onChange={(e) => _handleData(index, 'nbr', e.target.value)}
                                    />
                                </td>
                                {classify === projectType.PRODUCT ? (
                                    <td>
                                        <select
                                            value={piece.productFamily}
                                            onChange={(e) => _handleData(index, 'productFamily', e.target.value)}
                                        >
                                            <option value=''>선택</option>
                                            {productFamilies.map((piece, idx) => (
                                                <option value={piece.id} key={idx}>
                                                    {piece.name}
                                                </option>
                                            ))}
                                            <option value='typing'>직접입력</option>
                                        </select>
                                    </td>
                                ) : null}
                                {classify === projectType.PRODUCT ? (
                                    <td>
                                        {piece.productFamily === 'typing' ? (
                                            <input
                                                type='text'
                                                value={piece.name}
                                                onChange={(e) => _handleData(index, 'name', e.target.value)}
                                                placeholder='직접입력'
                                            />
                                        ) : (
                                            <select
                                                value={piece.itemFamily}
                                                onChange={(e) => _handleData(index, 'itemFamily', e.target.value)}
                                            >
                                                <option value=''>선택</option>
                                                {piece.productFamily !== '' ? (
                                                    <Fragment>
                                                        {productFamilies
                                                            .find(
                                                                (element) => element.id === Number(piece.productFamily)
                                                            )
                                                            .itemFamilies.map((element, idx) => (
                                                                <option value={element.id} key={idx}>
                                                                    {element.name}
                                                                </option>
                                                            ))}
                                                    </Fragment>
                                                ) : null}
                                            </select>
                                        )}
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            type='text'
                                            value={piece.name}
                                            onChange={(e) => _handleData(index, 'name', e.target.value)}
                                        />
                                    </td>
                                )}
                                <td>
                                    <input
                                        type='text'
                                        value={piece.specification}
                                        onChange={(e) => _handleData(index, 'specification', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        value={piece.unit}
                                        onChange={(e) => _handleData(index, 'unit', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        step='0.001'
                                        value={piece.quantity}
                                        onChange={(e) => _handleData(index, 'quantity', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        value={piece.note}
                                        onChange={(e) => _handleData(index, 'note', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        value={piece.status}
                                        onChange={(e) => _handleData(index, 'status', e.target.value)}
                                    >
                                        <option value='NORMAL'>일반</option>
                                        <option value='EMERGENCY'>긴급</option>
                                        <option value='SUSPENSION'>보류</option>
                                        <option value='CANCEL'>취소</option>
                                        <option value='DELETE'>삭제</option>
                                    </select>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className='center-td' colSpan={8}>
                                아이템 정보가 없습니다.
                            </td>
                        </tr>
                    )
                ) : null}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={classify === projectType.PRODUCT ? 8 : 7}>
                        <button type='button' onClick={_handleSize}>
                            아이템창 추가하기
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default Component;
