import React from 'react';
import { useDispatch } from 'react-redux';
import { user_data } from '../../../actions';
import { useAlert } from 'react-alert';

import { info } from '../../../configs';

import { AUTH_OUT } from '../../../util/data';

import CI from '../../../assets/images/ci.png';

import FI from 'feather-icons-react';

import './index.css';

const Component = () => {
    const dispatch = useDispatch();
    const alert = useAlert();

    const _handleLogout = () => {
        AUTH_OUT((res) => {
            if (res !== false) {
                alert.success('로그아웃 되었습니다.');
                dispatch(
                    user_data({
                        id: -1,
                        username: '',
                        name: '',
                        admin: false,
                    })
                );
            }
        });
    };

    return (
        <header className='pop-header'>
            <img className='pop-header-icon-left' src={CI} alt='ci' />
            <div className='pop-header-title'>{info.title} POS</div>
            <FI className='pop-header-icon-right' icon='x' onClick={_handleLogout} />
        </header>
    );
};

export default Component;
