import React, { Fragment } from 'react';
import { useAlert } from 'react-alert';

import ProjectViewer from '../../../../ui/viewer/ProjectViewer';
import FileDownloader from '../../../../ui/downloader/FileDownloader';
import ItemViewer from '../ItemViewer';

import { PUT } from '../../../../../util/data';

const Component = ({ piece }) => {
    const alert = useAlert();

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeForm;

        const data = {
            id: piece.item.designWork.id,
            dateOfReceipt: piece.item.designWork.dateOfReceipt,
            dateOfStart: piece.item.designWork.dateOfStart,
            progress: piece.item.designWork.progress,
            dateOfSchedule: piece.item.designWork.dateOfSchedule,
            dateOfFinish: piece.item.designWork.dateOfFinish,
            dateOfCheck: form.dateOfCheck.value,
            serialNbr: piece.item.designWork.serialNbr,
            note: piece.item.designWork.note,
        };

        PUT('design/work', data, (res) => {
            if (res !== null) {
                alert.success(res);
            }
        });
    };

    return (
        <Fragment>
            <ProjectViewer project={piece.project} showItemList={false} />
            <FileDownloader project={piece.project} />
            <ItemViewer item={piece.item} />
            <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
                <table className='form'>
                    <tbody>
                        <tr>
                            <th width='14.28%'>설계자료접수일</th>
                            <th width='14.28%'>설계시작일</th>
                            <th width='14.28%'>작업진도</th>
                            <th width='14.28%'>설계완료예정일</th>
                            <th width='14.28%'>설계완료일</th>
                            <th width='14.28%'>검도완료일</th>
                            <th width='14.28%'>Serial No.</th>
                        </tr>
                        <tr>
                            <td className='center-td'>{piece.item.designWork.dateOfReceipt}</td>
                            <td className='center-td'>{piece.item.designWork.dateOfStart}</td>
                            <td className='center-td'>{piece.item.designWork.progress}</td>
                            <td className='center-td'>{piece.item.designWork.dateOfSchedule}</td>
                            <td className='center-td'>{piece.item.designWork.dateOfFinish}</td>
                            <td>
                                <input
                                    type='date'
                                    name='dateOfCheck'
                                    defaultValue={piece.item.designWork.dateOfCheck}
                                />
                            </td>
                            <td className='center-td'>{piece.item.designWork.serialNbr}</td>
                        </tr>
                        <tr>
                            <th colSpan={7}>이슈사항</th>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <textarea
                                    className='wide-textarea'
                                    name='note'
                                    defaultValue={piece.item.designWork.note}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type='submit' className='proceedButton'></button>
            </form>
        </Fragment>
    );
};

export default Component;
