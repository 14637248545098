import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import FileUploader from '../../../ui/uploader/FileUploader';

import { POST, PUT } from '../../../../util/data';

const Component = ({ notice, setNotice }) => {
    const [done, setDone] = useState(false);

    const alert = useAlert();
    const [attachments, setAttachments] = useState(notice.id === -1 ? [] : [...notice.attachments]);

    const _handleForm = (e) => {
        e.preventDefault();

        if (notice.id > -1 || (notice.id === -1 && !done)) {
            const func = notice.id === -1 ? POST : PUT;

            const form = document.activeForm;

            const params = {
                title: form.title.value,
                content: form.content.value,
                attachments: attachments,
            };

            if (notice.id !== -1) {
                params.id = notice.id;
            }

            setDone(true);

            func('notice', params, (res) => {
                if (res !== null) {
                    alert.success(res);
                }
            });
        }
    };

    return (
        <form name='activeForm' onSubmit={_handleForm} autoComplete='off'>
            <table className='form'>
                <tbody>
                    <tr>
                        <th width='10%'>제목</th>
                        <td width='90%'>
                            <input
                                type='text'
                                name='title'
                                defaultValue={notice.id > -1 ? notice.title : ''}
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>내용</th>
                        <td>
                            <textarea
                                className='x-wide-textarea'
                                name='content'
                                defaultValue={notice.id > -1 ? notice.content : ''}
                                required
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <FileUploader attachments={attachments} setAttachments={setAttachments} />
            <button type='submit' className='proceedButton'></button>
        </form>
    );
};

export default Component;
