import React from 'react';

import { useLocation } from 'react-router-dom';

import { menu } from '../../../../configs';

import './index.css';

const Component = () => {
    const location = useLocation();

    const thisMenu = menu.find((piece) => piece.path.split('/')[1] === location.pathname.split('/')[1]);

    const subMenu = thisMenu !== undefined ? thisMenu.sub.find((piece) => piece.path === location.pathname) : undefined;

    return (
        <div className='pageHeader'>
            {thisMenu !== undefined && subMenu !== undefined ? `${thisMenu.title} - ${subMenu.title}` : ''}
        </div>
    );
};

export default Component;
