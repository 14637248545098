import React, { useEffect, useState } from 'react';

import { GET, PUT } from '../../../../../util/data';

const Component = () => {
    const [purchaseAlerts, setPurchaseAlerts] = useState([]);
    const [detector, setDetector] = useState(false);

    useEffect(() => {
        GET('purchase/alert', {}, (res) => {
            setPurchaseAlerts(res);
            console.log(res);
        });
    }, [detector]);

    const _handleSelectAll = (e) => {
        const list = document.getElementsByName('list[]');

        for (let i = 0; i < list.length; i++) {
            list[i].checked = e.target.checked;
        }
    };

    const _handleProceed = () => {
        const checkbox = document.getElementsByName('list[]');

        let arr = [];

        for (let i = 0; i < checkbox.length; i++) {
            if (checkbox[i].checked === true) {
                arr.push(checkbox[i].value);
                checkbox[i].checked = false;
            }
        }

        if (arr.length > 0) {
            PUT('purchase/alert', { ids: arr.toString() }, (res) => {
                if (res !== null) {
                    setDetector((detector) => (detector ? false : true));
                }
            });
        }
    };

    return (
        <div className='container'>
            <table className='selector'>
                <thead>
                    <tr>
                        <td colSpan={4}>
                            <button onClick={_handleProceed}>선택 확인</button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th width='5%'>
                            <input type='checkbox' onClick={_handleSelectAll} />
                        </th>
                        <th width='15%'>구분</th>
                        <th width='40%'>프로젝트</th>
                        <th width='40%'>아이템</th>
                    </tr>
                    {purchaseAlerts.length !== 0 ? (
                        purchaseAlerts.map((piece, index) => (
                            <tr key={index}>
                                <td className='center-td'>
                                    <input type='checkbox' name='list[]' value={piece.id} />
                                </td>
                                <td className='center-td'>
                                    {piece.purchaseAlert.partList === null ? '구매의뢰' : '파트리스트/BOM'}
                                </td>
                                <td>
                                    {piece.code} ({piece.name})
                                </td>
                                <td>{piece.item}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className='center-td'>
                                조회된 데이터가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Component;
