import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { GET, PUT } from '../../../../util/data';

const Component = () => {
    const [raw, setRaw] = useState([]);
    const [detector, setDetector] = useState(false);
    const [active, setActive] = useState(true);
    const alert = useAlert();

    useEffect(() => {
        return () => {
            setActive(false);
        };
    }, []);

    useEffect(() => {
        GET('user/all', {}, (res) => {
            if (active && res !== null) {
                setRaw(res.filter((piece) => piece.authority.designWork && !piece.resign));
            }
        });
    }, [active, detector]);

    const _handleForm = (e) => {
        e.preventDefault();

        const form = document.activeListForm;

        let arr = [];

        if (raw.length === 0) {
            arr[0] = {
                id: raw[0].designTeam.id,
                team: form.team.value,
                manager: form.manager.checked,
            };
        } else {
            for (let i = 0; i < raw.length; i++) {
                arr.push({
                    id: raw[i].designTeam.id,
                    team: form.team[i].value,
                    manager: form.manager[i].checked,
                });
            }
        }

        PUT('design/team', arr, (res) => {
            if (res !== null) {
                setDetector((detector) => !detector);
                alert.success(res);
            }
        });
    };

    return (
        <form name='activeListForm' onSubmit={_handleForm} autoComplete='off'>
            <table className='form'>
                <tbody>
                    <tr>
                        <th width='25%'>아이디</th>
                        <th width='25%'>사용자명</th>
                        <th width='25%'>설계팀</th>
                        <th width='25%'>관리자</th>
                    </tr>
                    {raw.map((piece, index) => (
                        <tr key={index}>
                            <td className='center-td'>{piece.username}</td>
                            <td className='center-td'>{piece.name}</td>
                            <td>
                                <select name='team' defaultValue={piece.designTeam.team}>
                                    <option value='NONE'>미지정</option>
                                    <option value='ONE'>1팀</option>
                                    <option value='TWO'>2팀</option>
                                    <option value='THREE'>3팀</option>
                                    <option value='RESEARCH'>R&D</option>
                                </select>
                            </td>
                            <td className='center-td'>
                                <input type='checkbox' name='manager' defaultChecked={piece.designTeam.manager} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button className='submitButton'>저장</button>
        </form>
    );
};

export default Component;
